import React, { useEffect, useState } from 'react'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'
import { useScheduler } from '../../contexts/scheduler'
import DropdownRadio from '../DropdownRadio'
import JFJobCard from '../JFJobCard'
import JFJobCardFlashing from '../JFJobCardFlashing'

const JobFlashingsPane = () => {
  const { selectedScheduler } = useScheduler()
  const { location: { jobs: allJobs, service: locationService } } = selectedScheduler
  const { flashingJobs } = useSchedulerCalendar()
  const [JFDisplay, setJFDisplay] = useState('jobs')
  const [query, setQuery] = useState('')
  const [sortBy, setSortBy] = useState('submittedAt')
  const [visibleJobs, setVisibleJobs] = useState(allJobs)
  const [visibleFlashingJobs, setVisibleFlashingJobs] = useState(flashingJobs)

  const sort = (jobs) => {
    if (sortBy === 'submit-date') {
      jobs.sort((a, b) => {
        if (b.submittedAt > a.submittedAt) { return 1; }
        if (b.submittedAt < a.submittedAt) { return -1; }
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) { return 1; }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) { return -1; }
        return 0;
      })
    } else if (sortBy === 'request-date') {
      jobs.sort(function (a, b) {
        if (b.requestedDate > a.requestedDate) { return 1; }
        if (b.requestedDate < a.requestedDate) { return -1; }
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) { return 1; }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) { return -1; }
        return 0;
      });
    } else if (sortBy === 'job-name') {
      jobs.sort(function (a, b) {
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) { return 1; }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) { return -1; }
        if (a.owner.customer.bizName.toLowerCase() > b.owner.customer.bizName.toLowerCase()) { return 1; }
        if (a.owner.customer.bizName.toLowerCase() < b.owner.customer.bizName.toLowerCase()) { return -1; }
        return 0;
      });
    } else if (sortBy === 'builder-name') {
      jobs.sort(function (a, b) {
        if (a.owner.customer.bizName.toLowerCase() > b.owner.customer.bizName.toLowerCase()) { return 1; }
        if (a.owner.customer.bizName.toLowerCase() < b.owner.customer.bizName.toLowerCase()) { return -1; }
        if (a.jobName.toLowerCase() > b.jobName.toLowerCase()) { return 1; }
        if (a.jobName.toLowerCase() < b.jobName.toLowerCase()) { return -1; }
        return 0;
      });
    }
    return jobs
  }

  useEffect(() => {
    const newFlashingJobs = flashingJobs
    const unScheduledJobs = newFlashingJobs.filter(job => !job.scheduledDate && !job.archivedAt)
    setVisibleFlashingJobs(unScheduledJobs)
  }, [flashingJobs, query, sortBy])

  useEffect(() => {
    if (Array.isArray(allJobs)) {
      let typeSpecificJobs = allJobs.filter(job => job.jobType === locationService.toLowerCase())
      let submittedJobs = typeSpecificJobs.filter(job => job.readyAt !== null && job.scheduledAt === null && job.archivedAt === null).sort((a, b) => new Date(b.requestedDate) - new Date(a.requestedDate))
      if (query !== '') {
        submittedJobs = submittedJobs.filter(job => {
          return (
            job.jobName.toLowerCase().includes(query.toLowerCase())
            || job.address.toLowerCase().includes(query.toLowerCase())
            || job.owner.customer.bizName.toLowerCase().includes(query.toLowerCase()))
        })
      }

      const sortedJobs = sort(submittedJobs)
      setVisibleJobs(sortedJobs)
    }
  }, [allJobs, query, sortBy])

  return (
    <div className='flex flex-col w-full h-full lg:w-1/4 p-2 min-h-[672px] bg-navy-bright/10 lg:border-r lg:border-navy-bright/30'>
      <div className='flex w-full'>
        {selectedScheduler.location.service === 'GUNITE'
          ?
          <>
            <button
              className={`w-full px-4 py-2 ${JFDisplay === 'jobs' ? 'bg-white rounded-t-lg border-solid border-x border-t border-black/30' : ''} `}
              onClick={() => setJFDisplay('jobs')}>
              <p className='text-sm font-semibold text-dark-blue'>Jobs</p>
            </button>
            <button
              className={`w-full px-4 py-2 ${JFDisplay === 'flashes' ? 'bg-white rounded-t-lg border-solid border-x border-t border-black/30' : ''} `}
              onClick={() => setJFDisplay('flashes')}>
              <p className='text-sm font-semibold text-dark-blue'>Flashes</p>
            </button>
          </>
          :
          null
        }
      </div>
      <div className={`flex flex-col gap-2 h-full overflow-hidden p-2 bg-white rounded-b-lg ${JFDisplay === 'jobs' ? "rounded-tr-lg" : "rounded-tl-lg"}`}>
        <div className='relative flex gap-2 items-center pr-2'>
          <div className='flex w-full items-center border rounded'>
            <img src='/PlannerColumn/search_icon.svg'
              alt='search'
              className='py-[2px] px-2'
            />
            <input
              className='h-8 w-full rounded border-none'
              placeholder='Search'
              value={query}
              onChange={(e) => {
                setQuery(e.target.value)
              }}
            />
          </div>
          <DropdownRadio sortBy={sortBy} setSortBy={setSortBy} i={1} />
        </div>
        <div className='flex flex-col gap-2 h-full overflow-y-auto droppable FDroppable' data-jobsidepane='jobSidePane'> {/*Note this div is a droppable zone for both regular jobs and flashing jobs all the time*/}
          {JFDisplay === 'jobs'
            ?
            visibleJobs.map((job, i) => {
              return (
                <JFJobCard key={`job${i}`} job={job} />
              )
            })
            :
            visibleFlashingJobs.map((job, i) => {
              return (
                <JFJobCardFlashing key={`jfjcf${i}`} job={job} />
              )
            })}

        </div>
      </div>
    </div>
  )
}

export default JobFlashingsPane
