import React from "react";
import './index.scss';
import { useResponsive } from '../../contexts/responsive';

const Footer = () => {
  const { responsive, isXXL, setIsXXL } = useResponsive();
  return (
    <div className='footer-container sm:mt-[0px] md:-mt-[0px] lg:mt-[0px] px-20 py-6 flex flex-col items-center 
      sm:flex-row sm:justify-between text-white bg-dark-blue'>
      <div className="flex w-auto">
        {/* <a className="text-sm sm:text-xl mr-6">FAQ</a>
        <a className="text-sm sm:text-xl sm:pr-[0%] md:pr-[20%] ml-6">Support</a> */}
      </div>
      <p className="w-auto text-sm lg:text-xl">© US Gunite 2023</p>
    </div>
  )
}

export default Footer;
