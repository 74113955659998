import React, { useState, useEffect } from "react";

const DEFAULT_SUMMARY = {
  invoice: {
    total: 0,
  },
  labor: {
    total: 0,
    percent: 0
  },
  material: {
    gunite: {
      total: 0,
      percent: 0
    },
    plaster: {
      total: 0,
      percent: 0
    }
  },
  fuel: {
    total: 0,
    percent: 0
  },
  insurance: {
    price: 0,
    percent: 0
  },
  totalCost: {
    total: 0,
    percent: 0
  },
  profit: {
    gross: 0,
    percent: 0
  }
}

const CostBreakdownSummary = ({ breakdown, plaster, savedCostBreakdown }) => {
  const [summary, setSummary] = useState(DEFAULT_SUMMARY)

  const updateSummary = () => {
    const invoiceTotal = breakdown.invoice.total || 0

    const discountPercent = breakdown.costBreakdown.discountPercent || 0
    const discountAmount = (discountPercent / 100) * invoiceTotal
    const totalRevenue = invoiceTotal - discountAmount

    const crewTotal = breakdown.crew.total.withTax || 0
    const driversTotal = breakdown.drivers.total.withTax || 0
    const labor = (crewTotal + driversTotal)
    const laborPercent = invoiceTotal ? (labor / invoiceTotal) * 100 : 0

    const materialTotalGunite = breakdown.material.total || 0
    const materialPercentGunite = invoiceTotal ? (materialTotalGunite / invoiceTotal) * 100 : 0

    const materialTotalPlaster = breakdown.material.total ? (breakdown.material.total * breakdown.costBreakdown.batches) : 0
    const materialPercentPlaster = invoiceTotal ? (materialTotalPlaster / invoiceTotal) * 100 : 0


    const fuelTotal = breakdown.fuel.total || 0
    const fuelPercent = invoiceTotal ? (fuelTotal / invoiceTotal) * 100 : 0

    const insurance = breakdown.costBreakdown.insurance || 0
    const insurancePercent = invoiceTotal ? (insurance / invoiceTotal) * 100 : 0
    const totalCost = labor + (plaster ? materialTotalPlaster : materialTotalGunite) + fuelTotal + insurance
    const totalPercent = invoiceTotal ? (totalCost / invoiceTotal) * 100 : 0

    const grossProfit = totalRevenue - totalCost
    const profitPercent = invoiceTotal ? (grossProfit / invoiceTotal) * 100 : 0



    setSummary({
      invoice: {
        total: invoiceTotal,
        discountAmount: discountAmount,
        totalRevenue: totalRevenue
      },
      labor: {
        total: labor,
        percent: laborPercent
      },
      material: {
        gunite: {
          total: materialTotalGunite,
          percent: materialPercentGunite
        },
        plaster: {
          total: materialTotalPlaster,
          percent: materialPercentPlaster
        }
      },
      fuel: {
        total: fuelTotal,
        percent: fuelPercent
      },
      insurance: {
        price: insurance,
        percent: insurancePercent
      },
      totalCost: {
        total: totalCost,
        percent: totalPercent
      },
      profit: {
        gross: grossProfit,
        percent: profitPercent
      }
    })
  }

  useEffect(() => {
    // if any totals change, update summary
    updateSummary()
  }, [breakdown, savedCostBreakdown])

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>
      <p className='text-2xl font-semibold text-dark-blue pb-4'>
        Summary
      </p>

      <div className='flex flex-col md:flex-row w-full justify-between gap-8'>
        <div className='flex flex-col md:w-1/2 justify-between'>
          <div>
            <p className='text-l font-semibold text-dark-blue'>
              Revenue
            </p>

            <div className='flex flex-col w-full p-2 justify-center align-center'>

              <div className='flex gap-4 text-dark-blue border-b py-2'>
                <p className='w-2/5'>Invoice</p>
                <div className='flex w-3/5 justify-end'>
                  <p>$</p>
                  <p>{Number(summary.invoice.total).toFixed(2)}</p>
                </div>
              </div>

              {Number(breakdown.costBreakdown.discountPercent) > 0 ? (
                <div className='flex gap-4 text-gunite-red border-b py-2'>
                  <p className='w-2/5'>Discount: {breakdown.costBreakdown.discountPercent}%</p>
                  <div className='flex w-3/5 justify-end'>
                    <p>-$</p>
                    <p>{Number(summary.invoice.discountAmount).toFixed(2)}</p>
                  </div>
                </div>
              ) : <></> }

            </div>
          </div>

          <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 mb-4 gap-2'>
            <div className='flex w-full justify-between text-dark-blue font-semibold'>
              <p className='w-fit pr-8'>
                Total Revenue
              </p>
              <div className='flex justify-end w-[180px]'>
                <p>$</p>
                <p>{Number(summary.invoice.totalRevenue).toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='md:w-1/2'>
          <p className='text-l font-semibold text-dark-blue'>
            Costs
          </p>

          <div className='flex flex-col w-full p-3 justify-center align-center'>

            <div className='flex gap-4 text-dark-blue py-2 border-b'>
              <p className='w-2/5'>Labor</p>
              <div className='flex w-3/5 justify-between'>
                <div className='flex w-3/5 justify-between text-gunite-red'>
                  <p>$</p>
                  <p>{summary.labor.total.toFixed(2)}</p>
                </div>
                <div className='flex'>
                  <p>{summary.labor.percent.toFixed(2)}</p>
                  <p>%</p>
                </div>
              </div>
            </div>

            <div className='flex gap-4 text-dark-blue py-2 border-b'>
              <p className='w-2/5'>Material</p>
              <div className='flex w-3/5 justify-between'>
                <div className='flex w-3/5 justify-between text-gunite-red'>
                  <p>$</p>
                  <p>{(plaster ? summary.material.plaster.total : summary.material.gunite.total).toFixed(2)}</p>
                </div>
                <div className='flex'>
                  <p>{(plaster ? summary.material.plaster.percent : summary.material.gunite.percent).toFixed(2)}</p>
                  <p>%</p>
                </div>
              </div>
            </div>

            <div className='flex gap-4 text-dark-blue py-2 border-b'>
              <p className='w-2/5'>Fuel</p>
              <div className='flex w-3/5 justify-between'>
                <div className='flex w-3/5 justify-between text-gunite-red'>
                  <p>$</p>
                  <p>{summary.fuel.total.toFixed(2)}</p>
                </div>
                <div className='flex'>
                  <p>{summary.fuel.percent.toFixed(2)}</p>
                  <p>%</p>
                </div>
              </div>
            </div>

            <div className='flex w-full gap-4 text-dark-blue pt-2'>
              <p className='w-2/5'>Insurance</p>
              <div className='flex w-3/5 justify-between'>
                <div className='flex w-3/5 justify-between text-gunite-red'>
                  <p>$</p>
                  <p>{summary.insurance.price.toFixed(2)}</p>
                </div>
                <div className='flex'>
                  <p>{summary.insurance.percent.toFixed(2)}</p>
                  <p>%</p>
                </div>
              </div>
            </div>

          </div>

          <div className='flex w-full rounded bg-card-bg-hover p-3 mb-4 justify-between text-dark-blue font-semibold'>
            <p className='w-2/5'>
              Total cost
            </p>
            <div className='flex justify-between w-3/5'>
              <div className='flex w-3/5 justify-between text-gunite-red'>
                <p className='pl-2.5'>$</p>
                <p>{summary.totalCost.total.toFixed(2)}</p>
              </div>
              <div className='flex'>
                <p>{summary.totalCost.percent.toFixed(2)}</p>
                <p>%</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className='flex w-full bg-card-bg-hover p-3 mb-4 md:gap-8 rounded justify-between text-dark-blue font-semibold'>
        <p className='w-2/5 md:pr-8'>
          Gross profit
        </p>
        <div className='flex justify-between w-3/5 md:pl-3'>
          <div className='hidden md:flex md:w-2/5'></div>
          <div className={`flex justify-between w-full md:w-1/2 ${summary.profit.gross < 0 ? 'text-gunite-red' : 'text-lime-500'}`}>
            <div className='flex w-3/5 justify-between'>
              <p className='pl-2.5'>$</p>
              <p>{summary.profit.gross.toFixed(2)}</p>
            </div>
            <div className='flex '>
              <p>{summary.profit.percent.toFixed(2)}</p>
              <p>%</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CostBreakdownSummary;
