import React, { useState, useEffect } from "react";
import CostBreakdownSelector from "../CostBreakdownSelector";
import CostBreakdownInput from "../CostBreakdownInput";
import CostBreakdownAddEmployee from "../CostBreakdownAddEmployee";


const DEFAULT_CREW_ROLES = [
  'Foreman',
  'Top Finisher',
  'Bottom Finisher',
  'Nozzleman'
]

const CostBreakdownCrews = ({ crew, breakdown, setBreakdown, employerTaxRate, savedCostBreakdown, resetToggle,
  DEFAULT_CREW, isPlaster, plasterType, selectedJob
}) => {
  const [addingActive, setAddingActive] = useState(false)
  const [crewUsed, setCrewUsed] = useState([])
  const [contractorsUsed, setContractorsUsed] = useState([])
  const [crewTotal, setCrewTotal] = useState({ total: 0, withTax: 0})

  const updateState = (e, i, field) => {
    const updatedCrewList = [
      ...crewUsed.slice(0, i),
      {
        ...crewUsed[i],
        [field]: e.target.value
      },
      ...crewUsed.slice(i + 1)
    ]

    setCrewUsed(updatedCrewList)
  }

  const updateContractorState = (e, i, field) => {
    const updatedCrewList = [
      ...contractorsUsed.slice(0, i),
      {
        ...contractorsUsed[i],
        [field]: e.target.value
      },
      ...contractorsUsed.slice(i + 1)
    ]

    setContractorsUsed(updatedCrewList)
  }

  const calculateCrewTotal = () => {
    // math to calculate overall total
    const crewTotal = crewUsed.reduce((accumulator, item) => {
      const pools = item.pools ? Number(item.pools) : 0
      const poolRate = item.poolRate ? Number(item.poolRate) : 0
      const travel = item.travel ? Number(item.travel) : 0
      const extras = item.extras ? Number(item.extras) : 0
      return accumulator += ((pools * poolRate) + travel + extras)
    }, 0)

    const contractorTotal = contractorsUsed.reduce((accumulator, item) => {
      const amount = item.contractorAmount ? Number(item.contractorAmount) : 0
      const price = item.contractorPrice ? Number(item.contractorPrice) : 0
      return accumulator += (amount * price)
    }, 0)

    const total = contractorTotal + crewTotal

    // math to calculate overall total with tax
    const totalWithTax = (total + (total * employerTaxRate))

    setCrewTotal({ total: Number(total), withTax: Number(totalWithTax) })

     // update breakdown
     setBreakdown((breakdown) => {
      return {
        ...breakdown,
        crew: {
          items: [...crewUsed, ...contractorsUsed],
          total: {
            total: total,
            withTax: totalWithTax
          }
        }
      }
    })
  }

  const calculateRowTotal = (row) => {
    // math to calculate row total
    const pools = row.pools ? Number(row.pools) : 0
    const poolRate = row.poolRate ? Number(row.poolRate) : 0
    const travel = row.travel ? Number(row.travel) : 0
    const extras = row.extras ? Number(row.extras) : 0
    return ((pools * poolRate) + travel + extras).toFixed(2)
  }

  const calculateContractorRowTotal = (row) => {
    // math to calculate row total
    const amount = row.contractorAmount ? Number(row.contractorAmount) : 0
    const price = row.contractorPrice ? Number(row.contractorPrice) : 0
    return (amount * price).toFixed(2)
  }

  const handleDeleteRow = (i) => {
    setCrewUsed([
      ...crewUsed.slice(0, i),
      ...crewUsed.slice(i + 1)
    ])
  }

  const handleDeleteContractorRow = (i) => {
    setContractorsUsed([
      ...contractorsUsed.slice(0, i),
      ...contractorsUsed.slice(i + 1)
    ])
  }

  // updates Plaster employee rates when plasterType changes
  useEffect(() => {
    if (isPlaster) {
      let updatedList = [...crewUsed]
      updatedList.forEach((member, i) => {
        let newRate = member.plasterPoolRate
        if (plasterType === 'quartz') {
          newRate = member.quartzPoolRate
        } else if (plasterType === 'pebble') {
          newRate = member.pebblePoolRate
        }

        updatedList = [
          ...updatedList.slice(0, i),
          {
              ...updatedList[i],
              poolRate: newRate ? newRate : updatedList[i].poolRate
            },
            ...updatedList.slice(i + 1)
        ]
      })
      setCrewUsed(updatedList)
    }
  }, [plasterType])

  useEffect(() => {
    const memberArray = []
    const contractorArray = []
    if (savedCostBreakdown) {
      savedCostBreakdown.crew.forEach((crew) => {
        if (crew.isContractor) {
          contractorArray.push(crew)
        } else {
          memberArray.push(crew)
        }
      })
    } else {
      memberArray.push(...(crew.members || []))
    }
    setCrewUsed(memberArray)
    setContractorsUsed(contractorArray)
  }, [crew, savedCostBreakdown, resetToggle])


  useEffect(() => {
    calculateCrewTotal()
  }, [crewUsed, contractorsUsed, breakdown.invoice])

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      {crewUsed.map((crewMember, i) => {
        return (
          <div
            className='flex flex-col md:flex-row w-full'
            key={`crew${i + 1}`}
          >
            <div className='flex flex-col w-full'>
              <div
                className={`w-full flex flex-col md:flex-row gap-2 md:gap-4 md:items-end pt-2 mb-4 ${i > 0 ? 'border-t' : ''}`}
              >
                <p className='md:pb-3 text-2xl md:text-lg md:w-1/4 text-dark-blue'>
                  {crewMember.name}
                </p>

                <div className='flex md:w-1/4'>
                  <CostBreakdownSelector
                    value={crewMember.role}
                    options={DEFAULT_CREW_ROLES}
                    placeholder='Role'
                    onChange={(e) => {
                      updateState(e, i, 'role')
                    }}
                  />
                </div>

                <div className='flex items-end md:w-[95px] gap-4'>
                  <CostBreakdownInput
                    value={crewMember.pools}
                    placeholder='Pools'
                    numbersOnly
                    onChange={(e) => {
                      updateState(e, i, 'pools')
                    }}
                  />
                </div>

                <div className='flex flex-col md:flex-row items-end md:w-3/4 gap-4'>
                  <CostBreakdownInput
                    value={crewMember.poolRate}
                    placeholder='Pool rate'
                    money
                    disabled
                    edit
                    onChange={(e) => {
                      updateState(e, i, 'poolRate')
                    }}
                  />
                  <CostBreakdownInput
                    value={crewMember.travel}
                    placeholder='Travel'
                    money
                    onChange={(e) => {
                      updateState(e, i, 'travel')
                    }}
                  />
                  <CostBreakdownInput
                    value={crewMember.extras}
                    placeholder='Extras'
                    money
                    onChange={(e) => {
                      updateState(e, i, 'extras')
                    }}
                  />
                </div>

                <div className='block md:w-1/4'>
                  <p className='text-xs text-dark-blue pb-[2px] pt-[5px]'>Total</p>
                  <div className='w-full px-3 py-2 rounded border border-tertiary-blue text-opaque-job-details-gray bg-disabled-gray'>
                    ${calculateRowTotal(crewMember)}
                  </div>
                </div>

              </div>

              <div className={`pb-4 ${crewMember.extras > 0 ? '' : 'hidden'}`}>
                <CostBreakdownInput
                  value={crewMember.extrasReason}
                  placeholder='Extras reason'
                  onChange={(e) => {
                    updateState(e, i, 'extrasReason')
                  }}
                />
              </div>

            </div>

            <div className='flex h-10 w-full justify-end  md:w-12 mb-1 md:mt-8'>
              <button
                className="flex w-12 h-8 p-1 text-gunite-red cursor-pointer items-center justify-center"
                onClick={() => { handleDeleteRow(i) }}
              >
                <img
                  src='/JobDetails/trash_icon.svg'
                  alt='trashcan icon'
                />
              </button>
            </div>
          </div>
        )
      })}

      {contractorsUsed.length > 0 &&
        <p className='md:pt-10 font-bold text-dark-blue text-2xl md:text-xl' >Contractors</p>
      }

      {contractorsUsed.map((contractor, i) => {
        return (
          <div
            className='flex flex-col md:flex-row w-full'
            key={`contractor${i + 1}`}
          >
            <div className='flex flex-col w-full'>
              <div
                className={`w-full flex flex-col md:flex-row gap-4 md:items-end pt-2 mb-4 ${i !== 0 ? 'border-t' : ''}`}
              >
                <p className='md:pb-3 text-xl md:text-md w-full md:w-6/12 text-dark-blue'>
                  {contractor.name}
                </p>

                <div className='flex flex-col md:flex-row md:justify-end items-end w-full md:w-6/12 gap-2 md:gap-4'>
                  <div className={`flex md:hidden w-full`}>
                    <CostBreakdownInput
                      value={contractor.contractorService}
                      placeholder='Service / Notes'
                      onChange={(e) => {
                        updateContractorState(e, i, 'contractorService')
                      }}
                    />
                  </div>

                  <div className='flex items-end w-full md:w-3/12 gap-4'>
                    <CostBreakdownInput
                      value={contractor.contractorAmount}
                      placeholder='Amount'
                      numbersOnly
                      onChange={(e) => {
                        updateContractorState(e, i, 'contractorAmount')
                      }}
                    />
                  </div>

                  <div className='flex items-end w-full md:w-4/12 gap-4'>
                    <CostBreakdownInput
                      value={ contractor.contractorPrice}
                      placeholder='Price'
                      money
                      onChange={(e) => {
                        updateContractorState(e, i, 'contractorPrice')
                      }}
                    />
                  </div>

                  <div className='block w-full md:w-4/12'>
                    <p className='text-xs text-dark-blue pb-[2px] pt-[5px]'>Total</p>
                    <div className='w-full px-3 py-2 rounded border border-tertiary-blue text-opaque-job-details-gray bg-disabled-gray'>
                      ${calculateContractorRowTotal(contractor)}
                    </div>
                  </div>

                </div>
              </div>

              <div className={`hidden md:flex pb-4`}>
                <CostBreakdownInput
                  value={contractor.contractorService}
                  placeholder='Service / Notes'
                  onChange={(e) => {
                    updateContractorState(e, i, 'contractorService')
                  }}
                />
              </div>

            </div>

            <div className='flex h-10 w-full justify-end md:w-12 mb-1 md:mt-8'>
              <button
                className="flex w-12 h-8 p-1 text-gunite-red cursor-pointer items-center justify-center"
                onClick={() => { handleDeleteContractorRow(i) }}
              >
                <img
                  src='/JobDetails/trash_icon.svg'
                  alt='trashcan icon'
                />
              </button>
            </div>

          </div>
        )
      })}


      {addingActive ?
        <CostBreakdownAddEmployee
          setAddingActive={setAddingActive}
          crewUsed={crewUsed}
          DEFAULT_CREW={DEFAULT_CREW}
          isPlaster={isPlaster}
          plasterType={plasterType}
          selectedJob={selectedJob}
          add={(member) => {
            setCrewUsed([...crewUsed, member])
          }}
          addContractor={(contractor) => {
            setContractorsUsed([...contractorsUsed, contractor])
          }}
        />
        :
        <div className='flex justify-end gap-4'>
          <button
            className='w-full border border-navy-light rounded text-dark-blue py-2 px-4 mt-4 font-semibold hover:border-dark-blue'
            onClick={() => {
              setAddingActive(true)
            }}
          >
            + Add new
          </button>
        </div>
      }


      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total crew cost
          </p>
          <div className='flex justify-between w-[100px]'>
            <p>$</p>
            <p>{crewTotal.total.toFixed(2)}</p>
          </div>
        </div>

        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total crew plus taxes
          </p>
          <div className='flex justify-between w-[100px]'>
            <p>$</p>
            <p>{crewTotal.withTax.toFixed(2)}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CostBreakdownCrews;
