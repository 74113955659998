import React from "react";

const MiniCalendarDay = ({ selectedDays, setSelectedDays, dateInfo, isMultiDay, today, blank, allowSelectPast = false }) => {
  const isDaySelected = (remove = false) => {
    for (let i = 0; i < selectedDays?.length; i++) {
      const dayObject = new Date(selectedDays[i])
      if (
        dayObject.getUTCFullYear() === dateInfo.year
        && dayObject.getUTCMonth() === dateInfo.month
        && dayObject.getUTCDate() === dateInfo.day
      ) {
        if (remove) {
          setSelectedDays([...selectedDays.slice(0, i), ...selectedDays.slice(i + 1)])
        }
        return true
      }
    }
    return false
  }

  let selectedFlag = isDaySelected()
  let isMiddleDay = false
  let isFirstDay = false
  let isLastDay = false

  const isToday = (
    today?.day === dateInfo?.day
    && today?.month === dateInfo?.month
    && today?.year === dateInfo?.year
  )

  // sets styling for days that are next to each other
  const setSelectedDayCssStyle = () => {
    let previousDaySelected = false
    let nextDaySelected = false
    if (selectedFlag) {
      for (let i = 0; i < selectedDays.length; i++) {
        const dayObject = new Date(selectedDays[i])
        if (
          dayObject.getUTCFullYear() === dateInfo.year
          && dayObject.getUTCMonth() === dateInfo.month
          && dayObject.getUTCDate() === dateInfo.day - 1
        ) {
          previousDaySelected = true
          continue
        }

        if (
          dayObject.getUTCFullYear() === dateInfo.year
          && dayObject.getUTCMonth() === dateInfo.month
          && dayObject.getUTCDate() === dateInfo.day + 1
        ) {
          nextDaySelected = true
        }
      }
    }
    if (previousDaySelected && nextDaySelected) {
      selectedFlag = false
      isMiddleDay = true
    } else if (!previousDaySelected && nextDaySelected) {
      selectedFlag = false
      isFirstDay = true
    } else if (previousDaySelected && !nextDaySelected) {
      selectedFlag = false
      isLastDay = true
    }
  }
  setSelectedDayCssStyle()

  const daySetter = () => {
    if (!allowSelectPast && (
      dateInfo.year < today.year ||
      (dateInfo.year === today.year && dateInfo.month < today.month) ||
      (dateInfo.year === today.year && dateInfo.month === today.month && dateInfo.day < today.day)
    )) {
        return;
    }

    if (!isMultiDay) {
      // if already selected, deselect and return
      if (isDaySelected(true)) return

      // replace selectedDay with day clicked
      const date = new Date(dateInfo.year, dateInfo.month, dateInfo.day)
      date.setUTCHours(0, 0, 0, 0)
      setSelectedDays([date.toUTCString()])
    } else {
      // if already selected, deselect and return
      if (isDaySelected(true)) return

      // add new day to selectedDays after sorting them
      const date = new Date(dateInfo.year, dateInfo.month, dateInfo.day)
      const sorted = [...selectedDays, date.toUTCString()]
      sorted.sort((a,b) => {
        return new Date(a) - new Date(b);
      })

      setSelectedDays([...sorted])
    }
  }

  return (
    <div className={`w-[34px] h-[24px]`}>
      {blank ? '' : (
        <button
          className={
            `flex h-full w-full items-center justify-center font-bold text-xs text-dark-blue
            ${selectedFlag ? 'selected bg-dark-blue text-white rounded-md' : ''}
            ${isFirstDay ? 'selected rounded-l-md bg-dark-blue text-white' : ''}
            ${isLastDay ? 'selected rounded-r-md bg-dark-blue text-white' : ''}
            ${isMiddleDay ? 'selected bg-dark-blue text-white' : ''}
            ${isToday ? 'text-gunite-red' : ''}`
          }
          onMouseDown={(e) => {
            e.preventDefault()
            daySetter()
          }}
        >
          {dateInfo?.day}
        </button>
      )}

    </div>
  )
}

export default MiniCalendarDay;
