import PopupSearchBox from "../PopupSearchBox";

const JobsListListHeaderBar = ({
  searchTerm, setSearchTerm,
  sortBy, setSortBy, DropdownMenu,
  jobType, setJobType,
  selectedStatuses, setSelectedStatuses
}) => {

  return (
    <div className="relative flex justify-between items-center px-6 h-16 bg-navy-bright/10 rounded-t-xl border border-navy-light">
      <h1 className="text-lg font-semibold text-dark-blue">Jobs</h1>
      <div className="flex flex-row justify-center items-center">
        <PopupSearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <DropdownMenu
          sortBy={sortBy}
          setSortBy={setSortBy}
          jobType={jobType}
          setJobType={setJobType}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
        />
      </div>
    </div>
  );
};

export default JobsListListHeaderBar;