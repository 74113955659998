import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ResponsiveContext } from '../../contexts/responsive';
import { formatDate } from '../../utils'

const CustomerJobsListItem = ({ job }) => {
  const { jobName, submittedAt, address, owner } = job;
  const statusOptions = [
    { value: 'Intake', bgColor: 'bg-opacity-10 bg-navy-bright', textColor: 'dark-blue', icon: '/JobsList/intake_icon.svg', iconSize: 14},
    { value: 'Scheduled', bgColor: 'bg-dark-blue', textColor: 'white', icon: '/JobsList/scheduled_icon.svg', iconSize: 15},
    { value: 'Completed', bgColor: 'bg-pale-green', textColor: 'solid-green', icon: '/JobsList/completed_icon.svg', iconSize: 16},
    { value: 'Ready for calendar', bgColor: 'bg-opacity-50 bg-navy-light', textColor: 'dark-blue', icon: '/JobsList/ready_for_calendar_icon.svg', iconSize: 14},
    { value: 'Backlog', bgColor: 'bg-navy-light', textColor: 'dark-blue', icon: '/JobsList/backlog_icon.svg', iconSize: 16},
    { value: 'None', bgColor: 'bg-opacity-10 bg-navy-bright', textColor: 'dark-blue', icon: '/JobsList/intake_icon.svg', iconSize: 14}
  ];

  const { responsive, BREAKPOINTS } = useContext(ResponsiveContext);
  const { width } = responsive;

  const jobIconMaker = (job) => {
    if (job.completedAt) {
      return (
        <div className='bg-pale-green rounded-full px-3 py-1.5 flex justify-center items-center gap-1'>
          <img src='/JobsList/completed_icon.svg' alt='completed' className='select-none fill-solid-green w-[18px]' />
          <p className='text-solid-green font-medium text-xs select-none'>Completed</p>
        </div>
      )
    } else if (job.scheduledAt) {
      return (
        <div className='bg-dark-blue rounded-full px-3 py-1.5 flex justify-center items-center gap-1'>
          <img src='/JobsList/scheduled_icon.svg' alt='Scheduled' className='select-none fill-solid-green w-[18px]' />
          <p className='text-white font-medium text-xs select-none'>Scheduled</p>
        </div>
      )
    } else if (job.readyAt) {
      return (
        <div className='bg-navy-light/50 rounded-full px-3 py-1.5 flex justify-center items-center gap-1'>
          <img src='/JobsList/ready_for_calendar_icon.svg' alt='Ready for calendar' className='select-none w-[14px]' />
          <p className='text-dark-blue font-medium text-xs select-none'>Ready for Calendar</p>
        </div>
      )
    } else if (job.backloggedAt) {
      return (
        <div className='bg-navy-light rounded-full px-3 py-1.5 flex justify-center items-center gap-1'>
          <img src='/JobsList/backlog_icon.svg' alt='Backlog' className='select-none w-[18px]' />
          <p className='text-dark-blue font-medium text-xs select-none'>Backlog</p>
        </div>
      )
    } else if (job.submittedAt) {
      return (
        <div className='bg-navy-bright/50 rounded-full px-3 py-1.5 flex justify-center items-center gap-1'>
          <img src='/JobsList/intake_icon.svg' alt='Intake' className='select-none w-[14px]' />
          <p className='text-dark-blue font-medium text-xs select-none'>Intake</p>
        </div>
      )
    }
  }

  return (
    <div className="flex gap-4 py-2 px-6 border-b border-navy-light justify-between">
      <p className="cursor-pointer text-dark-blue text-xl w-2/3 md:w-1/6">
        {job.displayName}
      </p>
      {(width > BREAKPOINTS.md) && (
        <>
          <div className='text-gray-blue w-1/3'>{address}</div>
          <div className='text-gray-blue w-1/6'>{formatDate(job.requestedDate)}</div>
          <div className='text-gray-blue w-1/6'>{ job.scheduledAt ? formatDate(job.scheduledAt) : ''}</div> 
          <div className='text-gray-blue w-1/12'>{job.jobType === 'gunite' ? "Gunite" : 'Plaster'}</div>
        </>
      )}
      <div className='flex text-gray-blue w-1/3 md:w-1/12 justify-self-start items-center'>
        {jobIconMaker(job)}
      </div>
    </div>
  );
}

export default CustomerJobsListItem;
