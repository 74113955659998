import React from 'react'
import { useScheduler } from '../../contexts/scheduler'
import { getDay } from 'date-fns'
import CrewMobile from '../CrewMobile'
import { useSchedulerCalendarMobile } from '../../contexts/schedulerCalendarMobile'

const CalendarDayMobile = (props) => {
  const { displayMode, dropActive, setDropActive, dropActiveFlashing, setDropActiveFlashing } = useSchedulerCalendarMobile()
  const { undeletedCrews } = useScheduler()
  const { day, dayJobs } = props //day is a Date object

  const crewJobsFilter = (i) => {
    return dayJobs?.filter((calendarJob) => (
      calendarJob?.crews[0]?.crew.id === undeletedCrews[i].id //this compares ids of calendarJobCrews and crews
    ))
  }

  const dayStrings = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]

  const resetDropState = () => {
    if (dropActive) setDropActive(false)
    if (dropActiveFlashing) setDropActiveFlashing(false)
  }

  const calendarDayDisplay = () => {
    if (displayMode === 'short') {
      return (
        <div className='flex flex-col gap-1 mb-1 p-1 bg-white rounded-lg overflow-x-auto'>
          <div className='flex gap-2 pl-2' onClick={resetDropState}>
            <p className='font-bold text-navy text-sm w-[18px]'>{day.getDate()}</p>
            <p className='font-normal text-sm text-gray-blue'>{dayStrings[day.getDay()]}</p>
          </div>
          <div className='flex flex-row gap-1 items-stretch'>
            {undeletedCrews?.map((crew, i) => <CrewMobile crew={crew} i={i} day={day} key={`crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    } else {
      return (
        <div className='flex flex-col w-full mb-1 p-1 rounded-lg bg-white'>
          <div className='flex gap-2 pl-2' onClick={resetDropState}>
            <p className='font-bold text-navy text-sm w-[18px]'>{day.getDate()}</p>
            <p className='font-normal text-sm text-gray-blue'>{dayStrings[day.getDay()]}</p>
          </div>
          <div className='flex flex-col gap-1 overflow-y-auto'>
            {undeletedCrews?.map((crew, i) => <CrewMobile crew={crew} i={i} day={day} key={`crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    }
  }

  return (
    <>{calendarDayDisplay()}</>
  )
}

export default CalendarDayMobile
