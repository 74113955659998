import { FaSearch } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";


const ActivityLogPeopleFilterDropdown = ({peopleOptions, selectedPeople, setSelectedPeople }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [displayOptions, setdisplayOptions] = useState([]);

  const dropdownWrapperRef = useRef(null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    setdisplayOptions(peopleOptions.filter(option => option.label.toLowerCase().includes(searchTerm.toLowerCase())))
  }, [searchTerm, peopleOptions])

  const handleOptionClick = (e, itemId) => {
    e.stopPropagation();
    const updatedItemsList = selectedPeople.includes(itemId)
      ? selectedPeople.filter((item) => item !== itemId)
      : [...selectedPeople, itemId];
    setSelectedPeople(updatedItemsList);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownWrapperRef} >
    <button
    className="ml-2 flex items-center justify-center h-full w-fit p-[8px] py-[5px] rounded-[4px] bg-all-f-white border border-navy-light"
    onClick={(e) => {
      e.preventDefault()
      setShowDropdown(!showDropdown)
      }}
    >
      <p className="text-[14px] font-[450] text-dark-blue select-none ">People</p>
      <img src={showDropdown ? '/show_less_arrow.svg' : '/show_more_arrow.svg'} alt='show filters' className="lg:pl-[3px] h-[20px] hidden lg:block" />
    </button>

      <div
      className={`w-[280px] top-[63px] left-8 absolute flex flex-col justify-between p-[8px] bg-all-f-white border border-navy-light rounded-[8px] shadow-md text-[14px] ${showDropdown ? '' : 'hidden'}`}
      >
        <div className="flex bg-white rounded-lg flex-option items-center border border-navy-light overflow-hidden">
          <FaSearch className="ml-3 text-gray-blue w-[16px]" />
          <input
          onChange={handleSearchChange}
          value={searchTerm}
          placeholder="Search"
          type="text" 
          className="p-[6px] flex-1 text-dark-blue border-none focus:ring-0" 
          />
        </div>
        <div className="overflow-y-scroll h-[300px] w-full">
        {displayOptions.map((option) => {
          if (option.label.toLowerCase().includes(searchTerm.toLowerCase()) || searchTerm == '') {
            return (
              <div
                key={option.id}
                className='flex w-full items-center p-[8px] cursor-pointer'
                onClick={(e) => handleOptionClick(e, option.id)}
              >
                <div className={`flex w-[16px] h-[16px] rounded-[4px] select-none ${selectedPeople.includes(option.id) ? 'bg-dark-blue' : 'bg-all-f-white border'}`}>
                  {selectedPeople.includes(option.id) && <img src='/check.svg' alt='check' className="w-full" />}
                </div>

                <p className='pl-[12px] select-none text-dark-blue'>
                  {option.label}
                </p>
              </div>
            );
          }
          return null;
        })}
        </div>
    </div>
  </div>
  )

}

export default ActivityLogPeopleFilterDropdown;