import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth'
import { useForeman } from "../../contexts/foreman";

const apiUrl = process.env.REACT_APP_API_URL;

const ForemanSidebar = () => {
  const [focus, setFocus] = useState('dailyjoblog');
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const logoImage = "/sidebar/gunite_logo_sm.svg"
  const { loginLink } = useForeman()

  useEffect(() => {
    if (pathname === '/foreman') setFocus('dailyjoblog')
    else {
      const regex = /\/([^\/]+)$/;
      const match = pathname.match(regex)
      if (Array.isArray(match)) {
        setFocus(match[1])
      }
    }
  }, [pathname])

  return (
    <div className='hidden lg:flex w-20 h-screen flex-col content-center justify-between bg-dark-blue'>

      <div className='flex w-full h-20 justify-center items-center'>
        <Link to='/'>
          <div className='w-[58px]'>
            <img src={logoImage} alt="Main Logo" className='w-full' crossOrigin="anonymous" />
          </div>
        </Link>
      </div>

      <div className='flex flex-col h-fit justify-start items-center grow'>

        <Link to={`/foreman`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={focus === 'dailyjoblog' ? '/sidebar/daily_job_log_icon.svg' : '/sidebar/daily_job_log_icon_disabled.svg'}
              alt="Daily Job Log Icon"
              className='w-[31px] pb-[6px]'
            />
            <p className={`text-xs text-center ${focus === 'dailyjoblog' ? 'text-white' : 'text-slate-400'}`}>Daily job log</p>
          </div>
        </Link>

        <Link to={`/foreman/account`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={focus === 'account' ? '/sidebar/account_icon.svg' : '/sidebar/account_icon_disabled.svg'}
              alt="Account Icon"
              className='w-[30px] pb-[6px]'
            />
            <p className={`text-xs ${focus === 'account' ? 'text-white' : 'text-slate-400'}`}>Account</p>
          </div>
        </Link>

      </div>

      <div className='flex w-full h-28 justify-center items-center'>
        <div
          className='flex flex-col w-full items-center cursor-pointer'
          onClick={() => {
            logout();
            window.location.assign(loginLink);
          }}
        >
          <img src="/sidebar/logout_icon.svg" alt="Logout Icon" className='w-[27px] pb-[6px]' />
          <p className='text-xs text-white'>Logout</p>
        </div>
      </div>
    </div>
  )
}

export default ForemanSidebar;
