import React, { useContext } from 'react';
import { useCustomerMyJobs } from '../../contexts/customerMyJobs';
import { result } from 'underscore';

const JobsListList = ({
  displayJobs,
  ColumnHeaders,
  ListItem
}) => {
  return (
    <>
      <div className="border-l border-r border-navy-light">
        <ColumnHeaders />
      </div>
      <div className="flex-1 overflow-auto border-l border-r border-navy-light">
        {displayJobs.length > 0 ? (
          <>
            {displayJobs.map((job, index) => (
              <ListItem
                key={index} 
                job={job} 
              />
            ))}
          </>
        ) : (
          <div className="flex justify-center items-center pt-3 h-16">
            <p className="text-gray-blue text-sm">No matches found</p>
          </div>
        )}
      </div>
    </>

  );
};

export default JobsListList;
