/* External imports */
import isEqual from 'lodash.isequal';
import { useState, useEffect } from "react";
/* Internal imports */
import { useAdminRates } from "../../contexts/adminRates";

export default function AdminGlobalRates() {
  const [displayChangesSavedText, setDisplayChangesSavedText] = useState(false);
  const [displayCancelChangesText, setDisplayCancelChangesText] = useState(false);
  const { rate, updateRate } = useAdminRates();

  const generateTempValues = (rate) => ({
    rateInvoice: {
      pricePerCubicYards: rate?.rateInvoice?.pricePerCubicYards || 0,
      insuranceRate: rate?.rateInvoice?.insuranceRate || 0,
      crewTaxRate: rate?.rateInvoice?.crewTaxRate || 0,
    },
    rateGuniteMaterial: {
      cementCostPerYard: rate?.rateGuniteMaterial?.cementCostPerYard || 0,
      cementConversionRate: rate?.rateGuniteMaterial?.cementConversionRate || 0,
      sandCostPerYard: rate?.rateGuniteMaterial?.sandCostPerYard || 0,
      sandConversionRate: rate?.rateGuniteMaterial?.sandConversionRate || 0,
    },
    rateGuniteFuel: {
      crewTruckCostPerGallon: rate?.rateGuniteFuel?.crewTruckCostPerGallon || 0,
      crewTruckMilesPerGallon: rate?.rateGuniteFuel?.crewTruckMilesPerGallon || 0,
      mobileMixersCostPerGallon: rate?.rateGuniteFuel?.mobileMixersCostPerGallon || 0,
      mobileMixersMilesPerGallon: rate?.rateGuniteFuel?.mobileMixersMilesPerGallon || 0,
      compressorClearCostPerGallon: rate?.rateGuniteFuel?.compressorClearCostPerGallon || 0,
      compressorClearMilesPerGallon: rate?.rateGuniteFuel?.compressorClearMilesPerGallon || 0,
      compressorRedCostPerGallon: rate?.rateGuniteFuel?.compressorRedCostPerGallon || 0,
      compressorRedGallonsPerYard: rate?.rateGuniteFuel?.compressorRedGallonsPerYard || 0,
    },
    ratePlasterFuel: {
      mixerCostPerGallon: rate?.ratePlasterFuel?.mixerCostPerGallon || 0,
      mixerMilesPerGallon: rate?.ratePlasterFuel?.mixerMilesPerGallon || 0,
      slurryTruckCostPerGallon: rate?.ratePlasterFuel?.slurryTruckCostPerGallon || 0,
      slurryTruckMilesPerGallon: rate?.ratePlasterFuel?.slurryTruckMilesPerGallon || 0,
    }
  });

  const defaultTempValues = generateTempValues(rate);

  const [tempValues, setTempValues] = useState(defaultTempValues);

  useEffect(() => {
    if (rate) {
      setTempValues(generateTempValues(rate));
    }
  }, [rate]);

  const checkForChanges = () => {
    if (defaultTempValues) {
      return !isEqual(defaultTempValues, tempValues);
    }
  }

  const onSave = () => {
    setDisplayChangesSavedText(true);
    setDisplayCancelChangesText(false)
    updateRate(tempValues)
  }

  const onCancel = () => {
    setDisplayCancelChangesText(true)
    setDisplayChangesSavedText(false)
    setTempValues(defaultTempValues);
  }

  const roundToTwoDecimalPlacesIfNeeded = (number) => {
    const roundedNumber = number.toFixed(2);
    return parseFloat(roundedNumber);
  };

  return (
    <div>
      <style jsx>{`
      /* CSS to hide the spinner */
      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield; /* Firefox */
      }
    `}</style>

      <h1 className="text-xl font-semibold text-dark-blue mb-6">
        Global rates
      </h1>

      <section
        className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
        <h2 className="text-lg font-semibold text-dark-blue">
          Taxes
        </h2>
        <div className="relative flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-3">
            Crew tax rate
          </p>
          <div className="flex flex-col w-3/12">
            <label className="text-dark-blue py-1 text-xs inline-size-max">Tax rate</label>
            <div className="relative w-full">
              <input
                value={tempValues.rateInvoice.crewTaxRate}
                onChange={(e) => setTempValues({ ...tempValues, rateInvoice: { ...tempValues.rateInvoice, crewTaxRate: parseFloat(parseFloat(e.target.value)) } })}
                className="pl-6 pr-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                type="number"
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-dark-blue">%</span>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
        <h2 className="text-lg font-semibold text-dark-blue">
          Insurance
        </h2>
        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-3">
            Insurance rate
          </p>
          <div className="flex flex-col w-3/12">
            <label className="text-dark-blue py-1 text-xs inline-size-max">Insurance rate</label>
            <div className="relative w-full">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
              <input
                value={tempValues.rateInvoice.insuranceRate}
                onChange={(e) => {
                  console.log('tempValues', tempValues)
                  setTempValues({
                    ...tempValues,
                    rateInvoice: {
                      ...tempValues.rateInvoice,
                      insuranceRate: parseFloat(parseFloat(e.target.value || 0))
                    }
                  })
                }}
                className="pl-6  text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                type="number"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
        <h2 className="text-lg font-semibold text-dark-blue">
          Invoice (gunite)
        </h2>
        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-3">
            Cubic yards
          </p>
          <div className="flex flex-col w-3/12">
            <label className="text-dark-blue py-1 text-xs inline-size-max">Price</label>
            <div className="relative w-full">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
              <input
                value={tempValues.rateInvoice.pricePerCubicYards}
                onChange={(e) => setTempValues({ ...tempValues, rateInvoice: { ...tempValues.rateInvoice, pricePerCubicYards: parseFloat(parseFloat(e.target.value)) } })}
                className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                type="text"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
        <h2 className="text-lg font-semibold text-dark-blue">
          Materials (gunite)
        </h2>
        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-2">
            Sand
          </p>
          <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Yard</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.rateGuniteMaterial.sandCostPerYard}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteMaterial: { ...tempValues.rateGuniteMaterial, sandCostPerYard: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Conversion rate</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.rateGuniteMaterial.sandConversionRate}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteMaterial: { ...tempValues.rateGuniteMaterial, sandConversionRate: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Ton</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={roundToTwoDecimalPlacesIfNeeded(tempValues.rateGuniteMaterial.sandCostPerYard * tempValues.rateGuniteMaterial.sandConversionRate)}
                  readOnly
                  className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="border-navy-light border w-full mb-4" />

        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-2">
            Cement
          </p>
          <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Yard</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.rateGuniteMaterial.cementCostPerYard}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteMaterial: { ...tempValues.rateGuniteMaterial, cementCostPerYard: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Conversion rate</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.rateGuniteMaterial.cementConversionRate}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteMaterial: { ...tempValues.rateGuniteMaterial, cementConversionRate: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Ton</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={roundToTwoDecimalPlacesIfNeeded(tempValues.rateGuniteMaterial.cementCostPerYard * tempValues.rateGuniteMaterial.cementConversionRate)}
                  readOnly
                  className="bg-navy-light bg-opacity-90 pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue text-opacity-80 border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>

      </section>

      <section
        className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
        <h2 className="text-lg font-semibold text-dark-blue">
          Fuel (gunite)
        </h2>

        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-3">
            Crew truck
          </p>

          <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
              <div className="relative w-full">
                <input
                  value={tempValues.rateGuniteFuel.crewTruckMilesPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteFuel: { ...tempValues.rateGuniteFuel, crewTruckMilesPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.rateGuniteFuel.crewTruckCostPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteFuel: { ...tempValues.rateGuniteFuel, crewTruckCostPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={roundToTwoDecimalPlacesIfNeeded(tempValues.rateGuniteFuel.crewTruckCostPerGallon / tempValues.rateGuniteFuel.crewTruckMilesPerGallon)}
                  readOnly
                  className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="border-navy-light border w-full mb-4" />

        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-2">
            Mobile mixers
          </p>
          <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
              <div className="relative w-full">
                <input
                  value={tempValues.rateGuniteFuel.mobileMixersMilesPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteFuel: { ...tempValues.rateGuniteFuel, mobileMixersMilesPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.rateGuniteFuel.mobileMixersCostPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteFuel: { ...tempValues.rateGuniteFuel, mobileMixersCostPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={roundToTwoDecimalPlacesIfNeeded(tempValues.rateGuniteFuel.mobileMixersCostPerGallon / tempValues.rateGuniteFuel.mobileMixersMilesPerGallon)}
                  readOnly
                  className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="border-navy-light border w-full mb-4" />

        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-2">
            Compressor clear
          </p>
          <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
              <div className="relative w-full">
                <input
                  value={tempValues.rateGuniteFuel.compressorClearMilesPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteFuel: { ...tempValues.rateGuniteFuel, compressorClearMilesPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.rateGuniteFuel.compressorClearCostPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteFuel: { ...tempValues.rateGuniteFuel, compressorClearCostPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={roundToTwoDecimalPlacesIfNeeded(tempValues.rateGuniteFuel.compressorClearCostPerGallon / tempValues.rateGuniteFuel.compressorClearMilesPerGallon)}
                  readOnly
                  className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="border-navy-light border w-full mb-4" />

        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-2">
            Compressor red
          </p>
          <div className="flex flex-row w-6/12 gap-2 min-[400px]:gap-5">
            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Gallons/Yard</label>
              <div className="relative w-full">
                <input
                  value={tempValues.rateGuniteFuel.compressorRedGallonsPerYard}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteFuel: { ...tempValues.rateGuniteFuel, compressorRedGallonsPerYard: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.rateGuniteFuel.compressorRedCostPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, rateGuniteFuel: { ...tempValues.rateGuniteFuel, compressorRedCostPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>

      </section>

      <section
        className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}>
        <h2 className="text-lg font-semibold text-dark-blue">
          Fuel (plaster)
        </h2>

        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-3">
            Slurry truck
          </p>

          <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
              <div className="relative w-full">
                <input
                  value={tempValues.ratePlasterFuel.slurryTruckCostPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, ratePlasterFuel: { ...tempValues.ratePlasterFuel, slurryTruckCostPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.ratePlasterFuel.slurryTruckMilesPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, ratePlasterFuel: { ...tempValues.ratePlasterFuel, slurryTruckMilesPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={roundToTwoDecimalPlacesIfNeeded(tempValues.ratePlasterFuel.slurryTruckMilesPerGallon / tempValues.ratePlasterFuel.slurryTruckCostPerGallon)}
                  readOnly
                  className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="border-navy-light border w-full mb-4" />

        <div className="flex flex-row w-full items-end justify-between mb-4">
          <p className="text-dark-blue text-sm mb-2">
            Mixers
          </p>
          <div className="flex flex-row w-9/12 gap-2 min-[400px]:gap-5">
            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Miles/Gallon</label>
              <div className="relative w-full">
                <input
                  value={tempValues.ratePlasterFuel.mixerMilesPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, ratePlasterFuel: { ...tempValues.ratePlasterFuel, mixerMilesPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Gallon</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={tempValues.ratePlasterFuel.mixerCostPerGallon}
                  onChange={(e) => setTempValues({ ...tempValues, ratePlasterFuel: { ...tempValues.ratePlasterFuel, mixerCostPerGallon: parseFloat(e.target.value) } })}
                  className="pl-6 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-opaque-job-details-gray border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-dark-blue py-1 text-xs inline-size-max">Cost/Mile</label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  value={roundToTwoDecimalPlacesIfNeeded(tempValues.ratePlasterFuel.mixerCostPerGallon / tempValues.ratePlasterFuel.mixerMilesPerGallon)}
                  readOnly
                  className="bg-navy-light bg-opacity-90 pl-6 text-opacity-80 text-right focus:border-navy-light focus:ring-0 w-full border border-[1px] text-gray-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-md px-2 py-2 focus-none"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='flex justify-between'>
        <button
          type="button"
          className={`text-sm font-semibold text-all-f-white py-[9px] px-3 bg-gunite-red rounded ${!checkForChanges() && 'opacity-40'}`}
          onClick={onCancel}
          disabled={!checkForChanges()}
        >
          Cancel
        </button>
        <button
          onClick={onSave}
          type="button"
          className={`text-sm font-semibold text-all-f-white py-[9px] px-3 bg-dark-blue rounded ${!checkForChanges() && 'opacity-40'}`}
          disabled={!checkForChanges()}
        >
          Submit
        </button>
      </div>
      <div className='flex pt-6 justify-between'>
        <div className='text-gunite-red font-medium text-xs'>
          {displayCancelChangesText && '*Changes have been reverted'}
        </div>
        <div className='text-dark-blue font-medium text-xs'>
          {displayChangesSavedText && '*Changes have been saved.'}
        </div>
      </div>
    </div>
  )
}
