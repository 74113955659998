/* External Imports */
import React, { useState } from 'react';
/* Internal Imports */
import { useAdminBranchMaterials } from '../../contexts/adminBranchMaterials';
import { useAdminMaterials } from '../../contexts/adminMaterials';

const AdminMaterialsEditTierModal = () => {
  const { editItemModal, setEditItemModal, handleSubmitEdit} = useAdminBranchMaterials();

  const { allSourceData } = useAdminMaterials();

  const [tempFormValues, setTempFormValues] = useState({
    pricePerSqFoot: editItemModal.itemData.pricePerSqFoot,
    costPerBag: editItemModal.itemData.costPerBag,
    spaPrice: editItemModal.itemData.spaPrice,
  })

  const resetToDefaults = () => {
    // get global values (defaults) from allSourceData.
    // find where there is a tier that matches current item's id.
    let globalVersionOfTier = null;
    for (let manufacturer of allSourceData) {
      for (let product of manufacturer.product) {
        for (let tier of product.tier) {
          if (tier.id == editItemModal.itemData.id) {
            globalVersionOfTier = tier;
            break;
          }
        }
      }
    }

    // set temp form vales
    setTempFormValues({
      pricePerSqFoot: globalVersionOfTier.pricePerSqFoot,
      costPerBag: globalVersionOfTier.costPerBag,
      spaPrice: globalVersionOfTier.spaPrice,
      totalIALarge: globalVersionOfTier.totalIALarge,
      spaAdd: globalVersionOfTier.spaAdd,
    })
  }

  const localHandleSubmitEdit = async () => {
    await handleSubmitEdit(tempFormValues)
    setEditItemModal({ ...editItemModal, open: false})
  }

  return (
    <div className='fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center'>
      <div className={`absolute z-10 flex flex-col h-auto sm:h-[350px] w-full sm:w-[600px] rounded-2xl border border-navy-light p-[20px] text-[14px] bg-navy-bright10 shadow-md justify-between items-center`}>
        <div className='w-full flex flex-row justify-between items-center'>
          <h1 className='text-[20px] font-[600]'>
            Edit tier - {editItemModal.itemData.name}
          </h1>
          <div className='p-1 cursor-pointer'
            onClick={() => setEditItemModal({ ...editItemModal, open: false})}
          >
            <img src='/JobDetails/close.svg' alt='close' className='h-[12px]' />
          </div>
        </div>
        <div className='w-full'>
          
        <div className='w-full flex flex-col flex-start'>
          <div className='flex flex-row justify-between gap-6'>
            <div
              className={`flex flex-col flex-grow w-[200px] items-start pb-[10px] pt-[3px]`}
            >
              <span className="text-gray-blue py-1">Price per sq ft</span>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span> 
                <input
                  className="w-full pl-6 pr-3 focus:border-navy-light focus:ring-0 border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-lg py-2 focus-none"
                  type="number"
                  disabled={false}
                  value={tempFormValues.pricePerSqFoot ?? 0}
                  onChange={e => setTempFormValues({ ...tempFormValues, pricePerSqFoot: e.target.value })}
                />
              </div>
            </div>
            <div
              className={`flex flex-col flex-grow w-[200px] items-end pb-[10px] pt-[3px]`}
            >
              <div className='flex flex-col justify-end w-full'>
                <span className="text-gray-blue py-1">SPA</span>
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    className="pl-6 w-full focus:border-navy-light focus:ring-0 border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-lg py-2 focus-none"
                    type="number"
                    disabled={false}
                    value={tempFormValues.spaPrice ?? 0}
                    onChange={e => setTempFormValues({ ...tempFormValues, spaPrice: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          
          <h2 className="pt-5 pb-2 text-dark-blue text-xl font-semibold">Cost</h2>
          <div
            className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}
          >
            <span className="text-gray-blue py-1">Cost per bag</span>
            <div className="relative w-full">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
              <input
                className="pl-6 focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-all-f-white bg-opacity-90 rounded-lg px-2 py-2 focus-none"
                type="number"
                disabled={false}
                value={tempFormValues.costPerBag ?? 0}
                onChange={e => setTempFormValues({ ...tempFormValues, costPerBag: e.target.value })}
              />
            </div>
          </div>
          <div className='flex flex-row justify-between items-center mt-4'>
            <button
                onClick={() => resetToDefaults()}
                className="bg-dark-blue py-[5px] rounded-[4px] bg-all-f-white border border-navy-light flex flex-row justify-center items-center px-2"
              >
                <p className="text-[13px] font-[450] text-all-f-white select-none" >
                  Reset to defaults
                </p>
              </button>
            <div className="flex flex-row gap-3 justify-end items-center">
              <button
                onClick={() => setEditItemModal({ ...editItemModal, open: false})}
                className="py-[5px] rounded-[4px] bg-all-f-white border border-navy-light flex flex-row justify-center items-center px-2"
              >
                <p className="text-[13px] font-[450] text-dark-blue select-none">
                  Cancel
                </p>
              </button>
              <button
                onClick={localHandleSubmitEdit}
                className="bg-dark-blue py-[5px] rounded-[4px] bg-all-f-white border border-navy-light flex flex-row justify-center items-center px-2"
              >
                <p className="text-[13px] font-[450] text-all-f-white select-none" >
                  Update
                </p>
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default AdminMaterialsEditTierModal;
