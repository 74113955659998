import React, { createContext, useContext, useState, useEffect } from 'react';
import serviceMethods from "../service";
import { useAdmin } from './admin';
import { useAdminRates } from './adminRates';
import { useAuth } from './auth';

export const AdminBranchRatesContext = createContext();

const AdminBranchRatesProvider = props => {
  const { getLocationIdFromUrl } = useAdmin();
  const { rate } = useAdminRates();
  const [locationRate, setLocationRate] = useState([])
  const { me } = useAuth()

  const updateLocationRate = async (formValues) => {
    const body = {
      locationId: getLocationIdFromUrl(),
      createdById: me.id,
      locationRateSuperObject: formValues
    }
    await serviceMethods.createLocationRate(body)
    refreshLocationRate()
  }

  const renameGlobalPropertiesToLocationProperties = (original) => ({
    locationRate: original.rate,
    locationRateGuniteFuel: original.rateGuniteFuel,
    locationRateGuniteMaterial: original.rateGuniteMaterial,
    locationRateInvoice: original.rateInvoice,
    locationRatePlasterFuel: original.ratePlasterFuel,
  });

  async function refreshLocationRate() {
    const locationId = parseInt(getLocationIdFromUrl())
    if (locationId){
      const res = await serviceMethods.readLocationRate(locationId)
      if (res.ok){
        // if there was none for this location, then we use the global rate
        if (res.status === 204){
          const renamedRateObject = renameGlobalPropertiesToLocationProperties(rate);
          setLocationRate(renamedRateObject)
        } else {
          const tempLocationRate = await res.json();
          setLocationRate(tempLocationRate)
        }
      }
    }
  }

  useEffect(()=>{
    refreshLocationRate()
  },[rate])

  return (
    <AdminBranchRatesContext.Provider value = {{
      locationRate,
      refreshLocationRate,
      renameGlobalPropertiesToLocationProperties,
      updateLocationRate
    }}>
      {props.children}
    </AdminBranchRatesContext.Provider>
  )
}

const useAdminBranchRates = () => useContext(AdminBranchRatesContext)
export { useAdminBranchRates }
export default AdminBranchRatesProvider;
