import React from "react";

const DateSelectorDay = ({ selectedDay, setSelectedDay, dateInfo, today, blank, allowSelectPast = false }) => {

  const isDaySelected = (remove = false) => {
    if (
      selectedDay && selectedDay?.getFullYear() === dateInfo.year
      && selectedDay?.getMonth() === dateInfo.month
      && selectedDay?.getDate() === dateInfo.day
    ) {
      if (remove) {
        setSelectedDay(null)
      }
      return true
    }
    return false
  }

  let selectedFlag = isDaySelected()

  const isToday = (
    today?.day === dateInfo?.day
    && today?.month === dateInfo?.month
    && today?.year === dateInfo?.year
  )
  const isPast = (
    dateInfo?.year < today?.year
    || (dateInfo?.year === today?.year && dateInfo?.month < today?.month)
    || (dateInfo?.year === today?.year && dateInfo?.month === today?.month && dateInfo?.day < today?.day)
  )

  const daySetter = () => {
    if (!allowSelectPast && (
      dateInfo.year < today.year ||
      (dateInfo.year === today.year && dateInfo.month < today.month) ||
      (dateInfo.year === today.year && dateInfo.month === today.month && dateInfo.day < today.day)
    )) {
        return;
    }

    // if already selected, deselect and return
    if (isDaySelected(true)) return

    // replace selectedDay with day clicked
    const date = new Date(dateInfo.year, dateInfo.month, dateInfo.day)
    setSelectedDay(date)

  }

  return (
    <div className={`w-[34px] h-[24px]`}>
      {blank ? '' : (
        <button
          className={
            `flex h-full w-full items-center justify-center font-bold text-xs text-dark-blue ${selectedFlag ? 'selected bg-dark-blue text-white rounded-md' : ''} ${isToday ? 'text-gunite-red' : ''} ${isPast ? 'text-gray-blue' : ''}`
          }
          onClick={(e) => {
            e.preventDefault()
            daySetter()
          }}
        >
          {dateInfo?.day}
        </button>
      )}

    </div>
  )
}

export default DateSelectorDay;
