import React, { useContext, createContext, useState, useEffect } from 'react';

export const ResponsiveContext = createContext();

const DEFAULT = {
  orientation: 'landscape',
  breakpoint: 'lg',
  width: window.innerWidth,
  height: window.innerHeight,
};

const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  xxxl: 1990,
};

const ResponsiveProvider = (props) => {
  const [responsive, setResponsive] = useState(DEFAULT);

  useEffect(() => {
    const updateResponsive = () => {
      const { innerHeight, innerWidth } = window;
      const orientation = innerWidth > innerHeight ? 'landscape' : 'portrait';
      let breakpoint = null;

      if (innerWidth < BREAKPOINTS.md) {
        breakpoint = 'sm';
      } else if (innerWidth >= BREAKPOINTS.md && innerWidth < BREAKPOINTS.lg) {
        breakpoint = 'md';
      } else if (innerWidth >= BREAKPOINTS.lg && innerWidth < BREAKPOINTS.xl) {
        breakpoint = 'lg';
      } else if (innerWidth >= BREAKPOINTS.xl && innerWidth < BREAKPOINTS.xxl) {
        breakpoint = 'xl';
      } else if (innerWidth >= BREAKPOINTS.xxl && innerWidth < BREAKPOINTS.xxxl) {
        breakpoint = 'xxl';
      } else if (innerWidth >= BREAKPOINTS.xxxl) {
        breakpoint = 'xxxl';
      }

      setResponsive({
        orientation,
        breakpoint,
        width: innerWidth,
        height: innerHeight,
      });
    };

    updateResponsive();
    window.addEventListener('resize', updateResponsive);
    return () => window.removeEventListener('resize', updateResponsive);
  }, []);

  return (
    <ResponsiveContext.Provider value={{ responsive, BREAKPOINTS }}>
      {props.children}
    </ResponsiveContext.Provider>
  );
};

const useResponsive = () => useContext(ResponsiveContext);

export { useResponsive };
export default ResponsiveProvider;
