import React, {useContext} from 'react';
import { FaPlus } from "react-icons/fa";
import { NavLink, useNavigate } from 'react-router-dom';
import { ProgressContext } from '../../contexts/progress';

const JobsListNewJobButton = () => {
  let navigate = useNavigate()
  const { setCurrentPosition, navigateToStart } = useContext(ProgressContext);

  const handleClick = () => {
    navigateToStart()
  }
  return (
    // <NavLink to='/'>
    <button onClick={handleClick} className="bg-red-500 text-white rounded-md px-4 py-2 flex items-center">
      <FaPlus className='w-3 h-3'/>
        &nbsp;
      New job
    </button>
    // </NavLink>
  );
};

export default JobsListNewJobButton;
