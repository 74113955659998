import { React, useState } from "react";

const CostBreakdownExpandableMenu = ({ i, text, subText, children }) => {
  const [isHidden, setIsHidden] = useState(false);

  function handleShowMoreClick() {
    setIsHidden(false);
  }

  function handleShowLessClick() {
    setIsHidden(true);
  }

  return (
    <div className='flex flex-col w-full'>
      <div className='flex justify-between w-full text-dark-blue font-semibold'>
        <div
          className='cursor-pointer items-end'
          onClick={isHidden ? handleShowMoreClick : handleShowLessClick}
        >
          <p className="text-2xl">
            {text}
          </p>

          <p className="text-md">
            {subText}
          </p>
        </div>

        <div className='expandable_menu_show_more_icon'
          onClick={isHidden ? handleShowMoreClick : handleShowLessClick}>
          {isHidden ? (
            <img src='/show_more_arrow.svg' alt='show more arrow' className="cursor-pointer" />
          ) : (
            <img src='/show_less_arrow.svg' alt='show less arrow' className="cursor-pointer" />
          )}
        </div>
      </div>

      <div
        className={`flex flex-col gap-6 ${!isHidden ? '' : 'hidden'}`}
      >
        {children}
      </div>
    </div>
  )
}

export default CostBreakdownExpandableMenu;
