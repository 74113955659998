import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './auth'
import Service from '../service'
import { useNavigate, useLocation } from 'react-router-dom';

const ForemanContext = createContext()

const DEFAULT_JOB = {
  scheduledDate: '',
  job: {
    jobName: '',
    address: '',
  },
  jobLog: {
    finishedJobPhotos: []
  }
}

const ForemanProvider = props => {
  const { me, logout } = useAuth()
  const [todaysJobs, setTodaysJobs] = useState([DEFAULT_JOB])
  const [locationCrewMembers, setLocationCrewMembers] = useState([])
  const [locationDrivers, setLocationDrivers] = useState([])
  const [selectedCalendarJob, setSelectedCalendarJob] = useState(DEFAULT_JOB)
  const [selectedCrewMembers, setSelectedCrewMembers] = useState([])
  const [selectedDrivers, setSelectedDrivers] = useState([])
  const [deletedLocationCrewMembers, setDeletedLocationCrewMembers] = useState([])
  const [deletedLocationDrivers, setDeletedLocationDrivers] = useState([])
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const [language, setLanguage] = useState('English')
  const { accessToken } = Service.getAuthTokens()
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const [allPersonnel, setAllPersonnel] = useState([])
  const [personnelLocation, setPersonnelLocation] = useState(1) //This is the id of the location which is being actively used to select drivers and crewMembers from.  Initialized as 1, then set to foreman's crew location upon page load.
  const [ loginLink, setLoginLink ] = useState(null)

  useEffect(()=>{
    if (!accessToken && !pathname.includes('onboard')) {
      navigate('/login')
      logout()
    }
  },[accessToken])

  useEffect(() => {
    const filteredForeman = me.foreman.filter(f => f.deletedAt === null)
    const selectedForeman = filteredForeman[0]
    if (selectedForeman) {
      const newTodaysJobs = selectedForeman.crew.calendarJobs.map((calendarJobCrew) => {
        return calendarJobCrew.calendarJob
      })
      if (newTodaysJobs.length) {
        setTodaysJobs(newTodaysJobs)
      }

      const newLocationCrewMembers = selectedForeman.crew.location.crews.flatMap((crew) =>
        crew?.crewMembers?.filter((member) => member?.deletedAt === null)
      )
      if (newLocationCrewMembers.length) {
        setLocationCrewMembers(newLocationCrewMembers)

        const newDeletedLocationCrewMembers = selectedForeman.crew.location.crews.flatMap((crew) =>
          crew?.crewMembers?.filter((member) => member?.deletedAt !== null)
        )
        setDeletedLocationCrewMembers(newDeletedLocationCrewMembers);
      }

      const newLocationDrivers = selectedForeman.crew.location.drivers
        .filter((driver) => driver?.deletedAt === null);
      if (newLocationDrivers.length) {
        setLocationDrivers(newLocationDrivers)
        const newDeletedLocationDrivers = selectedForeman.crew.location.drivers
          .filter((driver) => driver?.deletedAt !== null);
        setDeletedLocationDrivers(newDeletedLocationDrivers);
      }

      setPersonnelLocation(selectedForeman.crew.location.id)
      if (!loginLink) {
        async function fetchData() {
          const newLoginLink = await Service.getForemanLoginLink(me.email)
          if (newLoginLink) {
            setLoginLink(newLoginLink)
          }
        }
        fetchData()
      }
    }
  }, [me])

  useEffect(() => {
    const getPersonnelArray = async () => {
      const locationsArray = await Service.getPersonnelForAllLocations()
      setAllPersonnel(locationsArray)
    }
    getPersonnelArray()
  }, [])

  const updateLocationPersonnel = (personnelLocation) => {
    setPersonnelLocation(personnelLocation)
    setLocationDrivers(allPersonnel.find((location) => location.id === personnelLocation).drivers)
    setLocationCrewMembers(allPersonnel.find((location) => location.id === personnelLocation).crews.map((crew) => {
      return crew.crewMembers.map((crewMember) => crewMember)
    }).flat())
  }

  return (
    <ForemanContext.Provider value={{
      showMobileNav, setShowMobileNav,
      isAnimatingOut, setIsAnimatingOut,
      language, setLanguage,
      todaysJobs, setTodaysJobs,
      selectedCalendarJob, setSelectedCalendarJob,
      locationCrewMembers, setLocationCrewMembers,
      selectedCrewMembers, setSelectedCrewMembers,
      locationDrivers, setLocationDrivers,
      selectedDrivers, setSelectedDrivers,
      loginLink, DEFAULT_JOB,
      allPersonnel, setAllPersonnel,
      personnelLocation, setPersonnelLocation,
      updateLocationPersonnel,
      deletedLocationCrewMembers, setDeletedLocationCrewMembers,
      deletedLocationDrivers, setDeletedLocationDrivers
    }}>
      {props.children}
    </ForemanContext.Provider>
  )
}

const useForeman = () => useContext(ForemanContext)
export { useForeman }
export default ForemanProvider
