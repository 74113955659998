import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const CustomerMobileTopPane = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getTextForPage = () => {
    let text;
    if (pathname.includes('drafts')){
      text = 'Drafts'
    } else if (pathname.includes('account')){
      text = 'Account'
    } else if (pathname.includes('myjobs')){
      text = 'My Jobs'
    }
    return text;
  }

  return (
    <div className='flex lg:hidden flex-col gap-3 w-full px-6 py-5 bg-dark-blue/90'>
      <button>
        <div
          className='flex items-center gap-2'
          onClick={()=>{
            navigate(-1);
          }}
        >
          <img src='/back_arrow_red.svg' />
          <p className='font-semibold text-gunite-red'>Back</p>
        </div>
      </button>
      <div className='text-[24px] text-white font-bold'>
        {getTextForPage()}
      </div>
    </div>
  )
}

export default CustomerMobileTopPane
