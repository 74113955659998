import StatusCard from '../JobDetailsStatusCard';
import DetailsCard from '../JobDetailsDetailsCard';
import TabSelector from '../JobDetailsTabSelector';
import DetailsTab from '../JobDetailsDetailsTab';
import JobDetailsNotesTab from '../JobDetailsNotesTab';
import FilesTab from '../JobDetailsFilesTab';
import DailyJobLog from '../JobDetailsDailyJobLog';
import ActivityLogTab from '../JobDetailsActivityLogTab'
import JobDetailsPinnedNotes from '../JobDetailsPinnedNotes';
import CostBreakdown from '../CostBreakdown';

const JobDetailsBody = ({selectedContent, setSelectedContent}) => {
  return (
    <div className="py-4 flex-grow overflow-auto">
      <div className='px-4 flex flex-col lg:flex-row justify-between mb'>
        <StatusCard />
        <div className='w-4' />
        <DetailsCard />
      </div>

      {(selectedContent === "Notes" &&
        <JobDetailsPinnedNotes />
      )}

      <TabSelector
        selectedContent={selectedContent} setSelectedContent={setSelectedContent}
      />
      {/* tabs */}
      {(selectedContent === "Job Details") && (
        <DetailsTab />
      )}

      {(selectedContent === "Notes") && (
        <JobDetailsNotesTab />
      )}
      {(selectedContent === "Files") && (
          <FilesTab />
      )}
      {(selectedContent === "Daily job log") && (
          <DailyJobLog />
      )}
      {(selectedContent === "Activity log") && (
          <ActivityLogTab />
      )}
      {(selectedContent === "Cost Breakdown") && (
          <CostBreakdown />
      )}
    </div>
  );
};

export default JobDetailsBody;
