import React, { useState } from 'react'

const DriverView = () => {
 
  return (
    <div className='flex flex-col w-full'>
     <p>Driver Dashboard</p>
    </div>
  )
}

export default DriverView
