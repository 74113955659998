import React, { useEffect, useRef } from "react";
import { useSchedulerCalendar } from "../../contexts/schedulerCalendar";
import { useScheduler } from "../../contexts/scheduler";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";

function JFJobCard({ job: calendarJob }) {
  const { selectedScheduler, setSelectedJobId } = useScheduler()
  const { setShowJobDetailsModal } = useJobDetailsModal()
  const { location: { jobs: allJobs } } = selectedScheduler
  const { isDragging, handleDragStartFlash, handleDragEndFlash } = useSchedulerCalendar()
  const jobCardRef = useRef()

  useEffect(() => {
    const jobCard = jobCardRef.current
    jobCard.addEventListener('dragstart', handleDragStartFlash)
    jobCard.addEventListener('dragend', handleDragEndFlash)

    return () => {
      jobCard.removeEventListener('dragstart', handleDragStartFlash)
      jobCard.removeEventListener('dragend', handleDragEndFlash)
    }
  }, [])

  const jobDisplayDate = () => {
    if (calendarJob.job.requestedDate) {
      const date = new Date(calendarJob.job.requestedDate);
      return `R ${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`
    }
  }

  const jobDisplayDetails = {
    builder: calendarJob.job.owner.customer.bizName
  }

  const stateAbbreviator = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: "IL",
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VI',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY'
  }

  const tags = []

    //tags for all kinds of jobs
    if (calendarJob.job?.pools[0]?.newOrReplaster === 'Re-Plaster') {
      tags.push('R')
    }
    if (calendarJob.job?.isHudJob) {
      tags.push('H')
    }
    if (calendarJob.job?.pools[0]?.spa) {
      tags.push('S')
    }
    //series of tags for plaster only
    if (calendarJob.job.jobType === 'plaster') {
      if (calendarJob?.job?.pools[0]?.basin) {
        tags.push('B')
      }
    } else { //series of tags for gunite only
      if (calendarJob.job?.guniteType === 'ASR Certification') {
        tags.push('A')
      }
      if (calendarJob.job?.pools[0]?.negativeEdge) {
        tags.push('NE')
      }
      if (calendarJob.job?.pools[0]?.notch) {
        tags.push('N')
      }
      if (calendarJob?.job?.pools[0]?.raisedBeam) {
        tags.push('RB')
      }
      if (calendarJob?.job?.pools[0]?.retainingWalls) {
        tags.push('RW')
      }
    }

  return (
    <div
      ref={jobCardRef}
      className={`draggable flex w-full rounded-[8px] bg-subtle-grey text-dark-blue border shadow-md font-roboto text-[14px] ${isDragging ? '' : 'group hover:bg-card-bg-hover'}`}
      draggable='true'
      data-job={JSON.stringify(calendarJob)}
      onClick={async (e) => {
        setSelectedJobId(calendarJob.jobId)
        setShowJobDetailsModal(true)
      }}
    >

      <div className='flex flex-col w-[calc(100%-4px)] p-2'>
        <div className='flex justify-between'>
          <p className='font-[800]'>
            {calendarJob.job.jobName?.toUpperCase()}
          </p>
          <p className='text-gray-blue'>
            {jobDisplayDate()}
          </p>
        </div>

        <div className='flex flex-col'>
          <p className='font-[500]'>
            {calendarJob.job.address}, {calendarJob.job.city}, {stateAbbreviator[calendarJob.job.state]}
          </p>
          <p className='text-gray-blue leading-none'>
            {jobDisplayDetails?.builder}
          </p>
        </div>

        <div className='flex justify-between text-[12px] pt-[6px] items-center'>
          <p className='bg-navy-light group-hover:bg-card-accent-hover rounded-md h-fit mt-1 px-[8px] py-[4px] font-[600]'>
            {calendarJob.job.estimatedYards} Yards
          </p>
          <div className='tags flex flex-row'>
            {tags.map((tag, i) => (
              <p key={`tag${i}`} className='bg-navy-light group-hover:bg-card-accent-hover rounded-md h-fit p-2 ml-[4px] leading-none font-[600]'>
                {tag}
              </p>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}

export default JFJobCard;
