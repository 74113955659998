import React, { useState } from 'react'
import { useScheduler } from '../../contexts/scheduler'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'
import Crew from '../Crew'
import { getDay } from 'date-fns'

const CalendarDay = (props) => {
  const { interval, setInterval, displayMode, setDisplayMode, jobDisplay, setJobDisplay, days } = useSchedulerCalendar()
  const { undeletedCrews, crewColors } = useScheduler()
  const { day, dayIdx, dayJobs } = props //day is a Date object

  const crewJobsFilter = (i) => {
    return dayJobs?.filter((calendarJob) => (
      //not sure how to handle it if a calendarJob has multiple crews
      calendarJob?.crews[0]?.crew.id === undeletedCrews[i].id //this compares ids of calendarJobCrews and crews
    ))
  }

  const dayStrings = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]

  let colStartClasses = [
    '',
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6'
  ]

  const calendarDayDisplay = () => {
    if (interval === 'week' && displayMode === 'vertical') {
      return (
        <div className='flex flex-col h-full gap-1 p-1 bg-white rounded-lg'>
          <div className='flex gap-2 pl-2'>
            <p className='font-bold text-navy text-sm w-[18px]'>{day.getUTCDate()}</p>
            <p className='font-normal text-sm text-gray-blue'>{dayStrings[day.getUTCDay()]}</p>
          </div>
          <div className='flex flex-row gap-1 h-full overflow-x-auto'>
            {undeletedCrews?.map((crew, i) => <Crew crew={crew} i={i} day={day} key={`crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    } else if (interval === 'week' && displayMode === 'horizontal') {
      return (
        <div className='flex flex-col w-full p-1 rounded-lg bg-white'>
          <div className='flex gap-2 pl-2'>
            <p className='font-bold text-navy text-sm w-[18px]'>{day.getUTCDate()}</p>
            <p className='font-normal text-sm text-gray-blue'>{dayStrings[day.getUTCDay()]}</p>
          </div>
          <div className='flex flex-col gap-1 overflow-y-auto'>
            {undeletedCrews?.map((crew, i) => <Crew crew={crew} i={i} day={day} key={`crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    } else if (interval === 'month' && displayMode === 'vertical') {
      return (
        <div className={`flex flex-col gap-1 p-1 h-full rounded-lg bg-white ${dayIdx === 0 && colStartClasses[day.getUTCDay()]}`}>
          <p className='text-sm font-bold text-dark-blue'>{day.getUTCDate()}</p>
          <div className='flex gap-1 h-full overflow-x-auto'>
            {undeletedCrews?.map((crew, i) => <Crew crew={crew} i={i} day={day} key={`calendar-day-crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    } else if (interval === 'month' && displayMode === 'horizontal') {
      return (
        <div className={`flex flex-col gap-1 p-1 h-full rounded-lg bg-white ${dayIdx === 0 && colStartClasses[day.getUTCDay()]}`}>
          <p className='text-sm font-bold text-dark-blue'>{day.getUTCDate()}</p>
          <div className='flex flex-col gap-1'>
            {undeletedCrews?.map((crew, i) => <Crew i={i} crew={crew} day={day} key={`calendar-day-crew-${i}`} crewJobs={crewJobsFilter(i)} />)}
          </div>
        </div>
      )
    }
  }

  return (
    <>{calendarDayDisplay()}</>
  )
}

export default CalendarDay
