/* External Imports */
import React, { useState } from 'react';
/* Internal Imports */
import { useAdminMaterials } from '../../contexts/adminMaterials';

const AdminMaterialsAddOrEditItemModal = () => {
  const { addOrEditItemModal, setAddOrEditItemModal, handleAddOrEditItem, breadcrumbs, prevPageSelectedItem} = useAdminMaterials();

  const initialValue = addOrEditItemModal.editMode ? prevPageSelectedItem.name : '';
  const [value, setValue] = useState(initialValue);
  const title = () => {
    if (addOrEditItemModal.editMode) {
      switch(breadcrumbs?.currentPage) {
        case 'Products':
          return 'Edit manufacturer';
        case 'Tiers':
          return 'Edit product';
        case 'Colors':
          return 'Edit tier';
        case 'ColorDetails':
          return 'Edit color';
        default:
          return '';
      }
    }
    switch(breadcrumbs?.currentPage) {
      case 'Materials':
        return 'Add material';
      case 'Manufacturers':
        return 'Add manufacturer';
      case 'Products':
        return 'Add product';
      case 'Tiers':
        return 'Add tier';
      case 'Colors':
        return 'Add color';
      default:
        return '';
    }
  }

  return (
    <div className='fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center'>
      <div className={`absolute z-10 flex flex-col h-auto sm:h-[229px] w-full sm:w-[600px] rounded-2xl border border-navy-light p-[20px] text-[14px] bg-navy-bright10 shadow-md justify-between items-center`}>
        <div className='w-full flex flex-row justify-between items-center'>
          <h1 className='text-[20px] font-[600]'>
            {title()}
          </h1>
          <div className='p-1 cursor-pointer'
            onClick={() => setAddOrEditItemModal({ ...addOrEditItemModal, open: false})}
          >
            <img src='/JobDetails/close.svg' alt='close' className='h-[12px]' />
          </div>
        </div>
        <div className='w-full'>
          <label className='w-full'>Name *</label>
          <input 
            type='text'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className='w-full rounded'
          />

          <div className="w-full flex justify-end pt-[20px]">
            <button onClick={() => setAddOrEditItemModal({ ...addOrEditItemModal, open: false})} className="bg-white text-dark-blue rounded-md border border-gray-300 h-10 px-4 mr-2">
              Cancel
            </button>
            <button onClick={() => handleAddOrEditItem(addOrEditItemModal.editMode, {name: value})} 
                className={`bg-dark-blue text-white rounded-md h-10 px-4 ${!value ? 'cursor-not-allowed' : ''}`}
                disabled={!value}
            >
              {addOrEditItemModal.editMode ? 'Update' : 'Add'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )}

export default AdminMaterialsAddOrEditItemModal;
