import React, { useEffect } from 'react';
import './index.scss';
import { useProgress } from '../../contexts/progress';
import { useLocation } from 'react-router-dom';

function ProgressBar({ totalSteps }) {
  const { pathname } = useLocation();
  const {
    currentJobSiteStepGunite,
    currentJobSiteStepPlaster,
  } = useProgress();

  let possibleCombinationsGunite = [];
  let possibleCombinationsPlaster = [];
  for (let j = 0; j < totalSteps; j++) {
    possibleCombinationsGunite.push('t');
    possibleCombinationsPlaster.push('t');
  }

  for (let i = 0; i < totalSteps; i++) {
    if (currentJobSiteStepGunite < totalSteps) {
      possibleCombinationsGunite[i] = 'red';
      if (i >= 1) {
        possibleCombinationsGunite[i - 1] = 'blue';
      }
    } else if (currentJobSiteStepGunite === totalSteps) {
      for (let g = 0; g < possibleCombinationsGunite.length; g++) {
        possibleCombinationsGunite[g] = 'blue';
      }
      let lastArrayItem = possibleCombinationsGunite.length - 1;
      possibleCombinationsGunite[lastArrayItem] = 'red';
    }
    if (i === currentJobSiteStepGunite - 1) {
      break;
    }
  }

  for (let i = 0; i < totalSteps; i++) {
    if (currentJobSiteStepPlaster < totalSteps) {
      possibleCombinationsPlaster[i] = 'red';
      if (i >= 1) {
        possibleCombinationsPlaster[i - 1] = 'blue';
      }
    } else if (currentJobSiteStepPlaster === totalSteps) {
      for (let g = 0; g < possibleCombinationsPlaster.length; g++) {
        possibleCombinationsPlaster[g] = 'blue';
      }
      let lastArrayItem = possibleCombinationsPlaster.length - 1;
      possibleCombinationsPlaster[lastArrayItem] = 'red';
    }
    if (i === currentJobSiteStepPlaster - 1) {
      break;
    }
  }

  const colorMapGunite = {
    't': {
      source: '/progress_transparent_gunite.svg',
      alt: 'unfinished step',
    },
    'red': {
      source: '/progress_red_gunite.svg',
      alt: 'current step',
    },
    'blue': {
      source: '/progress_blue_gunite.svg',
      alt: 'previous step',
    }
  }

  const colorMapPlaster = {
    't': {
      source: '/progress_transparent.svg',
      alt: 'unfinished step',
    },
    'red': {
      source: '/progress_red.svg',
      alt: 'current step',
    },
    'blue': {
      source: '/progress_blue.svg',
      alt: 'previous step',
    }
  }

  return (
    <>
      {pathname.includes('gunite') ?
        <div className='flex mt-[20px]'>
          {possibleCombinationsGunite.map((combination, index) => {
            const { source, alt } = colorMapGunite[combination];
            return (
              <img
                key={index}
                src={source}
                alt={alt}
              />
            );
          })}
        </div>
        :
        <div className='flex mt-[20px]'>
          {possibleCombinationsPlaster.map((combination, index) => {
            const { source, alt } = colorMapPlaster[combination];
            return (
              <img
                key={index}
                src={source}
                alt={alt}
              />
            );
          })}
        </div>
      } 
    </>
  )
}

export default ProgressBar;