import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

const MobileNavLogout = () => {
  const { logout } = useAuth();

    return (
      <div onClick={()=>{
        logout();
      }}>
        <button className='flex flex-row w-full items-center'>
          <img src="/sidebar/logout_icon.svg" alt="Logout Icon" className='w-[27px] mr-8' />
          <p className='text-xl text-white'>Sign out</p>
        </button>
      </div>
    );
};

export default MobileNavLogout;