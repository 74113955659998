import React from 'react'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'
import { useScheduler } from '../../contexts/scheduler'
import { format } from 'date-fns'
import { useAuth } from '../../contexts/auth'
import SchedulerLocationDropdown from '../SchedulerLocationDropdown'
import cookies from 'browser-cookies'

const CalendarDisplaySettingsBar = () => {
  const { interval, setInterval, displayMode, setDisplayMode, days, previousWeek, nextWeek, previousMonth, nextMonth, setCurrentDay, today } = useSchedulerCalendar()
  const { selectedScheduler, setSelectedScheduler, locationDropdownOptions, crewColors, undeletedCrews } = useScheduler()
  const { me } = useAuth();


  const crewButtonStyles = (i) => {
    const fixedValues = 'flex justify-center items-center h-10 w-10 rounded-full border-4 border-[#f3f5f9] -ml-[11px] font-bold pt-[1px]'
    const styles = fixedValues.concat(' ', crewColors[i])
    return styles
  }

  const intervalDisplayString = () => {
    if (interval === 'week') {
      const day1 = format(days[1], 'LLL d')
      const day6 = format(days[6], 'LLL d')
      return `${day1} – ${day6}`
    } else {
      return format(days[1], 'LLLL yyyy')
    }
  }

  const previousInterval = () => {
    if (interval === 'week') previousWeek()
    else previousMonth()
  }

  const nextInterval = () => {
    if (interval === 'week') nextWeek()
    else nextMonth()
  }

  const setToday = () => {
    setCurrentDay(today)
  }

  return (
    <div className='flex justify-between items-center w-full h-[48px] px-6 py-2 text-navy font-roboto bg-navy-bright/10'>
      <div className='flex gap-4 items-center'>
        <p className='text-lg font-bold'>Calendar</p>
        <button className='rounded h-8 px-2 py-1 bg-white border-navy-light border-[1px] text-[12px] font-semibold'
          onClick={() => setToday()}
        >Today</button>
        <div className='flex justify-center w-[100px]'>
          <p className='text-[12px] font-semibold'>{intervalDisplayString()}</p>
        </div>
        <div className='items-center flex'>
          <button onClick={() => previousInterval()}><img src='/mgmtVectors/chevron_left.svg'></img></button>
          <button onClick={() => nextInterval()}><img src='/mgmtVectors/chevron_right.svg'></img></button>
        </div>
        <div className='p-[2px] h-8 flex rounded-[6px] bg-navy-bright/10 text-[12px] font-bold'>
          <button
            className={`px-2 py-[2px] rounded ${interval === 'month' ? 'bg-navy text-white' : ''}`}
            onClick={() => setInterval('month')}
          >Month</button>
          <button
            className={`px-2 py-[2px] rounded ${interval === 'week' ? 'bg-navy text-white' : ''}`}
            onClick={() => setInterval('week')}
          >Week</button>
        </div>
        <div className='flex gap- p-[2px] rounded-[6px] bg-navy-bright/10'>
          <button
            className={`p-[2px] ${displayMode === 'vertical' ? 'bg-navy rounded' : ''}`}
            onClick={() => setDisplayMode('vertical')}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill='none' xmlns="http://www.w3.org/2000/svg">
              <g id="&#60;Switch&#62;/icon">
                <path id="icon" d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9C9.55 3 10.0208 3.19583 10.4125 3.5875C10.8042 3.97917 11 4.45 11 5V19C11 19.55 10.8042 20.0208 10.4125 20.4125C10.0208 20.8042 9.55 21 9 21H5ZM15 21C14.45 21 13.9792 20.8042 13.5875 20.4125C13.1958 20.0208 13 19.55 13 19V5C13 4.45 13.1958 3.97917 13.5875 3.5875C13.9792 3.19583 14.45 3 15 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H15ZM19 5H15V19H19V5ZM15 5H19V19H15V5Z" fill={displayMode === 'vertical' ? 'white' : "#00154D"} /> {/*svg does not give the right color if you type 'navy' for the fill property so I had to put the hex code to make it accurate*/}
              </g>
            </svg>
          </button>
          <button
            className={`p-[2px] ${displayMode === 'horizontal' ? 'bg-navy rounded' : ''}`}
            onClick={() => setDisplayMode('horizontal')}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="&#60;Switch&#62;/icon">
                <path id="icon" d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V15C3 14.45 3.19583 13.9792 3.5875 13.5875C3.97917 13.1958 4.45 13 5 13H19C19.55 13 20.0208 13.1958 20.4125 13.5875C20.8042 13.9792 21 14.45 21 15V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 11C4.45 11 3.97917 10.8042 3.5875 10.4125C3.19583 10.0208 3 9.55 3 9V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V9C21 9.55 20.8042 10.0208 20.4125 10.4125C20.0208 10.8042 19.55 11 19 11H5ZM19 5H5V9H19V5ZM19 9H5V5H19V9Z" fill={displayMode === 'horizontal' ? 'white' : "#00154D"} />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div className='flex gap-4'>
        {me.scheduler.length > 1 &&
          <SchedulerLocationDropdown
            value={selectedScheduler?.location?.id}
            placeholder={'Schedule Location:'}
            options={locationDropdownOptions}
            onChange={(e) => {
              const selectedLocationId = parseInt(e.target.value);
              const matchingScheduler = me?.scheduler?.find((item => item.location.id === selectedLocationId));
              if (matchingScheduler) {
                cookies.set('schedulerId', matchingScheduler.id.toString());
                setSelectedScheduler(matchingScheduler);
              }
            }}
          />
        }
        <div className='flex flex-row'>
          {undeletedCrews?.map((crew, i) => {
            return (
              <div className={crewButtonStyles(i)} key={`crew-button${i}`} title={crew.name} >
                {crew.name.charAt(0)}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CalendarDisplaySettingsBar
