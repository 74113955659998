import React, { useState, useEffect } from "react";
import CostBreakdownInput from "../CostBreakdownInput";
import CostBreakdownAddDriver from "../CostBreakdownAddDriver";


const CostBreakdownDrivers = ({ drivers, breakdown, setBreakdown, employerTaxRate, savedCostBreakdown, resetToggle, DEFAULT_DRIVER }) => {
  const [driversUsed, setDriversUsed] = useState([])
  const [addingActive, setAddingActive] = useState(false)
  const [driverTotal, setDriverTotal] = useState({ total: 0, withTax: 0 })

  const updateState = (e, i, field) => {
    const newDriverList = []

    newDriverList.push(
      ...driversUsed.slice(0, i),
      {
        ...driversUsed[i],
        [field]: e.target.value
      },
      ...driversUsed.slice(i + 1),
    )

    setDriversUsed(newDriverList)
  }

  const calculateDriverTotal = () => {
    // math to calculate overall total
    const total = driversUsed.reduce((accumulator, item) => {
      const pools = item.pools ? Number(item.pools) : 0
      const poolRate = item.poolRate ? Number(item.poolRate) : 0
      const travel = item.travel ? Number(item.travel) : 0
      const extras = item.extras ? Number(item.extras) : 0
      return accumulator += ((pools * poolRate) + travel + extras)
    }, 0)
    const totalWithTax = (total + (total * employerTaxRate))

    setDriverTotal({ total: Number(total), withTax: Number(totalWithTax) })

    // update breakdown
    setBreakdown((breakdown) => {
      return {
        ...breakdown,
        drivers: {
          items: driversUsed,
          total: {
            total: total,
            withTax: totalWithTax
          }
        }
      }
    })
  }

  const calculateRowTotal = (row) => {
    // math to calculate row total
    const pools = row.pools ? Number(row.pools) : 0
    const poolRate = row.poolRate ? Number(row.poolRate) : 0
    const travel = row.travel ? Number(row.travel) : 0
    const extras = row.extras ? Number(row.extras) : 0
    return ((pools * poolRate) + travel + extras).toFixed(2)
  }

  const handleDeleteRow = (i) => {
    setDriversUsed([
      ...driversUsed.slice(0, i),
      ...driversUsed.slice(i + 1)
    ])
  }

  useEffect(() => {
    const driverArray = []
    if (savedCostBreakdown) {
      driverArray.push(...savedCostBreakdown.drivers)
    } else {
      driverArray.push(...(drivers.drivers || []))
    }
    setDriversUsed(driverArray)
  }, [drivers.drivers, savedCostBreakdown, resetToggle])

  useEffect(() => {
    calculateDriverTotal()
  }, [driversUsed, breakdown.crew])

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      {driversUsed.map((driver, i) => {
        return (
          <div
            className='flex flex-col md:flex-row w-full'
            key={`driver${i + 1}`}
          >
            <div className='flex flex-col w-full'>
              <div
                className={`w-full flex flex-col md:flex-row gap-2 md:gap-4 md:items-end pt-2 mb-4 ${i > 0 ? 'border-t' : ''}`}
              >
                <p className='md:pb-3 text-2xl md:text-lg md:w-1/4 text-dark-blue'>
                  {driver.name}
                </p>

                <div className='flex md:w-1/4'>
                </div>

                <div className='flex items-end md:w-[95px]'>
                  <CostBreakdownInput
                    value={driver.pools}
                    placeholder='Pools'
                    numbersOnly
                    onChange={(e) => {
                      updateState(e, i, 'pools')
                    }}
                  />
                </div>

                <div className='flex flex-col md:flex-row items-end md:w-3/4 gap-2 md:gap-4'>
                  <CostBreakdownInput
                    value={driver.poolRate}
                    placeholder='Pool rate'
                    money
                    disabled
                    edit
                    onChange={(e) => {
                      updateState(e, i, 'poolRate')
                    }}
                  />
                  <CostBreakdownInput
                    value={driver.travel}
                    placeholder='Travel'
                    money
                    onChange={(e) => {
                      updateState(e, i, 'travel')
                    }}
                  />
                  <CostBreakdownInput
                    value={driver.extras}
                    placeholder='Extras'
                    money
                    onChange={(e) => {
                      updateState(e, i, 'extras')
                    }}
                  />
                </div>

                <div className='block md:w-1/4'>
                  <p className='text-xs text-dark-blue pb-[2px] pt-[5px]'>Total</p>
                  <div className='w-full px-3 py-2 rounded border border-tertiary-blue text-opaque-job-details-gray bg-disabled-gray'>
                    ${calculateRowTotal(driver)}
                  </div>
                </div>

              </div>

              <div className={`pb-4 ${driver.extras > 0 ? '' : 'hidden'}`}>
                <CostBreakdownInput
                  value={driver.extrasReason || ''}
                  placeholder='Extras reason'
                  onChange={(e) => {
                      updateState(e, i, 'extrasReason')
                    }}
                />
              </div>

            </div>

            <div className='flex h-10 w-full justify-end md:w-12 mb-1 md:mt-8'>
              <button
                className="flex w-12 h-8 p-1 text-gunite-red cursor-pointer items-center justify-center"
                onClick={() => { handleDeleteRow(i) }}
              >
                <img
                  src='/JobDetails/trash_icon.svg'
                  alt='trashcan icon'
                />
              </button>
            </div>
          </div>
        )
      })}

      {addingActive ?
        <CostBreakdownAddDriver
          setAddingActive={setAddingActive}
          driversUsed={driversUsed}
          defaultCrew={DEFAULT_DRIVER}
          add={(member) => {
            setDriversUsed([...driversUsed, member])
          }}
        />
        :
        <div className='flex justify-end gap-4'>
          <button
            className='w-full border border-navy-light rounded text-dark-blue py-2 px-4 mt-4 font-semibold hover:border-dark-blue'
            onClick={() => {
              setAddingActive(true)
            }}
          >
            + Add new
          </button>
        </div>
      }

      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total drivers cost
          </p>
          <div className='flex justify-between w-[100px]'>
            <p>$</p>
            <p>{driverTotal.total.toFixed(2)}</p>
          </div>
        </div>

        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total drivers plus taxes
          </p>
          <div className='flex justify-between w-[100px]'>
            <p>$</p>
            <p>{driverTotal.withTax.toFixed(2)}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CostBreakdownDrivers;
