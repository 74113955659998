import { useAdminMaterials } from '../../contexts/adminMaterials'
import { useState } from 'react'

const AdminMaterialsTierDetails = () => {
  const { prevPageSelectedItem, handleUpdateItem } = useAdminMaterials();

  return (
    <div className="w-full">
        <div className='w-full flex flex-col flex-start'>
          <h3 className='pt-1 text-gray-blue text-sm'>Name</h3>
          <p className='font-semibold text-md text-dark-blue'>{prevPageSelectedItem.name}</p>
        </div>
        <div className='flex flex-row'> 
          <div className='w-1/2'> 
            <h3 className='pt-1 text-gray-blue text-sm'>Price per sq ft</h3>
            <p className='font-semibold text-md text-dark-blue'>${prevPageSelectedItem.pricePerSqFoot}</p>
          </div>
          <div className='w-1/2'> 
            <h3 className='pt-1 text-gray-blue text-sm'>SPA</h3>
            <p className='font-semibold text-md text-dark-blue'>${prevPageSelectedItem.spaPrice}</p>
          </div>
        </div>
        <h2 className="pt-5 pb-2 text-dark-blue text-xl font-semibold">Replaster</h2>
        <div className='flex flex-row'> 
          <div className='w-1/2'> 
            <h3 className='pt-1 text-gray-blue text-sm'>Cost per bag</h3>
            <p className='font-semibold text-md text-dark-blue'>${prevPageSelectedItem.costPerBag}</p>
          </div>
        </div>
    </div>
  );
};

export default AdminMaterialsTierDetails;