const JobStatusIcon = ({ job, px = "px-3", py = "py-1.5" }) => {
  const className = `rounded-full ${px} ${py} flex justify-center items-center gap-1`;

  if (job.archivedAt) {
    return (
      <div className={`bg-pale-green ${className}`}>
        <img src='/JobsList/completed_icon.svg' alt='completed' className='select-none fill-solid-green w-[18px]' />
        <p className='text-solid-green font-medium text-xs select-none'>Archived</p>
      </div>
    );
  } else if (job.completedAt) {
    return (
      <div className={`bg-pale-green ${className}`}>
        <img src='/JobsList/completed_icon.svg' alt='completed' className='select-none fill-solid-green w-[18px]' />
        <p className='text-solid-green font-medium text-xs select-none'>Completed</p>
      </div>
    );
  } else if (job.workCompletedAt) {
    return (
      <div className={`bg-pale-green ${className}`}>
        <img src='/JobsList/completed_icon.svg' alt='completed' className='select-none fill-solid-green w-[18px]' />
        <p className='text-solid-green font-medium text-xs select-none'>Work completed</p>
      </div>
    );
  } else if (job.scheduledAt) {
    return (
      <div className={`bg-dark-blue ${className}`}>
        <img src='/JobsList/scheduled_icon.svg' alt='Scheduled' className='select-none fill-solid-green w-[18px]' />
        <p className='text-white font-medium text-xs select-none'>Scheduled</p>
      </div>
    );
  } else if (job.backloggedAt) {
    return (
      <div className={`bg-navy-light ${className}`}>
        <img src='/JobsList/backlog_icon.svg' alt='Backlog' className='select-none w-[18px]' />
        <p className='text-dark-blue font-medium text-xs select-none'>Backlog</p>
      </div>
    );
  } else if (job.readyAt) {
    return (
      <div className={`bg-navy-light/50 ${className}`}>
        <img src='/JobsList/ready_for_calendar_icon.svg' alt='Ready for calendar' className='select-none w-[14px]' />
        <p className='text-dark-blue font-medium text-xs select-none'>Ready for Calendar</p>
      </div>
    );
  } else if (job.submittedAt) {
    return (
      <div className={`bg-navy-bright/50 ${className}`}>
        <img src='/JobsList/intake_icon.svg' alt='Intake' className='select-none w-[14px]' />
        <p className='text-dark-blue font-medium text-xs select-none'>Intake</p>
      </div>
    );
  }
};

export default JobStatusIcon;
