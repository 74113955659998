import React, { useState, useEffect } from "react";
import CostBreakdownSelector from "../CostBreakdownSelector";
import CostBreakdownInput from "../CostBreakdownInput";

const DEFAULT_NEW_MATERIAL = {
  name: '',
  bags: 0,
  costBag: 0,
}

const CostBreakdownAddMaterial = ({ setAddingActive, add, options = {} }) => {
  const [newMaterial, setNewMaterial] = useState(DEFAULT_NEW_MATERIAL)
  const [disableAdd, setDisableAdd] = useState(false)

  const updateState = (e, field) => {
    if (field === 'name') {
      setNewMaterial(
        {
          ...newMaterial,
          [field]: e.target.value,
          costBag: Number(options[e.target.value].price)
        }
      )
    } else {
      setNewMaterial(
        {
          ...newMaterial,
          [field]: e.target.value,
        }
      )
    }
  }

  useEffect(() => {
    if (newMaterial.name === '' || !newMaterial.bags || newMaterial.bags === '0') {
      setDisableAdd(true)
    } else {
      setDisableAdd(false)
    }
  }, [newMaterial.name, newMaterial.bags])

  return (

    <div className='flex flex-col w-full p-4 mt-4 justify-center rounded border bg-subtle-grey gap-4'>
      <div className='flex flex-col md:flex-row items-end gap-4'>
        <div className='flex w-full md:w-1/2'>
          <CostBreakdownSelector
            value={newMaterial.name}
            options={Object.keys(options)}
            placeholder='Product / Service'
            onChange={(e) => {
              updateState(e, 'name')
            }}
          />
        </div>

        <div className='flex flex-col md:flex-row items-end w-full md:w-1/2 gap-4'>
          <CostBreakdownInput
            value={newMaterial.bags || ''}
            placeholder='Bags'
            onChange={(e) => {
              updateState(e, 'bags')
            }}
          />
          <CostBreakdownInput
            value={newMaterial.costBag}
            placeholder='Cost/Bag'
            money
            disabled
            edit
            onChange={(e) => {
              updateState(e, 'costBag')
            }}
          />
          <CostBreakdownInput
            value={(newMaterial.bags * newMaterial.costBag).toFixed(2)}
            placeholder='Total'
            money
            disabled
          />
        </div>
      </div>

      <div className='flex justify-end gap-4'>
        <button
          className='border border-navy-light rounded bg-all-f-white py-2 px-3 font-semibold text-dark-blue hover:text-white hover:bg-gunite-red'
          onClick={() => {
            setAddingActive(false)
          }}
        >
          Cancel
        </button>
        <button
          className={`border border-navy-light rounded py-2 px-4 font-semibold  ${disableAdd ? 'text-opaque-job-details-gray bg-disabled-gray' : 'bg-white text-dark-blue hover:bg-dark-blue hover:text-white'}`}
          disabled={disableAdd}
          onClick={() => {
            setAddingActive(false)
            add(newMaterial)
          }}
        >
          Add
        </button>
      </div>

    </div>
  );
};

export default CostBreakdownAddMaterial;
