import React from 'react'
import { useIntakeForm } from "../../contexts/intakeForm"
import serviceMethods from "../../service";

const Trashcan = ({ i, trashcanClicked = ()=>{} }) => {

  const { jobDetails, setJobDetails, poolDetails, setPoolDetails } = useIntakeForm()

  const removeJob = async (i) => {
    let jobs = jobDetails
    let pools = poolDetails

    const jobId = jobDetails[i].id
    const poolId = poolDetails[i].id

    if (poolId || jobId) {
      const deleteRes = await serviceMethods.deletePoolAndJobById(poolId, jobId)

      if (!deleteRes.ok) {
      }
    }

    jobs = [...jobs.slice(0, i), ...jobs.slice(i + 1)]
    pools = [...pools.slice(0, i), ...pools.slice(i + 1)]
    setJobDetails(jobs)
    setPoolDetails(pools)
  }

  return (
    <div className="flex justify-end">
      <div className='pr-[10px]'>
        <img
          src='/trashcan_icon.svg'
          alt='trashcan icon'
          className="cursor-pointer"
          onClick={()=>{
            trashcanClicked()
            removeJob(i)
          }}
        />
      </div>
    </div>
  )
}

export default Trashcan