import React from 'react'

const DailyJobLogPhoto = ({ apiUrl, fileUri, id, deletePhoto, isEditing }) => {

  return (
    <div className='lg:w-1/3 p-[2px] relative'>
      {isEditing
?
      <div className='bg-white absolute cursor-pointer'>
        <img src='/trashcan_icon.svg' onClick={() => { deletePhoto(id) }} />
      </div>
:
null
      }
      <img className='' crossOrigin='anonymous' src={`${apiUrl}/${fileUri}`} alt='proof of work' />
    </div>
  )
}

export default DailyJobLogPhoto
