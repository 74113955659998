import React from "react";

const CostBreakdownSubmittedCrew = ({ savedCostBreakdown, employerTaxRate }) => {
  const crewMembers = []
  const contractors = []
  savedCostBreakdown?.crew?.forEach((crew) => {
    if (crew.isContractor) {
      contractors.push(crew)
    } else {
      crewMembers.push(crew)
    }
  })

  const calculateTotal = (tax) => {
    const crewTotal = crewMembers?.reduce((accumulator, item) => {
      const pools = item.pools ? Number(item.pools) : 0
      const poolRate = item.poolRate ? Number(item.poolRate) : 0
      const travel = item.travel ? Number(item.travel) : 0
      const extras = item.extras ? Number(item.extras) : 0
      return accumulator += ((pools * poolRate) + travel + extras)
    }, 0)

    const contractorTotal = contractors?.reduce((accumulator, item) => {
      const amount = item.contractorAmount ? Number(item.contractorAmount) : 0
      const price = item.contractorPrice ? Number(item.contractorPrice) : 0

      return accumulator += (amount * price)
    }, 0)

    const total = (crewTotal || 0) + (contractorTotal || 0)

    if (tax) {
      return Number(total + (total * employerTaxRate)).toFixed(2)
    } else {
      return Number(total).toFixed(2)
    }
  }

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      <div className='hidden md:flex w-full text-opaque-job-details-gray'>

        <div className='flex w-5/12'>
          <p className='flex justify-start w-1/2 pl-7'>Name</p>
          <p className='flex justify-end w-1/2'>Role</p>
        </div>

        <div className='flex w-4/12 justify-end'>
          <p className='flex justify-end w-2/6'>Pools</p>
          <p className='flex justify-end w-3/6'>Pool rate</p>
          <p className='flex justify-end w-2/6'>Travel</p>
        </div>

        <div className='flex w-3/12'>
          <p className='flex justify-end w-5/12'>Extras</p>
          <p className='flex justify-end w-7/12'>Total</p>
        </div>

        <div className='w-[32px]'></div>
      </div>

      {crewMembers?.map((crewMember, i) => {
        return (
          <div
            className={`flex flex-col ${i === 0 ? '' : 'border-t'}`}
            key={`crew${i + 1}`}
          >
            <div className='flex flex-col md:flex-row w-full py-4 md:items-center text-dark-blue'>
              <div className='flex flex-col md:flex-row w-full md:w-5/12'>
                <div className='flex w-full md:w-1/2'>
                  <p className='flex pr-4 items-center text-opaque-job-details-gray'>{i + 1}.</p>
                  <p className='flex justify-start w-full md:w-1/2 items-center whitespace-nowrap text-ellipsis font-semibold'>{crewMember.name || ''}</p>
                </div>
                <div className='flex md:w-1/2 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Role:</p>
                  <p className='flex w-full items-center justify-end whitespace-nowrap text-ellipsis'>{crewMember.role || ''}</p>
                </div>
              </div>

              <div className='flex flex-col md:flex-row w-full md:w-4/12 justify-end'>
                <div className='flex md:w-2/6 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Pools:</p>
                  <p className='flex w-full justify-end  items-center'>
                    {crewMember.pools || ''}
                  </p>
                </div>

                <div className='flex md:w-3/6 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Pool Rate:</p>
                  <p className='flex justify-end w-full items-center'>
                    ${(Number(crewMember.poolRate) || 0).toFixed(2)}
                  </p>
                </div>

                <div className='flex md:w-2/6 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Travel:</p>
                  <p className='flex justify-end w-full items-center'>
                    ${(Number(crewMember.travel) || 0).toFixed(2)}
                  </p>
                </div>

              </div>

              <div className='flex flex-col md:flex-row w-full md:w-3/12'>
                <div className='flex md:w-5/12 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>
                    Extras:
                  </p>
                  <p className='flex justify-end w-full items-center'>
                    ${(Number(crewMember.extras) || 0).toFixed(2)}
                  </p>
                </div>

                <div className='flex md:w-7/12'>
                  <p className='w-1/2 md:hidden'>Total:</p>
                  <p className='flex justify-end w-full items-center'>
                    ${(( Number(crewMember.poolRate) * Number(crewMember.pools) ) + Number(crewMember.travel) + Number(crewMember.extras)).toFixed(2)}
                  </p>
                </div>

              </div>

              <div className='w-[32px]'></div>

            </div>

            <p className={`text-opaque-job-details-gray px-7 pb-2 mt-[-10px] ${crewMember?.extrasReason?.length !== 0 ? '' : 'hidden'}`}>
              Extras reason: {crewMember.extrasReason}
            </p>

          </div>
        )
      })}

      {contractors.length > 0 &&
        <div className='md:flex md:flex-col'>
          <p className='py-4 font-bold text-dark-blue text-xl'>Contractors</p>

          <div className='hidden md:flex w-full text-opaque-job-details-gray'>

            <div className='flex w-5/12'>
              <p className='flex justify-start pl-7'>Name</p>
            </div>

            <div className='flex w-5/12 justify-end'>
              <p className='flex justify-end w-3/6'>Amount</p>
              <p className='flex justify-end w-3/6'>Price</p>
            </div>

            <div className='flex justify-end w-2/12'>
              <p className='flex '>Total</p>
            </div>

            <div className='w-[32px]'></div>
          </div>
        </div>
      }


      {contractors?.map((contractor, i) => {
        return (
          <div
            className={`flex flex-col ${i === 0 ? '' : 'border-t'}`}
            key={`crew${i + 1}`}
          >
            <div className='flex flex-col md:flex-row w-full py-4 md:items-center text-dark-blue'>
              <div className='flex w-5/12'>
                <p className='flex pr-4 items-center text-opaque-job-details-gray'>{i + 1}.</p>
                <p className='flex justify-start items-center whitespace-nowrap text-ellipsis font-semibold'>{contractor.name || ''}</p>
              </div>

              <p className={`md:hidden text-opaque-job-details-gray px-7 py-2 mt-[-10px] ${contractor.contractorService.length !== 0 ? '' : 'hidden'}`}>
                {contractor.contractorService}
              </p>

              <div className='flex flex-col md:flex-row w-full md:w-5/12 justify-end'>
                <div className='flex w-full md:w-3/6 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Amount:</p>
                  <p className='flex w-full justify-end items-center'>
                    {contractor.contractorAmount || ''}
                  </p>
                </div>

                <div className='flex w-full md:w-3/6 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Price:</p>
                  <p className='flex w-full justify-end items-center'>
                    ${(Number(contractor.contractorPrice) || 0).toFixed(2)}
                  </p>
                </div>

              </div>

              <div className='flex md:justify-end w-full md:w-2/12'>
                <p className='w-1/2 md:hidden'>Total:</p>
                <p className='flex w-full justify-end items-center'>
                  ${( Number(contractor.contractorAmount) * Number(contractor.contractorPrice)).toFixed(2)}
                </p>
              </div>

              <div className='w-[32px]'></div>

            </div>

            <p className={`hidden md:flex text-opaque-job-details-gray px-7 pb-2 mt-[-10px] ${contractor.contractorService.length !== 0 ? '' : 'hidden'}`}>
              {contractor.contractorService}
            </p>

          </div>
        )
      })}

      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total crew cost
          </p>
          <div className='flex justify-end w-[100px]'>
            <p className='w-1/5 flex justify-end'>$</p>
            <p className='w-4/5 flex justify-end'>{calculateTotal()}</p>
          </div>
        </div>

        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total crew plus taxes
          </p>
          <div className='flex justify-end w-[100px]'>
            <p className='w-1/5 flex justify-end'>$</p>
            <p className='w-4/5 flex justify-end'>{calculateTotal(true)}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CostBreakdownSubmittedCrew;
