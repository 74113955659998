import { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { MdOutlineClose } from 'react-icons/md';

const PopupSearchBox = ({searchTerm, setSearchTerm}) => {
  const [showSearch, setShowSearch] = useState(false);
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (showSearch && inputRef.current) {
      inputRef.current.focus();
    }
    const handleClickOutside = (event) => {
      if (
        !searchTerm &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setShowSearch(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [showSearch, searchTerm]); 

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && showSearch) {
        handleSearchToggle(event)
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showSearch])

  const handleSearchToggle = (e) => {
    e.stopPropagation();
    setShowSearch(!showSearch);
    setSearchTerm('');
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  }

  return (
    <div ref={wrapperRef}>
      {!showSearch ? (
        <FaSearch onClick={handleSearchToggle} className="cursor-pointer h-5 w-5 mr-2 text-gray-blue" />
        ) : (
        <div className="flex bg-white rounded-lg px-3 flex-row justify-center items-center border border-navy-light">
          <FaSearch className="h-5 w-5 mr-2 text-gray-blue" />
          <input 
            ref={inputRef}
            onChange={handleSearchChange}
            value={searchTerm}
            type="text" 
            className="max-w-[95px] md:max-w-[450px] h-10 text-dark-blue border-none focus:ring-0" 
          />
          <MdOutlineClose onClick={handleSearchToggle} className="cursor-pointer text-gray-blue text-2xl"/>
        </div>
      )}
    </div>
  );
};

export default PopupSearchBox;
