import React, { useContext, useEffect } from 'react';
import JobsList from '../../../components/JobsList';
import serviceMethods from '../../../service'
import { useScheduler } from '../../../contexts/scheduler'
import { useSchedulerJobsList } from '../../../contexts/schedulerJobsList';
import { useAuth } from '../../../contexts/auth'
import JobsListHeaderBar from '../../../components/JobsListHeaderBar'
import JobsListNewJobButton from '../../../components/JobsListNewJobButton'
import styled from 'styled-components';
import HeaderBar from '../../../components/JobsListHeaderBar'
import JobDetails from '../../../components/JobDetails'
import { ResponsiveContext } from '../../../contexts/responsive';

const ResponsiveDiv = styled.div`
  height: ${({ height }) => height - 112}px;
  @media (max-width: 1024px) {
    height: ${({ height }) => height - 112 - 48 }px;
  }
`;

const SchedulerJobsListView = () => {
  const {
    currentPage, setCurrentPage,
    searchTerm, setSearchTerm,
    displayJobs, allSubmittedJobs,
    ColumnHeaders, RESULTS_PER_PAGE,
    sortBy, setSortBy,
    ListItem, DropdownMenu,
  } = useSchedulerJobsList();

  const {
    selectedStatuses, setSelectedStatuses,
    jobType, setJobType,
  } = useScheduler()

  const { responsive } = useContext(ResponsiveContext);
  const { width, height } = responsive;

  const { getProfile, isFetchingProfile } = useAuth();

  useEffect(()=>{
    if (!isFetchingProfile) getProfile();
  },[])

  return (
    <div className='flex flex-col justify-between items-center h-screen w-full'>
      <HeaderBar
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        jobType={jobType}
        setJobType={setJobType}
      />
      <ResponsiveDiv height={height} className="flex flex-col w-11/12 md:w-5/6 my-6">
        <JobsList
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          displayJobs={displayJobs}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          jobType={jobType}
          setJobType={setJobType}
          allJobs={allSubmittedJobs}
          resultsPerPage={RESULTS_PER_PAGE}
          ListItem={ListItem}
          ColumnHeaders={ColumnHeaders}
          DropdownMenu={DropdownMenu}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      </ResponsiveDiv>
      <JobDetails />
    </div>
  );
};

export default SchedulerJobsListView;
