import { formatTime, formatDatePlain } from "../../utils";
import { useScheduler } from '../../contexts/scheduler';
import { useModal } from "../../contexts/modal";
import JobLogPDFViewer from "../JobLogPDFViewer";

const JobDetailsDailyJobLog = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { modalMethods } = useModal();
  const { selectedJobId, selectedScheduler } = useScheduler();
  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)

  const viewPDF = (prop) => {
    modalMethods.setContent(<JobLogPDFViewer calendarJob={prop} />)
    modalMethods.open()
  }

  return (
    <>{selectedJob.calendarJobs.map((calendarJob,i) => {
      return calendarJob.jobLog ? (
        <div className='p-4 mt-[21px] mx-[21px] flex flex-col justify-center items-center flex-1 rounded-[9px] bg-navy-bright/10 border border border-navy-light' key={`jddjl${i}`}>
          <div className='flex pb-2 w-full text-dark-blue font-bold text-[22px]'>{calendarJob.calendarJobType === 'REGULAR' ? 'JOB LOG' : 'FLASH LOG'}</div>
          <div className='w-full flex'>
            <div className="flex-1">
              <div className='flex flex-row justify-between items-between w-full'>
                <div className='flex flex-col flex-1 justify-center align-center'>
                  <p className="text-gray-blue text-[13px]">Date</p>
                  <p className="text-dark-blue font-bold text-[17px]">{calendarJob.scheduledDate ? formatDatePlain(calendarJob.scheduledDate) : ""}</p>
                </div>
                <div className='pl-12 flex flex-col flex-1 align-center'>
                  <p className="text-gray-blue text-[13px]">Shoot</p>
                  <p className="text-dark-blue font-bold text-[17px]">{calendarJob.calendarJobType === 'REGULAR' ? calendarJob.shift : calendarJob.fshift}</p>
                </div>
              </div>
              <div className='mt-4 flex flex-row justify-between items-between w-full'>
                <div className='flex flex-col flex-1 justify-center align-center'>
                  <p className="text-gray-blue text-[13px]">Start time</p>
                  <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.startTime ? formatTime(calendarJob.jobLog?.startTime, false) : ''}</p>
                </div>
                <div className='pl-12 flex flex-col flex-1 align-center'>
                  <p className="text-gray-blue text-[13px]">End time</p>
                  <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.endTime ? formatTime(calendarJob.jobLog?.endTime, false) : ''}</p>
                </div>
              </div>

              <div className='mt-4 flex flex-row justify-between items-between w-full'>
                <div className='flex flex-col flex-1 align-center'>
                  <p className="text-gray-blue text-[13px]">Foreman</p>
                  <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.foreman?.name || ''}</p>
                </div>

                {selectedJob.jobType === 'gunite'
                  ?
                  <div className='pl-12 flex flex-col flex-1 align-center'>
                    <p className="text-gray-blue text-[13px]">Yards</p>
                    <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.yards}</p>
                  </div>
                  :
                  <div className='pl-12 flex flex-col flex-1 align-center'>
                    <p className="text-gray-blue text-[13px]">Batches</p>
                    <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.batches}</p>
                  </div>
                }
              </div>
            </div>
            <button
            className="py-1 px-3 rounded flex-none self-start bg-dark-blue border border-primary-light border-solid border-4 text-white font-bold"
            onClick={() => viewPDF(calendarJob)}
            >View PDF</button>
          </div>
          <div className="border-b border-navy-light h-[1px] w-full  py-1" />
          <div className='w-full'>
            <div className='mt-4 flex flex-row justify-between items-between w-full'>
              <div className='flex flex-col flex-1 justify-center align-center'>
                <p className="text-dark-blue font-bold text-[17px] pb-2">Crew Members</p>
                {calendarJob.jobLog?.crewMembers.map((crewMember, index) => (
                  <p key={`crewMember${index}`} className="py-1 text-dark-blue text-[14.5px]">• {crewMember.crewMember.user.name}</p>
                ))}
              </div>
              <div className={`pl-12 flex flex-col flex-1 align-center ${selectedJob.jobType === 'gunite' ? '' : 'hidden'}`}>
                <p className="text-dark-blue font-bold text-[17px] pb-2">Drivers</p>
                {calendarJob.jobLog?.drivers?.map((driver, index) => (
                  <p key={`driver${index}`} className="py-1 text-dark-blue text-[14.5px]">• {driver.driver.user.name}</p>
                ))}
              </div>
            </div>
          </div>
          <div className="border-b border-navy-light h-[1px] w-full py-1" />
          <div className='w-full'>
            <div className='mt-4 flex flex-col flex-1 justify-center align-center'>
              <p className="text-dark-blue font-bold text-[17px]">Finished job photos</p>
              <div className='flex flex-row flex-wrap mt-1'>
                {calendarJob.jobLog?.finishedJobPhotos?.map((file, index) => {
                  if (file.isProofOfWork) {
                    return (
                      <a key={`jobFile${index}`} className='pr-6 py-4' href={`${apiUrl}/${file.fileUri}`} download={`${apiUrl}/${file.fileUri}`}>
                        <img crossOrigin="anonymous" src={`${apiUrl}/${file.fileUri}`} alt='Uploaded file' className='h-[150px] rounded-[4px]' />
                      </a>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
          <div className="border-b border-navy-light h-[1px] w-full py-1" />
          <div className='w-full flex'>
            {selectedJob.jobType === 'gunite' ?
              <>
                <div className='mt-4 flex flex-row justify-between items-between w-full'>
                  <div className='flex flex-col flex-1 justify-center align-center'>
                    <p className="text-dark-blue font-bold text-[17px] pb-2">Extra Details</p>
                    <div className='flex flex-col flex-1 align-center'>
                      <p className="text-gray-blue text-[13px]">Spa Notes</p>
                      <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.spa || 'N/A'}</p>
                    </div>
                    <div className='mt-4 flex flex-col flex-1 align-center'>
                      <p className="text-gray-blue text-[13px]">Step Notes</p>
                      <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.steps || 'N/A'}</p>
                    </div>

                    <div className='mt-4 flex flex-col flex-1 align-center'>
                      <p className="text-gray-blue text-[13px]">Bench Notes</p>
                      <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.benches || 'N/A'}</p>
                    </div>
                  </div>
                </div>

                <div className='mt-8 flex flex-row justify-between items-between w-full'>
                  <div className='flex flex-col flex-1 justify-center align-center'>
                    <div className='flex flex-col flex-1 align-center'>
                      <p className="mt-4 text-gray-blue text-[13px]">Comments</p>
                      <p className="text-dark-blue font-bold text-[17px] break-all">{calendarJob.jobLog?.guniteComments || 'N/A'}</p>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
                <div className='mt-4 flex flex-row justify-between items-between w-full'>
                  <div className='flex flex-col flex-1 justify-center align-center'>
                    <p className="text-dark-blue font-bold text-[17px] pb-2">Extra Details</p>
                    <div className='flex flex-col flex-1 align-center'>
                      <p className="text-gray-blue text-[13px]">Wall Fitting Notes</p>
                      <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.wallFittings || 'N/A'}</p>
                    </div>
                    <div className='mt-4 flex flex-col flex-1 align-center'>
                      <p className="text-gray-blue text-[13px]">Main Drain Notes</p>
                      <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.mainDrains || 'N/A'}</p>
                    </div>
                  </div>
                </div>

                <div className='mt-8 flex flex-row justify-between items-between w-full'>
                  <div className='flex flex-col flex-1 justify-center align-center'>
                    <div className='flex flex-col flex-1 align-center'>
                      <p className="mt-4 text-gray-blue text-[13px]">Comments</p>
                      <p className="text-dark-blue font-bold text-[17px]">{calendarJob.jobLog?.plasterComments || 'N/A'}</p>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      ) : (
        <div className='p-4 mt-[21px] mx-[21px] flex flex-col justify-center items-center flex-1 rounded-[9px] bg-navy-bright/10 border border border-navy-light' key={`jddjl2${i}`}>
          <div className='flex pb-2 w-full text-dark-blue font-bold text-[22px]'>{calendarJob.calendarJobType === 'REGULAR' ? 'JOB LOG' : 'FLASH LOG'}</div>
          <div className='w-full '>
            <div className='flex flex-row justify-between items-between w-full'>
              <div className='flex flex-col flex-1 justify-center align-center'>
                <p className="text-gray-blue text-[13px]">Scheduled Date</p>
                <p className="text-dark-blue font-bold text-[17px]">{calendarJob.scheduledDate ? formatDatePlain(calendarJob.scheduledDate) : ""}{calendarJob.shift ? `, shift ${calendarJob.shift}` : ''}</p>
              </div>
              <div className='pl-12 flex flex-col flex-1 align-center'>
                <p className="text-gray-blue text-[13px]">Assigned Foreman</p>
                <p className="text-dark-blue font-bold text-[17px]">{calendarJob.crews[0].crew.name}</p>
              </div>
            </div>
            <p className="mt-4 text-gray-blue text-md italic">No log has been filled out for this shift yet.</p>
          </div>
        </div>
      )
    }
    )}
    </>
  )
}

export default JobDetailsDailyJobLog
