import { useAdminMaterials } from '../../contexts/adminMaterials'
import { useState } from 'react'

const AdminMaterialsItemDetails = () => {
  const { prevPageSelectedItem, breadcrumbs } = useAdminMaterials();

  return (
    <div className="w-full">
        <div className='w-full flex flex-col flex-start'>
          <h3 className='text-gray-blue text-sm'>Name</h3>
          <p className='font-semibold text-md text-dark-blue'>{prevPageSelectedItem.name}</p>
        </div>
    </div>
  );
};

export default AdminMaterialsItemDetails;