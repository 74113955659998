import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import ForemanDailyJobLog from '../ForemanDailyJobLog'
import ForemanTopBar from '../../../components/ForemanTopBar'
import ForemanProvider from '../../../contexts/foreman'
import ForemanMobileNav from '../../../components/ForemanMobileNav'
import ForemanSidebar from '../../../components/ForemanSidebar'

const ForemanLayout = () => {
  const { pathname } = useLocation()
  return (
    <div className='flex flex-col lg:flex-row h-screen w-screen font-roboto'>
      <ForemanProvider>
        {(!pathname.includes('/foreman/onboard')) ? (
          <>
            <ForemanSidebar />
            <ForemanTopBar />
            <ForemanMobileNav />
          </>
        ) : null}
        <Routes>
          <Route exact={true} path="/" element={<ForemanDailyJobLog />} />
        </Routes>
      </ForemanProvider>
    </div>
  )
}

export default ForemanLayout
