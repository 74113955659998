import React, { useState, useEffect } from "react";

const CostBreakdownSubmittedDrivers = ({ savedCostBreakdown, employerTaxRate }) => {
  const calculateTotal = (tax) => {
    const total = savedCostBreakdown?.drivers?.reduce((accumulator, item) => {
      const pools = item.pools ? Number(item.pools) : 0
      const poolRate = item.poolRate ? Number(item.poolRate) : 0
      const travel = item.travel ? Number(item.travel) : 0
      const extras = item.extras ? Number(item.extras) : 0
      return accumulator += ((pools * poolRate) + travel + extras)
    }, 0)

    if (tax) {
      return Number(total + (total * employerTaxRate)).toFixed(2)
    } else {
      return Number(total).toFixed(2)
    }
  }

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      <div className='hidden md:flex w-full text-opaque-job-details-gray'>

        <div className='flex w-5/12'>
          <p className='flex justify-start w-1/2 pl-7'>Name</p>

        </div>

        <div className='flex w-4/12 justify-end'>
          <p className='flex justify-end w-2/6'>Pools</p>
          <p className='flex justify-end w-3/6'>Pool rate</p>
          <p className='flex justify-end w-2/6'>Travel</p>
        </div>

        <div className='flex w-3/12'>
          <p className='flex justify-end w-5/12'>Extras</p>
          <p className='flex justify-end w-7/12'>Total</p>
        </div>

        <div className='w-[32px]'></div>
      </div>

      {savedCostBreakdown?.drivers?.map((driver, i) => {
        return (
          <div
            className={`flex flex-col ${i > 0 ? 'border-t' : ''}`}
            key={`driver${i + 1}`}
          >
            <div className='flex flex-col md:flex-row w-full py-4 items-center text-dark-blue'>
              <div className='flex w-full md:w-5/12'>
                <p className='flex pr-4 items-center text-opaque-job-details-gray'>{i + 1}.</p>
                <p className='flex justify-start w-full md:w-1/2 items-center whitespace-nowrap text-ellipsis font-semibold'>{driver.name || ''}</p>
              </div>

              <div className='flex flex-col md:flex-row w-full md:w-4/12 justify-end'>

                <div className='flex md:w-2/6 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Pools:</p>
                  <p className='flex justify-end w-full items-center'>
                    {driver.pools || ''}
                  </p>
                </div>

                <div className='flex md:w-3/6 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Pool Rate:</p>
                  <p className='flex justify-end w-full items-center'>
                    ${(Number(driver.poolRate) || 0).toFixed(2)}
                  </p>
                </div>

                <div className='flex md:w-2/6 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Travel:</p>
                  <p className='flex justify-end w-full items-center'>
                    ${(Number(driver.travel) || 0).toFixed(2)}
                  </p>
                </div>

              </div>

              <div className='flex flex-col md:flex-row w-full md:w-3/12'>

                <div className='flex md:w-5/12 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Extras:</p>
                  <p className='flex justify-end w-full items-center'>
                    ${(Number(driver.extras) || 0).toFixed(2)}
                  </p>
                </div>

                <div className='flex md:w-7/12 md:border-none'>
                  <p className='w-1/2 md:hidden'>Total:</p>
                  <p className='flex justify-end w-full items-center'>
                    ${(( Number(driver.poolRate) * Number(driver.pools) ) + Number(driver.travel) + Number(driver.extras)).toFixed(2)}
                  </p>
                </div>

              </div>

              <div className='w-[32px]'></div>

            </div>

            <p className={`text-opaque-job-details-gray px-7 pb-2 mt-[-10px] ${!!driver?.extrasReason && driver?.extrasReason?.length !== 0 ? '' : 'hidden'}`}>
              Extras reason: {driver?.extrasReason || 'n/a'}
            </p>

          </div>
        )
      })}

      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total drivers cost
          </p>
          <div className='flex justify-end w-[100px]'>
            <p className='w-1/5 flex justify-end'>$</p>
            <p className='w-4/5 flex justify-end'>{calculateTotal()}</p>
          </div>
        </div>

        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total drivers plus taxes
          </p>
          <div className='flex justify-end w-[100px]'>
            <p className='w-1/5 flex justify-end'>$</p>
            <p className='w-4/5 flex justify-end'>{calculateTotal(true)}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CostBreakdownSubmittedDrivers;
