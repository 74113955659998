import React, { useState, useEffect } from "react";
import CostBreakdownAddProduct from "../CostBreakdownAddProduct";
import { useScheduler } from "../../contexts/scheduler";

  const CostBreakdownInvoice = ({ breakdown, setBreakdown, plaster, savedCostBreakdown, resetToggle, DEFAULT_INVOICE_ITEM, discount, setDiscount, setTotalYards, setPlasterType, DEFAULT_OPTIONS_GUNITE, DEFAULT_OPTIONS_PLASTER }) => {
    const { selectedJobId, selectedScheduler } = useScheduler()
    const [addingActive, setAddingActive] = useState(false)
    const [invoiceList, setInvoiceList] = useState([])
    const [invoiceTotal, setInvoiceTotal] = useState(0)
    const [editRow, setEditRow] = useState({ row: null, i: null })

  const calculateInvoiceTotal = () => {
    // math to calculate total, return total
    const total = invoiceList.reduce((accumulator, item) => {
      return accumulator += (item.amount * item.price)
    }, 0)
    setInvoiceTotal(total)

    setBreakdown((breakdown) => {
      return {
        ...breakdown,
        invoice: {
          items: invoiceList,
          total: total
        }
      }
    })
  }

  const calculateRowTotal = (row) => {
    // math to calculate row total, return total
    return (row.amount * row.price).toFixed(2)
  }

  const limitToNumbers = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '')
    e.target.value = value
  }

  useEffect(() => {
    if (invoiceList.length > 0) {
      setAddingActive(false)

      // adding all 'cubic yards' and 'minimum' invoice items together
      let total = 0
      invoiceList.forEach((item) => {
        if (item.name === 'Cubic yards') {
          total = total + Number(item.amount)
        } else if (item.name === 'Minimum') {
          total = total + Number(item.amountForMinimum)
        } else if (item.name === 'Plaster') {
        }
      })
      setTotalYards(total)
    } else {
      setAddingActive(true)
      setTotalYards(0)
    }

    calculateInvoiceTotal()
  }, [invoiceList, breakdown.costBreakdown])

  useEffect(() => {
    setEditRow({ row: null, i: null })

    if (savedCostBreakdown) {
      setInvoiceList(savedCostBreakdown.invoice)
      if (savedCostBreakdown.discountPercent > 0) {
        setDiscount({ isDiscount: true, percent: savedCostBreakdown.discountPercent })
      }
    } else {
      setInvoiceList([])
      setDiscount({ isDiscount: false, percent: 0 })
    }
  }, [savedCostBreakdown, selectedJobId, resetToggle])

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      <div className='hidden md:flex w-full text-opaque-job-details-gray'>
        <div className='w-1/2'>
          <p className='pl-7'>Product or service</p>
        </div>

        <div className='flex w-1/2'>
          <p className='flex justify-end w-1/3'>Amount</p>
          <p className='flex justify-end w-1/3'>Price</p>
          <p className='flex justify-end w-1/3'>Total</p>
        </div>
        <div className='w-[32px]'></div>
      </div>

      {invoiceList.map((invoiceItem, i) => {
        return (
          <div
            className={`flex flex-col md:flex-row w-full py-4 items-center ${editRow.i === i ? 'text-gunite-red' : 'text-dark-blue'} ${i > 0 ? 'border-t' : ''}`}
            key={`invoice${i + 1}`}
          >
            <div className='flex w-full md:w-1/2'>

              <p className='flex pr-4 items-center text-opaque-job-details-gray'>{i + 1}.</p>

              <div className='flex flex-col'>
                <p className='font-semibold'>{invoiceItem.name !== 'Plaster' ? (invoiceItem.name || '') : `${invoiceItem.manufacturer} - ${invoiceItem.color}`}</p>
                <p className={`text-opaque-job-details-gray ${invoiceItem.description.length > 0 ? '' : 'hidden'}`}>
                  {invoiceItem.description}
                </p>
              </div>

              <div className='sm:hidden flex w-full md:w-fit justify-end'>
                <button
                  className='w-[32px] h-[32px] pl-4'
                  onClick={() => {
                    setAddingActive(true)
                    setEditRow({ row: invoiceItem, i: i })
                  }}
                >
                  <img
                    src='/JobDetails/cost_breakdown_edit_icon.svg'
                    alt='edit icon'
                    className='w-[32px]'
                  />
                </button>
              </div>

            </div>

            <div className='flex flex-col md:flex-row w-full md:w-1/2 justify-between '>

              <div className='flex md:w-1/3 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Amount:</p>
                <p className='flex justify-end w-full items-center'>
                  {invoiceItem.amount ? invoiceItem.amount : ''}
                </p>
              </div>

              <div className='flex md:w-1/3 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Price:</p>
                <p className='flex justify-end w-full items-center'>
                  ${(invoiceItem.price ? Number(invoiceItem.price) : 0).toFixed(2)}
                </p>
              </div>

              <div className='flex md:w-1/3 md:border-none'>
                <p className='w-1/2 md:hidden'>Total:</p>
                <p className='flex justify-end w-full items-center'>
                  ${calculateRowTotal(invoiceItem)}
                </p>
              </div>

            </div>

            <div className='hidden sm:flex w-full md:w-fit justify-end'>
              <button
                className='w-[32px] h-[32px] pl-4'
                onClick={() => {
                  setAddingActive(true)
                  setEditRow({ row: invoiceItem, i: i })
                }}
              >
                <img
                  src='/JobDetails/cost_breakdown_edit_icon.svg'
                  alt='edit icon'
                  className='w-[32px]'
                />
              </button>
            </div>

          </div>
        )
      })}

      {addingActive ?
        <CostBreakdownAddProduct
          isPlaster={plaster}
          setAddingActive={setAddingActive}
          options={plaster ? DEFAULT_OPTIONS_PLASTER : DEFAULT_OPTIONS_GUNITE}
          defaultInvoice={DEFAULT_INVOICE_ITEM}
          editRow={editRow}
          setEditRow={setEditRow}
          setPlasterType={setPlasterType}
          add={(newItem) => {
            setInvoiceList([...invoiceList, newItem])
          }}
          update={(updatedItem, i) => {
            setInvoiceList([
              ...invoiceList.slice(0, i),
              updatedItem,
              ...invoiceList.slice(i + 1)
            ])
          }}
          deleteRow={(i) => {
            setInvoiceList([
              ...invoiceList.slice(0, i),
              ...invoiceList.slice(i + 1)
            ])
          }}
        />
        :
        <div className='flex justify-end gap-4'>
          <button
            className='w-full border border-navy-light rounded text-dark-blue py-2 px-4 mt-4 font-semibold hover:border-dark-blue'
            onClick={() => {
              setAddingActive(true)
            }}
          >
            + Add new
          </button>
        </div>
      }


      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        {discount.isDiscount ? (
          <div className='flex flex-col sm:flex-row w-full gap-2'>

            <div className='flex flex-col items-end sm:items-start sm:w-1/2 gap-2'>

              <div className='flex sm:w-1/2 text-sm text-dark-blue font-semibold gap-2 items-center'>
                <p>Discount</p>
                <input
                  type='checkbox'
                  className='text-tertiary-blue flex'
                  checked={discount.isDiscount}
                  onChange={(e) => {
                    setDiscount({ ...discount, isDiscount: e.target.checked })
                  }}
                />
              </div>

              <div className='flex gap-2 text-sm text-dark-blue items-center'>
                <input
                  className='rounded h-[28px] w-[60px] focus:ring-0 text-dark-blue'
                  value={discount.percent}
                  onChange={(e) => {
                    limitToNumbers(e)
                    setDiscount({ ...discount, percent: e.target.value })
                  }}
                />
                <p>%</p>
              </div>

            </div>

            <div className='flex flex-col sm:w-1/2 justify-between'>

              <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
                <p className='w-fit pr-8'>
                  Invoice total
                </p>
                <div className='flex justify-between w-[100px]'>
                  <p>$</p>
                  <p>{invoiceTotal.toFixed(2)}</p>
                </div>
              </div>

              <div className='flex w-full justify-end items-center text-sm text-dark-blue font-semibold'>
                <p className='w-fit pr-8'>
                  Discount amount
                </p>
                <div className='flex justify-between w-[100px] border-b border-black text-gunite-red'>
                  <p>$</p>
                  <p>{(invoiceTotal * (Number(discount.percent) / 100)).toFixed(2)}</p>
                </div>
              </div>

              <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
                <p className='w-fit pr-8'>
                  Balance due
                </p>
                <div className='flex justify-between w-[100px]'>
                  <p>$</p>
                  <p>{(invoiceTotal - (invoiceTotal * (Number(discount.percent) / 100))).toFixed(2)}</p>
                </div>
              </div>
            </div>


          </div>
        ) : (
          <div className='flex flex-col sm:flex-row w-full'>
            <div className='flex sm:w-1/2 justify-end sm:justify-start text-sm text-dark-blue font-semibold gap-2 items-center'>
              <p>Discount</p>
              <input
                type='checkbox'
                className='text-tertiary-blue'
                checked={discount.isDiscount}
                onChange={(e) => {
                  setDiscount({ ...discount, isDiscount: e.target.checked })
                }}
              />
            </div>

            <div className='flex sm:w-1/2 justify-end items-center text-sm text-dark-blue font-semibold'>
              <p className='w-fit pr-8'>
                Invoice total
              </p>
              <div className='flex justify-between w-[100px]'>
                <p>$</p>
                <p>{invoiceTotal.toFixed(2)}</p>
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default CostBreakdownInvoice;
