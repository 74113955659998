import AdminBranchMaterialsAccordionItem from '../AdminBranchMaterialsAccordionItem'
import AdminMaterialsEditProductModal from '../AdminBranchMaterialsEditProductModal';
import AdminMaterialsEditTierModal from '../AdminBranchMaterialsEditTierModal';
import { useAdminBranchMaterials } from '../../contexts/adminBranchMaterials';

export default function AdminBranchMaterials() {
  const { editItemModal, setEditItemModal, branchMaterialsSourceData } = useAdminBranchMaterials();

  function makeColorList(tier) {
    return (
      <>
        {tier.color.map((color) => (
          <AdminBranchMaterialsAccordionItem 
            key={color.id}
            headerData={color} 
            content={""}
          />
        ))}
      </>
    );
  }

  function makeTierList(product) {
    return (
      <>
        {product.tier.map((tier) => (
          <AdminBranchMaterialsAccordionItem 
            key={tier.id}
            headerData={tier} 
            content={makeColorList(tier)}
          />
        ))}
      </>
    );
  }

  function makeProductList(manufacturer) {
    return (
      <>
        {manufacturer.product.map((product) => (
          <AdminBranchMaterialsAccordionItem 
            key={product.id}
            headerData={product} 
            content={makeTierList(product)}
          />
        ))}
      </>
    );
  }


  return (
    <div>
      <h1 className="text-xl font-semibold text-dark-blue ml-5 sm:ml-8">Branch plaster menu</h1>
      <div className='pr-5 my-5'>
        {branchMaterialsSourceData.map((manufacturer) => (
          <div className="rounded-l-lg" key={manufacturer.id}>
            <AdminBranchMaterialsAccordionItem
              headerData={manufacturer} 
              content={makeProductList(manufacturer)}
            />
          </div>
        ))}
      </div>
      {editItemModal.open && (
        <>
          {editItemModal.tableName === 'Product' ? (
            <AdminMaterialsEditProductModal />
          ) : (editItemModal.tableName === 'Tier' ? (
            <AdminMaterialsEditTierModal />
          ) : null
          )}
        </>
      )}
    </div>
  );
}
