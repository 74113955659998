import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './auth';
import { useProgress } from './progress';
import ListItem from '../components/CustomerDraftsListItem';
import ColumnHeaders from '../components/CustomerDraftsColumnHeaders';

const RESULTS_PER_PAGE = 15;

const COLUMN_HEADERS = [
  'Job Name',
  'Address',
  'Service',
]

const CustomerDraftsContext = createContext()

const CustomerDraftsContextProvider = props => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [displayJobs, setDisplayJobs] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [jobType, setJobType] = useState('All');
  const [sortBy, setSortBy] = useState('submit-date');
  const { startIntakeFlow } = useProgress();
  const [ allDraftJobs, setAllDraftJobs ] = useState([]);

  const { me: {
    jobs: allJobs
  }} = useAuth()

  const paginate = (currentPage, jobs) => {
    const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
    const endIndex = (startIndex + RESULTS_PER_PAGE);
    let result = jobs.slice(startIndex, endIndex);
    return result;
  }

  useEffect(()=>{
    if (Array.isArray(allJobs)) {
      let draftJobs = allJobs.filter(job => job.submittedAt === null).sort((a,b)=>new Date(b.createdAt) - new Date(a.createdAt));
      draftJobs.sort((a, b) => b.intakeFlowId - a.intakeFlowId);

      if (searchTerm !== '') {
        draftJobs = draftJobs.filter(job => {
          return (
            job.jobName.toLowerCase().includes(searchTerm.toLowerCase())
            || job.address.toLowerCase().includes(searchTerm.toLowerCase())
          )
        })
      }
      if (jobType !== 'All') {
        draftJobs = draftJobs.filter(job => {
          return job.jobType.toLowerCase() === jobType.toLowerCase()
        })
      }

      const paginatedJobs = paginate(currentPage, draftJobs);
      const draftJobsWTitleTemplate = paginatedJobs.map(job => ({
        ...job,
        displayName: (
          <p onClick={()=>{
            startIntakeFlow(job.intakeFlowId)
          }}>
            {job.jobName}
          </p>
        )
      }))
      setAllDraftJobs(draftJobs);
      setDisplayJobs(draftJobsWTitleTemplate)
    }
  },[allJobs, jobType, searchTerm, currentPage])


  return (
    <CustomerDraftsContext.Provider value = {{
      displayJobs, setDisplayJobs,
      currentPage, setCurrentPage,
      searchTerm, setSearchTerm,
      selectedStatuses, setSelectedStatuses,
      jobType, setJobType,
      sortBy, setSortBy,
      allDraftJobs, RESULTS_PER_PAGE,
      ListItem, ColumnHeaders
    }}>
      {props.children}
    </CustomerDraftsContext.Provider>
  )
}

const useCustomerDrafts = () => useContext(CustomerDraftsContext)
export { useCustomerDrafts }

export default CustomerDraftsContextProvider
