
import { useAdmin } from "../../../contexts/admin";
import { useNavigate } from "react-router-dom";
import AdminGlobalBranchesList from "../../../components/AdminGlobalBranchesList";
import { useLocation } from 'react-router-dom';
import AdminGlobalMaterials from "../../../components/AdminGlobalMaterials";
import AdminMaterialsProvider from "../../../contexts/adminMaterials";
import AdminGlobalRates from "../../../components/AdminGlobalRates";

const AdminGlobalView = () => {

  const currentUrl = window.location.pathname;
  const navigate = useNavigate();

  return (
    <div className="w-full overflow-y-scroll bg-opacity-10 bg-navy-bright flex flex-col justify-start items-center">
      <div className={`bg-white rounded-2xl w-full md:w-5/6 xl:w-2/3 md:mr-5 my-6 flex flex-shrink flex-col border border-navy-light `}>
        <div className="h-16 relative flex justify-between items-center px-6  rounded-t-xl border-b border-navy-light bg-opacity-20 bg-navy-bright">
            <h1 className="text-xl font-semibold text-dark-blue">Global setup</h1>
            <img src={`/main-logo-dark.svg`} alt="Main Logo" className="w-[130px]" />
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="p-3 border-t sm:border-t-0 border-navy-light bg-opacity-20 bg-navy-bright w-full sm:w-1/5 flex flex-row sm:flex-col">
            <div
              className={`p-2 rounded-md cursor-pointer select-none ${currentUrl == '/admin/setup/global' ? 'bg-opacity-30 bg-navy-bright text-dark-blue' : 'text-opaque-job-details-gray'}`}
              onClick={() => navigate('/admin/setup/global')}
              >
              Branches
            </div>
            <div
              className={`p-2 rounded-md cursor-pointer select-none  ${currentUrl === '/admin/setup/global/rates' ? 'bg-opacity-30 bg-navy-bright' : 'text-opaque-job-details-gray'}`}
              onClick={() => navigate('/admin/setup/global/rates')}
              >
              Rates
            </div>
            <div
              className={`p-2 rounded-md cursor-pointer select-none  ${currentUrl === '/admin/setup/global/plaster-menu' ? 'bg-opacity-30 bg-navy-bright' : 'text-opaque-job-details-gray'}`}
              onClick={() => navigate('/admin/setup/global/plaster-menu')}
              >
              Plaster menu
            </div>
          </div>
          <div className="flex flex-col w-full sm:w-4/5 py-7 px-3">
            {currentUrl == '/admin/setup/global' &&
              <AdminGlobalBranchesList />
            }
            {currentUrl == '/admin/setup/global/rates' &&
              <AdminGlobalRates />
            }
            {currentUrl == '/admin/setup/global/plaster-menu' &&
              <AdminMaterialsProvider>
                <AdminGlobalMaterials />
              </AdminMaterialsProvider>
            }
          </div>

        </div>
      </div>
    </div>
  );
}

export default AdminGlobalView;
