/* external imports */
import React, { useRef, createContext, useContext, useState, useEffect } from 'react';
import { has, initial } from 'underscore';
/* internal imports */
import { useAuth } from './auth';
import { useAdmin } from './admin';
import serviceMethods from '../service';
import { useLocation } from 'react-router-dom';

const AdminMaterials = createContext();

const AdminMaterialsProvider = ({ children }) => {
  const RESULTS_PER_PAGE = 10;
  const BREADCRUMBS_DEFAULT = {
    currentPage: 'Manufacturers',
    selectedMaterial: 'Plaster',
    selectedManufacturer: '',
    selectedProduct: '',
    selectedTier: '',
    selectedColor: '',
  };

  const [selectedItemsForDeletion, setSelectedItemsForDeletion] = useState([]);
  const [allSourceData, setAllSourceData] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [prevPageSelectedItem, setPrevPageSelectedItem] = useState(null)
  const [allItemsAfterFiltering, setAllItemsAfterFiltering] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedItems, setSortedItems] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortAscending, setSortAscending] = useState(false);
  const [addOrEditItemModal, setAddOrEditItemModal] = useState({open: false, editMode: false});
  const [breadcrumbs, setBreadcrumbs] = useState(BREADCRUMBS_DEFAULT);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const { pathname } = useLocation();

  const { getLocationIdFromUrl } = useAdmin();

  const hasMounted = useRef(false);

  const handleAddOrEditItem = async (editMode, values) => {
    if (editMode) {
      await handleUpdateItem(values);
    }
    else {
      await handleAddItem(values);
    }

    setAddOrEditItemModal({addOrEditItemModal: false, editMode: false});
  }

  // in progress
  const handleAddItem = async (values) => {
    if (breadcrumbs.currentPage == 'Manufacturers') {
      await serviceMethods.addMaterialsSubcategoryItem({
        tableName: 'manufacturer',
        data: {
          name: values.name,
        }
      })
    } else if (breadcrumbs.currentPage == 'Products') {
      await serviceMethods.addMaterialsSubcategoryItem({
        tableName: 'product',
        data: {
          name: values.name,
          manufacturerId: prevPageSelectedItem.id,
          notes: values.notes,
          minimumTotalIA: values.minimumTotalIA,
          minimumPrice: values.minimumPrice,
          totalIASmall: values.totalIASmall,
          totalIALarge: values.totalIALarge,
          spaAdd: values.spaAdd
        }
      })
    } else if (breadcrumbs.currentPage == 'Tiers') {
      await serviceMethods.addMaterialsSubcategoryItem({
        tableName: 'tier',
        data: {
          tier: values.name,
          productId: prevPageSelectedItem.id,
          pricePerSqFoot: values.pricePerSqFoot,
          costPerBag: values.costPerBag,
          spaPrice: values.spaPrice,
        }
      })
    } else if (breadcrumbs.currentPage == 'Colors') {
      await serviceMethods.addMaterialsSubcategoryItem({
        tableName: 'color',
        data: {
          name: values.name,
          tierId: prevPageSelectedItem.id,
        }
      })
    }
    await initialize()
    setAddOrEditItemModal({addOrEditItemModal: false, editMode: false});
  }

  // in progress
  const handleUpdateItem = async (updatedValues) => {
    let newBreadcrumbs = { ...breadcrumbs };
  
    if (breadcrumbs.currentPage == 'Products') {
      await serviceMethods.updateMaterialsSubcategoryItem({
        tableName: 'manufacturer',
        id: prevPageSelectedItem.id,
        data: {
          name: updatedValues.name
        }
      });
      newBreadcrumbs = { ...breadcrumbs, selectedManufacturer: updatedValues.name };
    } else if (breadcrumbs.currentPage == 'Tiers') {
      await serviceMethods.updateMaterialsSubcategoryItem({
        tableName: 'product',
        id: prevPageSelectedItem.id,
        data: {
          name: updatedValues.name,
          manufacturerId: updatedValues.manufacturerId,
          notes: updatedValues.notes,
          minimumTotalIA: updatedValues.minimumTotalIA,
          minimumPrice: updatedValues.minimumPrice,
          totalIASmall: updatedValues.totalIASmall,
          totalIALarge: updatedValues.totalIALarge,
          spaAdd: updatedValues.spaAdd
        }
      });
      newBreadcrumbs = { ...breadcrumbs, selectedProduct: updatedValues.name };
    } else if (breadcrumbs.currentPage == 'Colors') {
      await serviceMethods.updateMaterialsSubcategoryItem({
        tableName: 'tier',
        id: prevPageSelectedItem.id,
        data: {
          tier: updatedValues.name,
          productId: updatedValues.productId,
          pricePerSqFoot: updatedValues.pricePerSqFoot,
          costPerBag: updatedValues.costPerBag,
          spaPrice: updatedValues.spaPrice,
        }
      });
      newBreadcrumbs = { ...breadcrumbs, selectedTier: updatedValues.name };
    } else if (breadcrumbs.currentPage == 'ColorDetails') {
      await serviceMethods.updateMaterialsSubcategoryItem({
        tableName: 'Color',
        id: prevPageSelectedItem.id,
        data: {
          name: updatedValues.name,
        }
      });
      newBreadcrumbs = { ...breadcrumbs, selectedColor: updatedValues.name };
    }
  
    updateQueryParams(newBreadcrumbs);
    
    await initialize();
  };
  

  const setBreadcrumbsAndSelectedSourceDataFromUrlParams = async (allSourceDataTemp) => {
    const url = window.location.search;
    const queryParams = new URLSearchParams(url);
    const params = {};
  
    for (const [key, value] of queryParams.entries()) {
      params[key] = value;

    }

    if (!has(params, 'currentPage')) {
      setBreadcrumbs(BREADCRUMBS_DEFAULT);
      return
    }

    const newBreadcrumbs = { ...BREADCRUMBS_DEFAULT, ...params }
    const selectedSourceData = getSelectedSourceData(newBreadcrumbs, allSourceDataTemp);
    selectSourceData(selectedSourceData);
    
    setBreadcrumbs(newBreadcrumbs);
  }

  const setCurrentPageParamIfNotExist = () => {
    const url = window.location.search;
    const queryParams = new URLSearchParams(url);
    let currentPage = queryParams.get('currentPage');
    if (!currentPage && pathname.includes('global/plaster-menu')) {
      currentPage = 'Manufacturers';
      queryParams.set('currentPage', currentPage);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, '', newUrl);
    }
  }
  
  const initialize = async () => {
    hasMounted.current = true;
    setCurrentPageParamIfNotExist();
    const allSourceDataTemp = await fetchAllSourceData()
    setAllSourceData(allSourceDataTemp);
    setTimeout(() => {
      setBreadcrumbsAndSelectedSourceDataFromUrlParams(allSourceDataTemp);
    }, 1);
  }

  useEffect(() => {
    const pastInitialization = async () => {
      const selectedSourceData =  getSelectedSourceData(breadcrumbs, undefined);
      selectSourceData(selectedSourceData);
    }

    if (!hasMounted.current) {
      initialize()
    } else {
      pastInitialization()
    }
  }, [breadcrumbs, allSourceData]);

  const updateQueryParams = (newBreadcrumbs) => {
    const params = new URLSearchParams();
    params.append('branchId', getLocationIdFromUrl())
    params.append('currentPage', newBreadcrumbs.currentPage)
    params.append('selectedMaterial', newBreadcrumbs.selectedMaterial)
    params.append('selectedManufacturer', newBreadcrumbs.selectedManufacturer)
    params.append('selectedProduct', newBreadcrumbs.selectedProduct)
    params.append('selectedTier', newBreadcrumbs.selectedTier)
    params.append('selectedColor', newBreadcrumbs.selectedColor)

    const newUrl = `${window.location.pathname}?${params.toString()}`;

    window.history.pushState({}, '', newUrl);
  }

  const handleClickItem = (item) => {
    let newBreadcrumbs = { ...breadcrumbs };
    if (breadcrumbs.currentPage == 'Materials') {
      newBreadcrumbs = {
        ...breadcrumbs,
        currentPage: 'Manufacturers',
        selectedMaterial: item.name,
      };
    } else if (breadcrumbs.currentPage == 'Manufacturers') {
      newBreadcrumbs = {
        ...breadcrumbs,
        currentPage: 'Products',
        selectedManufacturer: item.name,
      };
    } else if (breadcrumbs.currentPage == 'Products') {
      newBreadcrumbs = {
        ...breadcrumbs,
        currentPage: 'Tiers',
        selectedProduct: item.name,
      };
    } else if (breadcrumbs.currentPage == 'Tiers') {
      newBreadcrumbs = {
        ...breadcrumbs,
        currentPage: 'Colors',
        selectedTier: item.name,
      }; 
    } else if (breadcrumbs.currentPage == 'Colors') {
      newBreadcrumbs = {
        ...breadcrumbs,
        currentPage: 'ColorDetails',
        selectedColor: item.name,
      };
    }
    updateQueryParams(newBreadcrumbs);
    setBreadcrumbs(newBreadcrumbs);
  };

  const handleClickBreadcrumb = (target) => {
    const newBreadcrumbs = {
      ...breadcrumbs,
      currentPage: target,
    }
    updateQueryParams(newBreadcrumbs);
    setBreadcrumbs(newBreadcrumbs);
  }

  const addNameToTiers = (data) => {
    data.forEach(manufacturer => {
      manufacturer.product.forEach(product => {
        product.tier.forEach(tier => {
          tier.name = tier.tier
        });
      });
    });
  
    return data;
  };

  const fetchAllSourceData = async () => {
    const res = await serviceMethods.getMaterials()
    const allSourceDataObj = await res.json();
    const refinedAllSourceData = addNameToTiers(allSourceDataObj);

    return refinedAllSourceData;
  }


  const getSelectedSourceData = (breadcrumbsParam=null, allSourceDataParam=null) => {
    const breadcrumbsTemp = breadcrumbsParam || breadcrumbs;
    const allSourceDataTemp = allSourceDataParam || allSourceData;
    let selectedSourceData = [];
    let prevPageSelectedItem = {}
    if (allSourceDataTemp.length == 0) { return }
    if (!breadcrumbsTemp) { return }
      if (breadcrumbsTemp.currentPage == 'Materials') {
        selectedSourceData = [{ name: 'Plaster' }]
      } else if (breadcrumbsTemp.currentPage == 'Manufacturers') {
        selectedSourceData = allSourceDataTemp
      } else if (breadcrumbsTemp.currentPage == 'Products') { 
        prevPageSelectedItem = allSourceDataTemp.find(manufacturer => manufacturer.name == breadcrumbsTemp.selectedManufacturer)
        if (!prevPageSelectedItem) { return }
        const selectedManufacturerProducts = prevPageSelectedItem.product
        selectedSourceData = selectedManufacturerProducts
      } else if (breadcrumbsTemp.currentPage == 'Tiers') {
        const selectedManufacturerProducts = allSourceDataTemp.find(manufacturer => manufacturer.name == breadcrumbsTemp.selectedManufacturer).product;
        prevPageSelectedItem = selectedManufacturerProducts.find(product => product.name == breadcrumbsTemp.selectedProduct)
        if (!prevPageSelectedItem) { return }
        const selectedProductTiers = prevPageSelectedItem.tier;
        selectedSourceData = selectedProductTiers;
      } else if (breadcrumbsTemp.currentPage == 'Colors') {
        const selectedManufacturerProducts = allSourceDataTemp.find(manufacturer => manufacturer.name == breadcrumbsTemp.selectedManufacturer).product;
        const selectedProductTiers = selectedManufacturerProducts.find(product => product.name == breadcrumbsTemp.selectedProduct)?.tier;
        if (!selectedProductTiers) { return }
        const selectedTier = selectedProductTiers.find(tier => tier.name == breadcrumbsTemp.selectedTier)
        if (!selectedTier) { return }
        const refinedSelectedTier = {...selectedTier, name: selectedTier.tier }
        prevPageSelectedItem = refinedSelectedTier
        const selectedTierColors = prevPageSelectedItem.color;
        selectedSourceData = selectedTierColors;
      } else if (breadcrumbsTemp.currentPage == 'ColorDetails') {
        if (!allSourceDataTemp) { return }
        const selectedManufacturerProducts = allSourceDataTemp.find(manufacturer => manufacturer.name == breadcrumbsTemp.selectedManufacturer).product;
        if (!selectedManufacturerProducts) { return }

        const selectedProductTiers = selectedManufacturerProducts.find(product => product.name == breadcrumbsTemp.selectedProduct)?.tier;
        if (!selectedProductTiers) { return }
        const selectedTier = selectedProductTiers.find(tier => tier.name == breadcrumbsTemp.selectedTier)
        const refinedSelectedTier = {...selectedTier, name: selectedTier.name }
        if (!selectedTier) { return }
        prevPageSelectedItem = refinedSelectedTier.color.find(color => color.name == breadcrumbsTemp.selectedColor)
        if (!prevPageSelectedItem) { return }
        const selectedTierColors = prevPageSelectedItem.color;
        selectedSourceData = selectedTierColors;
      }
    setPrevPageSelectedItem(prevPageSelectedItem)
    return selectedSourceData
  };

  const getIdsByNames = (allSourceData, names, currentPage) => {
    let ids = [];
  
    switch (currentPage) {
      case 'Manufacturers':
        ids = allSourceData
          .filter(manufacturer => names.includes(manufacturer.name))
          .map(manufacturer => manufacturer.id);
        break;
  
      case 'Products':
        allSourceData.forEach(manufacturer => {
          const matchingProducts = manufacturer.product
            .filter(product => names.includes(product.name))
            .map(product => product.id);
          ids = [...ids, ...matchingProducts];
        });
        break;
  
      case 'Tiers':
        allSourceData.forEach(manufacturer => {
          manufacturer.product.forEach(product => {
            const matchingTiers = product.tier
              .filter(tier => names.includes(tier.name))
              .map(tier => tier.id);
            ids = [...ids, ...matchingTiers];
          });
        });
        break;
  
      case 'Colors':
        allSourceData.forEach(manufacturer => {
          manufacturer.product.forEach(product => {
            product.tier.forEach(tier => {
              const matchingColors = tier.color
                .filter(color => names.includes(color.name))
                .map(color => color.id);
              ids = [...ids, ...matchingColors];
            });
          });
        });
        break;
  
      default:
        console.error('Invalid currentPage:', currentPage);
    }
  
    return ids;
  };

  const handleTrashClick = async () => {
    setShowConfirmPopup(true);
  }

  const deleteItems = async (items) => {
    const ids = getIdsByNames(allSourceData, selectedItemsForDeletion, breadcrumbs.currentPage);    
    await serviceMethods.deleteMaterialsSubcategoryItems({
      tableName: breadcrumbs.currentPage.toLowerCase().slice(0, -1),
      ids: ids
    })
    await initialize()
  }
  
  const selectSourceData = (sourceData) => {
    const newSortedItems = sort(sourceData || []);
    setSortedItems(newSortedItems);
    const searchFilteredItems = newSortedItems.filter((item) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setAllItemsAfterFiltering(searchFilteredItems);
    paginate(undefined, searchFilteredItems);
  }

  const handleColumnHeaderClick = (column) => {
    setSortBy(column);
    setSortAscending(!sortAscending);
    const selectedSourceData = getSelectedSourceData()
    const newSortedItems = sort(selectedSourceData || [], column, !sortAscending);
    setSortedItems(newSortedItems);
    const searchFilteredItems = newSortedItems.filter((item) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setAllItemsAfterFiltering(searchFilteredItems);
    paginate(undefined, searchFilteredItems);
  };


  const sort = (items, sortByParam=sortBy, sortAscendingParam=sortAscending) => {
    const sortByTemp = sortByParam || sortBy;
    const sortAscendingTemp = sortAscendingParam != undefined ? sortAscendingParam : sortAscending;

    if (sortByTemp === 'name') {
      items.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (b.createdAt > a.createdAt) return 1;
        if (b.createdAt < a.createdAt) return -1;
        return 0;
      });
    }

    if (!sortAscendingTemp) {
      items.reverse();
    }

    return items;
  };
  
  const filterItemsBySearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    const searchFilteredItems = sortedItems.filter((item) => {
      return item.name.toLowerCase().includes(newSearchTerm.toLowerCase());
    });
    setAllItemsAfterFiltering(searchFilteredItems);
    paginate(undefined, searchFilteredItems);
  }
  
  const paginate = (direction=null, allItemsAfterFilteringParam=null) => {
    let newCurrentPage = currentPage;
    if (direction == 'prev') {
      if (currentPage < 2) return;
      newCurrentPage = currentPage - 1;
      setCurrentPage(newCurrentPage);
    }
    if (direction == 'next') {
      const maxPages = Math.ceil(allItemsAfterFiltering?.length / RESULTS_PER_PAGE);
      if (currentPage >= maxPages) return;
      newCurrentPage = currentPage + 1;
      setCurrentPage(newCurrentPage);
    }

    const startIndex = (newCurrentPage - 1) * RESULTS_PER_PAGE;
    const endIndex = startIndex + RESULTS_PER_PAGE;
    const allItemsAfterFilteringTemp = allItemsAfterFilteringParam || allItemsAfterFiltering;
    const paginatedItems = allItemsAfterFilteringTemp.slice(startIndex, endIndex);

    setDisplayItems(paginatedItems);
  };

  return (
    <AdminMaterials.Provider
      value={{
        allSourceData,
        selectedItemsForDeletion,
        setSelectedItemsForDeletion,
        searchTerm,
        filterItemsBySearchTerm,
        currentPage,
        setCurrentPage,
        RESULTS_PER_PAGE,
        sortBy,
        setSortBy,
        sortAscending,
        setSortAscending,
        handleColumnHeaderClick,
        allItemsAfterFiltering,
        displayItems,
        prevPageSelectedItem,
        addOrEditItemModal,
        setAddOrEditItemModal,
        handleTrashClick,
        breadcrumbs,
        setBreadcrumbs,
        handleClickItem,
        paginate,
        handleClickBreadcrumb,
        handleAddItem,
        showConfirmPopup,
        setShowConfirmPopup,
        deleteItems,
        handleUpdateItem,
        handleAddOrEditItem,
      }}
    >
      {children}
    </AdminMaterials.Provider>
  );
};

const useAdminMaterials = () => useContext(AdminMaterials);

export { useAdminMaterials };
export default AdminMaterialsProvider;
