import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AdminBranchView from '../../Admin/AdminBranchView/index.js'
import MobileNav from '../../../components/MobileNav'
import Sidebar from '../../../components/Sidebar'
import CalendarView from '../../Scheduler/CalendarView/index.js'
import PlannerView from '../../Scheduler/PlannerView/index.js'
import SchedulerJobsListView from '../../Scheduler/SchedulerJobsListView/index.js'
import ActivityLogView from '../../Scheduler/ActivityLogView/index.js'
import SchedulerTopBar from '../../../components/SchedulerTopBar'
import FilesView from '../../Scheduler/FilesView/index.js'
import AdminProvider from '../../../contexts/admin.js'
import SchedulerProvider from "../../../contexts/scheduler.js";
import SchedulerJobsListContextProvider from '../../../contexts/schedulerJobsList.js'
import JobDetailsModalContextProvider from '../../../contexts/jobDetailsModal.js'
import SchedulerActivityLogProvider from '../../../contexts/SchedulerActivityLog.js';
import SchedulerFilesProvider from '../../../contexts/SchedulerFiles.js';
import AdminFilesProvider from '../../../contexts/adminFiles.js'
import AdminMaterialsProvider from '../../../contexts/adminMaterials.js'
import AdminBranchMaterialsProvider from '../../../contexts/adminBranchMaterials.js'
import AdminRatesProvider from '../../../contexts/adminRates.js'
import AdminBranchRatesProvider from '../../../contexts/adminBranchRates.js'
import BranchAdminOnboard from '../BranchAdminOnboard/index.jsx'
import EmployeeProvider from '../../../contexts/employees.js'
import DailyJobLog from '../../Scheduler/DailyJobLogView/index.js'
import CustomersView from '../../Scheduler/CustomersView/index.js'
import AccountPage from '../../../components/AccountPage/index.js'

const BranchAdminLayout = () => {
  return (
    <div className="flex flex-col lg:flex-row h-screen w-screen min-h-[720px] font-roboto">
      <SchedulerProvider>
        <AdminProvider>
          <JobDetailsModalContextProvider>
            <SchedulerJobsListContextProvider>
              <SchedulerActivityLogProvider>
                <AdminFilesProvider>
                  <AdminMaterialsProvider>
                    <AdminBranchMaterialsProvider>
                      <SchedulerFilesProvider>
                        <AdminRatesProvider>
                          <AdminBranchRatesProvider>
                            <EmployeeProvider>
                              <Sidebar role={"branchadmin"}/>
                              <SchedulerTopBar />
                              <MobileNav role={"branchadmin"}/>
                              <Routes>
                                <Route exact={true} path='/onboard' element={<BranchAdminOnboard />} />
                                <Route exact={true} path='/calendar' element={<CalendarView />} />
                                <Route exact={true} path='/planner' element={<PlannerView />} />
                                <Route exact={true} path='/jobsList' element={<SchedulerJobsListView />} />
                                <Route exact={true} path='/activityLog' element={<ActivityLogView />} />
                                <Route exact={true} path='/files' element={<FilesView />} />
                                <Route exact={true} path='/setup/' element={<AdminBranchView />} />
                                <Route exact={true} path='/setup/details' element={<AdminBranchView />} />
                                <Route exact={true} path='/setup/employees' element={<AdminBranchView />} />
                                <Route exact={true} path='/setup/crews' element={<AdminBranchView />} />
                                <Route exact={true} path='/setup/poolbuilders' element={<AdminBranchView />} />
                                <Route exact={true} path='/setup/rates' element={<AdminBranchView />} />
                                <Route exact={true} path='/setup/plaster-menu' element={<AdminBranchView />} />
                                <Route exact={true} path='/setup/materials' element={<AdminBranchView />} />
                                <Route exact={true} path='/setup/files' element={<AdminBranchView />} />
                                <Route exact={true} path='/dailyJobLog' element={<DailyJobLog />} />
                                <Route exact={true} path='/account' element={<AccountPage />} />
                                <Route exact={true} path='/customers' element={<CustomersView />} />
                              </Routes>
                            </EmployeeProvider>
                          </AdminBranchRatesProvider>
                        </AdminRatesProvider>
                      </SchedulerFilesProvider>
                    </AdminBranchMaterialsProvider>
                  </AdminMaterialsProvider>
                </AdminFilesProvider>
              </SchedulerActivityLogProvider>
            </SchedulerJobsListContextProvider>
          </JobDetailsModalContextProvider>
        </AdminProvider>
      </SchedulerProvider>
    </div>
  )
}

export default BranchAdminLayout
