import React from "react";

const LinkExpired = ({ handleClose }) => {


  return (
    <div className="flex flex-col items-start w-[808px] p-6 bg-white rounded-[8px]">

      <div
        className="flex w-full gap-2 items-center select-none cursor-pointer pb-6"
        onClick={() => {
          handleClose()
        }}
      >
        <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
        <p className="font-semibold text-gunite-red">Back</p>
      </div>

      <div className='pb-6'>

        <div className='flex flex-row items-center'>
          <p className='text-[34px] font-bold text-secondary-blue'>
            Link expired
          </p>
        </div>

        <p className='text-[16px] text-light-blue'>
          You may request a new link from the Account page or by clicking Forgot Password at login.
        </p>

      </div>

      <button
        className='rounded px-9 py-3 text-tertiary-blue bg-white border-[3px] border-light-blue text-[24px] font-bold'
        onClick={() => {
          handleClose()
        }}
      >
        Return to home
      </button>

    </div>
  )
}

export default LinkExpired;
