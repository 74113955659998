import { useScheduler } from "../../contexts/scheduler";
import { useEffect, useState } from "react";
import ActivityLogMain from "../ActivityLogMain";

const JobDetailsActivityLogTab = () => {
  const [displayItems, setDisplayItems] = useState([]);
  const { selectedJobId, selectedScheduler } = useScheduler();
  const selectedJob = selectedScheduler.location.jobs.find(
    (item) => item.id === selectedJobId
  );

  useEffect(() => {
    const sortedData = [...selectedJob.activityLogs].sort((a, b) => {
      const dateA = new Date(a.activityDateTime);
      const dateB = new Date(b.activityDateTime);
      return dateB - dateA;
    });
    setDisplayItems(sortedData);
  }, [selectedJob]);

  return (
   <ActivityLogMain displayItems={displayItems} selectedJob={selectedJob}/>
  )
}

export default JobDetailsActivityLogTab;