import React from 'react';

const CustomerDraftsColumnHeaders = () => {
  return (
    <div className="flex gap-4 py-2 px-6 bg-navy-bright/10 border-b border-navy-light justify-between">
      <p className="text-gray-blue text-sm w-1/3">Job Name</p>
      <p className='text-gray-blue text-sm w-1/3'>Address</p>
      <p className='text-gray-blue text-sm w-1/3'>Service</p>
    </div>
  );
}

export default CustomerDraftsColumnHeaders;
