import { useState, useEffect } from "react";
import { useAdminMaterials } from "../../contexts/adminMaterials";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import AdminMaterialsItemDetails from "../AdminMaterialsManufacturerDetails";
import AdminMaterialsProductDetails from "../AdminMaterialsProductDetails";
import AdminMaterialsTierDetails from "../AdminMaterialsTierDetails";


const AdminMaterialsDetailsBox = () => { 
  const { breadcrumbs, addOrEditItemModal, setAddOrEditItemModal } = useAdminMaterials();

  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (breadcrumbs?.currentPage != 'Materials' && breadcrumbs?.currentPage != 'Manufacturers') {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [breadcrumbs])


  const toggleExpand = () => {
    setDetailsExpanded(!detailsExpanded);
  }

    return (
      <>
        {show && (
          <div className="mt-8 py-4 px-6 relative flex flex-col justify-between items-center w-full rounded-xl border border-navy-light bg-opacity-20 bg-navy-bright">
            <header className="w-full flex justify-between items-center">
              <h1 className="text-dark-blue text-xl font-semibold">Details</h1>
              <div className="flex">
                <img
                  src='/edit.svg' alt='Edit'
                  className='w-[14px] cursor-pointer'
                  onClick={() => setAddOrEditItemModal({ editMode: true, open: true })}
                />
                <div className="cursor-pointer ml-2" onClick={toggleExpand}>
                  {detailsExpanded && (
                    <FaChevronUp/>
                  )}
                  {!detailsExpanded && (
                    <FaChevronDown/>
                  )}
                </div>
              </div>
            </header>
            {detailsExpanded && (
              <div className="w-full">
                {breadcrumbs?.currentPage === 'Products' && (
                  <AdminMaterialsItemDetails/>
                )}
                {breadcrumbs?.currentPage === 'Tiers' && (
                  <AdminMaterialsProductDetails/>
                )}
                {breadcrumbs?.currentPage === 'Colors' && (
                  <AdminMaterialsTierDetails/>
                )}
                {breadcrumbs?.currentPage === 'ColorDetails' && (
                  <AdminMaterialsItemDetails/>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
}

export default AdminMaterialsDetailsBox;
