import React, { createContext, useContext, useState, useEffect } from 'react'
import { useAuth } from './auth'
import { DateTime } from 'luxon';
import { useScheduler } from './scheduler'
import { set } from 'date-fns';

const SchedulerFiles = createContext()

const SchedulerFilesProvider = props => {
  const RESULTS_PER_PAGE = 15;

  const [displayItems, setDisplayItems] = useState([]);
  const [ allItems, setAllItems ] = useState([])
  const [ locationFiles, setLocationFiles ] = useState([])
  const { selectedScheduler } = useScheduler();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("fileUri");
  const [sortAscending, setSortAscending] = useState(false);
  
  const paginate = (currentPage, items) => {
    const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
    const endIndex = (startIndex + RESULTS_PER_PAGE);
    return items.slice(startIndex, Math.min(endIndex, items.length));
  }

  const handleColumnHeaderClick = (column) => {
    setSortBy(column);
    if (sortBy === column) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
    }
  }

  const sort = (items) => {
    if (sortBy === 'fileUri') {
      items.sort(function(a,b) {
        if(a.fileUri.toLowerCase() > b.fileUri.toLowerCase()) {return  1;}
        if(a.fileUri.toLowerCase() < b.fileUri.toLowerCase()) {return -1;}
        if(b.createdAt > a.createdAt) {return  1;}
        if(b.createdAt < a.createdAt) {return -1;}
        return 0;
      });
    } else if (sortBy === 'createdAt') {
      items.sort(function(a,b) {
        if(b.createdAt > a.createdAt) {return  1;}
        if(b.createdAt < a.createdAt) {return -1;}
        if(a.fileUri.toLowerCase() > b.fileUri.toLowerCase()) {return  1;}
        if(a.fileUri.toLowerCase() < b.fileUri.toLowerCase()) {return -1;}
        return 0;
      });
    }

    if (!sortAscending) {
      items.reverse();
    }

    return items
  }

  useEffect(() => {
    const locationFiles = selectedScheduler?.location.locationFiles || []

    setLocationFiles(locationFiles)

    const sortedItems = sort(locationFiles)

    const searchFilteredItems = sortedItems.filter(item => {
      return item.fileUri.toLowerCase().includes(searchTerm.toLowerCase())
    })

    setAllItems(searchFilteredItems)

    const paginatedItems = paginate(currentPage, searchFilteredItems)

    setDisplayItems(paginatedItems)
    
  }, [selectedScheduler, searchTerm, currentPage, sortBy, sortAscending])

  useEffect(() => {
    if (locationFiles?.length == 0) { return }
    const paginatedJobs = paginate(currentPage, allItems); 
    setDisplayItems(paginatedJobs);
  }, [currentPage, allItems])

  useEffect(() => {
    if (locationFiles?.length == 0) { return }

    if (allItems.length > 0 && currentPage > Math.ceil(allItems.length / RESULTS_PER_PAGE)) {
        setCurrentPage(Math.ceil(allItems.length / RESULTS_PER_PAGE));  // Adjust page to the last possible page with items
    } else if (allItems.length === 0) {
        setCurrentPage(1);  // Reset to first page if no items exist
    }
  }, [allItems]);  // Adjust currentPage based on items count

  return (
    <SchedulerFiles.Provider value={{
      searchTerm,
      setSearchTerm,
      currentPage,
      setCurrentPage,
      RESULTS_PER_PAGE,
      sortBy,
      setSortBy,
      sortAscending,
      setSortAscending,
      handleColumnHeaderClick,
      allItems,
      displayItems,
    }}>
      {props.children}
    </SchedulerFiles.Provider>
  )
}

const useSchedulerFiles = () => useContext(SchedulerFiles)

export { useSchedulerFiles }
export default SchedulerFilesProvider
