import React from 'react'
import TimeslotFlashingMobile from '../TimeslotFlashingMobile'
import TimeslotMobile from '../TimeslotMobile'
import { useSchedulerCalendarMobile } from '../../contexts/schedulerCalendarMobile'
import { useScheduler } from '../../contexts/scheduler'

const CrewMobile = (props) => {
  const { crew, i, day, crewJobs } = props
  const { displayMode } = useSchedulerCalendarMobile()
  const { selectedScheduler, crewColors } = useScheduler()


  const timeslotJobsFilter = (shift) => {
    const data = crewJobs?.filter((calendarJob) => (
      shift === calendarJob?.shift
    ))
    return data[0] //return a single job or undefined (NOT an array)
  }

  const FTimeslotJobsFilter = (fshift) => {
    const data = crewJobs?.filter((flashJob) => (fshift === flashJob?.fshift))
    return data //return an array containing 0, 1, or multiple jobs (other things should keep it from being more than 2 jobs but this filter could theoretically return an unlimited number)
  }

  const crewDisplay = () => {
    if (selectedScheduler.location.service === 'GUNITE') {
      if (displayMode === 'short') {
        return (
          <div className={`w-full flex flex-col ${crewColors[i]} bg-opacity-20`}>
            <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  timeslotJob={timeslotJobsFilter('1')} shoot={'1'} />
            <TimeslotFlashingMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('1')} fshift={'1'} />
            <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('2')} shoot={'2'} />
            <TimeslotFlashingMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('2')} fshift={'2'} />
            <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('3')} shoot={'3'} />
            <TimeslotFlashingMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('3')} fshift={'3'} />
          </div>
        )
      } else {
        return (
          <div className={`h-auto ${crewColors[i]} bg-opacity-20`} >
          <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  timeslotJob={timeslotJobsFilter('1')} shoot={'1'} />
          <TimeslotFlashingMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('1')} fshift={'1'} />
          <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('2')} shoot={'2'} />
          <TimeslotFlashingMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('2')} fshift={'2'} />
          <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('3')} shoot={'3'} />
          <TimeslotFlashingMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  ftimeslotJobs={FTimeslotJobsFilter('3')} fshift={'3'} />
        </div>
        )
      }
    } else {
      if (displayMode === 'short') {
        return (
          <div className={`w-full flex flex-col ${crewColors[i]} bg-opacity-20`}>
            <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  timeslotJob={timeslotJobsFilter('1')} shoot={'1'} />
            <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('2')} shoot={'2'} />
            <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('3')} shoot={'3'} />
          </div>
        )
      } else {
        return (
          <div className={`h-auto ${crewColors[i]} bg-opacity-20`} >
          <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id}  timeslotJob={timeslotJobsFilter('1')} shoot={'1'} />
          <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('2')} shoot={'2'} />
          <TimeslotMobile i={i} scheduledDate={new Date(day)} crewId={crew.id} timeslotJob={timeslotJobsFilter('3')} shoot={'3'} />
        </div>
        )
      }
    }
  }

  return (
    <>{crewDisplay()}</>
  )
}

export default CrewMobile
