import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { formatDateLong, stateAbbreviator, formatTime } from '../../utils';

const styles = StyleSheet.create({
  page: {
    width: '100%',
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Helvetica',
  }
});

Font.registerHyphenationCallback(word => {
  // Return entire word as unique part
  return [word];
});

const JobLogPDFTemplate = ({ calendarJob }) => {
  const timeOptions = {
    hour12: true,
    dayPeriod: 'narrow'
  }

  return (
    <Document title={`${calendarJob.job.jobName} Log`}>
      <Page size='A4' style={styles.page}>
        <View style={{ display: 'flex', flexDirection: 'column', width: '100%', fontSize: 12, gap: 14 }}>
          <Text style={{ fontSize: 20, fontWeight: 'bold', width: '100%', textAlign: 'center' }}>{calendarJob.job.jobName} {calendarJob.calendarJobType === 'REGULAR' ? ' - JOB LOG' : ' - FLASH LOG'}</Text>

          <View style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 10 }}>
            <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <View style={{ display: 'flex', flexDirection: 'row', paddingBottom: 2, borderBottom: 1, marginBottom: 2 }}>
                <Text style={{ width: '40%' }}>ADDRESS</Text>
                <View style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                  <Text style={{ margin: 0, height: 'auto' }}>{calendarJob.job.address}</Text>
                  <Text style={{ margin: 0, height: 'auto' }}>{calendarJob.job.city}, {stateAbbreviator[calendarJob.job.state]} {calendarJob.job.zipCode}</Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', paddingBottom: 2, borderBottom: 1, marginBottom: 2 }}>
                <Text style={{ width: '40%' }}>POOL BUILDER</Text>
                <Text style={{ width: '60%' }}>{calendarJob.job.owner.customer.bizName}</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ width: '40%' }}>FOREMAN</Text>
                <Text style={{ width: '60%' }}>{calendarJob.jobLog.foreman.name}</Text>
              </View>

            </View>

            <View style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '0', borderLeft: 1 }}>
            </View>

            <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>

              <View style={{ display: 'flex', flexDirection: 'row', paddingBottom: 2, borderBottom: 1, marginBottom: 2 }}>
                <Text style={{ width: '40%' }}>WORK DATE</Text>
                <Text style={{ width: '60%' }}>{formatDateLong(calendarJob.scheduledDate)}</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ width: '40%' }}>SHOOT</Text>
                <Text style={{ width: '60%' }}>{calendarJob.calendarJobType === 'REGULAR' ? calendarJob.shift : calendarJob.fshift}</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ width: '40%' }}>START TIME</Text>
                <Text style={{ width: '60%' }}>{formatTime(calendarJob.jobLog?.startTime, false)}</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ width: '40%' }}>END TIME</Text>
                <Text style={{ width: '60%' }}>{formatTime(calendarJob.jobLog?.endTime, false)}</Text>
              </View>

            </View>
          </View>

          {calendarJob.job.jobType === 'gunite'
            ?
            <View style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 10 }}>
              <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <Text style={{ fontSize: 12, fontWeight: 'bold', padding: 2, border: 1 }}>Yards: {calendarJob.jobLog.yards || 'N/A'}</Text>


                <Text style={{ fontSize: 12, fontWeight: 'bold', padding: 2, borderBottom: 1, borderLeft: 1, borderRight: 1 }}>Spa: {calendarJob.jobLog.spa || 'N/A'}</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <Text style={{ fontSize: 12, fontWeight: 'bold', padding: 2, border: 1 }}>Steps: {calendarJob.jobLog.steps || 'N/A'}</Text>

                <Text style={{ fontSize: 12, fontWeight: 'bold', padding: 2, borderBottom: 1, borderLeft: 1, borderRight: 1 }}>Benches: {calendarJob.jobLog.benches || 'N/A'}</Text>
              </View>
            </View>
            :
            <View style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 10 }}>
              <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <Text style={{ fontSize: 12, fontWeight: 'bold', padding: 2, border: 1 }}>Batches: {calendarJob.jobLog.batches || 'N/A'}</Text>

                <Text style={{ fontSize: 12, fontWeight: 'bold', padding: 2, borderBottom: 1, borderLeft: 1, borderRight: 1 }}>Wall Fittings: {calendarJob.jobLog.wallFittings || 'N/A'}</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <Text style={{ fontSize: 12, fontWeight: 'bold', padding: 2, border: 1 }}>Main Drains: {calendarJob.jobLog.mainDrains  || 'N/A'}</Text>
              </View>
            </View>
          }
          <View style={{  display: 'flex', flexDirection: 'row', width: '100%', gap: 10 }}>
            {/* CREW SECTION */}
            <View style={{  display: 'flex', flexDirection: 'column', width: '50%' }} wrap={false}>
              <Text style={{ fontSize: 12, fontWeight: 'bold', borderBottom: 1 }}>Crew</Text>
              {calendarJob.jobLog.crewMembers?.map((member, index) => (
                <Text style={{ fontSize: 12 }} key={`crewMember-${index}`}>{member.crewMember?.user?.name || ''}</Text>
              ))}
            </View>

            {/* DRIVERS SECTION */}
            {calendarJob.job.jobType === 'gunite'
              ?
              <View style={{  display: 'flex', flexDirection: 'column', width: '50%' }} wrap={false}>
                <Text style={{ fontSize: 12, fontWeight: 'bold', borderBottom: 1 }}>Drivers</Text>
                {calendarJob.jobLog.drivers?.map((driver, index) => (
                  <Text style={{ fontSize: 12 }} key={`driver-${index}`}>{driver?.driver?.user?.name || ''}</Text>
                ))}
              </View>
              :
              null
            }
          </View>

          <View style={{  display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold', width: '100%' }}>Comments:</Text>
            <Text style={{ fontSize: 12, border: 1, padding: 3 }}>{(calendarJob.job.jobType === 'plaster' ? calendarJob.jobLog.plasterComments : calendarJob.jobLog.guniteComments) || 'N/A'}</Text>
          </View>

        </View>
      </Page>
    </Document>
  )
}


export default JobLogPDFTemplate;
