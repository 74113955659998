import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { formatDate } from "../../utils";
import { useScheduler } from '../../contexts/scheduler';

const JobDetailsFilesTab = () => {
  const [poolPlanIsExpanded, setPoolPlanIsExpanded] = useState(true);
  const [otherFilesIsExpanded, setOtherFilesIsExpanded] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL;
  
  const { selectedJobId, selectedScheduler } = useScheduler();
  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)

  const handleTogglePoolPlanExpand = () => {
    setPoolPlanIsExpanded(!poolPlanIsExpanded);
  }

  const handleToggleOtherFilesExpand = () => {
    setOtherFilesIsExpanded(!otherFilesIsExpanded);
  }


  const makeFileRow = ({ fileUri, date, index }) => {
    return (
      <div className={`flex flex-row justify-center items-center ${index !== 0 ? 'mt-5' : ''}`}>
        <div className='mr-[21px] flex justify-center items-center  w-[90px] h-[90px] rounded-[4px] bg-all-f-white border border-navy-light'>
          {fileUri == 'none' ? (
            <img src='/JobDetails/description.svg' alt='description' className='w-[48px] h-[48px] rounded-[4px]' />
          ) : (fileUri.toLowerCase().endsWith('.pdf') || fileUri.toLowerCase().endsWith('.heic')) ? (
            <a href={`${apiUrl}/${fileUri}`} download={`${apiUrl}/${fileUri}`}>
              <img src='/JobDetails/description.svg' alt='description' className='w-[48px] h-[48px] rounded-[4px]' />
            </a>
          ) : (
            <a href={`${apiUrl}/${fileUri}`} download={`${apiUrl}/${fileUri}`}>
              <img crossOrigin="anonymous" src={`${apiUrl}/${fileUri}`} alt='Uploaded file' className='w-[90px] h-[90px] rounded-[4px]' />
            </a>
          )}
        </div>
        <div className="flex-grow flex flex-col divide-y divide-navy-light bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-3 py-1">
          <div className={`flex justify-between items-center py-1 rounded-tl-lg rounded-tr-lg`}>
            <span className="text-dark-blue py-1">File name</span>
            {fileUri == 'none' ? (
              <span className="text-dark-blue font-bold py-1">None</span>
            ) : (
              <span className="text-dark-blue font-bold py-1">{fileUri}</span>
            )}    
          </div>
          <div className={`flex justify-between items-center py-1 rounded-bl-lg rounded-br-lg`}>
            <span className="text-dark-blue py-1">Date</span>
            {fileUri == 'none' ? (
              <></>
            ) : (
              <span className="text-dark-blue font-bold py-1">{formatDate(date)}</span>
            )}
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className='p-4'>
      <div className="mt-4 mb-6">
        <div className="flex justify-between mb-2 mx-2">
          <h3 className="text-lg font-bold text-dark-blue">Pool plan</h3>
          {poolPlanIsExpanded ? 
            <FaChevronUp onClick={handleTogglePoolPlanExpand} className="cursor-pointer text-dark-blue"/> 
            : 
            <FaChevronDown onClick={handleTogglePoolPlanExpand} className="cursor-pointer text-dark-blue"/>
          }

        </div>
          {poolPlanIsExpanded && (
            selectedJob.pools[0].planUri === null ? (
              <div className="flex justify-center items-center h-16">
                <p className="text-gray-blue text-sm">No pool plan to display</p>
              </div>
            ) : (
              makeFileRow({ fileUri: selectedJob.pools[0].planUri, date: selectedJob.submittedAt, index: 0 })
            )
          )}
      </div>

    <div className="mt-4 mb-6">
      <div className="flex justify-between mb-2 mx-2">
        <h3 className="text-lg font-bold text-dark-blue">Other files</h3>
        {otherFilesIsExpanded ? <FaChevronUp onClick={handleToggleOtherFilesExpand} className="cursor-pointer text-dark-blue"/> : <FaChevronDown onClick={handleToggleOtherFilesExpand} className="cursor-pointer text-dark-blue"/>}
      </div>
      {otherFilesIsExpanded && selectedJob?.jobFiles?.map((file, index) => 
        makeFileRow({ fileUri: file.fileUri, date: file.createdAt, index })
      )}
    </div>
  </div>
  );
};

export default JobDetailsFilesTab;
