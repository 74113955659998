import React, { useState, useRef } from "react";
import serviceMethods from '../../service'

const ResetPasswordModal = ({ userEmail, showResetPassword, setShowResetPassword }) => {
  const [emailSent, setEmailSent] = useState(false)
  const modal = useRef()
  const { requestPasswordResetEmail } = serviceMethods;

  const handleOutsideClick = (e) => {
    if (!modal.current.contains(e.target)) {
      setShowResetPassword(false)
      setEmailSent(false)
    }
  }

  const handleSendEmail = async() => {
    const emailSent = await requestPasswordResetEmail(userEmail)
    if (emailSent.ok) {
      setEmailSent(true)
    }
  }

  const handleClose = () => {
    setShowResetPassword(false)
    setEmailSent(false)
  }

  return (
    <div
      className={`fixed top-0 left-0 flex justify-center items-center w-screen h-screen bg-black bg-opacity-80 z-10 ${showResetPassword ? '' : 'hidden'}`}
      onClick={(e) => {
        handleOutsideClick(e)
      }}
    >

      <div ref={modal} className="flex flex-col items-start w-[760px] p-6 bg-white rounded-[8px]">

        <div
          className="flex w-full gap-2 items-center select-none cursor-pointer pb-6"
          onClick={() => {
            handleClose()
          }}
        >
            <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
            <p className="font-semibold text-gunite-red">Back</p>
        </div>

        <div className='pb-6'>

          <div className='flex flex-row items-center'>
            <p className='text-[34px] font-bold text-secondary-blue'>
              {!emailSent ? 'Reset Password' : 'Email sent'}
            </p>
            <img alt="back" src="/check_in_circle_icon.svg" className={`h-[24px] pl-3 ${emailSent ? '' : 'hidden'}`} />
          </div>

          <p className='text-[16px] text-light-blue'>
            {!emailSent ? 'We will send a link that will reset your password to your email address.' : 'Check your email and open the link we sent to continue.'}
          </p>
        </div>

        <div className={`pb-6 ${emailSent ? 'hidden' : ''}`}>
          <p className='text-[14px] text-light-blue'>
            Email Address
          </p>
          <p className='text-[20px] text-secondary-blue'>
            {userEmail}
          </p>
        </div>

        {!emailSent ?
          <button
            className='rounded px-9 py-3 text-white bg-gunite-red'
            onClick={() => {
              handleSendEmail()
            }}
          >
            Send reset password link
          </button>
          :
          <button
            className='rounded px-9 py-3 text-tertiary-blue bg-white border-[3px] border-light-blue text-[24px] font-bold'
            onClick={() => {
              handleClose()
            }}
          >
            Back to my account
          </button>
        }

      </div>

    </div>
  )
}

export default ResetPasswordModal;
