import { useContext } from 'react';
import { ResponsiveContext } from '../../contexts/responsive'
import { FaArrowDown } from "react-icons/fa";

const ColumnHeaders = () => {
  const { responsive, BREAKPOINTS } = useContext(ResponsiveContext);
  const { width, height } = responsive;

return (
  <div className="grid grid-cols-[1fr_1fr] md:grid-cols-[1.5fr_0.75fr_0.75fr_1.5fr_1fr_1.5fr] gap-4 py-2 px-3 bg-opacity-10 bg-navy-bright border-b border-navy-light">
    <div className='flex flex-row  items-start'>
      <div className="text-gray-blue text-sm pl-1 pr-1">Name</div>
      {/* <FaArrowDown className="text-gray-blue text-xs" /> */}
    </div>
    {(width > BREAKPOINTS.md) && (
      <>
        <div className='text-gray-blue text-sm'>Submitted Date</div>
        <div className='text-gray-blue text-sm'>Requested Date</div>
        <div className='text-gray-blue text-sm '>Address</div>
        <div className='text-gray-blue text-sm'>Poolbuilder</div>
      </>
    )}
    <div className='text-gray-blue text-sm flex justify-end gap-1'>Status</div>
  </div>
);
}

export default ColumnHeaders;