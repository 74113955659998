import React, { useContext, useState } from 'react'
import { useModal } from '../../contexts/modal'
import { useIntakeForm } from "../../contexts/intakeForm";
import { useAuth } from "../../contexts/auth";
import serviceMethods from "../../service"
import Selector from "../Selector";
import Input from '../Input';
import PhoneInput from '../PhoneInput'
import { FIFTY_STATES } from '../../constants'

const SignUpModal = () => {
  const { setMe, setIsLoggedIn } = useAuth();
  const [isPriorEmail, setIsPriorEmail] = useState(false)
  // const { navigateNext, navigatePrevious } = useProgress()
  const { modalMethods } = useModal()
  const { close } = modalMethods

  const { builderInfo, setBuilderInfo } = useIntakeForm()
  const ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
  const EMAIL_REGEX = /\S+@\S+\.\S+/;
  const formattedPhonePattern = /^(\(\d{3}\)[\s])\d{3}\-\d{4}$/;

  const firstNameError = builderInfo.firstName < 1
  const lastNameError = builderInfo.lastName < 1
  const bizNameError = builderInfo.bizName < 1
  const bizAddressError = builderInfo.bizAddress < 1
  const cityError = builderInfo.city.length < 1
  const stateError = builderInfo.state < 1
  const zipCodeError = !ZIP_REGEX.test(builderInfo.zipCode)
  const phoneError = !formattedPhonePattern.test(builderInfo.phone)
  const emailError = !EMAIL_REGEX.test(builderInfo.email)
  const confirmEmailError = !EMAIL_REGEX.test(builderInfo.confirmEmail) || builderInfo.email !== builderInfo.confirmEmail
  const passwordError = builderInfo.password?.length < 1

  const buttonDisabled = (
    firstNameError
    || lastNameError
    || bizNameError
    || bizAddressError
    || cityError
    || stateError
    || zipCodeError
    || phoneError
    || emailError
    || confirmEmailError
    || passwordError
  )

  const handleRegister = async (e) => {
    e.preventDefault()
    const res = await serviceMethods.newCustomer(builderInfo)
    const data = await res.json()
    if (res.status === 500 && data.message === 'email-already-taken') {
      setIsPriorEmail(true)
    }
    if (res.ok) {
      const { user, tokens } = data
      serviceMethods.setAuthTokens(tokens)
      setMe(user)
      setIsLoggedIn(true)
      close()
    }
    // navigateNext()  Does not work because the modal is called outside of the progress context
  }

  return (
    <div className='flex flex-col w-full lg:w-3/6 bg-white p-6 rounded-lg overflow-y-auto'>
      <div className="flex flex-col mb-6">
        <button className="flex gap-2 items-center mb-6" onClick={close}>
          <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
          <p className="font-semibold text-gunite-red">Back</p>
        </button>
        <p className="text-secondary-blue font-bold text-4xl mb-1">Builder Information</p>
        <p className="text-secondary-blue/60 font-semibold text-base">Contact Information for your Business</p>
      </div>

      <form className="flex flex-col gap-6" onSubmit={handleRegister}>

        <div className="flex flex-col lg:flex-row justify-stretch gap-6">
          {/* First name field */}
          <div className="relative lg:w-1/2 group">
            <Input
              value={builderInfo.firstName}
              placeholder={'First Name *'}
              onChange={(e) => {
                setBuilderInfo({
                  ...builderInfo,
                  firstName: e.target.value,
                })
              }
              }
              isError={firstNameError}
            />
          </div>

          {/* Last name field */}
          <div className="relative lg:w-1/2 group">
            <Input
              value={builderInfo.lastName}
              placeholder={'Last Name *'}
              onChange={(e) => {
                setBuilderInfo({
                  ...builderInfo,
                  lastName: e.target.value,
                })
              }
              }
              isError={lastNameError}
            />
          </div>
        </div>

        {/* Business name field */}
        <div className="relative group">
          <Input
            value={builderInfo.bizName}
            placeholder={'Business Name *'}
            onChange={(e) => {
              setBuilderInfo({
                ...builderInfo,
                bizName: e.target.value,
              })
            }
            }
            isError={bizNameError}
          />
        </div>

        {/* Business street address field */}
        <div className="relative group">
          <Input
            value={builderInfo.bizAddress}
            placeholder={'Business Address *'}
            onChange={(e) => {
              setBuilderInfo({
                ...builderInfo,
                bizAddress: e.target.value,
              })
            }
            }
            isError={bizAddressError}
          />
        </div>

        <div className="flex flex-col lg:flex-row gap-6">
          {/* City field */}
          <div className="relative lg:w-1/3 group">
            <Input
              value={builderInfo.city}
              placeholder={'City *'}
              onChange={(e) => {
                setBuilderInfo({
                  ...builderInfo,
                  city: e.target.value,
                })
              }
              }
              isError={cityError}
            />
          </div>

          {/* State field (selector) */}
          <div className="relative lg:w-1/3">
            <Selector
              value={builderInfo.state}
              placeholder={'State *'}
              options={FIFTY_STATES}
              onChange={(e) => {
                setBuilderInfo({
                  ...builderInfo,
                  state: e.target.value
                })
              }}
            />
          </div>

          {/* Zip code field */}
          <div className="relative lg:w-1/3 group">
            <Input
              value={builderInfo.zipCode}
              placeholder={'Zip Code *'}
              onChange={(e) => {
                setBuilderInfo({
                  ...builderInfo,
                  zipCode: e.target.value,
                })
              }
              }
              isError={zipCodeError}
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-6">
          {/* Phone number field */}
          <div className="relative lg:w-1/2 group">
          <PhoneInput
              value={builderInfo.phone}
              placeholder={'Phone *'}
              onChange={(e) => {
                setBuilderInfo({
                  ...builderInfo,
                  phone: e.target.value,
                })
              }
              }
              isError={phoneError}
            />
          </div>

          {/* Email Address field */}
          <div className="relative w-full lg:w-1/2 group">
            <Input
              value={builderInfo.email}
              placeholder={'Email Address *'}
              onChange={(e) => {
                setIsPriorEmail(false)
                setBuilderInfo({
                  ...builderInfo,
                  email: e.target.value,
                })
              }
              }
              isError={emailError}
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-stretch gap-6">

          {/* Confirm Email Address field */}
          <div className="relative lg:w-1/2 group">
            <Input
              value={builderInfo.confirmEmail}
              placeholder={'Confirm Email Address *'}
              onChange={(e) => {
                setBuilderInfo({
                  ...builderInfo,
                  confirmEmail: e.target.value,
                })
              }
              }
              isError={confirmEmailError}
            />
          </div>

          {/* Password field */}
          <div className="relative lg:w-1/2 group">
            <Input
              value={builderInfo.password ? builderInfo.password : ''}
              placeholder={'Password *'}
              onChange={(e) => {
                setBuilderInfo({
                  ...builderInfo,
                  password: e.target.value,
                })
              }
              }
              isError={passwordError}
              isPassword={true}
            />
          </div>
        </div>
        {isPriorEmail && <p className='text-gunite-red font-semibold'>An account with this email already exists!</p>}
        <div className="flex items-center">
          {/* Register button */}
          <div className="w-full lg:w-auto">
            <button
              type="submit"
              className={`w-full lg:w-auto lg:px-9 lg:py-3 rounded p-3  text-2xl font-bold ${buttonDisabled ? "bg-black/10 text-black/40" : "bg-gunite-red text-white"}`}
              disabled={buttonDisabled}
            >
              Register
            </button>
          </div>

          {/* keep me logged in button: desktop only */}

          <input type="checkbox" id="stayLoggedIn" className="hidden lg:inline-block ml-6 h-5 w-5" name="rememberMe"
          />
          <label className="hidden lg:inline-block ml-2 font-medium text-lg" htmlFor="stayLoggedIn">Keep me logged in</label>
        </div>
      </form>
    </div>
  )
}
export default SignUpModal
