import React from 'react'
import { useScheduler } from '../../contexts/scheduler'
import { format } from 'date-fns'
import { useSchedulerCalendarMobile } from '../../contexts/schedulerCalendarMobile'

const CalendarMobileDisplaySettingsBar = () => {
  const { displayMode, setDisplayMode, days, previousWeek, nextWeek, setCurrentDay, today, dropActive, setDropActive, dropActiveFlashing, setDropActiveFlashing } = useSchedulerCalendarMobile()
  const { undeletedCrews, crewColors } = useScheduler()

  const crewButtonStyles = (i) => {
    const fixedValues = 'flex justify-center items-center h-10 w-10 rounded-full border-4 border-[#f3f5f9] -ml-[11px] font-bold pt-[1px]'
    const styles = fixedValues.concat(' ', crewColors[i])
    return styles
  }

  const intervalDisplayString = () => {
    const day1 = format(days[1], 'LLL d')
    const day6 = format(days[6], 'LLL d')
    return `${day1} – ${day6}`
  }

  const setToday = () => {
    setCurrentDay(today)
  }

  const resetDropState = () => {
    if (dropActive) setDropActive(false)
    if (dropActiveFlashing) setDropActiveFlashing(false)
  }

  return (
    <div
      className='flex flex-col justify-between items-center w-full  px-6 text-navy font-roboto bg-navy-bright/10'
      onClick={resetDropState}
    >
      <div className='flex w-full gap-4 h-[48px] justify-between items-center'>
        <p className='text-lg font-bold'>Calendar</p>
        <button className='rounded h-8 px-2 py-1 bg-white border-navy-light border-[1px] text-[12px] font-semibold'
          onClick={() => setToday()}
        >Today</button>
        <div className='flex justify-center w-[100px]'>
          <p className='text-[12px] font-semibold'>{intervalDisplayString()}</p>
        </div>
        <div className='items-center flex'>
          <button onClick={() => previousWeek()}><img src='/mgmtVectors/chevron_left.svg'></img></button>
          <button onClick={() => nextWeek()}><img src='/mgmtVectors/chevron_right.svg'></img></button>
        </div>
      </div>
      <div className='flex justify-between w-full h-[40px]'>
        <div className='flex h-8 p-[2px] rounded-[6px] bg-navy-bright/10 text-[12px] font-bold'>
          <button
            className={`px-2 py-[2px] rounded ${displayMode === 'short' ? 'bg-navy text-white' : ''}`}
            onClick={() => setDisplayMode('short')}
          >
            Short
          </button>
          <button
            className={`px-2 py-[2px] rounded ${displayMode === 'long' ? 'bg-navy text-white' : ''}`}
            onClick={() => setDisplayMode('long')}
          >
            Long
          </button>
        </div>
        <div className='flex flex-row'>
          {undeletedCrews?.map((crew, i) => {
            return (
              <div className={crewButtonStyles(i)} key={`crew-button${i}`} title={crew.name} >
                {crew.name.charAt(0)}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CalendarMobileDisplaySettingsBar
