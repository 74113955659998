import cookies from 'browser-cookies'
import React, { createContext, useContext, useState, useEffect } from 'react'
import serviceMethods from '../service'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './auth'

const Admin = createContext()

const AdminProvider = props => {
  const DEFAULT_BRANCH = {
    id: null,
    latitude: '',
    longitude: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    label: '',
    image: '',
    email: '',
    phone: '',
    service: null
  }

  const RESULTS_PER_PAGE = 10;

  const [displayItems, setDisplayItems] = useState([]);
  const [allLocations, setAllLocations] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState(DEFAULT_BRANCH)
  const [selectedIds, setSelectedIds] = useState([])
  const { me, getProfile } = useAuth()
  const [previewImage, setPreviewImage] = useState(null);
  const [allAdminUsers, setAllAdminUsers] = useState([]);
  const [branchSpecificAdmins, setBranchSpecificAdmins] = useState([]);
  const [newAdminFormSearching, setNewAdminFormSearching] = useState(false);
  const [newAdminSearchTerm, setNewAdminSearchTerm] = useState('');
  const [selectedAdmin, setSelectedAdmin] = useState({})
  const [needToUpdateAdmins, setNeedToUpdateAdmins] = useState(false);
  const [sortBy, setSortBy] = useState("name");
  const [sortAscending, setSortAscending]= useState(false);
  const [ showAddBranchModal, setShowAddBranchModal] = useState(false);
  const [branchesSelectedForDeletion, setBranchesSelectedForDeletion] = useState([])
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false)

  const navigate = useNavigate()

  const handleTrashClick = async () => {
    if (branchesSelectedForDeletion.length > 0) {
      setShowConfirmDeletePopup(true)
    }
  }
  const confirmDeleteHeader = branchesSelectedForDeletion.length > 1 ? "Delete branches" : "Delete branch"
  const confirmDeleteDescription = branchesSelectedForDeletion.length > 1 ? `Are you sure you want to delete these branches?` : `Are you sure you want to delete this branch?`

  const handleConfirmDelete = async () => {
    await serviceMethods.deleteLocations(branchesSelectedForDeletion)
    // change the cookie to the next remaining scheduler id to prevent errors on contexts that have selectedScheduler
    const newSelectedScheduler = me.scheduler.find((scheduler) => !branchesSelectedForDeletion.includes(scheduler.location.id))
    cookies.set('schedulerId', newSelectedScheduler.id.toString())
    
    await getProfile()
    setBranchesSelectedForDeletion([])
    setShowConfirmDeletePopup(false)
  }

  useEffect(()=>{
    if (newAdminSearchTerm !== ''){
      setNewAdminFormSearching(true)
    } else {
      setNewAdminFormSearching(false)
    }
  },[newAdminSearchTerm])

  const paginate = (currentPage, items) => {
    const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
    const endIndex = (startIndex + RESULTS_PER_PAGE);
    return items?.slice(startIndex, Math.min(endIndex, items.length));
  }

  const sort = (items) => {
    if (sortBy === 'name') {
      items.sort(function(a,b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {return  1;}
        if(a.name.toLowerCase() < b.name.toLowerCase()) {return -1;}
        return 0;
      });
    }
    return items;
  }

  const getUpdatedAdminList = async () => {
    if (me?.role === 'ADMIN'){
      const res = await serviceMethods.getAllAdmins();
      if (res.ok){
        const data = await res.json();
        const globalAdmins = data.filter(admin => {
          return admin.role === 'ADMIN'
        })
        setAllAdminUsers(globalAdmins);
        if (selectedLocation?.id){
          const adminsForSelectedBranch = [];
          data.forEach(admin => {
            if (admin.role === 'BRANCHADMIN'){
              admin.branchAdmin.forEach(branch => {
                if (branch.locationId === selectedLocation.id){
                  adminsForSelectedBranch.push(admin)
                }
              })
            }
          })
          setBranchSpecificAdmins(adminsForSelectedBranch);
        }
      }
    } else if (me?.role === 'BRANCHADMIN'){
      const res = await serviceMethods.getAllBranchAdmins();
      if (res.ok){
        const data = await res.json();
        if (selectedLocation?.id){
          const adminsForSelectedBranch = [];
          data.forEach(admin => {
            if (admin.role === 'BRANCHADMIN'){
              admin.branchAdmin.forEach(branch => {
                if (branch.locationId === selectedLocation.id){
                  adminsForSelectedBranch.push(admin)
                }
              })
            }
          })
          setBranchSpecificAdmins(adminsForSelectedBranch);
        }
      }
    }
  }

  useEffect(()=>{
    if (me?.role === 'ADMIN' || me?.role === 'BRANCHADMIN') {
      getUpdatedAdminList()
    }
  },[selectedLocation])

  const getLocationIdFromUrl = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    return queryParameters.get("branchId");
  };

  const fetchLocationsAndSetSelected = async () => {
    const locationId = getLocationIdFromUrl()
    if (me?.role === 'ADMIN'){ 
      const allTheLocations = me?.admin?.allLocations?? []
      allTheLocations.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
      setAllLocations(allTheLocations)
      const newSelectedLocation = me?.admin?.allLocations?.find(item => item.id == locationId) 
      setSelectedLocation(newSelectedLocation)
    } else if (me?.role === 'BRANCHADMIN'){
      if (me?.admin?.allLocations){
        setSelectedLocation(me?.admin?.allLocations[0])
        setAllLocations(me.admin.allLocations)
      }
    }
  }

  useEffect(() => {
    if (me?.role === 'ADMIN' || me?.role === 'BRANCHADMIN') {
      fetchLocationsAndSetSelected()
      getUpdatedAdminList()
    }
  }, [me, needToUpdateAdmins])

  useEffect(() => {
    const searchFilteredItems = allLocations?.filter(item => {
      return item.label.toLowerCase().includes(searchTerm.toLowerCase())
    })

    setFilteredItems(searchFilteredItems)
    const paginatedItems = paginate(currentPage, searchFilteredItems)
    setDisplayItems(paginatedItems)
  }, [searchTerm, currentPage, allLocations])

  useEffect(() => {
    const paginatedJobs = paginate(currentPage, filteredItems);
    setDisplayItems(paginatedJobs);
  }, [currentPage, filteredItems])

  useEffect(() => {
    if (filteredItems?.length > 0 && currentPage > Math.ceil(filteredItems?.length / RESULTS_PER_PAGE)) {
        setCurrentPage(Math.ceil(filteredItems?.length / RESULTS_PER_PAGE));  // Adjust page to the last possible page with items
    } else if (filteredItems?.length === 0) {
        setCurrentPage(1);  // Reset to first page if no items exist
    }
  }, [filteredItems]);  // Adjust currentPage based on items count

  const handleLocationClick = (location) => {
    const selectedLocationId = location.id
    setSelectedLocation(location)
    navigate('/admin/setup/details?branchId=' + selectedLocationId)
  }

  useEffect(()=>{
    setPreviewImage(null) //updates displayed logo when you choose a different branch from dropdown
  },[selectedLocation])

  return (
    <Admin.Provider value={{
      selectedIds,
      setSelectedIds,
      searchTerm,
      setSearchTerm,
      currentPage,
      setCurrentPage,
      RESULTS_PER_PAGE,
      allLocations,
      displayItems,
      handleLocationClick,
      selectedLocation,
      setSelectedLocation,
      getLocationIdFromUrl,
      fetchLocationsAndSetSelected,
      previewImage,
      setPreviewImage,
      DEFAULT_BRANCH,
      allAdminUsers,
      setAllAdminUsers,
      filteredItems,
      setFilteredItems,
      newAdminFormSearching,
      setNewAdminFormSearching,
      newAdminSearchTerm,
      setNewAdminSearchTerm,
      selectedAdmin,
      setSelectedAdmin,
      getUpdatedAdminList,
      needToUpdateAdmins,
      setNeedToUpdateAdmins,
      paginate,
      branchSpecificAdmins,
      setBranchSpecificAdmins,
      sort,
      sortBy,
      setSortBy,
      sortAscending,
      setSortAscending,
      showAddBranchModal, setShowAddBranchModal,
      fetchLocationsAndSetSelected,
      branchesSelectedForDeletion,
      setBranchesSelectedForDeletion,
      handleTrashClick,
      handleConfirmDelete,
      showConfirmDeletePopup,
      setShowConfirmDeletePopup,
      confirmDeleteHeader,
      confirmDeleteDescription
    }}>
      {props.children}
    </Admin.Provider>
  )
}

const useAdmin = () => useContext(Admin)

export { useAdmin }
export default AdminProvider
