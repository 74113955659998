import react, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import PinCodeSignIn from '../../../components/PinCodeSignIn'
import PinCodeSetup from '../../../components/PinCodeSetup';
import service from '../../../service'

const ForemanSignIn = () => {
  const [searchParams] = useSearchParams()
  const [needsToConfirmPin, setNeedsToConfirmPin] = useState(true)
  const hash = searchParams.get('h')

  const getForemanInfo = async () => {
    const user = await service.detectForemanStatus(hash)
    setNeedsToConfirmPin(user.foreman[0].needsToConfirmPin)
  }

  getForemanInfo()

  return needsToConfirmPin ? <PinCodeSetup /> : <PinCodeSignIn />
}

export default ForemanSignIn
