/* External imports */
import { useState } from 'react';
import { RiEdit2Fill } from "react-icons/ri";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
/* Internal imports */
import { useAdminBranchMaterials } from '../../contexts/adminBranchMaterials';

export default function AdminBranchMaterialsAccordionItem({ headerData, content }) {
  const { setEditItemModal, handleCheckboxChange, getTableName} = useAdminBranchMaterials();

  const [isOpen, setIsOpen] = useState(false);

  const disabledHeaderDivStyle = 'border border-[2px] border-navy-light bg-navy-light bg-opacity-90 rounded-lg'
  const disabledHeaderTextStyle = 'text-gray-blue'
  const disabledHeaderCheckboxStyle = 'bg-gray-200'

  const tableName = getTableName(headerData);

  const localHandleCheckboxChange = async (e) => {
    await handleCheckboxChange(e, headerData, tableName);
    if (e.target.checked) {
      setIsOpen(false);
    }
  }

  const checkHasNoChildren = () => {
    if (tableName == 'Manufacturer') {
      if (headerData.product.length == 0) {
        return true;
      }
    } else if (tableName == 'Product') {
      if (headerData.tier.length == 0) {
        return true;
      }
    } else if (tableName == 'Tier') {
      if (headerData.color.length == 0) {
        return true;
      }
    } else if (tableName == 'Color') {
      return true;
    }
  }

  const chooseChevronState = () => {
   const hasNoChildren = checkHasNoChildren();
    if (hasNoChildren) {
      return <FaChevronUp className='text-transparent'/>
    }
    if (isOpen) {
      return <FaChevronUp/>
    } else {
      return <FaChevronDown/>
    }
  }
  
  return (
    <div className="relative ml-5 border-l border-t border-b border-navy-light rounded-lg">
      <div 
        className={`h-12 flex flex-row w-full items-center justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-3 py-1 ${headerData.disabled ? disabledHeaderDivStyle : ''}`}
        onClick={() => {
          if (tableName == 'Color') return;
          setIsOpen(!isOpen);
        }}>
        <div className='flex flex-row items-center'>        
          <input 
            type="checkbox"
            checked={!headerData.disabled}
            onChange={(e) => {localHandleCheckboxChange(e)}}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={`text-dark-blue mr-3 ${headerData.disabled ? disabledHeaderCheckboxStyle : ""}`}
          />
          <h3 className={`text-dark-blue text-lg ${headerData.disabled ? disabledHeaderTextStyle : ""}`}>
            {headerData.name}
          </h3>
        </div>
        <div className='flex flex-row justify-end w-[75px]'>
        {(tableName == 'Product' || tableName == 'Tier') && (
          <button
            className='mr-2 cursor-pointer'
            disabled={headerData.disabled}
            onClick={(e) => {
              e.stopPropagation();
              setEditItemModal({open: true, tableName: tableName, itemData: headerData })
            }}
          >
            <RiEdit2Fill className={`text-dark-blue ${headerData.disabled ? disabledHeaderTextStyle : ""}`} size={30}/>
          </button>
        )}
          
          <div className='flex flex-col items-end'>            
            <h3 className={`text-dark-blue text-xs ${headerData.disabled ? disabledHeaderTextStyle : ""}`}>
              {tableName}
            </h3>
            <p className={`text-dark-blue ${headerData.disabled ? disabledHeaderTextStyle : ""}`}>{chooseChevronState()}</p>
          </div>
        </div>
      </div>
      {isOpen && <div className="">{content}</div>}
    </div>
  );
}