/* external imports */
import { FaRegTrashAlt } from "react-icons/fa";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
/* internal imports */
import PopupSearchBox from "../PopupSearchBox";
import { useAdminMaterials } from "../../contexts/adminMaterials";
import AdminMaterialsPaginationFooterBar from "../AdminMaterialsPaginationFooterBar";

const MaterialsDataTable = () => {
  const {
    selectedItemsForDeletion,
    setSelectedItemsForDeletion,
    searchTerm,
    filterItemsBySearchTerm,
    currentPage,
    setCurrentPage,
    RESULTS_PER_PAGE,
    displayItems,
    handleColumnHeaderClick,
    handleTrashClick,
    allowItemSelection,
    sortBy,
    sortAscending,
    allItemsAfterFiltering,
    handleClickItem,
    breadcrumbs,
    paginate,
  } = useAdminMaterials();

  const handleCheckboxChange = (itemName) => {
    setSelectedItemsForDeletion((prevSelectedItemsForDeletion) => {
      if (prevSelectedItemsForDeletion.includes(itemName)) {
        return prevSelectedItemsForDeletion.filter((name) => name !== itemName);
      } else {
        return [...prevSelectedItemsForDeletion, itemName];
      }
    });
  };

  const handleMainCheckboxChange = () => {
    if (selectedItemsForDeletion?.length === displayItems?.length) {
      setSelectedItemsForDeletion([]);
    } else {
      const allItemNames = displayItems.map((item) => item.name);
      setSelectedItemsForDeletion(allItemNames);
    }
  };

  const allChecked =
    selectedItemsForDeletion?.length === displayItems?.length &&
    selectedItemsForDeletion?.length > 0;

  return (
    <>
      {displayItems.length !== 0 && (  
        <div
          className={`bg-white rounded-2xl w-full my-6 flex flex-col border border-navy-light `}
        >
          <header className="h-16 relative flex justify-between items-center px-6  rounded-t-xl border-b border-navy-light bg-opacity-20 bg-navy-bright">
            <h1 className="text-xl font-semibold text-dark-blue">
              {breadcrumbs?.currentPage}
            </h1>
            <div className="flex flex-row items-center">
              {selectedItemsForDeletion.length > 0 && (
                <FaRegTrashAlt
                  onClick={handleTrashClick}
                  size={20}
                  className="mr-2 cursor-pointer"
                />
              )}
              <PopupSearchBox
                searchTerm={searchTerm}
                setSearchTerm={filterItemsBySearchTerm}
              />
            </div>
          </header>
          <div className="flex py-2 px-2 border-navy-light bg-opacity-20 bg-navy-bright justify-between">
            <div className="flex flex-row w-1/2 mr-4 items-center cursor-pointer select-none">
              <input
                type="checkbox"
                className="mr-2 rounded checked:bg-dark-blue"
                checked={allChecked}
                onChange={handleMainCheckboxChange}
              />
              <div
                className="flex flex-row"
                onClick={() => handleColumnHeaderClick("name")}
              >
                <p className="text-gray-blue text-sm whitespace-nowrap">Name</p>
                {sortBy === "name" &&
                  (sortAscending ? (
                    <FaArrowUp className="mt-1 text-gray-blue text-xs pl-1" />
                  ) : (
                    <FaArrowDown className="mt-1 text-gray-blue text-xs pl-1" />
                  ))}
              </div>
            </div>
          </div>
          {displayItems.map((item, index) => (
            <div
              className="flex flex-row py-2 px-2 border-b border-navy-light"
              key={`data-table-${index}`}
            >
              <div className="w-1/2 mr-4 flex-shrink-0 flex min-w-0 items-center">
                <input
                  type="checkbox"
                  className="mr-2 rounded checked:bg-dark-blue"
                  checked={selectedItemsForDeletion.includes(item.name)}
                  onChange={() => handleCheckboxChange(item.name)}
                />
                <p
                  className="cursor-pointer text-dark-blue font-medium text-md truncate whitespace-nowrap overflow-hidden"
                  onClick={() => handleClickItem(item)}
                >
                  {item.name}
                </p>
              </div>
            </div>
          ))}

          <AdminMaterialsPaginationFooterBar
            paginate={paginate}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            allItems={allItemsAfterFiltering}
            resultsPerPage={RESULTS_PER_PAGE}
          />
        </div>
      )}
    </>
  );
};

export default MaterialsDataTable;
