import React from "react";
import _ from 'underscore';
import { useResponsive } from "../../contexts/responsive";

const SchedulerLocationDropdown = ({value, options, placeholder, onChange}) => {
  const uniqueId = `location-selector-${_.uniqueId()}`
  const { responsive } = useResponsive();
  const isMobileView = (
    responsive.breakpoint === 'sm'
    || responsive.breakpoint === 'md'
  )

  return (
    <div className={`group relative ${isMobileView && ''}`}>
      {isMobileView &&
        <div className='text-all-f-white font-bold text-xl'>
          Scheduling For:
        </div>
      }
      <select
        id={uniqueId}
        value={value || 1}
        onChange={onChange}
        className={`border rounded focus:outline-none ${!isMobileView ? 'text-all-f-white pb-[4px] pt-[5px] bg-dark-blue text-sm font-bold overflow-hidden whitespace-nowrap overflow-ellipsis h-full max-w-[210px]'
        : 'bg-[#6788B6] text-all-f-white max-w-[250px] text-sm font-bold overflow-hidden whitespace-nowrap overflow-ellipsis'}`}
      >
        <option className={`${!isMobileView ? 'text-all-f-white font-bold' : 'text-all-f-white opacity-70 font-bold'}`} value=''>{placeholder}</option>
          {options?.map((option, i) => (
            <option key={i} className={`${!isMobileView ? 'text-all-f-white font-bold' : 'text-all-f-white opacity-70 font-bold'}`} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  )
}

export default SchedulerLocationDropdown;
