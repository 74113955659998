import React, { useState, useRef, useEffect } from "react";
import { useAdmin } from "../../contexts/admin";
import serviceMethods from '../../service';
import Selector from "../Selector";
import PhoneInput from "../PhoneInput";
import { SERVICES, FIFTY_STATES } from '../../constants';
import isEqual from 'lodash.isequal';
import { useLocation } from "react-router-dom";
import AdminNewAdminForm from "../AdminNewAdminForm";
import { select } from "underscore";

const AdminBranchDetails = () => {
  const { 
    selectedLocation, setSelectedLocation, previewImage, setPreviewImage, 
    DEFAULT_BRANCH
  } = useAdmin();
  const [displayChangesSavedText, setDisplayChangesSavedText] = useState(false);
  const [displayCancelChangesText, setDisplayCancelChangesText] = useState(false);
  const hiddenFileInputBranchDetails = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [initialSelectedLocation, setInitialSelectedLocation] = useState(DEFAULT_BRANCH);

  const formattedPhonePattern = /^(\(\d{3}\)[\s])\d{3}\-\d{4}$/;
  const phoneError = !formattedPhonePattern.test(selectedLocation.phone)

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const branchIdFromQuery = query.get('branchId');

  useEffect(() => {
    setInitialSelectedLocation({ ...selectedLocation });
  }, [branchIdFromQuery]);

  const checkForChangesInLocation = () => {
    if (initialSelectedLocation?.id && selectedLocation?.id){
      return !isEqual(initialSelectedLocation, selectedLocation);
    }
  }

  useEffect(()=>{
    if (selectedLocation?.id && initialSelectedLocation.id === null){
      setInitialSelectedLocation({ ...selectedLocation })
    }
  },[selectedLocation])

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setDisplayCancelChangesText(false)
    const formData = new FormData();
    const file = hiddenFileInputBranchDetails.current.files[0]
    if (file) {
      formData.append('attachment', file);
    } else {
      formData.append('image', selectedLocation?.image)
    }
    
    const serviceString = selectedLocation.service.toUpperCase()  
        
    formData.append('id', selectedLocation.id);
    formData.append('label', selectedLocation.label || '');
    formData.append('address', selectedLocation.address || '');
    formData.append('city', selectedLocation.city || '');
    formData.append('state', selectedLocation.state || '');
    formData.append('latitude', Number(selectedLocation.latitude) || 0);
    formData.append('longitude', Number(selectedLocation.longitude) || 0);
    formData.append('zipCode', selectedLocation.zipCode || '');
    formData.append('email', selectedLocation.email || '');
    formData.append('phone', selectedLocation.phone || '');
    formData.append('serviceString', serviceString);
    formData.append('isMapActive', String(selectedLocation.isMapActive));
    formData.append('isPortalActive', String(selectedLocation.isPortalActive));

    const res = await serviceMethods.updateAdminLocationDetails(formData);
    if (res.ok){
      const data = await res.json();
      setSelectedLocation(data);
      setDisplayChangesSavedText(true);
      setInitialSelectedLocation(data);
    }
    e.target.reset();
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const cancelChanges = async () => {
    setDisplayChangesSavedText(false);
    setPreviewImage(null)
    const res = await serviceMethods.getLocationDetails(selectedLocation?.id);
    if (res.ok){
      const data = await res.json();
      setSelectedLocation(data);
      setDisplayCancelChangesText(true);
      setInitialSelectedLocation(data);
    }
    hiddenFileInputBranchDetails.current.value = "";
  }

  const removeCurrentLogo = () => {
    setPreviewImage(null);
    setSelectedLocation({
      ...selectedLocation,
      image: null
    })
    hiddenFileInputBranchDetails.current.value = "";
  }

  const handleFileChange = () => {
    const file = hiddenFileInputBranchDetails.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedLocation({
        ...selectedLocation,
        image: file
      });
    }
  };

  const hideRemoveOption = selectedLocation?.image === null

  return (
    <div>
      <div className='text-dark-blue font-bold text-xl sm:text-[21px] sm:px-8 sm:pt-8 gap-4'>
        Branch Details
      </div>

      <form onSubmit={handleFormSubmit}>
        <div className='  sm:px-8 pt-8 gap-4 lg:flex w-full'>
          <div className='w-full lg:w-1/2 flex-col mr-[16px]'>
            <div className='font-normal text-xs'>
              Branch name
            </div>
            <input 
              className='rounded border-navy-light text-dark-blue text-base w-full'
              type='text'
              id='adminBranchSetupLabel'
              value={selectedLocation?.label || ''}
              onChange={(e)=>{
                const updatedLocation = {
                  ...selectedLocation,
                  label: e.target.value
                }
                setSelectedLocation(updatedLocation)
              }}
            />
          </div>
          <div className='w-full lg:w-1/2 flex-col'>
            <div className='pt-8 lg:pt-0 font-normal text-xs'>
              Address
            </div>
            <input 
              className='rounded border-navy-light text-dark-blue text-base w-full'
              value={selectedLocation?.address}
              onChange={(e)=>{
                const updatedLocation = {
                  ...selectedLocation,
                  address: e.target.value
                }
                setSelectedLocation(updatedLocation)
              }}
            />
          </div>
        </div>

        <div className='  sm:px-8 pt-8 gap-4 lg:flex w-full'>
          <div className='w-full lg:w-1/2 flex-col'>
            <div className='font-normal text-xs'>
              City
            </div>
            <input
              className='rounded border-navy-light text-dark-blue text-base w-full'
              value={selectedLocation?.city || ''}
              onChange={(e)=>{
                const updatedLocation = {
                  ...selectedLocation,
                  city: e.target.value
                }
                setSelectedLocation(updatedLocation)
              }}
            />
          </div>
          <div className='w-full lg:w-1/4 flex-col pl-0 lg:pl-[16px]'>
            <div className='pt-8 lg:pt-0 font-normal text-xs'>
              State
            </div>
            <Selector
                value={selectedLocation?.state}
                required={false}
                placeholder={'State'}
                options={FIFTY_STATES}
                reducedSize={true}
                onChange={(e) => {
                  setSelectedLocation({
                    ...selectedLocation,
                    state: e.target.value
                  })
                }}
              />
          </div>
          <div className='w-full lg:w-1/4 flex-col'>
            <div className='pt-8 lg:pt-0 font-normal text-xs'>
              Zip Code
            </div>
            <input
              className='rounded border-navy-light text-dark-blue text-base w-full'
              value={selectedLocation?.zipCode || ''}
              onChange={(e)=>{
                const value = e.target.value;
                const isValidNumber = /^[0-9]{0,5}$/.test(value);
                if (isValidNumber){
                  const updatedLocation = {
                    ...selectedLocation,
                    zipCode: e.target.value
                  }
                  setSelectedLocation(updatedLocation)
                }
              }}
            />
          </div>
        </div>
          
        <div className='  sm:px-8 pt-8 gap-4 lg:flex w-full'>
          <div className='w-full lg:w-1/2 flex-col sm:mr-[16px]'>
            <div className='font-normal text-xs'>
              Latitude
            </div>
            <input
              className='rounded border-navy-light text-dark-blue text-base w-full'
              value={selectedLocation?.latitude || ''}
              onChange={(e) => {
                const value = e.target.value;
                // Allow empty string, negative sign, or a valid float number
                const isValidFloat = /^-?\d*\.?\d*$/.test(value);
                if (isValidFloat || value === '' || value === '-') {
                  const updatedLocation = {
                    ...selectedLocation,
                    latitude: value
                  };
                  setSelectedLocation(updatedLocation);
                }
              }}
              onBlur={(e) => {
                const value = parseFloat(e.target.value);
                if (!isNaN(value)) {
                  const updatedLocation = {
                    ...selectedLocation,
                    latitude: value 
                  };
                  setSelectedLocation(updatedLocation);
                }
              }}
            />
          </div>
          <div className='w-full lg:w-1/2 flex-col'>
            <div className='pt-8 lg:pt-0 font-normal text-xs'>
              Longitude
            </div>
            <input
              className='rounded border-navy-light text-dark-blue text-base w-full'
              value={selectedLocation?.longitude || ''}
              onChange={(e) => {
                const value = e.target.value;
                // Allow empty string, negative sign, or a valid float number
                const isValidFloat = /^-?\d*\.?\d*$/.test(value);
                if (isValidFloat || value === '' || value === '-') {
                  const updatedLocation = {
                    ...selectedLocation,
                    longitude: value
                  };
                  setSelectedLocation(updatedLocation);
                }
              }}
              onBlur={(e) => {
                const value = parseFloat(e.target.value);
                if (!isNaN(value)) {
                  const updatedLocation = {
                    ...selectedLocation,
                    longitude: value 
                  };
                  setSelectedLocation(updatedLocation);
                }
              }}
            />
          </div>
        </div>

        <div className='  sm:px-8 pt-8 gap-4 lg:flex w-full'>
          <div className='w-full lg:w-1/2 flex-col mr-[16px]'>
            <div className='font-normal text-xs'>
              Email
            </div>
            <input 
              className='rounded border-navy-light text-dark-blue text-base w-full'
              value={selectedLocation?.email || ''}
              onChange={(e)=>{
                const updatedLocation = {
                  ...selectedLocation,
                  email: e.target.value
                }
                setSelectedLocation(updatedLocation)
              }}
            />
          </div>
          <div className='w-full lg:w-1/2 flex-col'>
            <div className='pt-8 lg:pt-0 font-normal text-xs'>
              Phone
            </div>
            <PhoneInput
              placeholder={''}
              value={selectedLocation?.phone || ''}
              reducedSize={true}
              onChange={(e) => {
                setSelectedLocation({
                  ...selectedLocation,
                  phone: e.target.value
                })
              }}
              isError={phoneError}
            />
          </div>
        </div>

        <div className='  sm:px-8 pt-8 lg:flex w-full'>
          <div className='w-full lg:w-1/2 flex-col pr-0 lg:pr-[16px]'>
            <div className='pt-8 lg:pt-0 font-normal text-xs'>
              Service
            </div>
            <Selector
                value={capitalizeFirstLetter(selectedLocation?.service)}
                options={SERVICES}
                reducedSize={true}
                onChange={(e) => {
                  setSelectedLocation({
                    ...selectedLocation,
                    service: e.target.value
                  })
                }}
              />
          </div>

          <div className='flex w-full pt-8 lg:pt-0 pl-0 lg:pl-[16px] lg:w-1/2 gap-4 items-center'>
            <div className='max-h-[68px] max-w-[68px]'>
              <img crossOrigin='anonymous' src={previewImage || `${selectedLocation?.image ? `${apiUrl}/${selectedLocation?.image}` : '/main-logo-dark.png'}`} />
            </div>
            <input 
              type='file'
              className='hidden'
              accept='image/*'
              ref={hiddenFileInputBranchDetails} 
              onChange={()=>handleFileChange()}
            />
            <button
              type="button"
              className='text-dark-blue text-base font-medium'
              onClick={()=>{hiddenFileInputBranchDetails.current.click();}}
            >
              Change logo
            </button>
            <button
              type="button"
              className={`text-gunite-red text-base font-medium ${hideRemoveOption ? 'hidden' : ''}`}
              onClick={()=>removeCurrentLogo()}
            >
              Remove
            </button>
          </div>
        </div>
        
        <div className='  sm:px-8 pt-8 gap-4 lg:flex w-full'>
          <div className='w-full lg:w-1/2 flex flex-row'>
            <input
              id="show-on-map"
              type="checkbox"
              className="mr-2 rounded checked:bg-dark-blue"
              checked={selectedLocation.isMapActive === true}
              onChange={(e)=>{
              const updatedLocation = {
                ...selectedLocation,
                isMapActive: e.target.checked
              }
              setSelectedLocation(updatedLocation)
            }}
            />
            <label htmlFor="show-on-map" className={`font-normal text-xs`}>
              Show on map
            </label>
          </div>
          <div className='flex flex-row w-full lg:w-1/2 pt-6 lg:pt-0 pl-0 lg:pl-[16px]'>
            <input
              id="portal-active"
              type="checkbox"
              className="mr-2 rounded checked:bg-dark-blue"
              checked={selectedLocation.isPortalActive}
              onChange={(e)=>{
                const updatedLocation = {
                  ...selectedLocation,
                  isPortalActive: e.target.checked
                }
                setSelectedLocation(updatedLocation)
              }}
            />
            <div htmlFor="portal-active" className={`font-normal text-xs`}>
              Portal active
            </div>
          </div>
        </div>
        
        <div className='pt-8  sm:px-8'>
          <AdminNewAdminForm />
        </div>
        
        <div className='flex  sm:px-8 pt-8 justify-between'>
          <button 
            type="button"
            className={`text-sm font-semibold text-all-f-white py-[9px] px-3 bg-gunite-red rounded ${!checkForChangesInLocation() && 'opacity-40'}`}
            onClick={()=>cancelChanges()}
            disabled={!checkForChangesInLocation()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`text-sm font-semibold text-all-f-white py-[9px] px-3 bg-dark-blue rounded ${!checkForChangesInLocation() && 'opacity-40'}`}
            disabled={!checkForChangesInLocation()}
          >
            Submit
          </button>
        </div>
        <div className='flex px-8 pt-6 justify-between'>
          <div className='text-gunite-red font-medium text-xs'>
            {displayCancelChangesText && '*Changes have been reverted'}
          </div>
          <div className='text-dark-blue font-medium text-xs'>
            {displayChangesSavedText && '*Changes have been saved.'}
          </div>
        </div>
      </form>
    </div>
  )
}

export default AdminBranchDetails;
