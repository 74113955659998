import React from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import CostBreakdownPDFTemplate from '../CostBreakdownPDFTemplate';
import { useScheduler } from '../../contexts/scheduler';
import { useAuth } from '../../contexts/auth';
import CostBreakdownInvoicePDFTemplate from '../CostBreakdownInvoicePDFTemplate';
import { format } from 'date-fns';

const apiUrl = process.env.REACT_APP_API_URL;

const CostBreakdownPDFViewer = ({ breakdown, selectedJob, isViewerOpen, setIsViewerOpen, invoiceOrBreakdown }) => {
  const { selectedScheduler } = useScheduler()
  const { me } = useAuth()
  const logoImage = me?.role === 'SCHEDULER'
    ? `${apiUrl}/${selectedScheduler?.location?.image}`
    : "http://localhost:3000/sidebar/gunite_logo_sm.svg"

  return (
    <div className='flex justify-center items-center fixed w-screen h-screen top-0 left-0 bg-navy-light bg-opacity-60 z-10'>
      <div className="flex flex-col w-fit h-fit rounded-lg bg-all-f-white border border-navy-light p-6">
        <h2 className="text-lg font-bold text-dark-blue">{invoiceOrBreakdown === 'invoice' ? 'Invoice Preview' : 'Breakdown Preview'}</h2>

        <div className='bg-[rgb(64,67,69)] p-1 rounded-md'>
          <PDFViewer className='h-[50vh] w-[420px]' showToolbar={false}>
            {invoiceOrBreakdown.current === 'breakdown' ? (
              <CostBreakdownPDFTemplate
                breakdown={breakdown}
                selectedJob={selectedJob}
                selectedScheduler={selectedScheduler}
                logoImage={logoImage}
              />
            ) : (
              <CostBreakdownInvoicePDFTemplate
                breakdown={breakdown}
                selectedJob={selectedJob}
                selectedScheduler={selectedScheduler}
                logoImage={logoImage}
              />
            )}
          </PDFViewer>
        </div>

        <div className="flex justify-center gap-4 pt-4">

          <button
            onClick={() => {
              setIsViewerOpen(!isViewerOpen)
            }}
            className="bg-white text-dark-blue rounded-md border border-gray-300 py-2 px-4 mr-2"
          >
            Close
          </button>

          {invoiceOrBreakdown.current === 'breakdown'
            ?
            <PDFDownloadLink
              className="bg-white text-gunite-red border border-gunite-red rounded-md py-2 px-4 "
              fileName={`${format(new Date(), 'yyyyMMdd')}${selectedJob.jobName}`}
              document={<CostBreakdownPDFTemplate
                  breakdown={breakdown}
                  selectedJob={selectedJob}
                  selectedScheduler={selectedScheduler}
                  logoImage={logoImage}
                />
              }
            >Download PDF</PDFDownloadLink>
            :
            <PDFDownloadLink
              className="bg-white text-gunite-red border border-gunite-red rounded-md py-2 px-4 "
              fileName={`Invoice#${breakdown?.id}`}
              document={
                <CostBreakdownInvoicePDFTemplate
                  breakdown={breakdown}
                  selectedJob={selectedJob}
                  selectedScheduler={selectedScheduler}
                  logoImage={logoImage}
                />
              }
            >Download PDF</PDFDownloadLink>
          }

        </div>
      </div>
    </div>
  )
};

export default CostBreakdownPDFViewer;
