import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from "../../contexts/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faPlay } from '@fortawesome/free-solid-svg-icons'

const PinCodeSetup = () => {
  const [pin, setPin] = useState('')
  const [firstPin, setFirstPin] = useState('')
  const { loginWithPin, isLoggedIn, isInvalidCreds, me, setNewPin } = useAuth()
  const [instructions, setInstructions] = useState('Please enter your 4-digit PIN')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [stepNumber, setStepNumber] = useState(1)

  const hash = searchParams.get('h')

  const numberPressed = (value) => {
    if (pin.length < 4) {
      setPin(`${pin}${value}`)
    }
  }

  const resetPressed = () => {
    setPin('')
  }

  const submitPressed = () => {
    if (stepNumber === 1) {
      setFirstPin(pin)
      setInstructions('Confirm PIN')
      setPin('')
      setStepNumber(2)
    }
    if (stepNumber === 2) {
      if (pin === firstPin) {
        console.log('hash', hash, '\npin', pin)
        setNewPin(hash, pin)
      } else {
        setInstructions('PINs do not match!  Reenter your PIN')
        setFirstPin('')
        setPin('')
        setStepNumber(1)
      }
    }
  }

  useEffect(() => {
    if (isLoggedIn && me?.role) {
      if (me.role === 'ADMIN') {
        navigate('/admin')
      } else if (me.role === 'SCHEDULER') {
        navigate('/scheduler/planner')
      } else if (me.role === 'CUSTOMER') {
        navigate('/')
      } else if (me.role === 'FOREMAN') {
        navigate('/foreman')
      }
    }
  }, [isLoggedIn, me?.role])

  const isSubmitDisabled = pin.length < 4

  return (
    <div className='p-6 bg-subtle-grey w-screen h-screen min-h-[600px] flex flex-col justify-center items-center'>
      <img className='h-12 mb-10' src="/main-logo-dark.svg" alt="Main Logo" />
      <div className='border border-navy-light overflow-hidden flex flex-col w-full h-auto min-h-[400px] gap-3 sm:w-3/6 lg:w-1/3 xl:w-1/4 bg-white rounded-2xl pb-7'>
        <div className='pr-6 pl-6 pt-4 pb-4  bg-opacity-25  bg-navy-bright border-b border-navy-light'>
          <p className="text-dark-blue font-bold text-3xl">Pin Code Setup</p>
        </div>
        <div className='pl-6'>
          <p className="text-gunite-red font-semibold">{instructions}</p>
        </div>
        <div className="w-full text-center text-5xl h-[48px]">
          {pin}
        </div>
        <div className='px-6'>

          <form className="flex flex-col h-[350px] gap-2"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <div className="flex h-1/4">
              <button
                onClick={() => {
                  numberPressed(1)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >1</button>
              <button
                onClick={() => {
                  numberPressed(2)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >2</button>
              <button
                onClick={() => {
                  numberPressed(3)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >3</button>
            </div>
            <div className="flex h-1/4">
              <button
                onClick={() => {
                  numberPressed(4)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >4</button>
              <button
                onClick={() => {
                  numberPressed(5)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >5</button>
              <button
                onClick={() => {
                  numberPressed(6)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >6</button>
            </div>
            <div className="flex h-1/4">
              <button
                onClick={() => {
                  numberPressed(7)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >7</button>
              <button
                onClick={() => {
                  numberPressed(8)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >8</button>
              <button
                onClick={() => {
                  numberPressed(9)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >9</button>
            </div>
            <div className="flex h-1/4">
              <button
                onClick={resetPressed}
                className="w-1/3 m-1 bg-blue-500 text-white font-semibold py-auto h-full px-4 rounded"
              >
                <FontAwesomeIcon icon={faUndo} size="2x" />
              </button>
              <button
                onClick={() => {
                  numberPressed(0)
                }}
                className="w-1/3 m-1 bg-transparent hover:bg-blue-100 text-blue-700 font-semibold py-auto h-full px-4 border border-blue-500 rounded text-3xl"
              >0</button>
              <button
                className="w-1/3 m-1 bg-gunite-red text-white font-semibold py-auto h-full px-4 rounded disabled:opacity-50"
                disabled={isSubmitDisabled}
                onClick={submitPressed}
              >
                <FontAwesomeIcon icon={faPlay} size="2x" />
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  )

}

export default PinCodeSetup
