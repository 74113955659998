import React, { useState, useEffect, useRef } from "react";
import { useModal } from "../../contexts/modal";
import { useAuth } from "../../contexts/auth";
import serviceMethods from '../../service';
import isEqual from 'lodash.isequal';

const NewAdminModal = ({ selectedAdmin, setNeedToUpdateAdmins, getUpdatedAdminList }) => {
  const { me } = useAuth();
  const { modalMethods, modal } = useModal();
  const { isEditing, setIsEditing } = modal;
  const [newAdmin, setNewAdmin] = useState({})
  const [newAdminFName, setNewAdminFName] = useState('');
  const [newAdminLName, setNewAdminLName] = useState('');
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const isDeletingRef = useRef(isDeleting);
  const [initialSelectedAdmin, setInitialSelectedAdmin] = useState({})
  const [adminEmailAlreadyTaken, setAdminEmailAlreadyTaken] = useState(false);
  const [userIsAnotherRole, setUserIsAnotherRole] = useState(false);
  const [isAlreadyActiveAtLocation, setIsAlreadyActiveAtLocation] = useState(false);
  const [disableEmailField, setDisableEmailField] = useState(false);

  useEffect(()=>{
    setInitialSelectedAdmin({
      ...selectedAdmin
    })
  },[selectedAdmin])

  useEffect(() => {
    isDeletingRef.current = isDeleting;
  }, [isDeleting]);

  useEffect(()=>{
    const onEnterKeyPress = (event) => {
      if (event.key === 'Enter' && isDeletingRef.current){
        handleDeleteAdmin(event)
      }
    }
    document.addEventListener('keydown', onEnterKeyPress);
    return () => {
      document.removeEventListener('keydown', onEnterKeyPress);
    }
  },[])

  const saveButtonDisabled = () => {
    const adminObjForComparison = ({
      ...initialSelectedAdmin,
      name: newAdminFName + ' ' + newAdminLName,
      email: newAdminEmail
    })
    return isEqual(initialSelectedAdmin, adminObjForComparison)
  }

  const addButtonDisabled = () => {
    return !newAdminFName || !newAdminLName || !newAdminEmail;
  }

  useEffect(()=>{
    if (isEditing){
      const nameToSplit = selectedAdmin?.name?.split(' ');
      const firstName = nameToSplit[0];
      const lastName = nameToSplit[1];
      setNewAdminFName(firstName)
      setNewAdminLName(lastName)
      setNewAdminEmail(selectedAdmin?.email)
      setDisableEmailField(selectedAdmin?.email.length)
    } else {
      setNewAdminFName('')
      setNewAdminLName('')
      setNewAdminEmail('')
      setDisableEmailField(false)
    }
  }, [isEditing])

  const handleNewAdminFormSubmit = async (e) => {
    e.preventDefault();
    const combinedNames = newAdminFName + ' ' + newAdminLName;
    setNewAdmin({
      ...newAdmin,
      name: combinedNames,
      email: newAdminEmail,
      id: selectedAdmin?.id ? selectedAdmin.id : null,
      locationId: selectedAdmin?.locationId
    })
    const payload = {
      ...newAdmin,
      name: combinedNames,
      email: newAdminEmail,
      id: selectedAdmin?.id ? selectedAdmin.id : null,
      locationId: selectedAdmin?.locationId           
    }
    if (!isEditing) {
      const res = await serviceMethods.createBranchAdmin(payload)
      if (res.ok){
        const data = await res.json();
        getUpdatedAdminList()
        setNeedToUpdateAdmins(true);
        setIsDeleting(false);
        setIsEditing(false);
        modalMethods.close();
      } else {
        const resNotOk = await res.json();
        if (resNotOk.message.includes('User_email_key')){
          setAdminEmailAlreadyTaken(true)
        } else if (resNotOk.message.includes('User already exists and is active as another role')){
          setUserIsAnotherRole(true);
        } else if (resNotOk.message.includes('User already exists as an active Branch Manager at this location')){
          setIsAlreadyActiveAtLocation(true);
        }
      }
    } else {
      const res = await serviceMethods.updateBranchAdmin(payload)
      if (res.ok){
        const data = await res.json();
        getUpdatedAdminList()
        setNeedToUpdateAdmins(true);
        setIsDeleting(false);
        setIsEditing(false);
        modalMethods.close();
      } else {
        const resNotOk = await res.json();
        if (resNotOk.message.includes('User_email_key')){
          setAdminEmailAlreadyTaken(true)
        }
      }
    }
  }

  const handleDeleteAdmin = async (e) => {
    e.preventDefault();
    const payload = {
      ...newAdmin,
      id: selectedAdmin?.id,
      locationId: selectedAdmin?.locationId
    }
    const res = await serviceMethods.deleteBranchAdmin(payload)
    if (res.ok){
      const data = await res.json();
      getUpdatedAdminList()
      setNeedToUpdateAdmins(true);
      setIsDeleting(false);
      setIsEditing(false);
      modalMethods.close();
    }
  }

  return (
    <div className='mt-12 lg:mt-0 absolute z-10 bg-navy-bright10 w-full sm:w-3/4 lg:w-1/2 items-center border rounded-2xl py-4 px-6'>
      <div className='flex justify-between'>
        <div className='text-dark-blue font-bold mb-3 text-[21px]'>
          {`${isEditing ? 'Edit Branch Manager' : 'Add Branch Manager'}`}
        </div>
        <button>
          <img src='/close_dark.svg' onClick={()=>{
            modalMethods.close();
            setIsEditing(false);
          }}/>
        </button>
      </div>
      
      <form className='py-3 flex-row' onSubmit={handleNewAdminFormSubmit}>
        <div className='text-dark-blue font-normal text-xs mb-1 font-roboto'>
          First name *
        </div>
        <input
          className='w-full mb-3 border rounded border-navy-light'
          value={newAdminFName}
          onChange={(e)=>{
            setNewAdminFName(e.target.value)
          }}
        />

        <div className='text-dark-blue font-normal text-xs mb-1 font-roboto'>
          Last name *
        </div>
        <input
          className='w-full mb-3 border rounded border-navy-light'
          value={newAdminLName}
          onChange={(e)=>{
            setNewAdminLName(e.target.value)
          }}
        />

        <div className='text-dark-blue font-normal text-xs mb-1 font-roboto'>
          Email *
        </div>
        <input
          className={`w-full mb-6 border rounded border-navy-light ${disableEmailField && 'bg-navy-light/50'}`}
          value={newAdminEmail}
          onChange={(e)=>{
            setNewAdminEmail(e.target.value)
          }}
          disabled={disableEmailField}
        />

        {!isEditing ?
          <>
            <div className='w-full flex-row'>
              {adminEmailAlreadyTaken &&
              <div className='pb-3 lg:pb-0 text-gunite-red font-semibold'>
                An account with this email already exists!
              </div>
              }
              {userIsAnotherRole &&
                <div className='pb-3 lg:pb-0 text-gunite-red font-semibold'>
                  This user already exists as another role!
                </div>
              }
              {isAlreadyActiveAtLocation &&
                <div className='pb-3 lg:pb-0 text-gunite-red font-semibold'>
                  This user is already a Branch Manager at this location!
                </div>
              }
              <div className='flex justify-end'>
                <button
                  type="button"
                  className='border rounded border-navy-light bg-all-f-white py-2 px-3 font-semibold text-base mr-4'
                  onClick={()=>modalMethods.close()}>
                  Cancel
                </button>

                <button
                  type="submit"
                  className={`border rounded border-jet-black/10 py-2 px-3 
                    font-semibold text-base 
                    ${addButtonDisabled() ? 'bg-jet-black/10 text-jet-black/40' : 'text-all-f-white bg-dark-blue'}`}
                  disabled={addButtonDisabled()}
                >
                  Add
                </button>
              </div>
            </div>
          </>
        :
          <div className='w-full flex-row'>
            {adminEmailAlreadyTaken &&
              <div className='pb-3 text-gunite-red font-semibold'>
                An account with this email already exists!
              </div>
            }
            <div className='flex justify-between'>
              {!isDeleting ?
                <button
                  type="button"
                  className={`border rounded border-gunite-red py-2 px-3 text-gunite-red bg-all-f-white ${me.role === 'BRANCHADMIN' ? 'invisible' : ''}`}
                  onClick={()=>setIsDeleting((prevIsDeleting) => {
                    const newIsDeleting = !prevIsDeleting;
                    isDeletingRef.current = newIsDeleting;
                    return newIsDeleting;
                  })}>
                  Delete
                </button>
                :
                <>
                  <div>
                    Confirm Delete?
                  </div>

                  <div className='flex justify-end'>
                    <button
                      type="button"
                      className='border rounded border-gunite-red py-2 px-3 text-gunite-red bg-all-f-white mr-4'
                      onClick={()=>setIsDeleting((prevIsDeleting) => {
                        const newIsDeleting = !prevIsDeleting;
                        isDeletingRef.current = newIsDeleting;
                        return newIsDeleting;
                      })}>
                      Cancel
                    </button>

                    <button
                      type="button"
                      className='border rounded border-jet-black/10 py-2 px-3 text-all-f-white bg-dark-blue font-semibold text-base'
                      onClick={(e)=>handleDeleteAdmin(e)}>
                      Confirm
                    </button>
                  </div>
                </>
              }
              
              {!isDeleting &&
                <div>
                  <button
                    type="button"
                    className='border rounded border-navy-light bg-all-f-white py-2 px-3 font-semibold text-base mr-4'
                    onClick={()=>{modalMethods.close(); setIsEditing(false)}}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`border rounded border-jet-black/10 bg-dark-blue py-2 px-3 
                      font-semibold text-base text-all-f-white 
                      ${saveButtonDisabled() ? 'bg-jet-black/10 text-jet-black/40' : 'text-all-f-white bg-dark-blue'}`}
                    disabled={saveButtonDisabled()}
                  >
                    Save
                  </button>
                </div>
              }
            </div>
          </div>
        }
      </form>
    </div>
  )
}

export default NewAdminModal;
