import React, { useEffect } from 'react';
import './index.scss';
import { useResponsive } from '../../contexts/responsive';
import { useAuth } from '../../contexts/auth'
import { useProgress } from '../../contexts/progress';
import { useNavigate } from 'react-router-dom';
import ProgressPaneMobile from '../ProgressPaneMobile';
import ProgressPaneDesktop from '../ProgressPaneDesktop';

function Landing() {
  const { responsive } = useResponsive();
  const { startGunite, startPlaster } = useProgress();

  const navigate = useNavigate()
  const {
    login,
    isInvalidCreds,
    isLoggedIn,
    me,
  } = useAuth()

  useEffect(()=>{
    if (isLoggedIn && me?.role) {
      if (me.role === 'ADMIN') {
        navigate('/admin/planner')
      } else if (me.role === 'SCHEDULER') {
        navigate('/scheduler/planner')
      } else if (me.role === 'CUSTOMER') {
        navigate('/')
      } else if (me.role === 'FOREMAN') {
        navigate('/foreman')
      } else if (me.role === 'BRANCHADMIN') {
        navigate('/branchadmin/planner')
      } else if (me.role === 'DRIVER') {
        navigate('/driver')
      }
    }
  },[isLoggedIn, me?.role])

  const isMobileView = (
    responsive.breakpoint === 'sm'
    || responsive.breakpoint === 'md'
  )
  const isXXL = responsive.breakpoint === 'xxl'
  const isXXXL = responsive.breakpoint === 'xxxl'

  return (
    <div className={`grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3
      ${!isMobileView ?
        'landing_outer_container_desktop'
        :
        'landing_outer_container_mobile'}`}>

      {isMobileView ?
        <ProgressPaneMobile />
        :
        <ProgressPaneDesktop />
      }

      <div className={`col-span-2 p-6 sm:p-6 lg:p-5 md:gap-2 xl:p-[68px] lg:gap-6 xxl:gap-18
        lg:flex justify-center 
        ${!isMobileView ?
          'landing_gunite_plaster_columns_desktop'
          :
          'landing_gunite_plaster_columns_mobile'}
        ${isXXXL &&
        'landing_is_xxxl'
        }`}>
        <div className='col-span-1 mb-3 sm:mb-3 lg:mb-0 flex justify-center align-center
            lg:h-[65%] lg:w-full 
            xl:h-[85%] xl:w-full'
        >
          {!isMobileView ?
            <img className='object-fill lg:object-cover xl:object-cover cursor-pointer'
              src='./../gunite_new_landing_image.png'
              onClick={() => { startGunite() }}
            />
            :
            <img className='cursor-pointer'
              src='./../gunite_new_landing_mobile.png'
              onClick={() => { startGunite() }}
            />
          }
        </div>

        <div className='col-span-1 flex justify-center align-center
            lg:h-[65%] lg:w-full 
            xl:h-[85%] xl:w-full'
        >
          {!isMobileView ?
            <img className='object-fill lg:object-cover xl:object-cover cursor-pointer'
              src='./../plaster_new_landing_image.png'
              onClick={() => { startPlaster() }}
            />
            :
            <img className='cursor-pointer'
              src='./../plaster_new_landing_mobile.png'
              onClick={() => { startPlaster() }}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default Landing;
