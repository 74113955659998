import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth'
import { useIntakeForm } from '../../../contexts/intakeForm';
import { useResponsive } from '../../../contexts/responsive';
import Input from '../../../components/Input';
import PhoneInput from '../../../components/PhoneInput';
import service from '../../../service';
import { FIFTY_STATES } from '../../../constants';
import Selector from '../../../components/Selector';
import './index.scss';
import ResetPasswordModal from '../../../components/ResetPasswordModal';

const Account = () => {
  const { me, getProfile } = useAuth();
  const { builderInfo, setBuilderInfo } = useIntakeForm();
  const [editedBuilderInfo, setEditedBuilderInfo] = useState(builderInfo);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
  const EMAIL_REGEX = /\S+@\S+\.\S+/;
  const formattedPhonePattern = /^(\(\d{3}\)[\s])\d{3}\-\d{4}$/;
  const emailError = !EMAIL_REGEX.test(editedBuilderInfo.email);
  const zipCodeError = !ZIP_REGEX.test(editedBuilderInfo.zipCode);
  const firstNameError = editedBuilderInfo.firstName < 1
  const lastNameError = editedBuilderInfo.lastName < 1
  const bizNameError = editedBuilderInfo.bizName < 1
  const bizAddressError = editedBuilderInfo.bizAddress < 1
  const cityError = editedBuilderInfo.city.length < 1
  const phoneError = !formattedPhonePattern.test(editedBuilderInfo.phone)
  const stateError = editedBuilderInfo.state < 1
  const [isUpdating, setIsUpdating] = useState(false);

  const hasChanged = (
    builderInfo.bizAddress !== editedBuilderInfo.bizAddress
    || builderInfo.bizName !== editedBuilderInfo.bizName
    || builderInfo.city !== editedBuilderInfo.city
    || builderInfo.state !== editedBuilderInfo.state
    || builderInfo.phone !== editedBuilderInfo.phone
    || builderInfo.zipCode !== editedBuilderInfo.zipCode
    || builderInfo.firstName !== editedBuilderInfo.firstName
    || builderInfo.lastName !== editedBuilderInfo.lastName
  )

  const disabledSaveButton = (
    emailError
    || zipCodeError
    || firstNameError
    || lastNameError
    || bizNameError
    || bizAddressError
    || cityError
    || stateError
    || phoneError
    || !hasChanged
    || isUpdating
  )

  const { responsive } = useResponsive();
  const isMobileView = (
    responsive.breakpoint === 'sm'
    || responsive.breakpoint === 'md'
  )

  const editInfo = () => {
    if (!isOpen){
      setIsOpen(true);
    }
  }

  useEffect(()=>{
    setEditedBuilderInfo(builderInfo)
  },[builderInfo])

  const handleUpdateCustomerAccountInfo = async (e) => {
    e.preventDefault();
    const updatedCustomer = {
      customerId: editedBuilderInfo.id,
      id: me.id,
      firstName: editedBuilderInfo.firstName,
      lastName: editedBuilderInfo.lastName,
      bizName: editedBuilderInfo.bizName,
      phone: editedBuilderInfo.phone,
      bizAddress: editedBuilderInfo.bizAddress,
      city: editedBuilderInfo.city,
      state: editedBuilderInfo.state,
      zipCode: editedBuilderInfo.zipCode,
      email: editedBuilderInfo.email
    }
    setIsUpdating(true);
    const res = await service.updateCustomerAccountInfo(updatedCustomer);
    if (res.ok) {
      await getProfile();
      setIsUpdating(false);
      setIsOpen(false);
    }
  }

  return (
    <div className={`flex-col py-16 mr-[15%] ml-[15%] lg:mr-[25%] lg:ml-[25%]
      ${!isMobileView ?
        'intake_account_outer_container'
        :
        'intake_account_outer_container_mobile'
      }`}>
      {!isMobileView &&
        <div className='text-[34px] text-secondary-blue font-bold'>
          My account
        </div>
      }

      <form onSubmit={handleUpdateCustomerAccountInfo}>
        {!isOpen ? (
          <>
            <div className='mt-[48px] text-[12px] text-gray-blue'>
              <div>
                Business Name
              </div>
              <div className='text-[16px] text-jet-black font-semibold'>
                {builderInfo.bizName}
              </div>
            </div>

            <div className='flex flex-col lg:flex-row lg:justify-between mt-[32px]'>
              <div className='lg:w-1/2'>
                <div className='text-[12px] text-gray-blue'>
                  Email Address
                </div>
                <div className='text-[16px] text-jet-black font-semibold'>
                  {builderInfo.email}
                </div>
              </div>

              <div className='mt-[32px] lg:mt-[0px] w-full lg:w-1/2'>
                <div className='text-[12px] text-gray-blue'>
                  Phone Number
                </div>
                <div className='text-[16px] text-jet-black font-semibold'>
                  {builderInfo.phone}
                </div>
              </div>
            </div>

            <div className='flex flex-col lg:flex-row lg:justify-between mt-[32px]'>
              <div className='lg:w-1/2'>
                <div className='text-[12px] text-gray-blue'>
                  First Name
                </div>
                <div className='text-[16px] text-jet-black font-semibold'>
                  {builderInfo.firstName}
                </div>
              </div>

              <div className='mt-[32px] lg:mt-[0px] lg:w-1/2'>
                <div className='text-[12px] text-gray-blue'>
                  Last Name
                </div>
                <div className='text-[16px] text-jet-black font-semibold'>
                  {builderInfo.lastName}
                </div>
              </div>
            </div>

            <div className='flex flex-col lg:flex-row lg:justify-between mt-[32px]'>
              <div className='lg:w-1/2'>
                <div className='text-[12px] text-gray-blue'>
                  Business Address
                </div>
                <div className='text-[16px] text-jet-black font-semibold'>
                {builderInfo.bizAddress}
                </div>
              </div>

              <div className='mt-[32px] lg:mt-[0px] lg:w-1/2'>
                <div className='text-[12px] text-gray-blue'>
                  City
                </div>
                <div className='text-[16px] text-jet-black font-semibold'>
                  {builderInfo.city}
                </div>
              </div>
            </div>

            <div className='flex flex-col lg:flex-row lg:justify-between mt-[32px] lg:mb-[24px]'>
              <div className='lg:w-1/2'>
                <div className='text-[12px] text-gray-blue'>
                  State
                </div>
                <div className='text-[16px] text-jet-black font-semibold'>
                {builderInfo.state}
                </div>
              </div>

              <div className='mt-[32px] lg:mt-[0px] lg:w-1/2'>
                <div className='text-[12px] text-gray-blue'>
                  Zip Code
                </div>
                <div className='text-[16px] text-jet-black font-semibold'>
                  {builderInfo.zipCode}
                </div>
              </div>
            </div>

          <div className='flex justify-between'>
            <button
              className='text-[16px] font-medium text-tertiary-blue'
              onClick={(e) => {
                e.preventDefault()
                setShowResetPassword(true)
              }}
            >
              Change password
            </button>

              <button className='py-1.5 px-3 text-tertiary-blue rounded border-gray-button border-2'
              onClick={()=>{editInfo()}}>
                Edit
              </button>
            </div>
          </>
        ) : (
          <>
            <div className='mt-[48px]'>
              <Input
                placeholder={'Business Name *'}
                value={editedBuilderInfo.bizName}
                onChange={(e) => {
                  setEditedBuilderInfo({
                    ...editedBuilderInfo,
                    bizName: e.target.value
                  })
                }}
                isError={bizNameError}
              />
            </div>

            <div className='flex flex-col lg:flex-row gap-6 mt-[16px]'>
              <div className='lg:w-1/2 bg-navy-light/50'>
                <Input
                  placeholder={'Email Address *'}
                  value={editedBuilderInfo.email}
                  onChange={(e) => {
                    setEditedBuilderInfo({
                      ...editedBuilderInfo,
                      email: e.target.value
                    })
                  }}
                  disabled={true}
                />
              </div>

              <div className='lg:w-1/2'>
                <div>
                  <PhoneInput
                    placeholder={'Phone Number'}
                    value={editedBuilderInfo.phone}
                    onChange={(e) => {
                      setEditedBuilderInfo({
                        ...editedBuilderInfo,
                        phone: e.target.value
                      })
                    }}
                    isError={phoneError}
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-col lg:flex-row gap-6 mt-[16px]'>
              <div className='lg:w-1/2'>
                <Input
                  placeholder={'First name'}
                  value={editedBuilderInfo.firstName}
                  onChange={(e) => {
                    setEditedBuilderInfo({
                      ...editedBuilderInfo,
                      firstName: e.target.value
                    })
                  }}
                  isError={firstNameError}
                />
              </div>

              <div className='lg:w-1/2'>
                <Input
                  placeholder={'Last name'}
                  value={editedBuilderInfo.lastName}
                  onChange={(e) => {
                    setEditedBuilderInfo({
                      ...editedBuilderInfo,
                      lastName: e.target.value
                    })
                  }}
                  isError={lastNameError}
                />
              </div>
            </div>

            <div className='flex flex-col lg:flex-row gap-6 mt-[16px]'>
              <div className='lg:w-1/2'>
                <Input
                  placeholder={'Business Address'}
                  value={editedBuilderInfo.bizAddress}
                  onChange={(e)=>{
                    setEditedBuilderInfo({
                      ...editedBuilderInfo,
                      bizAddress: e.target.value
                    })
                  }}
                  isError={bizAddressError}
                />
              </div>

              <div className='lg:w-1/2'>
                <Input
                  placeholder={'City'}
                  value={editedBuilderInfo.city}
                  onChange={(e)=>{
                    setEditedBuilderInfo({
                      ...editedBuilderInfo,
                      city: e.target.value
                    })
                  }}
                  isError={cityError}
                />
              </div>
            </div>

            <div className='flex flex-col lg:flex-row gap-6 mt-[16px]'>
              <div className='lg:w-1/2'>
                <Selector
                  placeholder={'State'}
                  value={editedBuilderInfo.state}
                  options={FIFTY_STATES}
                  onChange={(e) => {
                    setEditedBuilderInfo({
                      ...editedBuilderInfo,
                      state: e.target.value
                    })
                  }}
                />
              </div>

              <div className='lg:w-1/2 mb-[48px]'>
                <Input
                  placeholder={'Zip Code'}
                  value={editedBuilderInfo.zipCode}
                  onChange={(e) => {
                    setEditedBuilderInfo({
                      ...editedBuilderInfo,
                      zipCode: e.target.value
                    })
                  }}
                  isError={zipCodeError}
                />
              </div>
            </div>

          <div className='flex justify-between'>
            <button
              className='text-[16px] font-medium text-tertiary-blue'
              onClick={(e) => {
                e.preventDefault()
                setShowResetPassword(true)
              }}
            >
              Change password
            </button>

              <div className='flex'>
                <button
                className='mr-[16px] py-1.5 px-3 text-tertiary-blue rounded border-gray-button border-2'
                onClick={()=>{
                  setIsOpen(false);
                  setEditedBuilderInfo(builderInfo);
                }}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className={`py-1.5 px-3 rounded border-2 border-tertiary-blue
                  bg-tertiary-blue text-all-f-white ${disabledSaveButton && 'opacity-40'}`}
                  disabled={disabledSaveButton}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </form>
      <ResetPasswordModal
        userEmail={me?.email}
        showResetPassword={showResetPassword}
        setShowResetPassword={setShowResetPassword}
      />
    </div>
  )
}

export default Account;