import React, { createContext, useState, useContext } from 'react'

const ModalContext = createContext()

export const useModal = () => useContext(ModalContext)

const ModalProvider = props => {
  const [isActive, setIsActive] = useState(false)
  const [content, setContent] = useState(null)
  const [width, setWidth] = useState(null)
  const [xIsHidden, setxIsHidden] = useState(false)
  const [isEditing, setIsEditing] = useState(false);

  const modal = {
    isActive,
    content,
    width,
    xIsHidden,
    isEditing,
    setIsEditing
  }

  const modalMethods = {
    open: () => {
      setIsActive(true)
    },
    close: () => {
      setWidth(null)
      setContent(null)
      setIsActive(false)
    },
    setContent,
    setxIsHidden,
    setModalWidth: newWidth => {
      setWidth(newWidth)
    },
  }

  return (
    <ModalContext.Provider
      value={{ modal, modalMethods }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
