import React, { useState } from 'react'

const PersonnelSelectorButton = ({ person, selectPerson, unselectPerson, isSelected }) => {

  const handleClick = (e) => {
    if (isSelected) {
      unselectPerson(person)
    } else {
      selectPerson(person)
    }
  }


  return (
    <button
      className={`${isSelected ? 'bg-dark-blue' : 'bg-gray-600'} p-3 rounded-full text-white font-semi-bold h-fit`}
      onClick={handleClick}
    >{person.user.name}</button>
  )
}

export default PersonnelSelectorButton