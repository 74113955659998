import React, { useState, useRef, useEffect } from "react";
import serviceMethods from '../../../service'
import Input from "../../../components/Input";
import { useNavigate } from 'react-router-dom';
import LinkExpired from "../../../components/LinkExpired";

const UpdatePassword = () => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordSubmitted, setPasswordSubmitted] = useState(false)
  const [token, setToken] = useState('')
  const [tokenExpired, setTokenExpired] = useState(false)
  const [highlightRules, setHighlightRules] = useState(false)
  const modal = useRef()
  const { updatePassword } = serviceMethods;
  let navigate = useNavigate()

  const passwordOneError = newPassword.length < 1
  const passwordTwoError = confirmPassword.length < 1
  const passwordMatchError = newPassword !== confirmPassword

  const submitDisabled = (
    passwordOneError
    || passwordTwoError
    || passwordMatchError
  )

  const handleOutsideClick = (e) => {
    if (!modal.current.contains(e.target)) {
      if (passwordSubmitted || tokenExpired) {
        navigate('/')
      }
    }
  }

  const handleSetPassword = async() => {
    if (newPassword === confirmPassword && newPassword !== '') {
      const passwordSet = await updatePassword(token, newPassword)
      if (passwordSet.ok) {
        setPasswordSubmitted(true)
        setHighlightRules(false)
      } else {
        const data = await passwordSet.json()
        handleFailedResponse(data.message)
      }
    } else {
      handleFailedResponse('Passwords do not match.')
    }
  }

  const handleFailedResponse = (message) => {
    const regexExpired = /jwt expired/
    const regexNotFound = /Token not found/
    const regexRule1 = /at least 8 characters/
    const regexRule2 = /1 letter and 1 number/
    const regexRule3 = /Passwords do not match/

    if (regexExpired.test(message) || regexNotFound.test(message)) {
      setTokenExpired(true)
    } else if (regexRule1.test(message) || regexRule2.test(message) || regexRule3.test(message)) {
      // turn password rules text red
      setHighlightRules(true)
    }
  }

  const handleClose = () => {
    navigate('/')
  }

  useEffect(() => {
    const url = window.location.search
    const queryParams = new URLSearchParams(url)
    const paramsToken = queryParams.get('token')
    setToken(paramsToken)

    // verify token
    async function fetchData() {
      const res = await serviceMethods.verifyToken(paramsToken)
      if (!res.ok) {
        setTokenExpired(true)
      }
    }
    fetchData()
  }, [])

  return (
    <div className='flex w-full h-full'>
      <div
        className='fixed top-0 left-0 flex justify-center items-center w-screen h-screen bg-black bg-opacity-80 z-10'
        onClick={(e) => {
          handleOutsideClick(e)
        }}
      >
        <div ref={modal} className="flex w-[760px]">

          {tokenExpired ? <LinkExpired handleClose={handleClose} /> :
            <div className="flex flex-col items-start w-full p-6 bg-white rounded-[8px]">

              <div
                className="flex w-full gap-2 items-center select-none cursor-pointer pb-6"
                onClick={() => {
                  handleClose()
                }}
              >
                  <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
                  <p className="font-semibold text-gunite-red">Back</p>
              </div>

              <div className='pb-6'>

                <div className='flex flex-row items-center'>
                  <p className='text-[34px] font-bold text-secondary-blue'>
                    {!passwordSubmitted ? 'Set new password' : 'New password set'}
                  </p>
                  <img alt="back" src="/check_in_circle_icon.svg" className={`h-[24px] pl-3 ${passwordSubmitted ? '' : 'hidden'}`} />
                </div>

                {!passwordSubmitted ? (
                  <>
                    <div className='flex flex-row'>
                      <p className='text-[16px] text-light-blue'>
                        Please enter and confirm your new password.
                      </p>
                    </div>
                    <div className='flex flex-row'>
                      <p className={`text-[16px] ${highlightRules ? 'text-gunite-red' : 'text-light-blue'}`}>
                        It must contain at least 7 letters and 1 number.
                      </p>
                    </div>
                  </>
                ) : (
                  <div>
                    <p className={`text-[16px] ${highlightRules ? 'text-gunite-red' : 'text-light-blue'}`}>
                      Your new password is now set.
                    </p>
                  </div>
                )}

              </div>

              <div className={`flex flex-col gap-6 pb-6 w-full ${passwordSubmitted ? 'hidden' : ''}`}>
                <div className='w-full'>
                  <Input

                    placeholder={'New password *'}
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value)
                    }}
                    isPassword={true}
                    isError={passwordOneError}
                  />
                </div>

                <div className='w-full'>
                  <Input
                    placeholder={'Confirm new password *'}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }}
                    isPassword={true}
                    isError={passwordTwoError}
                  />
                </div>
              </div>


              {!passwordSubmitted ?
                <button
                  className={`rounded px-9 py-3 font-bold ${submitDisabled ? "bg-black/10 text-black/40" : "bg-gunite-red text-white"}`}
                  onClick={() => {
                    handleSetPassword()
                  }}
                  disabled={submitDisabled}
                >
                  Set new password
                </button>
                :
                <button
                  className='rounded px-9 py-3 text-tertiary-blue bg-white border-[3px] border-light-blue text-[24px] font-bold'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  Return to home
                </button>
              }

            </div>
          }

        </div>

      </div>

    </div>
  )
}

export default UpdatePassword;
