import React from "react";
import { useLongPress } from "@uidotdev/usehooks";
import { useScheduler } from "../../contexts/scheduler";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import { useSchedulerCalendarMobile } from "../../contexts/schedulerCalendarMobile";

function CalendarJobCardMobile({ job, i }) {
  const { setSelectedJobId } = useScheduler()
  const { displayMode, moveJob, dropActive, setDropActive, dropActiveFlashing, setDropActiveFlashing } = useSchedulerCalendarMobile()
  const { setShowJobDetailsModal } = useJobDetailsModal()


  const jobObj = {
    calendarJobId: job.id,
    calendarJobCrewId: job.crews[0].id,
    calendarJobType: 'REGULAR',
    jobId: job.jobId
  }

  const tags = []

  //tags for all kinds of jobs
  if (job.job?.pools[0]?.newOrReplaster === 'Re-Plaster') {
    tags.push('R')
  }
  if (job.job?.isHudJob) {
    tags.push('H')
  }
  if (job.job?.pools[0]?.spa) {
    tags.push('S')
  }
  //series of tags for plaster only
  if (job.job.jobType === 'plaster') {
    if (job?.job?.pools[0]?.basin) {
      tags.push('B')
    }
  } else { //series of tags for gunite only
    if (job.job?.guniteType === 'ASR Certification') {
      tags.push('A')
    }
    if (job.job?.pools[0]?.negativeEdge) {
      tags.push('NE')
    }
    if (job.job?.pools[0]?.notch) {
      tags.push('N')
    }
    if (job?.job?.pools[0]?.raisedBeam) {
      tags.push('RB')
    }
    if (job?.job?.pools[0]?.retainingWalls) {
      tags.push('RW')
    }
  }

  const stateAbbreviator = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: "IL",
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VI',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY'
  }

  const attrs = useLongPress(
    () => {
      moveJob(jobObj)
    },
    {
      threshold: 500,
    }
  );

  const crewColorsInactive = [
    'bg-[#f8f5c2]/60',
    'bg-[#b9fbc0]/60',
    'bg-[#9fe7ff]/60',
    'bg-[#fde4cf]/60',
    'bg-[#f1c0e8]/60',
    'bg-[#ffcfd2]/60',
    'bg-[#99e4eb]/60',
    'bg-[#d1bba7]/60',
    'bg-[#cbbaf0]/60',
    'bg-[#8bebbe]/60',
    'bg-[#bcd8ff]/60',
    'bg-[#bcc4d1]/60',
  ]

  const jobSizeDisplay = () => {
    if (job.job.jobType === 'gunite') return `${job.job.estimatedYards} yards`
    else return `${job.job.estimatedIA} IA`
  }

  const handleClick = () => {
    if (dropActive) {
      setDropActive(false)
    } else if (dropActiveFlashing) {
      setDropActiveFlashing(false)
    } else {
      setSelectedJobId(job.jobId)
      setShowJobDetailsModal(true)
    }
  }

  const calendarJobDisplay = () => {
    if (displayMode === 'long') {
      return (
        <div
          className={`flex flex-col justify-between gap-1 mb-[1px]
          basis-1/3 ${crewColorsInactive[i]}
          w-full p-1 rounded text-dark-blue text-[12px] leading-tight shadow`}
          onClick={handleClick}
          {...attrs}
        >
          <div className="flex flex-col gap-1">
            <p className={`font-extrabold leading-5 ${job.job.completedAt ? 'opacity-50' : ''}`}>{job?.job?.jobName.toUpperCase()}</p>
            <div className="flex flex-col gap-1">
              <p className={`font-medium ${job.job.completedAt ? 'opacity-50' : ''}`}>{job?.job?.address}, {job?.job?.city}, {stateAbbreviator[job?.job?.state]}</p>
              <p className={`font-medium ${job.job.completedAt ? 'opacity-50' : ''}`}>{job.job.owner.customer.bizName}</p>
              {(job.job.jobType === 'plaster' && job?.job?.pools[0]?.material)
                &&
                <p className="font-medium opacity-50">
                  {job?.job?.pools[0]?.material}
                </p>
              }
            </div>
          </div>
          <div className={`flex flex-wrap-reverse gap-1 justify-between ${job.job.completedAt ? 'opacity-50' : ''}`}>
            <div className="px-1 py-[2px] bg-black/10 w-max">
              <p className="">{jobSizeDisplay()}</p>
            </div>
            <div className="flex flex-row gap-1">
              {tags.map((tag, i) => (
                <p key={`tag${i}`} className='bg-black/10 rounded h-fit px-1 py-1 leading-none font-[600]'>
                  {tag}
                </p>))}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={`flex flex-col justify-between h-auto w-full ${crewColorsInactive[i]} py-1 px-1 rounded text-dark-blue text-[12px] leading-tight`}
          onClick={handleClick}
          {...attrs}
        >
          <p className={`font-extrabold leading-5 ${job.job.completedAt ? 'opacity-50' : ''}`}>{job?.job?.jobName.toUpperCase()}</p>
          <div className={`flex justify-between ${job.job.completedAt ? 'opacity-50' : ''}`}>
            <div className="px-1 py-[2px] bg-black/10 w-max">
              <p className="">{jobSizeDisplay()}</p>
            </div>
            <div className="flex flex-row gap-1">
              {tags.map((tag, i) => (
                <p key={`tag${i}`} className='bg-black/10 rounded h-fit px-1 py-1 leading-none font-[600]'>
                  {tag}
                </p>))}
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>{calendarJobDisplay()}</>
  )
}

export default CalendarJobCardMobile;
