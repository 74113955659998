import React, { useState, useEffect } from 'react'
import { useScheduler } from '../../contexts/scheduler'
import { useJobDetailsModal } from '../../contexts/jobDetailsModal'

const MiniCalendarDrivers = ({ day, allDrivers, scheduleInfo, oldScheduleInfo, oldFlashScheduleInfo, availability, toggle, setToggle, isFlash }) => {
  const { selectedJobId } = useScheduler()
  const { selectedCalendarJobId, showMiniCalendar } = useJobDetailsModal()
  const [driverSelection, setDriverSelection] = useState([])
  const [isAvailable, setIsAvailable] = useState({})

  const handleDriverSelection = (driver) => {
    if (!scheduleInfo?.current[day]) {
      scheduleInfo.current[day] = {}
    }
    const driverIndex = driverSelection.indexOf(driver?.id)
    if (driverIndex !== -1) {
      const driverRemoved = [...driverSelection.slice(0, driverIndex), ...driverSelection.slice(driverIndex + 1)]

      setDriverSelection(driverRemoved)
      scheduleInfo.current[day].drivers = driverRemoved
    } else {
      const driverAdded = [...driverSelection, driver?.id]
      setDriverSelection(driverAdded)
      scheduleInfo.current[day].drivers = driverAdded
    }

    // triggers useEffect in miniCalendarModal
    setToggle(!toggle)
  }

  const checkDriverAvailability = () => {
    if (availability.current[day]) {
      const updates = {}

      // set defaults
      allDrivers?.forEach((driver) => {
        if (updates[driver.id] === undefined) {
          const defaultAvailability = {
            shoot1: true,
            shoot2: true,
            shoot3: true,
            active: true
          }
          updates[driver.id] = defaultAvailability
        }
      })

      const shoot1 = []
      const shoot2 = []
      const shoot3 = []

      // collect + organize all ids that have a scheduled shift on the given day
      Object.keys(availability.current[day]).forEach((jobId) => {
        Object.keys(availability.current[day][jobId]).forEach((calJobId) => {
          // exclude availability details / ids from job being rescheduled
          if (!isFlash && Number(jobId) === selectedJobId && availability.current[day][jobId][calJobId].jobType === 'REGULAR') return
          if (isFlash  && Number(jobId) === selectedJobId && availability.current[day][jobId][calJobId].jobType === 'FLASHING') return

          shoot1.push(...availability.current[day][jobId][calJobId].drivers.shoot1)
          shoot2.push(...availability.current[day][jobId][calJobId].drivers.shoot2)
          shoot3.push(...availability.current[day][jobId][calJobId].drivers.shoot3)
        })
      })


      // check if current selection blocks / disables a driver
      shoot1.forEach((id) => {
        if (!updates[id]) updates[id] = {}
        updates[id].shoot1 = false
        if (scheduleInfo.current[day]?.shift.includes('1')) {
          updates[id].active = false
        }
      })
      shoot2.forEach((id) => {
        if (!updates[id]) updates[id] = {}
        updates[id].shoot2 = false
        if (scheduleInfo.current[day]?.shift.includes('2')) {
          updates[id].active = false
        }
      })
      shoot3.forEach((id) => {
        if (!updates[id]) updates[id] = {}
        updates[id].shoot3 = false
        if (scheduleInfo.current[day]?.shift.includes('3')) {
          updates[id].active = false
        }
      })

      // check if a driver is not available / fully booked that day
      Object.keys(updates).forEach((driverId) => {
        if (!updates[driverId].shoot1 && !updates[driverId].shoot2 && !updates[driverId].shoot3) {
          updates[driverId].active = false
        }
      })

      setIsAvailable({ ...isAvailable, ...updates })
    }
  }

  useEffect(() => {
    if (!isFlash) {
      if (oldScheduleInfo.current.days[day]) {
        if (!scheduleInfo?.current[day]) {
          scheduleInfo.current[day] = {}
        }
        setDriverSelection(oldScheduleInfo.current.days[day].drivers)
        scheduleInfo.current[day].drivers = oldScheduleInfo.current.days[day].drivers
      }
    } else {
      if (oldFlashScheduleInfo.current.days[day]) {
        if (!scheduleInfo?.current[day]) {
          scheduleInfo.current[day] = {}
        }
        setDriverSelection(oldFlashScheduleInfo.current.days[day].drivers)
        scheduleInfo.current[day].drivers = oldFlashScheduleInfo.current.days[day].drivers
      }
    }
  }, [oldScheduleInfo.current.days?.length, oldFlashScheduleInfo.current.days?.length, isFlash, oldScheduleInfo.current.calendarJobs])

  useEffect(() => {
    checkDriverAvailability()
  }, [toggle])

  return (
    <div className='flex flex-col w-full px-2' >
      <div className='border p-3 pt-0 mt-1 rounded shadow-sm bg-all-f-white'>
        <form>
          {allDrivers.map((driver, i) => {
            return (
              <div className='flex items-center pt-3' key={`driver${i}-${day}`}>
                <input
                  type='checkbox'
                  id={`driver-${i}-${day}`}
                  name={day}
                  value={driver.id}
                  className='text-secondary-blue rounded h-[16px] w-[16px] border-navy-light'
                  onChange={() => {
                    handleDriverSelection(driver)
                  }}
                  checked={driverSelection?.includes(driver.id)}
                  disabled={isAvailable[driver.id] && !isAvailable[driver.id].active}
                >
                </input>
                <label
                  className={`flex justify-center text-[14px] text-dark-blue ml-3 py-[2px] px-3 rounded-2xl bg-card-bg-hover ${(isAvailable[driver.id] && !isAvailable[driver.id].active) ? 'text-mini-calendar-light-gray bg-gray-light' : ''}`}
                  htmlFor={`driver-${i}-${day}`}
                >
                  {driver.user.name}
                </label>
              </div>
            )
          })}
        </form>
      </div>
    </div>
  )
}

export default MiniCalendarDrivers
