import React, { useState, useEffect } from 'react'
import { useJobDetailsModal } from '../../contexts/jobDetailsModal'
import DateSelectorDay from '../DateSelectorDay'

const DateSelector = ({ askIfContinueDate, selectedDay, setSelectedDay }) => {
  const { days, previousMonth, nextMonth, currentDay } = useJobDetailsModal()

  const [mondays, setMondays] = useState([])
  const [tuesdays, setTuesdays] = useState([])
  const [wednesdays, setWednesdays] = useState([])
  const [thursdays, setTursdays] = useState([])
  const [fridays, setFridays] = useState([])
  const [saturdays, setSaturdays] = useState([])

  const monthsKey = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const sortDays = () => {
    clearDays()

    const mo = []
    const tu = []
    const we = []
    const th = []
    const fr = []
    const sa = []
    const weekDays = ['skip', mo, tu, we, th, fr, sa]

    // inserts blank days for months that don't start on a monday
    const numberOfBlanks = days[0].getDay() - 1;
    for (let i = 1; i < numberOfBlanks + 1; i++) {
      const blankDay = (
        <DateSelectorDay blank key={`blank${i}`} allowSelectPast={false}/>
      )
      weekDays[i].push(blankDay)
    }

    const newDate = new Date()
    const today = {
      day: newDate.getDate(),
      month: newDate.getMonth(),
      year: newDate.getFullYear(),
    }

    days.forEach((day, i) => {
      const date = {
        day: day.getDate(),
        month: day.getMonth(),
        year: day.getFullYear(),
      }
      const calendarDayComponent = (
        <DateSelectorDay
          key={`day-${date.month}-${date.day}-${Date.now()}`}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          dateInfo={date}
          today={today}
          data-date={date}
          askIfContinueDate={askIfContinueDate}
        />
      )
      // skipping sunday, push each day into the proper weekDay list
      const dayOfTheWeek = day.getDay()
      if (dayOfTheWeek !== 0) {
        weekDays[dayOfTheWeek].push(calendarDayComponent)
      }
    })

    setMondays(mo)
    setTuesdays(tu)
    setWednesdays(we)
    setTursdays(th)
    setFridays(fr)
    setSaturdays(sa)
  }

  const clearDays = () => {
    mondays.current = []
    tuesdays.current = []
    wednesdays.current = []
    thursdays.current = []
    fridays.current = []
    saturdays.current = []
  }

  useEffect(() => {
    sortDays()
  }, [selectedDay, currentDay, days])

  return (
    <div className='contents lg:flex-col lg:bg-white'>
      <div className='flex justify-between items-center text-dark-blue font-bold bg-white rounded-t'>
        <img
          className='rounded-2xl h-[28px] w-[28px]'
          alt="calendar icon"
          src='/vectors/Calendar_left_chevron.svg'
          onClick={() => {
            previousMonth()
          }}
        />
        <p className=''>
          {`${monthsKey[days[0].getMonth()]} ${days[0].getFullYear()}`}
        </p>
        <img
          className='rounded-2xl h-[28px] w-[28px]'
          alt="calendar icon"
          src='/vectors/Calendar_right_chevron.svg'
          onClick={() => {
            nextMonth()
          }}
        />
      </div>

      <div className='flex justify-center w-full  pb-3 pt-2 bg-white rounded-b'>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Mo</p>
          {mondays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Tu</p>
          {tuesdays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >We</p>
          {wednesdays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Th</p>
          {thursdays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Fr</p>
          {fridays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Sa</p>
          {saturdays}
        </div>
      </div>

    </div>
  )
}

export default DateSelector
