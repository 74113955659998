import React, { useState, useEffect } from 'react'
import Input from "../../components/Input/index"
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/auth";
import { useModal } from '../../contexts/modal'
import { set } from 'date-fns';
import ForgotPasswordModal from '../../components/ForgotPasswordModal';

const CalendarSignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const { login, isLoggedIn, isInvalidCreds, portalInactiveForAllUserLocations, me } = useAuth()
  const { modalMethods: { open, setContent }} = useModal()

  useEffect(()=>{
    if (isLoggedIn && me?.role) {
      if (me.role === 'ADMIN') {
        navigate('/admin/planner')
      } else if (me.role === 'BRANCHADMIN') {
        navigate('/branchadmin/planner')
      } else if (me.role === 'SCHEDULER') {
        navigate('/scheduler/planner')
      } else if (me.role === 'CUSTOMER') {
        navigate('/')
      } else if (me.role === 'FOREMAN') {
        navigate('/foreman')
      } else if (me.role === 'DRIVER') {
        navigate('/driver')
      }
    }
  },[isLoggedIn, me?.role])

  return (
    <div className='p-6 bg-subtle-grey w-screen h-screen min-h-[600px] flex flex-col justify-center items-center'>
      <img className='h-12 mb-10' src="/main-logo-dark.svg" alt="Main Logo" />
      <div className='border border-navy-light overflow-hidden flex flex-col w-full h-auto min-h-[400px] sm:w-3/6 lg:w-1/3 xl:w-1/4 bg-white rounded-2xl'>
        <div className='pr-6 pl-6 pt-4 pb-4  bg-opacity-25  bg-navy-bright border-b border-navy-light'>
          <p className="text-dark-blue font-bold text-3xl mb-1">Sign In</p>
        </div>
        <div className='p-6'>

          <form className="flex flex-col gap-6"
            onSubmit={(e) => {
              e.preventDefault()
              login(email, password)
            }}
          >
            <div className="flex flex-col items-left">
              <label className='text-dark-blue'>Email</label>
              <input
                className='rounded border-navy-light'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div className="flex flex-col items-left">
              <label className='text-dark-blue'>Password</label>
              <input
                className='rounded border-navy-light'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                type={'password'}
              />
            </div>

            <div className={`flex items-center ${isInvalidCreds ? '' : 'hidden'}`}>
              <p className="text-gunite-red font-semibold">Incorrect email / password combination!</p>
            </div>
            <div className={`flex items-center ${portalInactiveForAllUserLocations ? '' : 'hidden'}`}>
              <p className="text-gunite-red font-semibold">Portal is not active for your location</p>
            </div>

            <div className="flex items-center">
              <div className="w-full ">
                <button
                  type="submit"
                  className="w-full  lg:px-9 lg:py-3 rounded p-3 bg-gunite-red text-white text-2xl font-bold"
                >
                  Sign in
                </button>
              </div>

            </div>
            <button
              className='text-tertiary-blue font-bold text-center sm:text-left'
              onClick={(e) => {
                e.preventDefault()
                setContent(<ForgotPasswordModal/>)
                open()
              }}
            >
              Forgot password?
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default CalendarSignIn
