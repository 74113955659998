import JobsListList from '../JobsListList'
import JobsListListHeaderBar from '../JobsListListHeaderBar'
import PaginationFooterBar from '../PaginationFooterBar'

const JobsList = ({
  displayJobs,
  currentPage, setCurrentPage,
  selectedStatuses, setSelectedStatuses,
  jobType, setJobType,
  allJobs,
  searchTerm, setSearchTerm,
  ColumnHeaders, DropdownMenu, resultsPerPage,
  ListItem,
  sortBy, setSortBy,
}) => {

  return (
    <>
      <div className="flex flex-col w-full">
        <JobsListListHeaderBar 
          jobType={jobType}
          setJobType={setJobType}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          DropdownMenu={DropdownMenu}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        <JobsListList 
          displayJobs={displayJobs}
          ColumnHeaders={ColumnHeaders}
          ListItem={ListItem}
        />
        <PaginationFooterBar 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allItems={allJobs}
          resultsPerPage={resultsPerPage}
        />
      </div>
    </>
  );
}

export default JobsList;
