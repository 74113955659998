import React, { createContext, useContext, useState, useEffect } from 'react';
import serviceMethods from "../service";
import { useLocation } from "react-router-dom";

export const CalendarContext = createContext();

const CalendarContextProvider = props => {
  const [isFetchingDisabledDates, setIsFetchingDisabledDates] = useState(false);
  const [hasFetchedDisabledDates, setHasFetchedDisabledDates] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const { pathname } = useLocation();

  const getDisabledDates = async () => {
    setHasFetchedDisabledDates(true)
    setIsFetchingDisabledDates(true)
    const res = await serviceMethods.getDisabledDates();
    if (res.ok){
      const result = await res.json()
      const dates = result.map(item => item.date);
      setDisabledDates(dates);
    }
    setIsFetchingDisabledDates(false)
  }

  const extractedDays = disabledDates?.map(dateString => {
    const date = new Date(dateString);
    return {
      month: date.getMonth() + 1,
      day: date.getDate(),
      year: date.getFullYear()
    }
  });

  useEffect(()=>{
    if (
      disabledDates.length === 0 
      && !isFetchingDisabledDates
      && !hasFetchedDisabledDates
    ) {
      getDisabledDates()
    }
  },[])

  return (
    <CalendarContext.Provider value = {{
      disabledDates, setDisabledDates, extractedDays
    }}>
      {props.children}
    </CalendarContext.Provider>
  )
}

const useCalendar = () => useContext(CalendarContext)
export { useCalendar }
export default CalendarContextProvider;
