import React, { useState } from 'react'
import { useModal } from '../../contexts/modal'
import { useAuth } from "../../contexts/auth";
import Input from "../Input";
import ForgotPasswordModal from '../ForgotPasswordModal';

const SignInModal = () => {
  const { login, isInvalidCreds, setIsInvalidCreds, portalInactiveForAllUserLocations, setPortalInactiveForAllUserLocations } = useAuth()
  const { modalMethods: { close, open, setContent }} = useModal()
  const [modalEmail, setModalEmail] = useState('')
  const [modalPassword, setModalPassword] = useState('')

  const modalEmailError = modalEmail.length < 1
  const modalPasswordError = modalPassword.length < 1

  return (
    <div className='flex flex-col w-full lg:w-3/6 bg-white p-6 rounded-lg'>
      <div className="flex flex-col mb-6">
        <button
          className="flex gap-2 items-center mb-6"
          onClick={()=>{
            close()
            setIsInvalidCreds(false)
            setPortalInactiveForAllUserLocations(false)
          }}
        >
          <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
          <p className="font-semibold text-gunite-red">Back</p>
        </button>
        <p className="text-secondary-blue font-bold text-4xl mb-1">Welcome Back</p>
        <p className="text-secondary-blue/60 font-semibold text-base">Log in below</p>
      </div>
      <form className="flex flex-col gap-6"
        onSubmit={async (e) => {
          e.preventDefault()
          const loginSuccess = await login(modalEmail, modalPassword)
          if (loginSuccess) close()
        }}
      >

        <div className="flex flex-col lg:flex-row gap-6">
          {/* Email Address field */}
          <div className="relative lg:w-1/2 group">
            <Input
              value={modalEmail}
              placeholder={'Email Address *'}
              onChange={(e) => {
                setModalEmail(e.target.value)
              }}
              isError={modalEmailError}
            />
          </div>

          {/* Password field */}
          <div className="relative lg:w-1/2 group">
            <Input
              value={modalPassword}
              placeholder={'Password *'}
              onChange={(e) => {
                setModalPassword(e.target.value)
              }}
              isError={modalPasswordError}
              isPassword={true}
            />
          </div>
        </div>

        <div className={`flex items-center ${portalInactiveForAllUserLocations ? '' : 'hidden'}`}>
          <p className="text-gunite-red font-semibold">Portal is not active for your location.</p>
        </div>

        <div className={`flex items-center ${isInvalidCreds ? '' : 'hidden'}`}>
          <p className="text-gunite-red font-semibold">Incorrect email / password combination!</p>
        </div>

        <div className="flex items-center justify-between">
          <div className='flex items-center'>
            {/* Log in button */}
            <div className="w-full lg:w-auto">
              <button
                type="submit"
                className="w-full lg:w-auto lg:px-9 lg:py-3 rounded p-3 bg-gunite-red text-white text-2xl font-bold"
              >
                Log in
              </button>
            </div>

            {/* keep me logged in button: desktop only */}

            <input type="checkbox" id="stayLoggedIn" className="hidden ml-6 lg:inline-block h-5 w-5" />
            <label className="hidden lg:inline-block ml-2 font-medium text-lg" htmlFor="stayLoggedIn">Keep me logged in</label>

          </div>

          <div>
            <button
              className='text-tertiary-blue font-bold'
              onClick={(e) => {
                e.preventDefault()
                close()
                setContent(<ForgotPasswordModal/>)
                open()
              }}
            >
              Forgot password?
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
export default SignInModal
