import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth'
import { useScheduler } from '../../contexts/scheduler';
import { useAdmin } from "../../contexts/admin";

const apiUrl = process.env.REACT_APP_API_URL;

const Sidebar = ({role}) => {
  const [focus, setFocus] = useState('planner');
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const { selectedScheduler } = useScheduler()
  const { selectedLocation } = useAdmin()
  const { me } = useAuth()
  const logoImage = me?.role === 'SCHEDULER' || me?.role === 'ADMIN' || me?.role === 'BRANCHADMIN'
    ? `${apiUrl}/${selectedScheduler?.location?.image}`
    : "/sidebar/gunite_logo_sm.svg"

  useEffect(() => {
    const regex = /\/([^\/]+)$/;
    const match = pathname.match(regex)
    if (match) setFocus(match[1])
  }, [pathname])

  return (
    <div className='flex w-20 h-full flex-col content-center justify-between bg-dark-blue hidden lg:flex'>

      <div className='flex w-full h-20 justify-center items-center'>
        <Link to='/'>
          <div  className='w-[58px]'>
            <img src={logoImage} alt="Main Logo" className='w-full' crossOrigin="anonymous" />
          </div>
        </Link>
      </div>

      <div className='flex flex-col h-fit justify-center items-center'>

        <Link to={`/${role}/planner`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={focus === 'planner' ? '/sidebar/planner_icon.svg' : '/sidebar/planner_icon_disabled.svg'}
              alt="Planner Icon"
              className='w-[27px] pb-[6px]'
            />
            <p className={`text-xs ${focus === 'planner' ? 'text-white' : 'text-slate-400'}`}>Planner</p>
          </div>
        </Link>

        <Link to={`/${role}/calendar`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={focus === 'calendar' ? '/sidebar/calendar_icon.svg' : '/sidebar/calendar_icon_disabled.svg'}
              alt="Calendar Icon"
              className='w-[27px] pb-[6px]'
            />
            <p className={`text-xs ${focus === 'calendar' ? 'text-white' : 'text-slate-400'}`}>Calendar</p>
          </div>
        </Link>

        <Link to={`/${role}/jobsList`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={focus === 'jobsList' ? '/sidebar/jobs_list_icon.svg' : '/sidebar/jobs_list_icon_disabled.svg'}
              alt="Jobs List Icon"
              className='w-[27px] pb-[6px]'
            />
            <p className={`text-xs ${focus === 'jobs-list' ? 'text-white' : 'text-slate-400'}`}>Jobs List</p>
          </div>
        </Link>

        <Link to={`/${role}/activityLog`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={focus === 'activityLog' ? '/sidebar/activity_log_icon.svg' : '/sidebar/activity_log_icon_disabled.svg'}
              alt="Activity Log Icon"
              className='w-[37px] pb-[6px]'
            />
            <p className={`text-xs ${focus === 'activity-log' ? 'text-white' : 'text-slate-400'}`}>Activity Log</p>
          </div>
        </Link>

        <Link to={`/${role}/dailyJobLog`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={focus === 'dailyJobLog' ? '/sidebar/daily_job_log_icon.svg' : '/sidebar/daily_job_log_icon_disabled.svg'}
              alt="Daily Job Log Icon"
              className='w-[31px] pb-[6px]'
            />
            <p className={`text-xs ${focus === 'daily-job-log' ? 'text-white' : 'text-slate-400'}`}>Daily job log</p>
          </div>
        </Link>

        <Link to={`/${role}/files`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={pathname === '/admin/files' ? '/sidebar/files_icon.svg' : '/sidebar/files_icon_disabled.svg'}
              alt="Files Icon"
              className='w-[24px] pb-[6px]'
            />
            <p className={`text-xs ${pathname === '/admin/files' ? 'text-white' : 'text-slate-400'}`}>Files</p>
          </div>
        </Link>

        {(role === 'scheduler' || role === 'branchadmin' || role === 'admin') && (
          <Link to={`/${role}/customers`}>
            <div className='flex flex-col w-full items-center py-[10px]'>
              <img
                src={focus === 'customers' ? '/sidebar/jobs_list_icon.svg' : '/sidebar/jobs_list_icon_disabled.svg'}
                alt="Customers Icon"
                className='w-[30px] pb-[6px]'
              />
              <p className={`text-xs ${focus === 'customers' ? 'text-white' : 'text-slate-400'}`}>Customers</p>
            </div>
          </Link>
        )}

        <Link to={`/${role}/account`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={focus === 'account' ? '/sidebar/account_icon.svg' : '/sidebar/account_icon_disabled.svg'}
              alt="Account Icon"
              className='w-[30px] pb-[6px]'
            />
            <p className={`text-xs ${focus === 'account' ? 'text-white' : 'text-slate-400'}`}>Account</p>
          </div>
        </Link>

        {(role === 'admin' || role === 'branchadmin') && (
        <Link to={`/${role}/setup${role === 'admin' ? '/global' : `/details?branchId=${selectedLocation?.id}`}`}>
          <div className='flex flex-col w-full items-center py-[10px]'>
            <img
              src={(focus === 'setup' || focus === 'global') ? '/sidebar/setup_icon.svg' : '/sidebar/setup_icon_disabled.svg'}
              alt="Setup Icon"
              className='w-[30px] pb-[6px]'
            />
            <p className={`text-xs ${(focus === 'setup' || focus === 'global') ? 'text-white' : 'text-slate-400'}`}>Setup</p>
          </div>
        </Link>
        )}

      </div>

      <div className='flex w-full h-28 justify-center items-center'>
        <div
          className='flex flex-col w-full items-center cursor-pointer'
          onClick={()=>{
            logout();
          }}
        >
          <img src="/sidebar/logout_icon.svg" alt="Logout Icon" className='w-[27px] pb-[6px]' />
          <p className='text-xs text-white'>Logout</p>
        </div>
      </div>
    </div>
  )
}

export default Sidebar;
