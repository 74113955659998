import React, { useState } from 'react'
import { useSchedulerJobLog } from '../../contexts/schedulerJobLog'

const ForemanSelector = ({ setIsChangingForeman, noAvailableForemen }) => {
  const { locationForemen, setSelectedForeman } = useSchedulerJobLog()

  const onChange = (e) => {
    e.preventDefault()
    const newForeman = locationForemen.find(foreman => foreman.user.id == e.target.value)
    if (newForeman){
      setSelectedForeman(newForeman.user)
      setIsChangingForeman(false)
    }
  }

  return (
    <select
      onClick={onChange}
      disabled={noAvailableForemen}
    >
      {locationForemen.map((foreman, i) => {
        return <option value={foreman.user.id} key={`cs${i}`}>
          {foreman.user.name}
        </option>
      })}
    </select>
  )
}

export default ForemanSelector
