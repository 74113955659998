import { useScheduler } from "../../contexts/scheduler";
import { Fragment, useEffect, useState } from "react";
import { formatDateLong } from "../../utils";

const ActivityLogMain = ({displayItems, selectedJob}) => {
  let lastDate = null;

  const formatDay = (dateStr) => {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC',
    }).format(new Date(dateStr));
  };

  const formatTime = (date) => {
    const formatted = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: 'UTC',
    }).format(new Date(date));

    const [hour, rest] = formatted.split(':');
    return `${hour.padStart(2, '0')}:${rest}`;
  };

  const makeActivityRowContents = (activityItem) => {
    let formattedScheduledDateTime = null
    switch (activityItem.activityType) {
      case "intake":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/inbox.svg' alt='intake' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">intake form submitted</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "backlog":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/assignment.svg' alt='backlog' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">job moved to backlog</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "ready":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/event_available.svg' alt='ready' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">job marked as ready for calendar</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "scheduled":
        formattedScheduledDateTime = activityItem.metadata?.scheduledDateTime ? formatDateLong(activityItem.metadata?.scheduledDateTime) : activityItem.metadata?.scheduledDateTime

        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/calendar_month.svg' alt='scheduled' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">job scheduled</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>to</p>
            <p className="text-[14px] font-bold text-dark-blue ml-[7px]">{formattedScheduledDateTime}</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "rescheduled":
        formattedScheduledDateTime = activityItem.metadata?.scheduledDateTime ? formatDateLong(activityItem.metadata?.scheduledDateTime) : activityItem.metadata?.scheduledDateTime

        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/event_upcoming.svg' alt='rescheduled' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">rescheduled</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>to</p>
            <p className="text-[14px] font-bold text-dark-blue ml-[7px]">{formattedScheduledDateTime}</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "flashingScheduled":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/calendar_month.svg' alt='flashing scheduled' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">flashing scheduled</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "guniteCompleted":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/check_circle.svg' alt='gunite completed' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">gunite completed</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "plasterCompleted":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/check_circle.svg' alt='plaster completed' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">plaster completed</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "completed":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/JobDetails/archived_icon.svg' alt='completed' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">job cost breakdown completed</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "workCompleted":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/JobDetails/completed_icon.svg' alt='workCompleted' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">job work marked as completed</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "archive":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/archived.svg' alt='archived' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">archived</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "unarchive":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/archived.svg' alt='archived' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">job removed from archive</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "poolPlanUploaded":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/assignment_returned.svg' alt='pool plan uploaded' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">pool plan uploaded</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "assignedCrew":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/groups.svg' alt='assigned crew' className="ml-[10px] w-[19px]"/>
            <p className="text-[14px] font-bold text-dark-blue ml-[8.5px]">job assigned</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>to</p>
            <p className="text-[14px] font-bold text-dark-blue ml-[7px]">{activityItem.metadata?.foremanName}</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "assignedDrivers":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/search_hands_free.svg' alt='assigned drivers' className="ml-[12px]"/>
            <p className="text-[14px] font-bold text-dark-blue ml-[10px]">job assigned</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>to</p>
            <p className="text-[14px] font-bold ml-[7px] text-dark-blue]">{activityItem.metadata?.driverNames.join(', ')}</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "jobDetailsEdited":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/edit.svg' alt='job details edited' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">job details edited</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "jobTransferred":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/search_hands_free.svg' alt='job transferred' className="ml-[12px]"/>
            <p className="text-[14px] font-bold text-dark-blue ml-[10px]">job transferred</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>to</p>
            <p className="text-[14px] font-bold ml-[7px] text-dark-blue]">{activityItem.metadata?.newLocationLabel}</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "dailyJobLogSubmitted":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/assignment_returned.svg' alt='daily job log submitted' className="ml-[12px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[10px]">daily job log submitted</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      case "costBreakdownSubmitted":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/monetization_on.svg' alt='cost breakdown submitted' className="ml-[8.5px]"/>
            <p className="text-[14px] font-bold ml-1.5 text-dark-blue ml-[8.5px]">cost breakdown submitted</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>by</p>
            <p className='text-[14px] text-gray-blue ml-[7px]'>{activityItem.performedBy.name}</p>
          </span>
        )
      default:
        return
    }
  }

  const makeDayRow = (date) => {
    return (
      <span className="py-3 flex flex-row justify-center items-center">
        <div className="border-b border-navy-light h-[1px] flex-grow"/>
          <p className='px-3 text-[14px] text-gray-blue'>{formatDay(date)}</p>
        <div className="border-b border-navy-light h-[1px] flex-grow"/>
      </span>
    )
  }

  const makeActivityRow = (activityItem) => {
    const activityRowContents = makeActivityRowContents(activityItem);

    // If makeActivityRowContents returns null, don't render anything
    if (!activityRowContents) {
      return null;
    }

    return (
      <span className="py-1.5 w-full flex flex-row items-center justify-start">
        <p className='text-[14px] text-gray-blue'>{formatTime(activityItem.activityDateTime)}</p>
        <p className="text-[15px] font-extrabold ml-[9px] text-dark-blue">
          {selectedJob ? selectedJob.jobName : activityItem.jobName}
        </p>
        {activityRowContents}
      </span>
    );
  };

  return (
    <div className="flex flex-col py-5 px-4 ">
      {displayItems.length === 0 &&
        <div className="flex justify-center items-center pt-3 h-16">
          <p className="text-gray-blue text-sm">No activity to display</p>
        </div>
      }
      {displayItems.map((activityItem, index) => {
        let activityDate = new Date(activityItem.activityDateTime).toUTCString();
        activityDate = activityDate.substring(0, activityDate.length - 13)

        const newDay = lastDate !== activityDate;
        lastDate = activityDate;

        return (
          <Fragment key={index}>
            {newDay && makeDayRow(activityItem.activityDateTime)}
            {makeActivityRow(activityItem)}
          </Fragment>
        );
      })}
    </div>
  )
}

export default ActivityLogMain;