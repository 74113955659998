import React, { useState, useEffect } from "react";

const CostBreakdownSubmittedInvoice = ({ savedCostBreakdown }) => {

  const calculateTotal = () => {
    const total = savedCostBreakdown?.invoice?.reduce((accumulator, item) => {
      const amount = item.amount ? Number(item.amount) : 0
      const price = item.price ? Number(item.price) : 0
      return accumulator += (amount * price)
    }, 0)

    return Number(total).toFixed(2)
  }

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      <div className='hidden md:flex w-full text-opaque-job-details-gray'>
        <div className='w-1/2'>
          <p className='pl-7'>Product or service</p>
        </div>

        <div className='flex w-1/2'>
          <p className='flex justify-end w-1/3'>Amount</p>
          <p className='flex justify-end w-1/3'>Price</p>
          <p className='flex justify-end w-1/3'>Total</p>
        </div>
        <div className='w-[32px]'></div>
      </div>

      {savedCostBreakdown?.invoice?.map((invoiceItem, i) => {
        return (
          <div
            className={`flex flex-col md:flex-row w-full py-4 md:items-center text-dark-blue ${savedCostBreakdown.invoice.length === i + 1 ? '' : 'border-b'}`}
            key={`invoice${i + 1}`}
          >
            <div className='flex md:w-1/2'>

              <p className='flex pr-4 items-center text-opaque-job-details-gray'>{i + 1}.</p>
              <div className='flex flex-col'>
                <p className='font-semibold'>{invoiceItem.name !== 'Plaster' ? (invoiceItem.name || '') : `${invoiceItem.manufacturer} - ${invoiceItem.color}`}</p>
                <p className={`text-opaque-job-details-gray ${invoiceItem.description.length !== 0 ? '' : 'hidden'}`}>
                  {invoiceItem.description}
                </p>
              </div>

            </div>

            <div className='flex flex-col md:flex-row md:w-1/2 justify-between '>

              <div className='flex w-full md:w-1/3 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Amount:</p>
                <p className='flex justify-end w-full items-center'>
                  {invoiceItem.amount || ''}
                </p>
              </div>

              <div className='flex w-full md:w-1/3 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Price:</p>
                <p className='flex justify-end w-full items-center'>
                  ${(invoiceItem.price ? Number(invoiceItem.price) : 0).toFixed(2)}
                </p>
              </div>

              <div className='flex w-full md:w-1/3 md:border-none'>
                <p className='w-1/2 md:hidden'>Total:</p>
                <p className='flex justify-end w-full items-center'>
                  ${(Number(invoiceItem.amount) * Number(invoiceItem.price)).toFixed(2)}
                </p>
              </div>

            </div>

            <div className='w-[32px]'></div>

          </div>
        )
      })}

      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>

        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Invoice total
          </p>
          <div className='flex justify-end w-[100px]'>
            <p className='w-1/5 flex justify-end'>$</p>
            <p className='w-4/5 flex justify-end'>{calculateTotal()}</p>
          </div>
        </div>

      </div>

    </div>
  );
};

export default CostBreakdownSubmittedInvoice;
