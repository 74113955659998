import React, { useState, useRef, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import { useModal } from '../../../contexts/modal'
import { useForeman } from '../../../contexts/foreman'
import { useAuth } from '../../../contexts/auth'
import JobSelector from '../../../components/JobSelector'
import SelectPersonnel from '../../../components/SelectPersonnel'
import service from '../../../service'
import DailyJobLogPhoto from '../../../components/DailyJobLogPhoto'
import AskIfContinueModal from '../../../components/AskIfContinueModal'

const ForemanDailyJobLog = () => {
  const { me, setMe } = useAuth()
  const { language, setLanguage, todaysJobs, selectedCalendarJob, setSelectedCalendarJob,
    selectedCrewMembers, setSelectedCrewMembers, locationCrewMembers, locationDrivers,
    selectedDrivers, setSelectedDrivers, DEFAULT_JOB, personnelLocation, allPersonnel,
    updateLocationPersonnel, deletedLocationCrewMembers, setDeletedLocationCrewMembers,
    deletedLocationDrivers, setDeletedLocationDrivers,
  } = useForeman()
  const { modalMethods } = useModal()
  const [startTime, setStartTime] = useState(null) //Tied to the 'value' field of the time inputs.  Looks like '07:00', '13:00', etc.
  const [endTime, setEndTime] = useState(null) //Tied to the 'value' field of the time inputs.  Looks like '07:00', '13:00', etc.
  const [yards, setYards] = useState(null)
  const [spa, setSpa] = useState(null)
  const [steps, setSteps] = useState(null)
  const [benches, setBenches] = useState(null)
  const [wallFittings, setWallFittings] = useState(null)
  const [mainDrains, setMainDrains] = useState(null)
  const [guniteComments, setGuniteComments] = useState(null)
  const [plasterComments, setPlasterComments] = useState(null)
  const [batches, setBatches] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const crewMemberOrDriver = useRef(null)
  const hiddenFileInput = useRef(null)
  const previousSelectedCalendarJob = useRef(null)
  const apiUrl = process.env.REACT_APP_API_URL;

  const createNewJobLog = async (e) => {
    e.preventDefault()
    const jobDetails = {
      calendarJobId: selectedCalendarJob.id,
      userId: me.id
    }
    const res = await service.createNewJobLogForeman(jobDetails)
    setMe(res.me)
    setSelectedCrewMembers([])
    setSelectedDrivers([])
    setYards(null)
    setBatches(null)
    setStartTime(null)
    setEndTime(null)
    setIsEditing(true)
    setSelectedCalendarJob(res.updatedCalendarJob)
    setSpa(null)
    setSteps(null)
    setBenches(null)
    setWallFittings(null)
    setMainDrains(null)
    setGuniteComments(null)
    setPlasterComments(null)
  }

  const handleUpload = async (e) => {
    const filesUploaded = e.target.files
    const formData = new FormData()
    if (filesUploaded) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/svg', 'image/gif', 'image/jpg', 'image/heic', 'application/pdf'];
      for (const file of filesUploaded) {
        if (allowedTypes.includes(file.type)) {
          formData.append('attachment', file)
        }
      }
    }
    formData.append('jobId', selectedCalendarJob.job.id)
    formData.append('userId', me.id) //used as foremanId for JobLog and creatorId for JobFile.  Note: this is the id in the USER table, not the FOREMAN table
    formData.append('jobLogId', selectedCalendarJob.jobLog.id)
    formData.append('calendarJobId', selectedCalendarJob.id)
    const res = await service.uploadPhotosToJobLog(formData);
    setSelectedCalendarJob({
      ...selectedCalendarJob,
      jobLog: {
        ...selectedCalendarJob.jobLog,
        startTime: startTime,
        endTime: endTime,
        yards,
        batches,
        crewMembers: selectedCrewMembers,
        drivers: selectedDrivers,
        finishedJobPhotos: res.updatedCalendarJob.jobLog.finishedJobPhotos,
        spa,
        steps,
        benches,
        wallFittings,
        mainDrains,
        guniteComments,
        plasterComments
      }
    });
  }

  const deletePhoto = async (id) => {
    const photoDetails = { photoId: id, calendarJobId: selectedCalendarJob.id }
    const res = await service.markPoWPhotoDeleted(photoDetails)
    setSelectedCalendarJob({
      ...selectedCalendarJob,
      jobLog: {
        ...selectedCalendarJob.jobLog,
        startTime: startTime,
        endTime: endTime,
        yards,
        batches,
        crewMembers: selectedCrewMembers,
        drivers: selectedDrivers,
        finishedJobPhotos: res.updatedCalendarJob.jobLog.finishedJobPhotos
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // checks if all other calJobs have dailyJobLogs
    let moveToWorkCompleted = true
    me.foreman[0].crew.location.jobs.forEach((job) => {
      if (job.id === selectedCalendarJob.jobId) {
        job.calendarJobs.forEach((calJob) => {
          if (calJob.id !== selectedCalendarJob.id && (calJob.jobLog === null || calJob.jobLog.submittedAt === null)) {
            moveToWorkCompleted = false
          }
        })
      }
    })

    const scheduledDate = new Date(selectedCalendarJob.scheduledDate) //date object
    const startTimeString = scheduledDate.setUTCHours(parseInt(startTime)) //string
    const startTimeUTC = new Date(startTimeString).toUTCString() //string
    const endTimeString = scheduledDate.setUTCHours(parseInt(endTime)) //string
    const endTimeUTC = new Date(endTimeString).toUTCString() //string

    const formDetails = {
      userId: me.id,
      jobLogId: selectedCalendarJob.jobLog.id,
      scheduledDate: selectedCalendarJob.scheduledDate,
      startTime: startTimeUTC,
      endTime: endTimeUTC,
      yards: yards,
      batches: batches,
      crewMembers: selectedCrewMembers,
      drivers: selectedDrivers,
      moveToWorkCompleted: moveToWorkCompleted,
      spa: spa,
      steps: steps,
      benches: benches,
      wallFittings: wallFittings,
      mainDrains: mainDrains,
      guniteComments: guniteComments,
      plasterComments: plasterComments
    }
    const res = await service.submitJobLogForeman(formDetails)
    setMe(res.me)
    setIsEditing(false)
  }

  const askIfContinue = (calendarJobId, previousCalendarJob) => {
    if (isEditing) {
      modalMethods.setContent(
        <AskIfContinueModal
          chooseCalendarJob={chooseCalendarJob}
          calendarJobId={calendarJobId}
          previousCalendarJob={previousCalendarJob}
          setSelectedCalendarJob={setSelectedCalendarJob}
          setIsEditing={setIsEditing}
        />)
      modalMethods.open()
    } else {
      chooseCalendarJob(calendarJobId)
    }
  }

  const chooseCalendarJob = (calendarJobId) => {
    if (calendarJobId == 0) {
      setSelectedCalendarJob(DEFAULT_JOB)
    } else {
      const newSelectedCalendarJob = todaysJobs.find(calendarJob => calendarJob.id === parseInt(calendarJobId))
      setSelectedCalendarJob(newSelectedCalendarJob)
      if (newSelectedCalendarJob?.jobLog?.id) {
        const newSelectedCrewMembers = newSelectedCalendarJob.jobLog.crewMembers.map(object => object.crewMember)
        setSelectedCrewMembers(newSelectedCrewMembers)
        const newSelectedDrivers = newSelectedCalendarJob.jobLog.drivers.map(object => object.driver)
        setSelectedDrivers(newSelectedDrivers)
        setYards(newSelectedCalendarJob.jobLog.yards)
        setBatches(newSelectedCalendarJob.jobLog.batches)
        const timeOptions = {
          hour12: false,
        }
        setSpa(newSelectedCalendarJob.jobLog.spa)
        setSteps(newSelectedCalendarJob.jobLog.steps)
        setBenches(newSelectedCalendarJob.jobLog.benches)
        setWallFittings(newSelectedCalendarJob.jobLog.wallFittings)
        setMainDrains(newSelectedCalendarJob.jobLog.mainDrains)
        setGuniteComments(newSelectedCalendarJob.jobLog.guniteComments)
        setPlasterComments(newSelectedCalendarJob.jobLog.plasterComments)
        if (newSelectedCalendarJob.jobLog.startTime) {

          setStartTime(newSelectedCalendarJob.jobLog.startTime.substring(newSelectedCalendarJob.jobLog.startTime.length - 12, newSelectedCalendarJob.jobLog.startTime.length - 7))
        } else {
          setStartTime(null)
        }
        if (newSelectedCalendarJob.jobLog.endTime) {
          setEndTime(newSelectedCalendarJob.jobLog.endTime.substring(newSelectedCalendarJob.jobLog.endTime.length - 12, newSelectedCalendarJob.jobLog.endTime.length - 7))
        } else {
          setEndTime(null)
        }
      } else {
        setSelectedCrewMembers([])
        setSelectedDrivers([])
        setYards(null)
        setBatches(null)
        setStartTime(null)
        setEndTime(null)
        setSpa(null)
        setSteps(null)
        setBenches(null)
        setWallFittings(null)
        setMainDrains(null)
        setGuniteComments(null)
        setPlasterComments(null)
      }
    }
  }


  const yardsOrBatchesInput = () => {
    if (selectedCalendarJob.job.jobType === 'gunite') {
      return <>
        <label
          htmlFor='yards'
          className='text-sm text-gray-blue'
        >{language === 'English' ? 'Yards' : 'Yardas'}</label>
        <input
          id='yards'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={yards || 0}
          onChange={(e) => {
            e.preventDefault()
            setYards(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='spa'
          className='text-sm text-gray-blue'
        >Spa</label>
        <input
          id='spa'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={spa || ''}
          onChange={(e) => {
            e.preventDefault()
            setSpa(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='steps'
          className='text-sm text-gray-blue'
        >Steps</label>
        <input
          id='steps'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={steps || ''}
          onChange={(e) => {
            e.preventDefault()
            setSteps(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='benches'
          className='text-sm text-gray-blue'
        >Benches</label>
        <input
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={benches || ''}
          onChange={(e) => {
            e.preventDefault()
            setBenches(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label htmlFor="guniteComments" className="text-sm text-gray-blue">
          Comments
        </label>
        <textarea
          id='guniteComments'
          className={`w-full border rounded resize-none ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={guniteComments || ''}
          onChange={(e) => {
            e.preventDefault();
            setGuniteComments(e.target.value);
            e.target.style.height = 'auto';
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          disabled={!isEditing}
          rows={1}
          style={{ overflow: 'hidden' }}
        />
      </>
    } else {
      return <>
        <label
          htmlFor='batches'
          className='text-sm text-gray-blue'
        >{language === 'English' ? 'Batches' : 'Lotes'}</label>
        <input
          id='batches'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={batches || 0}
          onChange={(e) => {
            e.preventDefault()
            setBatches(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='wallFittings'
          className='text-sm text-gray-blue'
        >Wall Fittings</label>
        <input
          id='wallFittings'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          onChange={(e) => {
            e.preventDefault()
            setWallFittings(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label
          htmlFor='mainDrains'
          className='text-sm text-gray-blue'
        >Main Drains</label>
        <input
          id='mainDrains'
          className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          onChange={(e) => {
            e.preventDefault()
            setMainDrains(e.target.value)
          }}
          disabled={!isEditing}
        />

        <label htmlFor="plasterComments" className="text-sm text-gray-blue">
          Comments
        </label>
        <textarea
          id='plasterComments'
          className={`w-full border rounded resize-none ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
          value={plasterComments || ''}
          onChange={(e) => {
            e.preventDefault();
            setPlasterComments(e.target.value);
            e.target.style.height = 'auto';
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          disabled={!isEditing}
          rows={1}
          style={{ overflow: 'hidden' }}
        />
      </>
    }
  }

  const submitOrEditButton = () => {
    if (isEditing) {
      return <button
        type='button'
        className={`w-full p-4 font-semibold rounded text-lg ${errorChecker() ? 'bg-black/10 text-black/40' : 'bg-dark-blue text-white'}`}
        onClick={handleSubmit}
        disabled={errorChecker()}
      >
        {language === 'English' ? 'Submit' : 'Entregar'}
      </button>
    } else {
      return <button
        type='button'
        onClick={() => { setIsEditing(true) }}
        className={`w-full p-4 font-semibold rounded text-lg bg-dark-blue text-white`}>
        {language === 'English' ? 'Click to edit' : 'Haz click para editar'}
      </button>
    }
  }

  useEffect(() => {
    if (crewMemberOrDriver.current === 'crewMember') {
      modalMethods.setContent(<SelectPersonnel
        personnel={locationCrewMembers}
        locationPersonnel={locationCrewMembers}
        jobPersonnel={selectedCrewMembers}
        setJobPersonnel={setSelectedCrewMembers}
        allPersonnel={allPersonnel}
        deletedPersonnel={deletedLocationCrewMembers}
        setDeletedPersonnel={setDeletedLocationCrewMembers}
        crewMembersOrDrivers={'crewMembers'}
        personnelLocation={personnelLocation}
        updateLocationPersonnel={updateLocationPersonnel}
      />)
    } else {
      modalMethods.setContent(<SelectPersonnel
        personnel={locationDrivers}
        locationPersonnel={locationDrivers}
        jobPersonnel={selectedDrivers}
        setJobPersonnel={setSelectedDrivers}
        allPersonnel={allPersonnel}
        deletedPersonnel={deletedLocationDrivers}
        setDeletedPersonnel={setDeletedLocationDrivers}
        crewMembersOrDrivers={'drivers'}
        personnelLocation={personnelLocation}
        updateLocationPersonnel={updateLocationPersonnel}
      />)
    }
  }, [selectedCrewMembers, selectedDrivers, locationCrewMembers, locationDrivers,
    setSelectedCrewMembers, setSelectedDrivers, crewMemberOrDriver,
    deletedLocationCrewMembers, deletedLocationDrivers
  ])

  const errorChecker = () => {
    if (startTime === null ||
      endTime === null ||
      selectedCrewMembers.length === 0 ||
      (selectedCalendarJob.job.jobType === 'gunite' && selectedDrivers.length === 0)
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (
      previousSelectedCalendarJob.current !== selectedCalendarJob
      && selectedCalendarJob?.jobLog?.id
    ) {
      if (selectedCalendarJob?.jobLog?.startTime && selectedCalendarJob.jobLog.startTime !== startTime) {
        setStartTime(selectedCalendarJob.jobLog.startTime.substring(selectedCalendarJob.jobLog.startTime.length - 12, selectedCalendarJob.jobLog.startTime.length - 7))
      } else if (!startTime) {
        setStartTime(null)
      }
      if (selectedCalendarJob?.jobLog?.endTime && selectedCalendarJob.jobLog.endTime !== endTime) {
        setEndTime(selectedCalendarJob.jobLog.endTime.substring(selectedCalendarJob.jobLog.endTime.length - 12, selectedCalendarJob.jobLog.endTime.length - 7))
      } else if (!endTime) {
        setEndTime(null)
      }
      setYards(selectedCalendarJob.jobLog.yards)
      setBatches(selectedCalendarJob.jobLog.batches)
      setSelectedDrivers(selectedCalendarJob.jobLog.drivers.map(d => d?.driver ? d.driver : d))
      setSelectedCrewMembers(selectedCalendarJob.jobLog.crewMembers.map(c => c?.crewMember ? c.crewMember : c))
      previousSelectedCalendarJob.current = selectedCalendarJob
    }
  }, [selectedCalendarJob])

  return (
    <div className='overflow-auto w-full'>
      <div className='flex flex-col gap-3 w-full p-4 lg:p-0 lg:w-2/3 lg: mx-auto lg:my-4 lg:border lg:rounded-2xl lg:border-navy-light'>
        <div className='flex flex-col gap-3 lg:gap-0'>
          <div className='bg-navy-bright/30 hidden lg:inline rounded-t-2xl border-b border-navy-light p-6'>
            <p className='text-dark-blue text-xl font-semibold'>{language === 'English' ? 'Daily Job Log' : 'Registro Diario de Trabajo'}</p>
          </div>
          {todaysJobs[0].id //condition checks for existence of an actual calendar job in the array of todaysJobs (DEFAULT_JOB is not in the array)
            ?
            <>
              <div className='flex flex-col gap-4 items-start lg:flex-row-reverse justify-between lg:px-6 lg:pt-6'>
                <div className='flex p-[2px] rounded-[6px] bg-navy-bright/10 text-sm font-bold'>
                  <button
                    className={`px-4 py-3 rounded ${language === 'English' ? 'bg-navy text-white' : ''}`}
                    onClick={() => setLanguage('English')}
                  >English</button>
                  <button
                    className={`px-4 py-3 rounded ${language === 'Spanish' ? 'bg-navy text-white' : ''}`}
                    onClick={() => setLanguage('Spanish')}
                  >Español</button>
                </div>
                <JobSelector
                  todaysJobs={todaysJobs}
                  selectedCalendarJob={selectedCalendarJob}
                  askIfContinue={askIfContinue}
                  language={language} />
              </div>
              <div className='contents lg:flex lg:flex-col lg:gap-6 lg:p-6'>

                {selectedCalendarJob?.jobLog?.id //if an actual jobLog exists for selectedJob, render the rest of the form.
                  ?
                  <>
                    <div className='flex justify-between'>
                      <div>
                        <p className='text-xl text-dark-blue font-bold'>{selectedCalendarJob?.job?.jobName}</p>
                        <p className='text-gray-blue'>{selectedCalendarJob?.job?.address}</p>
                      </div>

                    </div>
                    <div className='flex flex-col gap-2'>
                      <div className='flex'>
                        <div className='w-1/2'>
                          <p className='text-sm text-gray-blue'>{language === 'English' ? 'Date' : 'Fecha'}</p>
                          <p className='font-semibold text-dark-blue'>{selectedCalendarJob.scheduledDate.substring(5, 7).concat('.' + selectedCalendarJob.scheduledDate.substring(8, 10) + '.' + selectedCalendarJob.scheduledDate.substring(0, 4))
                          }</p>
                        </div>
                        <div className='w-1/2'>
                          <p className='text-sm text-gray-blue'>{language === 'English' ? 'Foreman' : 'Capataz'}</p>
                          <p className='font-semibold text-dark-blue'>{me.name}</p>
                        </div>
                      </div>
                    </div>
                    <form className='flex flex-col gap-2'>
                      <button
                        className={`w-full p-4 font-semibold rounded text-lg ${isEditing ? 'bg-dark-blue text-white' : 'bg-black/10 text-black/40'}`}
                        disabled={!isEditing}
                        onClick={(e) => {
                          e.preventDefault()
                          crewMemberOrDriver.current = 'crewMember'
                          modalMethods.setContent(<SelectPersonnel
                            personnel={locationCrewMembers}
                            jobPersonnel={selectedCrewMembers}
                            setJobPersonnel={setSelectedCrewMembers}
                            allPersonnel={allPersonnel}
                            deletedPersonnel={deletedLocationCrewMembers}
                            setDeletedPersonnel={setDeletedLocationCrewMembers}
                            crewMembersOrDrivers={'crewMembers'}
                            personnelLocation={personnelLocation}
                            updateLocationPersonnel={updateLocationPersonnel}

                          />)
                          modalMethods.open()

                        }}>{language === 'English' ? 'Select Crew Members' : 'Seleccionar miembros de la tripulación'}</button>
                      {selectedCalendarJob.job.jobType === 'gunite'
                        ?
                        <button
                          className={`w-full p-4 font-semibold rounded text-lg ${isEditing ? 'bg-dark-blue text-white' : 'bg-black/10 text-black/40'}`}
                          disabled={!isEditing}
                          onClick={(e) => {
                            e.preventDefault()
                            crewMemberOrDriver.current = 'driver'
                            modalMethods.setContent(<SelectPersonnel
                              personnel={locationDrivers}
                              jobPersonnel={selectedDrivers}
                              setJobPersonnel={setSelectedDrivers}
                              allPersonnel={allPersonnel}
                              deletedPersonnel={deletedLocationDrivers}
                              setDeletedPersonnel={setDeletedLocationDrivers}
                              crewMembersOrDrivers={'drivers'}
                              personnelLocation={personnelLocation}
                              updateLocationPersonnel={updateLocationPersonnel}

                            />)
                            modalMethods.open()
                          }}
                        >{language === 'English' ? 'Select Drivers' : 'Seleccionar manejadores'}</button>
                        :
                        null
                      }
                      <p>{language === 'English' ? 'Selected Crew Members' : 'Miembros de la tripulación seleccionados'}: {selectedCrewMembers.map((crewMember) => crewMember.user.name).join(', ')}</p>
                      {selectedCalendarJob.job.jobType === 'gunite'
                        ?
                        <p>{language === 'English' ? 'Selected Drivers' : 'Manejadores seleccionados'}: {selectedDrivers.map((driver) => driver.user.name).join(', ')}</p>
                        :
                        null
                      }
                      <label
                        htmlFor='startTime'
                        className='text-sm text-gray-blue'
                      >{language === 'English' ? 'Start Time' : 'Hora de Inicio'}</label>
                      <input
                        type='time'
                        id='startTime'
                        className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                        list='suggestedTimes'
                        value={startTime || ''}
                        onChange={(e) => {
                          e.preventDefault()
                          setStartTime(e.target.value)
                        }}
                        disabled={!isEditing}
                      />
                      <datalist id='suggestedTimes'>
                        <option value='07:00'></option>
                        <option value='08:00'></option>
                        <option value='09:00'></option>
                        <option value='10:00'></option>
                        <option value='11:00'></option>
                        <option value='12:00'></option>
                        <option value='13:00'></option>
                        <option value='14:00'></option>
                        <option value='15:00'></option>
                        <option value='16:00'></option>
                        <option value='17:00'></option>
                        <option value='18:00'></option>
                        <option value='19:00'></option>
                      </datalist>
                      <label
                        htmlFor='endTime'
                        className='text-sm text-gray-blue'
                      >{language === 'English' ? 'End Time' : 'Hora de Finalización'}</label>
                      <input
                        type='time'
                        id='endTime'
                        className={`w-full border rounded ${isEditing ? '' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
                        list='suggestedTimes'
                        value={endTime || ''}
                        onChange={(e) => {
                          e.preventDefault()
                          setEndTime(e.target.value)
                        }}
                        disabled={!isEditing}
                      />
                      {yardsOrBatchesInput()}
                      {isEditing
                        ?
                        <>
                          <p className='text-sm text-gray-blue'>{language === 'English' ? 'Photos of Completed Work' : 'Fotos del Trabajo Completado'}</p>
                          <input
                            type='file'
                            multiple={true}
                            className='hidden'
                            onChange={handleUpload}
                            ref={hiddenFileInput}
                          />
                          <button
                            className='w-full py-10 flex flex-col gap-4 items-center rounded border-dashed border-2 border-black/30'
                            onClick={(e) => {
                              e.preventDefault()
                              hiddenFileInput.current.click()
                            }}
                          >
                            <img src='/vectors/photoUploadIcon.svg' />
                            <div>
                              <p className='font-semibold text-sm text-dark-blue tracking-wide'>{language === 'English' ? 'Upload Photo' : 'Subir Foto'}</p>
                              <p className='font-light text-gray-blue text-sm'>{language === 'English' ? 'of a finished job' : 'de un trabajo terminado'}</p>
                            </div>
                          </button>
                        </>
                        :
                        null
                      }
                      {selectedCalendarJob.jobLog.finishedJobPhotos.length ? <>
                        <p className='text-sm text-gray-blue'>{language === 'English' ? 'Uploaded Photos' : 'Fotos Subidas'}</p>
                        <div className='flex flex-wrap items-center'>
                          {selectedCalendarJob.jobLog.finishedJobPhotos.map((photo, i) => {
                            return <DailyJobLogPhoto apiUrl={apiUrl} fileUri={photo.fileUri} key={`pow${i}`} id={photo.id} deletePhoto={deletePhoto} isEditing={isEditing} />
                          })}
                        </div>
                      </>
                        :
                        null
                      }
                      {submitOrEditButton()}
                    </form>
                  </>
                  :
                  <>
                    {
                      selectedCalendarJob.job.id
                        ?
                        <div className='flex flex-col gap-2 items-start'>
                          <p>{language === 'English' ? 'No log exists for this job.' : 'No existe ningún registro para este trabajo'}</p>
                          <button
                            className='p-4 bg-dark-blue text-white font-semibold rounded text-xl'
                            onClick={createNewJobLog}
                          >{language === 'English' ? 'Create job log' : 'Crear registro de trabajo'}</button>
                        </div>
                        :
                        <></>
                    }
                  </>
                }
              </div>
            </>
            :
            <div className='p-6'>
              <p className='text-dark-blue text-xl font-semibold lg:hidden'>Daily Job Log</p>
              <div className='h-[1px] bg-navy-bright/30 my-2 lg:hidden' />
              <p>There are no jobs scheduled for today.</p>
              <p>(No hay trabajo en el horario para el día de hoy.)</p>
            </div>
          }

        </div>
      </div>
    </div>
  )
}

export default ForemanDailyJobLog
