import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DriverView from '../DriverView'
import DriverOnboard from '../DriverOnboard'

const DriverLayout = () => {
  return (
    <div className='flex flex-col lg:flex-row h-auto w-screen'>
        <Routes>
          <Route exact={true} path='/onboard' element={<DriverOnboard />} />
          <Route exact={true} path='/' element={<DriverView />} />
        </Routes>
    </div>
  )
}

export default DriverLayout
