import React from 'react';
import cookies from 'browser-cookies'
import './index.scss';
import { useProgress } from '../../../contexts/progress';

const RequestSubmitted = () => {
  const { navigateToStart } = useProgress()
    return (
        <div className='flex flex-col items-center req_sub_outer_container p-16'>
            <div className='font-bold text-secondary-blue text-[34px] md:text-[60px] req_sub_header_top'>
                Schedule Request
            </div>
            <div className='font-bold text-secondary-blue text-[34px] md:text-[60px] req_sub_header_bot'>
                Submitted
            </div>

            <div className='mt-[60px] req_sub_check_container'>
                <img src='/green_check_circle.svg' />
            </div>

            <div className='flex flex-col items-center text-base text-secondary-blue mt-5 req_sub_body_text_container'>
                <div className='req_sub_body_text_one'>
                    Your schedule request has been submitted,
                </div>
                <div className='req_sub_body_text_two'>
                    and someone will be in touch with you
                </div>
                <div className='req_sub_body_text_three'>
                    shortly.
                </div>
                <div className='mt-6 req_sub_body_text_four'>
                    Thank you!
                </div>
            </div>

            <div className='mt-[60px] py-3 px-9 bg-gunite-red text-all-f-white rounded req_sub_schedule_button_container'>
                <button
                    className='text-2xl font-bold req_sub_schedule_button'
                    onClick={()=>{
                        cookies.erase('currentPosition')
                        cookies.erase('currentService')
                        navigateToStart()
                    }}>
                    Schedule New Job
                </button>
            </div>
        </div>
    )
}


export default RequestSubmitted;
