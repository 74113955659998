import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const PaginationFooterBar = ({ currentPage, setCurrentPage, allItems, resultsPerPage, setSortAscending }) => {

  const handleBackClick = () => {
    if (currentPage < 2) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  }
  const handleForwardClick = () => {
    const maxPages = Math.ceil(allItems.length / resultsPerPage);
    if (currentPage >= maxPages) return;
    setCurrentPage(currentPage + 1);
  }

  const startItem = allItems?.length > 0 ? 1 + (resultsPerPage * (currentPage - 1)) : 0;
  const endItem = allItems?.length > 0 ? Math.min(currentPage * resultsPerPage, allItems?.length) : 0;

  return (
    <div className="flex justify-between items-center px-4 h-16 bg-opacity-10 bg-navy-bright bg-navy-bright rounded-b-xl border border-navy-light">
      <div></div>
      <div className="flex flex-row justify-center items-center select-none">
        <p className="text-gray-blue mr-3">
          {startItem} - {endItem} &nbsp; of &nbsp; {allItems?.length}
        </p>
        <FaChevronLeft onClick={handleBackClick} className="cursor-pointer h-4 w-4 inline mr-2 text-gray-blue" />
        <FaChevronRight onClick={handleForwardClick} className="cursor-pointer h-4 w-4 inline text-gray-blue" />
      </div>
    </div>
  );
}

export default PaginationFooterBar;
