import React, { useRef, useState, useEffect } from 'react'
import { useModal } from '../../contexts/modal'
import QRCode from 'react-qr-code'
import { useReactToPrint } from 'react-to-print';
import service from '../../service';


const QRCodeModal = ({ employee }) => {
  const printRef = useRef()
  const { modalMethods } = useModal()

  const [ link, setLink ] = useState(null)
  const [ hasFetched, setHasFetched ] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(()=>{
    const getLink = async ()=>{
      setHasFetched(true)
      const newLink = await service.getForemanLoginLink(employee.email)
      setLink(newLink)
    }
    if (!link && !hasFetched) {
      getLink()
    }
  },[hasFetched, setLink, setHasFetched])


  return (
    <div className='relative bg-white p-6 font-roboto'>
      <button onClick={(e) => {
        e.preventDefault()
        modalMethods.close()
      }}>
        <img src='/x_icon_black.svg' className='w-[30px] absolute right-[12px] top-[12px]' />
      </button>
      <div className="px-[300px] py-[150px]" ref={printRef}>
        { link && (
          <>
            <QRCode value={link} />
          </>
        ) }
      </div>
      <div className='flex justify-center pb-[25px]'>
        <button
          className='bg-dark-blue text-white px-3 py-2 font-medium rounded'
          onClick={handlePrint}><p>Print</p></button>
      </div>
      <div className="flex justify-center">
        <button
          className='bg-dark-blue text-white px-3 py-2 font-medium rounded'
          onClick={()=>{
            navigator.clipboard.writeText(link)
          }}
        >
          Copy Login Link
        </button>
      </div>
    </div>
  )
}

export default QRCodeModal
