import React, { useContext, useEffect } from 'react';
import JobsList from '../../../components/JobsList';
import serviceMethods from '../../../service'
import { useCustomerMyJobs } from '../../../contexts/customerMyJobs';
import { useAuth } from '../../../contexts/auth'
import JobsListHeaderBar from '../../../components/JobsListHeaderBar'
import JobsListNewJobButton from '../../../components/JobsListNewJobButton'
import DropdownMenu from '../../../components/CustomerMyJobsDropdownGeneral'

const CustomerMyJobsView = () => {
  const {
    currentPage, setCurrentPage,
    searchTerm, setSearchTerm,
    displayJobs,
    selectedStatuses, setSelectedStatuses,
    jobType, setJobType,
    ColumnHeaders,
    allSubmittedJobs, RESULTS_PER_PAGE,
    ListItem,
  } = useCustomerMyJobs();

  const { getProfile, isFetchingProfile } = useAuth();

  useEffect(()=>{
    if (!isFetchingProfile) getProfile();
  },[])

  return (
    <div className='flex flex-col items-center h-full w-full lg:mx-auto
      min-h-[calc(100vh-280px)] lg:min-h-[calc(100vh-176px)] p-8 lg:gap-8'>
      <div className='hidden lg:flex flex-row justify-between items-center w-full'>
        <h1 className='text-dark-blue bg-all-f-white text-3xl font-semibold '>My Jobs</h1>
        <JobsListNewJobButton />
      </div>
      <JobsList
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSearchTerm={setSearchTerm}
        displayJobs={displayJobs}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        jobType={jobType}
        setJobType={setJobType}
        allJobs={allSubmittedJobs}
        resultsPerPage={RESULTS_PER_PAGE}
        ListItem={ListItem}
        ColumnHeaders={ColumnHeaders}
        DropdownMenu={DropdownMenu}
      />
    </div>
  );
};

export default CustomerMyJobsView;
