import React, { useRef, useState, useEffect } from "react"
import { useAuth } from '../../contexts/auth';
import { useScheduler } from '../../contexts/scheduler';
import { useJobDetailsModal } from '../../contexts/jobDetailsModal';

const JobDetailsNotesTab = () => {
  const { me } = useAuth();
  const { selectedJobNotes, setSelectedJobNotes, selectedJobId } = useScheduler();
  const { 
    togglePinNote, sentenceCaseResult, getTimeElapsedForNotes, 
    saveUpdatedJobNote, createJobNote, deleteJobNote,
    toggleShowTooltipMenu, toggleShowDeleteConfirmation,
    toggleShowEditNoteInput
  } = useJobDetailsModal();

  const hiddenFileInput = useRef(null)
  const tooltipWrapper = useRef();
  const [fileIsNotImage, setFileIsNotImage] = useState(false);

  useEffect(() => {
    const handleClickToCloseTooltip = (e) => {
      if (!tooltipWrapper?.current?.contains(e.target)) {
        setSelectedJobNotes((prevSelectedJobNotes) => {
          const updatedNotes = prevSelectedJobNotes.map((note) => ({
            ...note,
            showTooltip: false,
          }));
          return updatedNotes;
        });
      }
    };

    const outerWrapper = document.getElementById('jobDetailsNotesOuterDivId')
    outerWrapper.addEventListener('click', handleClickToCloseTooltip);
    return () => {
      outerWrapper.removeEventListener('click', handleClickToCloseTooltip);
    };
  }, []);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleNoteFormSubmit = async (e) => {
    e.preventDefault();
    const body = e.target.elements.jobNote.value;
    const file = hiddenFileInput.current.files[0];
  
    const formData = new FormData();
    formData.append('jobId', selectedJobId);
    formData.append('body', body);
    formData.append('creatorId', me.id);

    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/svg', 'image/gif', 'image/jpg', 'image/heic', 'application/pdf'];
      if (!allowedTypes.includes(file.type)){
        e.target.value = '';
        setFileIsNotImage(true);
        return;
      } else {
        setFileIsNotImage(false);
      }
      formData.append('attachment', file);
    }
    await createJobNote(formData);
    e.target.reset();
  }

  return (
    <div id='jobDetailsNotesOuterDivId'>
      {selectedJobNotes?.map((note, index) => (
        <div key={index} className='flex w-full px-4 py-5 relative'>
          <button>
            <img src='/JobDetails/avatar_placeholder.svg' />
          </button>

          <div className='flex flex-col w-full pt-1 px-4 text-sm'>
            <div className='flex justify-between'>
              <div className='flex'>
                <div className='pr-[20px] text-dark-blue font-bold'>
                  {me?.name}
                </div>
                <div className='text-opaque-job-details-gray'>
                  {sentenceCaseResult}
                </div>
              </div>

              <div className='flex'>
                <div className='pr-[20px] text-opaque-job-details-gray'>
                  {getTimeElapsedForNotes(note.createdAt)}
                </div>
                <button className='pr-[10px]' >
                  <img src={`${note?.pinnedAt ? '/JobDetails/pinned_pin.svg' : '/JobDetails/push_pin.svg'}`} onClick={()=>togglePinNote(note)}/>
                </button>
                <button className='pl-[10px] pr-[20px]' onClick={()=>toggleShowTooltipMenu(index)}>
                  <img src='/JobDetails/vertical_dots.svg'/>
                </button>
                {note?.showTooltip &&
                  <div 
                    className={`${note?.showTooltip ? 'absolute' : 'invisible'} 
                    top-0 mt-9 ml-16 bg-white p-2 shadow-md rounded border border-navy-light`}
                    ref={tooltipWrapper}
                  >
                    <div>
                      <button 
                        className='pb-2'
                        onClick={()=>{toggleShowTooltipMenu(index); toggleShowEditNoteInput(index);}}
                      >
                        Edit
                      </button>
                    </div>
                    
                    <div>
                      <button onClick={()=>{toggleShowTooltipMenu(index); toggleShowDeleteConfirmation(index);}}>
                        Delete
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div>
              {note?.body}
            </div>
            {note?.attachmentPath && (
              (note?.attachmentPath?.includes('pdf')) ? (
                <a href={`${apiUrl}/${note.attachmentPath}`} download={`${apiUrl}/${note.attachmentPath}`} className='max-w-[40px]'>
                  <img src='/JobDetails/description.svg' alt='uploaded file download icon'/>
                </a>
              ) : (
                <a href={`${apiUrl}/${note.attachmentPath}`} download={`${apiUrl}/${note.attachmentPath}`} className='max-w-[40px]'>
                  <img crossOrigin='anonymous' src={`${apiUrl}/${note.attachmentPath}`} alt='Uploaded note image file' />
                </a>
              ))
            }

            {note.showDelete &&
              <div className='flex-col w-full rounded border border-navy-light mt-3'>
                <div className='text-sm mt-3 pl-2 mb-3'>
                  Confirm delete note?
                </div>
                <div className='flex justify-between py-2'>
                  <div className='pl-2'>
                    <button
                      className='text-sm font-semibold text-all-f-white py-[9px] px-3 bg-gunite-red rounded'
                      onClick={()=>toggleShowDeleteConfirmation(index)}
                    >
                      Cancel
                    </button>
                  </div>
                    
                    <div className='pr-2'>
                      <button
                        className='text-sm font-semibold text-all-f-white py-[9px] px-3 bg-dark-blue rounded'
                        onClick={()=>deleteJobNote(note)}
                      >
                        Delete
                      </button>
                    </div>
                </div>
              </div>
            }

            {note.showEdit &&
              <div className='flex-col w-full rounded border border-navy-light mt-3'>
                <form onSubmit={(e) => {
                  e.preventDefault();
                  const updatedNote = { ...note, body: e.target.jobNoteEdit.value };
                  setSelectedJobNotes((prevNotes) =>
                    prevNotes.map((prevNote) =>
                      prevNote === note ? updatedNote : prevNote
                    )
                  );
                  saveUpdatedJobNote(updatedNote);
                  toggleShowEditNoteInput(index);
                }}>
                  <input
                    type='text'
                    name='jobNoteEdit'
                    id={`job_details_edit_note${index}`}
                    placeholder='New note'
                    defaultValue={note.body}
                    className='w-full border-none text-sm mt-[1px] pb-[70px]'
                  />
                  <div className='flex justify-between pb-2'>
                    <div className='pl-2'>
                      <button 
                        type="button"
                        className='text-sm font-semibold text-all-f-white py-[9px] px-3 bg-gunite-red rounded'
                        onClick={() => toggleShowEditNoteInput(index)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className='pr-2'>
                      <button
                        type="submit"
                        className='text-sm font-semibold text-all-f-white py-[9px] px-3 bg-dark-blue rounded'
                      >
                        Update Note
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>
        </div>
      ))}

      <form
        className='flex w-full px-4 py-6 gap-4'
        onSubmit={handleNoteFormSubmit}
      >
        <button className='flex flex-start'>
          <img src='/JobDetails/avatar_placeholder.svg' />
        </button>

        <div className='flex-col w-full rounded border border-navy-light'>
          <input
            type='text'
            name='jobNote'
            id='job_details_new_note'
            placeholder='Add your note'
            className='w-full border-none text-sm mt-[1px] pb-[70px]'
          />

          <input type='file' className='hidden' accept='image/*' ref={hiddenFileInput} />
          <div className='flex justify-between pb-2'>
            <button className='pl-2' type="button" onClick={()=>{hiddenFileInput.current.click()}}>
              <img src='/JobDetails/attach_file.svg' />
            </button>

            <div className='pr-2'>
              <button type='submit'
                className='text-sm font-semibold text-all-f-white py-[9px] px-3 bg-dark-blue rounded'
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className='flex justify-center'>
        {fileIsNotImage &&
          <p className='text-gunite-red text-roboto text-sm'>
            <i>
              *the uploaded file is not an image extension, please choose
              an image file and try again
            </i>
          </p>
        }
      </div>
    </div>
  )
}

export default JobDetailsNotesTab;
