import { useAdminMaterials } from '../../contexts/adminMaterials'
import { useState } from 'react'

const AdminMaterialsProductDetails = () => {
  const { prevPageSelectedItem, handleUpdateItem } = useAdminMaterials();

  return (
    <div className="w-full">
        <div className='w-full flex flex-col flex-start'>
          <h3 className='pt-1 text-gray-blue text-sm'>Name</h3>
          <p className='font-semibold text-md text-dark-blue'>{prevPageSelectedItem.name}</p>
          <div className='flex flex-row'> 
            <div className='w-1/2'> 
              <h3 className='pt-1 text-gray-blue text-sm'>Minimum total IA</h3>
              <p className='font-semibold text-md text-dark-blue'>{prevPageSelectedItem.minimumTotalIA}</p>
            </div>
            <div className='w-1/2'> 
              <h3 className='pt-1 text-gray-blue text-sm'>Minimum price</h3>
              <p className='font-semibold text-md text-dark-blue'>${prevPageSelectedItem.minimumPrice}</p>
            </div>
          </div>
          <h3 className='pt-1 text-gray-blue text-sm'>Notes</h3>
          <p className='font-semibold text-md text-dark-blue'>{prevPageSelectedItem.notes}</p>

          <h2 className="pt-5 pb-2 text-dark-blue text-xl font-semibold">Replaster</h2>
          <div className='flex flex-row'> 
            <div className='w-1/2'> 
              <h3 className='pt-1 text-gray-blue text-sm'>0'-1200' total IA add</h3>
              <p className='font-semibold text-md text-dark-blue'>${prevPageSelectedItem.totalIASmall}</p>
            </div>
            <div className='w-1/2'> 
              <h3 className='pt-1 text-gray-blue text-sm'>1200'-2000' total IA add</h3>
              <p className='font-semibold text-md text-dark-blue'>${prevPageSelectedItem.totalIALarge}</p>
            </div>
          </div>
          <h3 className='pt-1 text-gray-blue text-sm'>SPA add</h3>
          <p className='font-semibold text-md text-dark-blue'>${prevPageSelectedItem.spaAdd}</p>
        </div>
    </div>
  );
};

export default AdminMaterialsProductDetails;