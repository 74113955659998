import React, { useState, useRef } from 'react'
import _ from 'underscore'

const Input = ({ value, placeholder, onChange, isError, isPassword, numbersOnly = false, numbersAndDecimalsOnly = false, disabled = false }) => {
  const [touched, setTouched] = useState(false)
  const inputRef = useRef()

  const uniqueId = `input-${_.uniqueId()}`

  const focusInput = () => {
    inputRef.current.focus()
  }

  const inputStyles = () => {
    if (!touched || (touched && !isError)) {
      return (
        'peer mt-0 block w-full px-3 pb-2 pt-6 border-0 border-b focus:ring-0 rounded-t font-semibold  bg-tertiary-blue/5 placeholder-transparent  text-base border-black focus:border-tertiary-blue'
      )
    } else {
      return 'peer mt-0 block w-full px-3 pb-2 pt-6 border-0 border-b focus:ring-0 rounded-t bg-tertiary-blue/5 placeholder-transparent font-semibold text-base border-red-500'
    }
  }


  const labelStyles = () => {
    if (!touched || (touched && !isError)) {
      return (
        'absolute left-3 top-2 text-xs font-normal text-black/60 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-4 peer-focus:text-xs peer-focus:top-2 text-black/60 peer-focus:text-tertiary-blue cursor-text'
      )
    } else {
      return (
        'absolute left-3 top-2 text-xs font-normal text-black/60 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-4 peer-focus:text-xs peer-focus:top-2 text-red-500 peer-focus:text-tertiary-blue cursor-text'
      )
    }
  }

  const limitToNumbers = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '')
    e.target.value = value
  }

  const limiToNumbersAndDecimals = (e) => {
    const value = e.target.value.replace(/[^\d.]/g, '');
    const sanitizedValue = value.replace(/(\..*)\./g, '$1');
    e.target.value = sanitizedValue;
  };

  return (
    <div className='group relative w-full'>
      <input
        value={value}
        type={isPassword ? 'password' : 'text'}
        id={uniqueId}
        placeholder={placeholder}
        onChange={(e) => {
          if (numbersOnly){
            limitToNumbers(e)
            setTouched(false)
            onChange(e)
          } else if (numbersAndDecimalsOnly){
            limiToNumbersAndDecimals(e)
            setTouched(false)
            onChange(e)
          } else {
            setTouched(false)
            onChange(e)
          }
        }}
        onBlur={() => setTouched(true)}
        className={inputStyles()}
        ref={inputRef}
        disabled={disabled}
      />
      <label
        htmlFor={uniqueId}
        className={labelStyles()}
        onClick={focusInput}
      >{placeholder}</label>

    </div>
  )
}


export default Input

