import React, { useState } from 'react'
import { MdOutlineClose } from 'react-icons/md';
import { RxHamburgerMenu } from "react-icons/rx";
import { useAuth } from '../../contexts/auth';
import { useForeman } from '../../contexts/foreman';

const ForemanTopBar = () => {
  const {showMobileNav, setShowMobileNav } = useForeman()

  const handleToggle = () => {
    setShowMobileNav(!showMobileNav)
  }

  return (
      <div className='lg:hidden h-16 py-3 px-6 w-screen bg-dark-blue'>
        <div className='w-full flex flex-row justify-between items-center lg:hidden text-white bg-dark-blue'>
          <img src='/sidebar/gunite_logo_sm.svg' alt="Main Logo" className="h-full" />
          <div onClick={handleToggle} className='cursor-pointer overflow-hidden'>
            {!showMobileNav &&(
              <RxHamburgerMenu onClick={handleToggle} className='text-3xl'/>
            )}
          </div>
        </div>
      </div>
  )
}

export default ForemanTopBar
