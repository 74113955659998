const CostBreakdownLeaveWarning = ({ header, description, confirmButtonText, onContinue, onSaveFirst, onCancel, activeButtons }) => {
  return (
    <div className='flex justify-center items-center fixed w-screen h-screen top-0 left-0 bg-navy-light bg-opacity-60 z-10'>
      <div className="w-[500px] rounded-[4px] bg-all-f-white border border-navy-light p-4">
        <h2 className="text-lg font-bold text-dark-blue">{header}</h2>
        <p className="text-gray-blue my-4">{description}</p>
        <div className="flex justify-between">
          <button
            onClick={() => {
              onCancel()
            }}
            className="text-dark-blue hover:text-white bg-white hover:bg-dark-blue rounded-md border border-navy-light py-2 px-4 mr-2"
          >
            Cancel
          </button>

          <div className='flex gap-4'>
            <button
              onClick={() => {
                onContinue()
              }}
              className="text-dark-blue hover:text-white bg-white hover:bg-gunite-red border border-navy-light rounded-md py-2 px-4 "
            >
              {confirmButtonText}
            </button>

            <button
              onClick={() => {
                onSaveFirst()
              }}
              className={`rounded-md py-2 px-4 border border-navy-light ${activeButtons.save ? 'text-dark-blue hover:text-white bg-white hover:bg-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
            >
              Save for later
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CostBreakdownLeaveWarning;
