import React from 'react'
import SchedulerJobLogProvider from '../../../contexts/schedulerJobLog'
import SchedulerDailyJobLog from '../../../components/SchedulerDailyJobLog'


const DailyJobLogView = () => {

  return (
    <div className='flex flex-col w-full overflow-auto'>
      <SchedulerJobLogProvider>
        <SchedulerDailyJobLog />
      </SchedulerJobLogProvider>
    </div>
  )
}

export default DailyJobLogView