import React from 'react'
import CalendarJobCardFlash from '../CalendarJobcardFlash'
import { useScheduler } from '../../contexts/scheduler'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'

const TimeslotFlashing = ({ i, scheduledDate, crewId, ftimeslotJobs, fshift }) => {
  const { crewColors } = useScheduler()
  const { interval, displayMode } = useSchedulerCalendar()

  const cardData = {
    scheduledDate: scheduledDate,
    crewId: crewId,
    fshift: fshift,
  }

  const borderNames = (i) => {
    if (i === 0) return 'border-[#f8f5c2]'
    if (i === 1) return 'border-[#b9fbc0]'
    if (i === 2) return 'border-[#9fe7ff]'
    if (i === 3) return 'border-[#fde4cf]'
    if (i === 4) return 'border-[#f1c0e8]'
    if (i === 5) return 'border-[#ffcfd2]'
    if (i === 6) return 'border-[#99e4eb]'
    if (i === 7) return 'border-[#d1bba7]'
    if (i === 8) return 'border-[#cbbaf0]'
    if (i === 9) return 'border-[#8bebbe]'
    if (i === 10) return 'border-[#bcd8ff]'
    if (i === 11) return 'border-[#bcc4d1]'
  }

  const fTimeslotDisplay = () => {
    if (interval === 'week' && displayMode === 'vertical') {
      return (
        <div
          className={`${ftimeslotJobs.length < 1 ? 'FDroppable' : ''} ${crewColors[i]} ${borderNames(i)} bg-opacity-30 basis-5`}
          data-timeslotdata={JSON.stringify(cardData)}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlash job={flashJob} i={i} key={`fj-${timeslotIdx}`} />
          ))}
        </div>
      )
    } else if (interval === 'week' && displayMode === 'horizontal') {
      return (
        <div
          className={`${ftimeslotJobs.length < 1 ? 'FDroppable' : ''} h-min-content min-h-[22px] ${crewColors[i]} ${borderNames(i)} bg-opacity-30 basis-5`}
          data-timeslotdata={JSON.stringify(cardData)}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlash job={flashJob} i={i} key={`fj-${timeslotIdx}`} />
          ))}
        </div>
      )
    } else if (interval === 'month' && displayMode === 'vertical') {
      return (
        <div
          className={`${ftimeslotJobs.length < 1 ? 'FDroppable' : ''} ${crewColors[i]} ${borderNames(i)} bg-opacity-30 basis-5`}
          data-timeslotdata={JSON.stringify(cardData)}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlash job={flashJob} i={i} key={`fj-${timeslotIdx}`} />
          ))}
        </div>
      )
    } else if (interval === 'month' && displayMode === 'horizontal') {
      return (
        <div
          className={`${ftimeslotJobs.length < 1 ? 'FDroppable' : ''} h-min-content min-h-[15px] ${crewColors[i]} ${borderNames(i)} bg-opacity-30 basis-5`}
          data-timeslotdata={JSON.stringify(cardData)}
        >
          {ftimeslotJobs.map((flashJob, timeslotIdx) => (
            <CalendarJobCardFlash job={flashJob} i={i} key={`fj-${timeslotIdx}`} />
          ))}
        </div>
      )
    }
  } 

  return (
    <>{fTimeslotDisplay()}</>
  )
}

export default TimeslotFlashing