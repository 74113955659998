import { useScheduler } from "../../contexts/scheduler";
import { useAuth } from "../../contexts/auth";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";

const JobDetailsPinnedNotes = () => {
  const { me } = useAuth();
  const { selectedJobNotes } = useScheduler();
  const { togglePinNote, sentenceCaseResult, getTimeElapsedForNotes } = useJobDetailsModal();

  const hasPinnedNotes = selectedJobNotes?.some(note => note.pinnedAt);
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <>
      {hasPinnedNotes && (
        <div className='p-4 mb-4'>
          <div className='rounded border border-navy-light'>
            {selectedJobNotes?.map((note, index) => (
              note?.pinnedAt &&
                <div key={index} className='flex w-full px-4 py-6 bg-navy-bright bg-opacity-10'>
                  <button>
                    <img src='/JobDetails/avatar_placeholder.svg' />
                  </button>

                  <div className='flex flex-col w-full pt-1 px-4 text-sm'>
                    <div className='flex justify-between'>
                      <div className='flex'>
                        <div className='pr-[20px] text-dark-blue font-bold'>
                          {me?.name}
                        </div>
                        <div className='text-opaque-job-details-gray'>
                          {sentenceCaseResult}
                        </div>
                      </div>

                      <div className='flex'>
                        <div className='pr-[20px] text-opaque-job-details-gray'>
                          {getTimeElapsedForNotes(note.createdAt)}
                        </div>
                        <button className='pr-[20px]' onClick={()=>togglePinNote(note)}>
                          <img src={`${note?.pinnedAt ? '/JobDetails/pinned_pin.svg' : '/JobDetails/push_pin.svg'}`}/>
                        </button>
                      </div>
                    </div>

                    <div>
                      {note?.body}
                    </div>
                    {note?.attachmentPath === null ? (
                      <></>
                      ) : (
                        (note?.attachmentPath?.includes('pdf')) ? (
                          <a href={`${apiUrl}/${note.attachmentPath}`} download={`${apiUrl}/${note.attachmentPath}`}>
                            <img src='/JobDetails/description.svg' alt='uploaded file download icon' className='max-w-[40px] max-h-[40px]'/>
                          </a>
                        ) : (
                          <a href={`${apiUrl}/${note.attachmentPath}`} download={`${apiUrl}/${note.attachmentPath}`}>
                            <img crossOrigin='anonymous' className='max-w-[40px] max-h-[40px]' src={`${apiUrl}/${note.attachmentPath}`} alt='Uploaded note image file' />
                          </a>
                        )
                      )
                    }
                  </div>
                </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default JobDetailsPinnedNotes;