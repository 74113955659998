import { createContext, useContext, useEffect, useState } from "react"
import Service from '../service'
import cookies from "browser-cookies"

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

const DEFAULT_USER = {
  id: null,
  email: null,
  role: null,
  name: null,
  jobs: [],
  crewMember: [{}],
  customer: {},
  foreman: [{
    crew: {
      calendarJobs: [],
      location: {
        crews: [],
        drivers: []
      }
    }
  }],
  driver: [{}],
  scheduler: [],
  admin: { allLocations: [] },
}

function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [me, setMe] = useState(DEFAULT_USER)
  const [isFetchingProfile, setIsFetchingProfile] = useState(false)
  const [isInvalidCreds, setIsInvalidCreds] = useState(false)
  const [portalInactiveForAllUserLocations, setPortalInactiveForAllUserLocations] = useState(false)
  const [hasFetchedProfile, setHasFetchedProfile] = useState(false)
  const { accessToken } = Service.getAuthTokens()

  const logout = () => {
    Service.clearAllCookies()
    setMe(DEFAULT_USER)
    setIsLoggedIn(false)
    setHasFetchedProfile(false)
  }

  const authMethods = {
    getProfile: async () => {
      setIsFetchingProfile(true)
      const res = await Service.getProfile()
      if (res.ok) {
        const { user } = await res.json()
        setMe(user)
        setIsLoggedIn(true)
        setIsFetchingProfile(false)
        if (
          (user.role === 'SCHEDULER' || user.role === 'ADMIN' || user.role === 'BRANCHADMIN')
          && !cookies.get('schedulerId')
        ) {
          const schedulerId = user.scheduler[0].id.toString()
          cookies.set('schedulerId', schedulerId)
        }
      } else if (isLoggedIn) {
        logout()
      }
      setHasFetchedProfile(true)
    },
    loginWithPin: async (hash, pin) => {
      if (portalInactiveForAllUserLocations) setPortalInactiveForAllUserLocations(false)
      if (isInvalidCreds) setIsInvalidCreds(false)
      const res = await Service.loginWithPin(hash, pin)
      const { user, tokens, message } = await res.json()
      if (res.ok) {
        Service.setAuthTokens(tokens)
        setMe(user)
        setIsLoggedIn(true)
      } else if (message === 'Incorrect pin') {
        setIsInvalidCreds(true)
      } else if (message === 'Portal is not active for your location') {
        setPortalInactiveForAllUserLocations(true)
      }
      
      return res.ok
    },
    login: async (email, password) => {
      if (isInvalidCreds) setIsInvalidCreds(false)
      if (portalInactiveForAllUserLocations) setPortalInactiveForAllUserLocations(false)
      const res = await Service.login(email, password)
      const { user, tokens, message } = await res.json()
      if (res.ok) {
        Service.setAuthTokens(tokens)
        setMe(user)
        setIsLoggedIn(true)
        if (user.role === 'CUSTOMER') {
          const intakeFlowId = cookies.get('intakeFlowId')
          if (intakeFlowId) {
            let hasJob = false
            user.jobs.forEach((job) => {
              if (job.intakeFlowId === Number(intakeFlowId)) {
                hasJob = true
              }
            })
            if (hasJob === false) {
              cookies.erase('intakeFlowId')
            }
          }
        } else if (user.role === 'SCHEDULER' || user.role === 'ADMIN' || user.role === 'BRANCHADMIN') {
          const schedulerCookieId = cookies.get('schedulerId')
          const scheduler = user.scheduler.find(item => item.id === schedulerCookieId)
          if (!schedulerCookieId || !scheduler) {
            const schedulerId = user.scheduler[0].id.toString()
            cookies.set('schedulerId', schedulerId)
          }
        }
      } else if (message === 'Incorrect email or password') {
        setIsInvalidCreds(true)
      } else if (message === 'Portal is not active for your location') {
        setPortalInactiveForAllUserLocations(true)
      }
      return res.ok
    },
    logout,
    setNewPin: async (hash, pin) => {
      const res = await Service.setNewPin(hash, pin)
      const { user, tokens, message } = await res.json()
      if (res.ok) {
        Service.setAuthTokens(tokens)
        setMe(user)
        setIsLoggedIn(true)
      } else if (message === 'Incorrect pin') {
        setIsInvalidCreds(true)
      }
      return res.ok
    }
  }


  useEffect(() => {
    if (
      accessToken
      && !me?.id
      && !isFetchingProfile
      && !hasFetchedProfile
    ) {
      authMethods.getProfile()
    }
    if (!accessToken) {
      logout()
    }
  }, [me, isFetchingProfile, hasFetchedProfile, accessToken])

  return (
    <AuthContext.Provider value={{
      isLoggedIn, hasFetchedProfile, setIsLoggedIn, isInvalidCreds, 
      setIsInvalidCreds, portalInactiveForAllUserLocations, setPortalInactiveForAllUserLocations,
      me, setMe, ...authMethods
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
