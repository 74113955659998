import React from "react";
import { useIntakeForm } from "../../../contexts/intakeForm";
import ProgressPaneMobile from '../../../components/ProgressPaneMobile';
import ProgressPaneDesktop from '../../../components/ProgressPaneDesktop';
import { useResponsive } from '../../../contexts/responsive';
import { useProgress } from "../../../contexts/progress";
import serviceMethods from "../../../service"
import PoolDetailsForm from '../../../components/PoolDetailsForm'
import "./index.scss";
import Trashcan from "../../../components/Trashcan";

const GuniteIntake3b = () => {
  const { navigateNext, navigatePrevious } = useProgress();
  const { poolDetails, setPoolDetails, jobDetails } = useIntakeForm()

  const { responsive } = useResponsive();
  const isMobileView = (
    responsive.breakpoint === 'sm'
    || responsive.breakpoint === 'md'
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await serviceMethods.updatePoolsGunite3b({
      poolDetails,
    })
    if (res.ok) {
      navigateNext()
    }
  }

  const errorChecker = () => {
    let error = false;
    if (Array.isArray(poolDetails)) {
      poolDetails.forEach((pool) => {
        if (!pool.perimeterFt || !pool.perimeterInches || !pool.squareFt || !pool.depth) {
          error = true;
        }
      })
    }
    return error;
  }

  const continueButtonVisibility = () => {
    let isVisible = true
    if (Array.isArray(poolDetails)) {
      poolDetails.forEach((pool) => {
        if (!pool.planUri
        ) {
          isVisible = false
        }
      })
    }
    return isVisible
  }

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className="lg:w-1/3">
        {isMobileView ?
          <ProgressPaneMobile />
          :
          <ProgressPaneDesktop />
        }
      </div>
      <div className={`p-6 lg:p-20 lg:w-full lg:mb-0 gap-12 flex flex-col
        ${isMobileView &&
        'gunite_intake_3b_content_container_mobile'
        }
      `}>
        {/* Note: this div hidden in mobile */}
        <div className="hidden lg:flex flex-col gap-6 mb-[45px]">
          <button onClick={()=>{navigatePrevious()}}>
            <div className="flex gap-2 items-center">
              <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
              <p className="font-semibold text-gunite-red">Back</p>
            </div>
          </button>
          <div className="flex flex-col gap-1">
            <p className="text-secondary-blue font-bold text-4xl">Job site information</p>
            <p className="text-secondary-blue/60 font-semibold text-base">Information about job site, address, pool plan, and job site supervisor.</p>
          </div>
          <div className="flex gap-[2px] items-center">
            {/* progress bar */}
            <div className="h-2 w-1/4 bg-primary-light" />
            <div className="h-2 w-1/4 bg-gunite-red" />
            <div className="h-1 w-1/4 bg-black/50" />
            <div className="h-1 w-1/4 bg-black/50" />
          </div>
        </div>
        {Array.isArray(poolDetails) ? poolDetails.map((pool, i) => (
          <div key={`jobDetailsForm${i}`}>
            {poolDetails.length > 1 ?
              (
                <>
                  <div className="text-secondary-blue text-xl font-semibold">
                    {`Job #${i+1}: ${jobDetails[i].jobName}`}
                  </div>
                  <Trashcan
                    i={i}
                  />
                </>
              ) : ''
              
            }
            <PoolDetailsForm
              pool={pool}
              i={i}
              setPoolDetails={setPoolDetails}
              poolDetails={poolDetails}
              isGunite={true}
              jobDetails={jobDetails}
              key={`pool-details-${i}`}
            />
          </div>
        )) : null}
        <button
          className={`w-full rounded p-3 lg:py-3 lg:px-9 lg:w-auto self-start text-2xl font-bold
          ${continueButtonVisibility() ? '' : 'hidden'}
          ${errorChecker() ? 'bg-black/10 text-black/40 cursor-not-allowed' : 'bg-gunite-red text-white  cursor-pointer'}`}
          disabled={errorChecker()}
          onClick={handleSubmit}
          >Continue
        </button>
      </div>
    </div>
  )
}

export default GuniteIntake3b;
