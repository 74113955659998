import React, { useEffect, useState } from "react";
import { useAdmin } from "../../contexts/admin";
import { useModal } from "../../contexts/modal";
import PopupSearchBox from "../PopupSearchBox";
import { useEmployee } from "../../contexts/employees";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import PaginationFooterBar from '../PaginationFooterBar';
import NewEmployeeModal from "../NewEmployeeModal";
import QRCodeModal from "../QRCodeModal";

const AdminBranchEmployees = () => {
  const {
    selectedLocation,
  } = useAdmin();
  const {
    newEmployeeFormSearching, newEmployeeSearchTerm,
    setNewEmployeeSearchTerm, branchSpecificEmployees, setBranchSpecificEmployees,
    setSelectedEmployee, getEmployeesByBranch, needToUpdateEmployees,
    setNeedToUpdateEmployees, paginate, currentPage, setCurrentPage,
    sort, sortAscending, setSortAscending, RESULTS_PER_PAGE, sortBy, setSortBy
  } = useEmployee();
  const { modal, modalMethods } = useModal();
  const { setIsEditing } = modal;

  const [employeesToDisplay, setEmployeesToDisplay] = useState([]);
  const [filteredEmployeesBeforePagination, setFilteredEmployeesBeforePagination] = useState([]);
  const [listOfForeman, setListOfForeman] = useState([]);

  useEffect(() => {
    setSortAscending(true)
    setCurrentPage(1)
    setNeedToUpdateEmployees(false)
  }, [selectedLocation])

  useEffect(() => {
    getListOfForeman(branchSpecificEmployees);
  }, [selectedLocation, branchSpecificEmployees])

  const isLocationGunite = () => {
    if (selectedLocation?.label?.toLowerCase().includes('gunite')) {
      return true;
    } else if (selectedLocation?.label?.toLowerCase().includes('plaster')) {
      return false;
    }
  }

  const formatRoles = (roleString) => {
    let resultString;
    const tempLowerCase = roleString.toLowerCase();
    if (tempLowerCase === 'crewmember' || tempLowerCase === 'crew member') {
      resultString = 'Crew Member'
    } else if (tempLowerCase === 'foreman') {
      resultString = 'Foreman'
    } else if (tempLowerCase === 'scheduler') {
      resultString = 'Scheduler'
    } else if (tempLowerCase === 'driver') {
      resultString = 'Driver'
    }
    return resultString;
  }

  const getListOfForeman = (employees) => {
    let uniqueCrewNames = [];
    for (let i = 0; i < employees.length; i++) {
      if (!uniqueCrewNames.some(foreman => foreman.foremanName === employees[i].foreman)
        && employees[i].crewId) {
        uniqueCrewNames.push({
          foremanName: employees[i].foreman,
          crewId: employees[i].crewId,
          email: employees[i].email
        });
      }
    }
    setListOfForeman(uniqueCrewNames)
  }

  useEffect(() => {
    const employeesToPaginate = !newEmployeeFormSearching
      ? branchSpecificEmployees?.map(employee => ({
        id: employee.id,
        name: employee.name,
        email: employee.email || '',
        role: formatRoles(employee.role),
        foreman: employee.foreman,
        crewId: employee.crewId || null,
        guniteEmployee: isLocationGunite()
      }))
      : branchSpecificEmployees
        ?.filter(item =>
          item?.name?.toLowerCase().includes(newEmployeeSearchTerm?.toLowerCase())
        )
        .map(employee => ({
          id: employee.id,
          name: employee.name,
          email: employee.email || '',
          role: formatRoles(employee.role),
          foreman: employee.foreman,
          crewId: employee.crewId || null,
          guniteEmployee: isLocationGunite()
        }))
    const sortedEmployees = sort(employeesToPaginate, sortBy)
    if (!sortAscending) sortedEmployees.reverse()
    const updatedEmployees = paginate(currentPage, sortedEmployees)
    setFilteredEmployeesBeforePagination(employeesToPaginate)
    setEmployeesToDisplay(updatedEmployees);
  }, [newEmployeeFormSearching, branchSpecificEmployees, newEmployeeSearchTerm, sortAscending]);

  useEffect(() => {
    setCurrentPage(1)
  }, [newEmployeeSearchTerm, selectedLocation])

  useEffect(() => {
    if (!branchSpecificEmployees.length) return
    const employeesToPaginate = !newEmployeeFormSearching
      ? branchSpecificEmployees.map(employee => ({
        id: employee.id,
        name: employee.name,
        email: employee.email || '',
        role: formatRoles(employee.role),
        foreman: employee.foreman,
        crewId: employee.crewId || null,
        guniteEmployee: isLocationGunite()
      }))
      : branchSpecificEmployees
        ?.filter(item =>
          item?.name?.toLowerCase().includes(newEmployeeSearchTerm?.toLowerCase())
        )
        .map(employee => ({
          id: employee.id,
          name: employee.name,
          email: employee.email || '',
          role: formatRoles(employee.role),
          foreman: employee.foreman,
          crewId: employee.crewId || null,
          guniteEmployee: isLocationGunite()
        }))
    const sortedEmployees = sort(employeesToPaginate, sortBy)
    if (!sortAscending){
      sortedEmployees.reverse();
    }
    const updatedEmployees = paginate(currentPage, sortedEmployees)
    setFilteredEmployeesBeforePagination(employeesToPaginate)
    setEmployeesToDisplay(updatedEmployees)
  }, [currentPage, branchSpecificEmployees])

  const toggleNameSorting = (e, columnToSortBy) => {
    e.preventDefault();
    if (sortAscending) {
      const employeesToPaginate = !newEmployeeFormSearching
        ? branchSpecificEmployees.map(employee => ({
          id: employee.id,
          name: employee.name,
          email: employee.email || '',
          role: formatRoles(employee.role),
          foreman: employee.foreman,
          guniteEmployee: isLocationGunite()
        }))
        : branchSpecificEmployees
          ?.filter(item =>
            item.name.toLowerCase().includes(newEmployeeSearchTerm?.toLowerCase())
          )
          .map(employee => ({
            id: employee.id,
            name: employee.name,
            email: employee.email || '',
            role: formatRoles(employee.role),
            foreman: employee.foreman,
            crewId: employee.crewId || null,
            guniteEmployee: isLocationGunite()
          }))
      setSortAscending(false)
      const sortedEmployees = sort(employeesToPaginate, columnToSortBy)
      sortedEmployees.reverse();
      const updatedEmployees = paginate(currentPage, sortedEmployees)
      setEmployeesToDisplay(updatedEmployees)
      setFilteredEmployeesBeforePagination(employeesToPaginate)
    } else {
      const employeesToPaginate = !newEmployeeFormSearching
        ? branchSpecificEmployees.map(employee => ({
          id: employee.id,
          name: employee.name,
          email: employee.email || '',
          role: formatRoles(employee.role),
          foreman: employee.foreman,
          crewId: employee.crewId || null,
          guniteEmployee: isLocationGunite()
        }))
        : branchSpecificEmployees
          ?.filter(item =>
            item.name.toLowerCase().includes(newEmployeeSearchTerm.toLowerCase())
          )
          .map(employee => ({
            id: employee.id,
            name: employee.name,
            email: employee.email || '',
            role: formatRoles(employee.role),
            foreman: employee.foreman,
            crewId: employee.crewId || null,
            guniteEmployee: isLocationGunite()
          }))
      setSortAscending(true)
      sort(employeesToPaginate, columnToSortBy)
      const updatedEmployees = paginate(currentPage, employeesToPaginate)
      setFilteredEmployeesBeforePagination(employeesToPaginate)
      setEmployeesToDisplay(updatedEmployees)
    }
  }

  const generateQR = (employee) => {
    modalMethods.setContent(<QRCodeModal employee={employee} />)
    modalMethods.open()
  }


  return (
    <>
      <div className='text-dark-blue font-bold text-xl sm:text-[21px] sm:px-8 sm:pt-8 gap-4'>
        Branch Employees
      </div>

      <div className='sm:px-6'>
        <div className={`bg-white rounded-2xl my-6 flex flex-col border border-navy-light relative z-0`}>
          <div className="h-16 relative flex justify-between items-center px-6 rounded-t-xl border-b border-navy-light bg-opacity-20 bg-navy-bright">
            <h1 className="text-xl font-semibold text-dark-blue">Employees</h1>
            <PopupSearchBox searchTerm={newEmployeeSearchTerm} setSearchTerm={setNewEmployeeSearchTerm} />
          </div>
          <div className="flex py-2 px-3 border-navy-light bg-opacity-20 bg-navy-bright">
            <div className='flex flex-row w-full items-center justify-between'>
              <div className='w-1/3 flex justify-start'>
                <button
                  onClick={(e) => { toggleNameSorting(e, 'name'); setSortBy('name'); }}
                  className='text-gray-blue text-sm whitespace-nowrap pl-3'
                >
                  Name
                </button>
                {sortAscending ?
                  <button>
                    <FaArrowUp className="text-gray-blue text-xs pl-1"
                      onClick={(e) => { toggleNameSorting(e, 'name'); setSortBy('name'); }} />
                  </button>
                  :
                  <button>
                    <FaArrowDown className="text-gray-blue text-xs pl-1"
                      onClick={(e) => { toggleNameSorting(e, 'name'); setSortBy('name'); }} />
                  </button>
                }
              </div>

              <button
                onClick={(e) => { toggleNameSorting(e, 'crew'); setSortBy('crew'); }}
                className='w-1/3 flex justify-start text-gray-blue text-sm whitespace-nowrap'
              >
                Crew
              </button>
              <button
                onClick={(e) => { toggleNameSorting(e, 'role'); setSortBy('role'); }}
                className='w-1/3 flex justify-start text-gray-blue text-sm whitespace-nowrap'>Role</button>
            </div>
          </div>

          {employeesToDisplay?.map((employee, index) => (
            <div className="flex flex-row py-2 px-6 border-b border-navy-light" key={`employeeNewEmployeeForm-${index}`}>
              <div className='w-1/3 flex-shrink-0'>
                <p className="cursor-pointer text-dark-blue font-medium text-md w-full truncate"
                  onClick={(e) => {
                    const matchingEmployee = branchSpecificEmployees.find(user => user?.id === employee?.id)
                    e.preventDefault()
                    const matchingForeman = listOfForeman.find(foreman => foreman.foremanName === employee.foreman)
                    setIsEditing(true)
                    const employeeToSendToModal = {
                      id: matchingEmployee.id,
                      name: employee.name,
                      email: employee.email || '',
                      locationId: selectedLocation?.id,
                      guniteEmployee: isLocationGunite(),
                      role: formatRoles(employee.role),
                      foreman: employee.foreman,
                      crewId: matchingForeman?.crewId || null,
                      gunitePoolRate: matchingEmployee.gunitePoolRate || null,
                      plasterPoolRate: matchingEmployee.plasterPoolRate || null,
                      quartzPoolRate: matchingEmployee.quartzPoolRate || null,
                      pebblePoolRate: matchingEmployee.pebblePoolRate || null,
                      driverRate: matchingEmployee.driverRate || null,
                      phone: matchingEmployee.phone || null
                    }
                    setSelectedEmployee(employeeToSendToModal)
                    modalMethods.setContent(
                      <NewEmployeeModal
                        selectedEmployee={employeeToSendToModal}
                        setBranchSpecificEmployees={setBranchSpecificEmployees}
                        listOfForeman={listOfForeman}
                        isLocationGunite={isLocationGunite}
                        getEmployeesByBranch={getEmployeesByBranch}
                        needToUpdateEmployees={needToUpdateEmployees}
                        setNeedToUpdateEmployees={setNeedToUpdateEmployees}
                      />
                    )
                    modalMethods.open()
                  }}>
                  {employee?.name}
                </p>
              </div>

              <div className='w-1/3 flex-shrink-0'>
                <p className="cursor-pointer text-dark-blue font-medium text-md w-full truncate">
                  {employee?.foreman}
                </p>
              </div>

              <div className='w-1/3 flex-shrink-0 flex'>
                <p className="cursor-pointer text-dark-blue font-medium text-md w-full truncate">
                  {formatRoles(employee?.role)}
                </p>
                {employee.role === 'Foreman'
                  ?
                  <button 
                  className=""
                  onClick={() => generateQR(employee)}
                  >
                    QR
                  </button>

                  :
                  null}
              </div>
            </div>
          ))}

          <PaginationFooterBar
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            allItems={filteredEmployeesBeforePagination}
            resultsPerPage={RESULTS_PER_PAGE}
            setSortAscending={setSortAscending}
          />
        </div>
      </div>

      <div className='flex-row sm:px-6'>
        <button
          className='w-full border rounded border-navy-light px-2 py-3 flex items-center justify-center'
          onClick={(e) => {
            e.preventDefault()
            const employeeToSendToModal = {
              locationId: selectedLocation?.id
            }
            modalMethods.setContent(
              <NewEmployeeModal
                selectedEmployee={employeeToSendToModal}
                setSelectedEmployee={setSelectedEmployee}
                listOfForeman={listOfForeman}
                isLocationGunite={isLocationGunite}
                getEmployeesByBranch={getEmployeesByBranch}
                needToUpdateEmployees={needToUpdateEmployees}
                setNeedToUpdateEmployees={setNeedToUpdateEmployees}
              />
            )
            modalMethods.open();
          }}
        >
          <img src='/plus_sign.svg' className='mb-[2px]' />
          <div className='text-dark-blue font-semibold text-sm'>
            Add Employee
          </div>
        </button>
      </div>
    </>
  )
}

export default AdminBranchEmployees;
