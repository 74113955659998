import React, { useState, useEffect } from "react";
import CostBreakdownInput from "../CostBreakdownInput";
import CostBreakdownAddMaterial from "../CostBreakdownAddMaterial";
import { useScheduler } from "../../contexts/scheduler";


const DEFAULT_PLASTER_INGREDIENTS = {
  'Black Pearl PebbleSheen': 29.33,
  'Ocean Blue PebbleSheen': 29.33,
  'Ocean Blue Pigment': 101.77,
  'White Cement': 29.33,
}

const CostBreakdownMaterials = ({ batches, setBatches, breakdown, setBreakdown, plaster, savedCostBreakdown, resetToggle, DEFAULT_MATERIAL_GUNITE, DEFAULT_MATERIAL_PLASTER, batchIngredients }) => {
  const { selectedJobId } = useScheduler()
  const [materialUsed, setMaterialUsed] = useState(plaster ? DEFAULT_MATERIAL_PLASTER : DEFAULT_MATERIAL_GUNITE)
  const [materialTotal, setMaterialTotal] = useState(0)
  const [addingActive, setAddingActive] = useState(false)

  const updateState = (e, i, field) => {
    if (field === 'yards') {
      const yards = e.target.value
      let tons = 0
      let costTon = 0
      if (materialUsed[i].name === 'Sand') {
        tons = yards * 1.3
        costTon = tons * 23
      } else if (materialUsed[i].name === 'Cement') {
        tons = (yards * 564) / 2000
        costTon = tons * 147
      }

      setMaterialUsed([
        ...materialUsed.slice(0, i),
        {
          ...materialUsed[i],
          yards: yards,
          tons: Number(tons).toFixed(2),
          costTon: Number(costTon).toFixed(2)
        },
        ...materialUsed.slice(i + 1),
      ])
    } else {
      setMaterialUsed([
        ...materialUsed.slice(0, i),
        {
          ...materialUsed[i],
          [field]: e.target.value
        },
        ...materialUsed.slice(i + 1),
      ])
    }
  }

  const calculateMaterialTotal = () => {
    // math to calculate overall total
    const total = materialUsed.reduce((accumulator, item) => {
      const rowTotal = plaster ? (item.bags * item.costBag) : (item.yards * item.costYard)
      return accumulator += rowTotal
    }, 0)
    setMaterialTotal(total)

    setBreakdown((breakdown) => {
      return {
        ...breakdown,
        material: {
          items: materialUsed,
          total: total
        }
      }
    })
  }

  const calculateRowTotal = (row) => {
    // math to calculate row total
    const rowTotal = plaster ? (row.bags * row.costBag) : (row.yards * row.costYard)
    return rowTotal.toFixed(2)
  }

  const handleDeleteRow = (i) => {
    setMaterialUsed([
      ...materialUsed.slice(0, i),
      ...materialUsed.slice(i + 1)
    ])
  }

  useEffect(() => {
    calculateMaterialTotal()
  }, [materialUsed, batches, breakdown.drivers])

  useEffect(() => {
    if (savedCostBreakdown) {
      setBatches(savedCostBreakdown.batches)
      setMaterialUsed(savedCostBreakdown.material)
    } else {
      setMaterialUsed(plaster ? DEFAULT_MATERIAL_PLASTER : DEFAULT_MATERIAL_GUNITE)
      setBatches(0)
    }
  }, [savedCostBreakdown, selectedJobId, resetToggle])

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      {materialUsed.map((material, i) => {
        return (
          <div
            key={`material${i + 1}`}
            className={`w-full flex flex-col md:flex-row gap-4 items-end pt-2 mb-4 ${i !== 0 ? 'border-t' : ''}`}
          >
            <p className={`pb-3 text-dark-blue w-full font-semibold ${plaster ? 'md:w-2/4' : 'md:w-1/4'}`}>
              {material.name}
            </p>

            {plaster ?
              <div className='flex flex-col md:flex-row items-end w-full md:w-2/4 gap-4'>
                <CostBreakdownInput
                  value={material.bags}
                  placeholder='Bags'
                  numbersOnly
                  onChange={(e) => {
                    updateState(e, i, 'bags')
                  }}
                />
                <CostBreakdownInput
                  value={material.costBag}
                  placeholder='Cost/Bag'
                  money
                  disabled
                  edit
                  onChange={(e) => {
                    updateState(e, i, 'costBag')
                  }}
                />
              </div>
              :
              <div className='flex flex-col md:flex-row items-end w-full md:w-3/4 gap-4'>
                <CostBreakdownInput
                  value={material.yards}
                  placeholder='Yards'
                  numbersOnly
                  onChange={(e) => {
                    updateState(e, i, 'yards')
                  }}
                />
                <CostBreakdownInput
                  value={material.tons}
                  placeholder='Tons'
                  numbersOnly
                  disabled
                  onChange={(e) => {
                    updateState(e, i, 'tons')
                  }}
                />
                <CostBreakdownInput
                  value={material.costTon}
                  placeholder='Cost/Ton'
                  money
                  disabled
                  onChange={(e) => {
                    updateState(e, i, 'costTon')
                  }}
                />
                <CostBreakdownInput
                  value={material.costYard}
                  placeholder='Cost/Yard'
                  money
                  disabled
                  edit
                  onChange={(e) => {
                    updateState(e, i, 'costYard')
                  }}
                />
              </div>
            }

              <div className='block w-full md:w-1/4'>
                <p className='text-xs text-dark-blue pb-[2px] pt-[5px]'>Total</p>
                <div className='w-full px-3 py-2 rounded border border-tertiary-blue text-opaque-job-details-gray bg-disabled-gray'>
                  ${calculateRowTotal(material)}
                </div>
              </div>

              <div className={`flex h-10 w-12 mb-1 ${plaster ? '' : 'hidden'}`}>
                <button
                  className="flex w-full h-8 p-1 text-gunite-red cursor-pointer items-center justify-center"
                  onClick={() => { handleDeleteRow(i) }}
                >
                  <img
                    src='/JobDetails/trash_icon.svg'
                    alt='trashcan icon'
                  />
                </button>
              </div>

          </div>
        )
      })}

      <div className={`${plaster ? '' : 'hidden'}`}>
        {addingActive ?
          <CostBreakdownAddMaterial
            setAddingActive={setAddingActive}
            options={batchIngredients}
            add={(material) => {
              setMaterialUsed([...materialUsed, material])
            }}
          />
          :
          <div className='flex justify-end gap-4'>
            <button
              className='w-full border border-navy-light rounded text-dark-blue py-2 px-4 mt-4 font-semibold hover:border-dark-blue'
              onClick={() => {
                setAddingActive(true)
              }}
            >
              + Add new
            </button>
          </div>
        }
      </div>

      <div className={`flex w-full rounded bg-card-bg-hover p-3 my-4 gap-4 ${plaster ? '' : 'hidden'}`}>

        <div className='flex w-full justify-between text-sm text-dark-blue font-semibold items-end'>
          <p className='pb-5 w-fit pr-8'>
            Number of batches
          </p>
          <div className='flex justify-between w-[120px]'>
            <CostBreakdownInput
              value={batches || 0}
              numbersOnly
              placeholder='Batches'
              onChange={(e) => { setBatches(e.target.value) }}
            />
          </div>
        </div>

        <div className='block w-1/4'>
          <p className='text-xs text-dark-blue pb-[2px] pt-[5px]'>Cost per batch</p>
          <div className='w-full px-3 py-2 rounded border border-tertiary-blue text-opaque-job-details-gray bg-disabled-gray'>
            ${materialTotal.toFixed(2)}
          </div>
        </div>


      </div>

      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total materials cost
          </p>
          <div className='flex justify-between w-[100px]'>
            <p>$</p>
            <p>{plaster ? (materialTotal * batches).toFixed(2) : materialTotal.toFixed(2)}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CostBreakdownMaterials;
