import React, { useState, useEffect } from "react";
import { useScheduler } from "../../contexts/scheduler";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";

const MiniCalendarCrews = ({ day, crews, crewColors, scheduleInfo, oldScheduleInfo, oldFlashScheduleInfo, toggle, setToggle, availability, isFlash }) => {
  const { selectedJobId } = useScheduler()
  const { selectedCalendarJobId } = useJobDetailsModal()
  const [crewSelection, setCrewSelection] = useState([])
  const [isAvailable, setIsAvailable] = useState({})
  const crewList = crews.filter((crew) =>  crew.deletedAt === null)

  const handleCrewSelection = (crew) => {
    if (!scheduleInfo?.current[day]) {
      scheduleInfo.current[day] = {}
    }
    const crewIdIndex = crewSelection.indexOf(crew.id)
    if (crewIdIndex !== -1) {
      const crewRemoved = [...crewSelection.slice(0, crewIdIndex), ...crewSelection.slice(crewIdIndex + 1)]

      setCrewSelection(crewRemoved)
      scheduleInfo.current[day].crew = crewRemoved
    } else {
      const crewAdded = [...crewSelection, crew.id]
      setCrewSelection(crewAdded)
      scheduleInfo.current[day].crew = crewAdded
    }

    // triggers useEffect in miniCalendarModal
    setToggle(!toggle)
  }

  const checkCrewAvailability = () => {
    if (availability.current[day]) {
      const updates = {}

      // set defaults
      crewList?.forEach((crew) => {
        if (updates[crew.id] === undefined) {
          const defaultAvailability = {
            shoot1: true,
            shoot2: true,
            shoot3: true,
            active: true
          }
          updates[crew.id] = defaultAvailability
        }
      })

      const shoot1 = []
      const shoot2 = []
      const shoot3 = []

      // collect + organize all ids that have a scheduled shift on the given day
      Object.keys(availability.current[day]).forEach((jobId) => {
        // exclude availability details / ids from job being rescheduled
        if (Number(jobId) === selectedJobId) return
        Object.keys(availability.current[day][jobId]).forEach((calJobId) => {
          if (isFlash && Number(calJobId) === selectedCalendarJobId) return

          shoot1.push(...availability.current[day][jobId][calJobId].crews.shoot1)
          shoot2.push(...availability.current[day][jobId][calJobId].crews.shoot2)
          shoot3.push(...availability.current[day][jobId][calJobId].crews.shoot3)
        })
      })
      // check if current selection blocks / disables a crew
      shoot1.forEach((id) => {
        if (!updates[id]) updates[id] = {}
        updates[id].shoot1 = false
        if (scheduleInfo.current[day]?.shift.includes('1')) {
          updates[id].active = false
        }
      })
      shoot2.forEach((id) => {
        if (!updates[id]) updates[id] = {}
        updates[id].shoot2 = false
        if (scheduleInfo.current[day]?.shift.includes('2')) {
          updates[id].active = false
        }
      })
      shoot3.forEach((id) => {
        if (!updates[id]) updates[id] = {}
        updates[id].shoot3 = false
        if (scheduleInfo.current[day]?.shift.includes('3')) {
          updates[id].active = false
        }
      })

      // check if a crew is not available / fully booked that day
      Object.keys(updates).forEach((crewId) => {
        if (!updates[crewId].shoot1 && !updates[crewId].shoot2 && !updates[crewId].shoot3) {
          updates[crewId].active = false
        }
      })

      setIsAvailable({ ...isAvailable, ...updates })
    }
  }

  useEffect(() => {
    if (!isFlash) {
      if (oldScheduleInfo.current.days[day]) {
        if (!scheduleInfo?.current[day]) {
          scheduleInfo.current[day] = {}
        }
        setCrewSelection(oldScheduleInfo.current.days[day].crew)
        scheduleInfo.current[day].crew = oldScheduleInfo.current.days[day].crew
      }
    } else {
      if (oldFlashScheduleInfo.current.days[day]) {
        if (!scheduleInfo?.current[day]) {
          scheduleInfo.current[day] = {}
        }
        setCrewSelection(oldFlashScheduleInfo.current.days[day].crew)
        scheduleInfo.current[day].crew = oldFlashScheduleInfo.current.days[day].crew
      }
    }
  }, [oldScheduleInfo.current.days?.length, oldFlashScheduleInfo.current.days?.length, isFlash, oldScheduleInfo.current.calendarJobs])

  useEffect(() => {
    checkCrewAvailability()
  }, [toggle])

  return (
    <div className='flex flex-col justifty-between w-full px-2' >
      <div className='flex justify-between border p-3 pt-0 mt-1 rounded shadow-sm bg-all-f-white'>
        <form className='flex flex-col pl-2'>
          {crewList?.map((crew, i) => {
            return (
              <div className='flex items-center pt-3' key={`crew${i}`}>
                <input
                  type='checkbox'
                  id={`crew-${crew.id}-${day}`}
                  value={crew.id}
                  className='text-secondary-blue rounded h-[16px] w-[16px] border-navy-light'
                  onChange={() => {
                    handleCrewSelection(crew)
                  }}
                  checked={crewSelection?.includes(crew.id)}
                  disabled={isAvailable[crew.id] && !isAvailable[crew.id].active}
                >
                </input>
                <label
                  className={`text-[14px] text-dark-blue font-medium ml-3 py-[2px] px-3 rounded-2xl ${crewColors[i]} ${(isAvailable[crew.id] && !isAvailable[crew.id].active) ? 'text-mini-calendar-light-gray bg-subtle-grey' : ''}`}
                  htmlFor={`crew-${crew.id}-${day}`}
                >
                  {crew.name}
                </label>
              </div>
            )
          })}
        </form>
      </div>
    </div>
  )
}

export default MiniCalendarCrews;
