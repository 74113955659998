import React, { useState } from 'react'
import { useModal } from '../../contexts/modal'
import PersonnelSelectorButton from '../PersonnelSelectorButton'

//Note: this is a dumb component for selecting crew members and drivers for the foremen job log page.
//the locationPersonnel prop is a list of all the crew members available to select from.
//jobPersonnel is a list of only the crew members who have been selected for this job
//setJobPersonnel is a setter for that.
//everything is done generally to make the component dumb.
//two separate methods are needed for selecting and unselecting crew members
const SelectPersonnel = ({
  personnel, jobPersonnel, setJobPersonnel, allPersonnel, personnelLocation,
  updateLocationPersonnel, crewMembersOrDrivers, deletedPersonnel
}) => {
  const { modalMethods } = useModal()
  const [showDeletedPersonnel, setShowDeletedPersonnel] = useState(false);

  const selectPerson = (person) => {
    const newJobPersonnel = [...jobPersonnel, person]
    setJobPersonnel(newJobPersonnel)
  }

  const unselectPerson = (person) => {
    const personIndex = jobPersonnel.findIndex((element) => element.id === person.id)
    const newJobPersonnel = [
      ...jobPersonnel.slice(0, personIndex),
      ...jobPersonnel.slice(personIndex + 1)
    ]
    setJobPersonnel(newJobPersonnel)
  }

  const toggleShowDeletedPersonnel = () => {
    const updatedShowDeletePersonnel = !showDeletedPersonnel;
    setShowDeletedPersonnel(updatedShowDeletePersonnel);
  }


  return (
    <div className={`absolute flex flex-col m-4 p-4 h-full w-full max-w-[calc(100vw-32px)] max-h-[calc(100vh-32px)] bg-white font-roboto overflow-auto`}>
      <button onClick={(e) => {
        e.preventDefault()
        modalMethods.close()
      }}>
        <img src='/x_icon_black.svg' className='w-[30px] absolute right-[12px] top-[12px]' />
      </button>

      <div className='flex flex-col lg:flex-row justify-between lg:justify-normal'>
        <div className='lg:w-2/3 flex flex-col gap-10'>
          <div className='flex flex-col items-start'>
            <label htmlFor='altLocSelect'>Select {crewMembersOrDrivers === 'crewMembers' ? 'crew' : 'drivers'} from location:</label>
            <select
              id='altLocSelect'
              value={personnelLocation?.toString()}
              onChange={(e) => {
                updateLocationPersonnel(parseInt(e.target.value))
              }}>
              {allPersonnel.map((location) => {
                return <option value={location.id} key={`allPersonnel${location.id}`}>{location.label}</option>
              })}
            </select>
          </div>
          <div className='flex flex-wrap gap-2 overflow-y-auto min-h-[300px]'>
            {personnel?.map((person, i) => (
              <PersonnelSelectorButton
                person={person}
                selectPerson={selectPerson}
                unselectPerson={unselectPerson}
                isSelected={jobPersonnel.some(item => item.id === person.id)}
                key={`p${i}`} />
            ))}
          </div>

          <div className='flex'>
            <label htmlFor='showDeletedPersonnelCheckbox'>
              {`${crewMembersOrDrivers === 'crewMembers' ? 'Show Deleted CrewMembers'
                : 'Show Deleted Drivers'
                }`}
            </label>
            <div className='pl-2'>
              <input type='checkbox' checked={showDeletedPersonnel} onClick={toggleShowDeletedPersonnel}
                value={showDeletedPersonnel} id='showDeletedPersonnelCheckbox'
              />
            </div>
          </div>

          {showDeletedPersonnel &&
            <div className='flex flex-wrap gap-2 overflow-y-auto min-h-[300px]'>
              {deletedPersonnel?.map((person, j) => (
                <PersonnelSelectorButton
                  person={person}
                  selectPerson={selectPerson}
                  unselectPerson={unselectPerson}
                  isSelected={jobPersonnel.some(item => item.id === person.id)}
                  key={`deletedpersonnel${j}`} />
              ))}
            </div>
          }
        </div>

        <div className='lg:w-1/3'>
          <p className='font-medium'>Selected {crewMembersOrDrivers === 'crewMembers' ? 'crew members' : 'drivers'}:</p>
          {jobPersonnel.map((person, i) => <p className='font-normal text-nowrap' key={`person${i}`}>{person.user.name}</p>)}
        </div>
      </div>
    </div>
  )
}

export default SelectPersonnel