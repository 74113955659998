const { useState, useEffect, useRef } = require("react");

const CustomerMyJobsDropdownGeneral = ({
  jobType,
  setJobType,
  i,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownWrapperRef = useRef(null);

  const statusOptions = [
    {
      title: "Received",
      id: "Received",
    },
    {
      title: "Scheduled",
      id: "Scheduled",
    },
    {
      title: "Completed",
      id: "Completed",
    },
  ];

  const jobTypeOptions = [
    {
      title: "All",
      id: "All",
    },
    {
      title: "Gunite",
      id: "Gunite",
    },
    {
      title: "Plaster",
      id: "Plaster",
    },
  ];

  const handleJobTypeOptionClick = (e, itemId) => {
    e.stopPropagation();
    setJobType(itemId);
  };

  const handleStatusOptionClick = (e, itemId) => {
    e.stopPropagation();
    const updatedItemsList = selectedStatuses.includes(itemId)
      ? selectedStatuses.filter((item) => item !== itemId)
      : [...selectedStatuses, itemId];
    setSelectedStatuses(updatedItemsList);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownWrapperRef.current &&
        !dropdownWrapperRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div ref={dropdownWrapperRef} id={`dropdownRadioWrapper${i}`}>
      <img
        src="/PlannerColumn/sort_icon.svg"
        alt="sort"
        className="cursor-pointer min-w-[30px] py-[2px] px-[8px]"
        style={{ height: `18px` }}
        onClick={(e) => {
          e.preventDefault();
          setShowDropdown(!showDropdown);
        }}
      />

      <div
        className={`absolute top-11 md:right-0 transform -translate-x-28 md:-translate-x-0 z-10 lg:lef flex flex-col
        rounded-[8px] border border-navy-light p-[8px] text-[14px] bg-all-f-white shadow-md ${showDropdown ? "" : "hidden"}`}
      >
        <p>Type</p>
        {jobTypeOptions.map((column, i) => (
          <div
            key={`typeOption${i}`}
            className='flex items-center w-[155px] md:w-[200px] h-[32px] p-[6px]'
            onClick={(e) => {
              e.preventDefault()
              handleJobTypeOptionClick(e, column.id)
            }}
          >
            <img src={jobType === column.id ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px]' />
            <p  className='pl-[10px]'>
            {column.title}
            </p>
          </div>
        ))}

        <p>Status</p>
        {statusOptions.map((column, i) => (
          <div
            key={`typeOption${i}`}
            className="flex w-full items-center p-[8px] cursor-pointer"
            onClick={(e) => handleStatusOptionClick(e, column.id)}
          >
            <div
              className={`flex w-[16px] h-[16px] rounded-[4px] select-none ${
                selectedStatuses.includes(column.id)
                  ? "bg-dark-blue"
                  : "bg-all-f-white border"
              }`}
            >
              {selectedStatuses.includes(column.id) && (
                <img src="/check.svg" alt="check" className="w-full" />
              )}
            </div>

            <p className="pl-[12px] select-none">{column.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerMyJobsDropdownGeneral;
