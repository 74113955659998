import React, { useState, useRef } from "react";
import { useModal } from '../../contexts/modal'
import serviceMethods from '../../service'
import Input from "../Input";

const ForgotPasswordModal = ({ userEmail, showResetPassword, setShowResetPassword }) => {
  const { modalMethods: { close }} = useModal()
  const [emailSent, setEmailSent] = useState(false)
  const [inputEmail, setInputEmail] = useState('')
  const [userError, setUserError] = useState('')
  const modal = useRef()
  const { requestPasswordResetEmail } = serviceMethods;

  const EMAIL_REGEX = /\S+@\S+\.\S+/;
  const emailValidationError = !EMAIL_REGEX.test(inputEmail)
  const submitDisabled = emailValidationError

  const handleSendEmail = async() => {
    const emailSent = await requestPasswordResetEmail(inputEmail)
    if (emailSent.ok) {
      setUserError('')
      setEmailSent(true)
    } else {
      // inform user why request failed
      const data = await emailSent.json()
      if (data.message.includes('No users found with this email')) {
        setUserError('Email could not be found in our records')
      } else if (data.message.includes('must be a valid email')) {
        setUserError('Please enter a valid email')
      }
    }
  }

  const handleClose = () => {
    close()
    setEmailSent(false)
  }

  return (
    <div ref={modal} className="flex flex-col items-start w-fit lg:w-[760px] h-fit p-6 bg-white rounded-[8px]">

      <div
        className="flex w-full gap-2 items-center select-none cursor-pointer pb-6"
        onClick={() => {
          handleClose()
        }}
      >
          <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
          <p className="font-semibold text-gunite-red">Close</p>
      </div>

      <div className='pb-6'>

        <div className='flex flex-row items-center'>
          <p className='text-[34px] font-bold text-secondary-blue'>
            {!emailSent ? 'Reset Password' : 'Email sent'}
          </p>
          <img alt="back" src="/check_in_circle_icon.svg" className={`h-[24px] pl-3 ${emailSent ? '' : 'hidden'}`} />
        </div>

        <p className='text-[16px] text-light-blue'>
          {!emailSent ? 'Please enter your email address and we will send you a link that will reset your password.' : 'Check your email and open the link we sent to continue.'}
        </p>


      </div>

      <div className={`pb-6 w-full ${emailSent ? 'hidden' : ''}`}>

        <div className='w-full'>
          <Input

            placeholder={'Email Address *'}
            value={inputEmail}
            onChange={(e) => {
              setInputEmail(e.target.value)
            }}
            isError={emailValidationError}
          />
        </div>
      </div>

      <div className='flex w-full items-center'>

        {!emailSent ?
          <button
            className={`rounded px-9 py-3 font-bold ${submitDisabled ? "bg-black/10 text-black/40" : "bg-gunite-red text-white"}`}
            disabled={submitDisabled}
            onClick={() => {
              handleSendEmail()
            }}
          >
            Reset password
          </button>
          :
          <button
            className='rounded px-9 py-3 text-tertiary-blue bg-white border-[3px] border-light-blue text-[24px] font-bold'
            onClick={() => {
              handleClose()
            }}
          >
            Back to homescreen
          </button>
        }

        <p className={`text-gunite-red pl-10 ${userError === '' ? 'hidden' : ''}`}>
          {userError}
        </p>
      </div>

    </div>
  )
}

export default ForgotPasswordModal;
