import React, { useState } from 'react'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'
import _ from 'underscore'
import CalendarJobCard from '../CalendarJobCard'
import { useScheduler } from '../../contexts/scheduler'

const Timeslot = ({ i, scheduledDate, crewId, timeslotJob, shoot }) => {
  const { interval, displayMode } = useSchedulerCalendar()
  const { crewColors } = useScheduler()

  const uniqueId = _.uniqueId() //not needed anymore?

  const cardData = {
    scheduledDate: scheduledDate,
    crewId: crewId,
    shift: shoot
  }

  const borderNames = (i) => {
    if (i === 0) return 'border-[#f8f5c2]'
    if (i === 1) return 'border-[#b9fbc0]'
    if (i === 2) return 'border-[#9fe7ff]'
    if (i === 3) return 'border-[#fde4cf]'
    if (i === 4) return 'border-[#f1c0e8]'
    if (i === 5) return 'border-[#ffcfd2]'
    if (i === 6) return 'border-[#99e4eb]'
    if (i === 7) return 'border-[#d1bba7]'
    if (i === 8) return 'border-[#cbbaf0]'
    if (i === 9) return 'border-[#8bebbe]'
    if (i === 10) return 'border-[#bcd8ff]'
    if (i === 11) return 'border-[#bcc4d1]'
  }

  const timeslotDisplay = () => {
    if (interval === 'week' && displayMode === 'vertical') {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCard job={timeslotJob} i={i} />
            :
            <div
              className={`droppable bg-opacity-30 flex justify-center items-center ${crewColors[i]} 
              basis-1/3 
              ${borderNames(i)}
              border-dashed`}
              id={uniqueId}
              data-timeslotdata={JSON.stringify(cardData)}
            />
          }
        </>
      )
    } else if (interval === 'week' && displayMode === 'horizontal') {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCard job={timeslotJob} i={i} />
            :
            <div
              className={`droppable h-[44px] flex flex-1 justify-center items-center ${crewColors[i]} bg-opacity-30 border-dashed ${borderNames(i)}`}
              id={uniqueId}
              data-timeslotdata={JSON.stringify(cardData)}
            />
          }
        </>
      )
    } else if (interval === 'month' && displayMode === 'vertical') {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCard job={timeslotJob} i={i} />
            :
            <div
              className={`droppable flex flex-1 justify-center items-center ${crewColors[i]} bg-opacity-30 h-1/4 border-dashed ${borderNames(i)}`}
              id={uniqueId}
              data-timeslotdata={JSON.stringify(cardData)}
            />
          }
        </>
      )
    } else if (interval === 'month' && displayMode === 'horizontal') {
      return (
        <>
          {timeslotJob
            ?
            <CalendarJobCard job={timeslotJob} i={i} />
            :
            <div
              className={`droppable flex h-[20px] justify-center items-center ${crewColors[i]} bg-opacity-30 border-dashed ${borderNames(i)}`}
              id={uniqueId}
              data-timeslotdata={JSON.stringify(cardData)}
            />
          }
        </>
      )
    }
  }


  return (
    <>{timeslotDisplay()}</>
  )
}

export default Timeslot