import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useJobDetailsModal } from '../../contexts/jobDetailsModal';
import { useScheduler } from '../../contexts/scheduler';

const ScrollContainer = styled.div`
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;

const JobDetailsTabSelector = ({ selectedContent, setSelectedContent }) => {
  const { selectedJobDetailsTab, isCostBreakdownChange, setShowCostBreakdownWarning, setSelectedJobDetailsTab } = useJobDetailsModal();
  const { selectedJobId, selectedScheduler } = useScheduler()
  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId);
  const [showCostBreakdown, setShowCostBreakdown] = useState(false);

  const darkBlue = '#00154D'

  const tabValidator = () => {
    const options = ["Job Details", "Files", "Notes", "Activity log"]
    if (selectedJob.scheduledAt) {
      options.push('Daily job log')
    }
    if (showCostBreakdown) {
      options.push('Cost Breakdown')
    }
    return options
  }

  const tabOptions = tabValidator()

  const checkForJobLog = (calendarJobs) => {
    if (calendarJobs.some((job) => job.jobLog === null) || !calendarJobs.length){
      setShowCostBreakdown(false)
      return false
    } else {
      setShowCostBreakdown(true)
      return true
    }
  }

  useEffect(()=>{
    checkForJobLog(selectedJob.calendarJobs)
  }, [selectedJob, selectedJobDetailsTab])

  const handleOptionClick = (option) => {
    if (selectedJobDetailsTab === 'Cost Breakdown' && isCostBreakdownChange.current) {
      setShowCostBreakdownWarning({ show: true, continueFunction: () => { setSelectedContent(option) } })
      return
    }
    setSelectedContent(option);
  };

  useEffect(() => {
    if ((selectedJobDetailsTab === 'Cost Breakdown' && !checkForJobLog(selectedJob.calendarJobs)) || (selectedJobDetailsTab === 'Daily job log' && selectedJob.scheduledAt === null )) {
      setSelectedJobDetailsTab('Job Details')
    }
  }, [selectedJobId])

  return (
    <ScrollContainer className="md:mx-4 overflow-x-auto whitespace-nowrap items-center border-b border-navy-light">
      {tabOptions.map((option, index) => (
          <button
            onClick={() => handleOptionClick(option)}
            style={{
              color: selectedContent === option ? '#00008b' : '#123456',
              borderBottom: selectedContent === option ? `2px solid ${darkBlue}` : '2px solid transparent'
            }}
            className="text-navy-bright pb-2 px-4"
            key={`tab-options-${index}`}
          >
            {option}
          </button>
      ))}
    </ScrollContainer>
  );
};

export default JobDetailsTabSelector;
