// Materials --> Manufacturers --> Products --> Tiers --> Colors
import { useAdmin } from "../../contexts/admin";
import FilesBox from "../FilesBox";
import AddButton from "../AddButton";
import AdminMaterialsProvider from "../../contexts/adminMaterials";
import AdminMaterialsBreadcrumbs from "../AdminMaterialsBreadcrumbs";
import MaterialsDataTable from "../MaterialsDataTable";
import { useAdminMaterials } from "../../contexts/adminMaterials";
import AdminMaterialsAddOrEditItemModal from "../AdminMaterialsAddOrEditItemModal";
import AdminMaterialsAddOrEditProductModal from "../AdminMaterialsAddOrEditProductModal";
import AdminMaterialsAddOrEditTierModal from "../AdminMaterialsAddOrEditTierModal";
import ConfirmPopup from "../ConfirmPopup";
import AdminMaterialsDetailsBox from "../AdminMaterialsDetailsBox";

const AdminGlobalMaterials = () => {
  const {
    addOrEditItemModal,
    setAddOrEditItemModal,
    breadcrumbs,
    showConfirmPopup,
    setShowConfirmPopup,
    deleteItems,
  } = useAdminMaterials();

  const addButtonText = breadcrumbs?.currentPage === 'Materials' ? 'Add material' :
    breadcrumbs?.currentPage === 'Manufacturers' ? 'Add manufacturer' :
    breadcrumbs?.currentPage === 'Products' ? 'Add product' :
    breadcrumbs?.currentPage === 'Tiers' ? 'Add tier' :
    breadcrumbs?.currentPage === 'Colors' ? 'Add color' : '';

  return (
    <div>
        <AdminMaterialsBreadcrumbs />
        <div className="w-full overflow-y-scroll flex flex-col justify-start items-center">

          <AdminMaterialsDetailsBox />

          {breadcrumbs?.currentPage != 'ColorDetails' && (
            <>
              <MaterialsDataTable />
              <AddButton text={addButtonText} className={`w-full`} onClick={()=>setAddOrEditItemModal({editMode: false, open: true })}/>
            </>
          )}

          {addOrEditItemModal.open && ( 
            <>
              {(breadcrumbs?.currentPage === 'Materials' || breadcrumbs?.currentPage === 'Manufacturers') && (
                <AdminMaterialsAddOrEditItemModal /> 
              )}
              {(breadcrumbs?.currentPage === 'Products') && (
                <>
                  {addOrEditItemModal.editMode ? (
                    <AdminMaterialsAddOrEditItemModal /> 
                  ) : ( 
                    <AdminMaterialsAddOrEditProductModal /> 
                  )}
                </>
              )}
              {(breadcrumbs?.currentPage === 'Tiers') && (
                <>
                  {addOrEditItemModal.editMode ? (
                    <AdminMaterialsAddOrEditProductModal /> 
                    ) : (
                    <AdminMaterialsAddOrEditTierModal /> 
                  )}
                </>
              )}
              {(breadcrumbs?.currentPage === 'Colors') && (
                <>
                {addOrEditItemModal.editMode ? (
                  <AdminMaterialsAddOrEditTierModal /> 
                  ) : (
                  <AdminMaterialsAddOrEditItemModal /> 
                )}
              </>
              )}
              {(breadcrumbs?.currentPage === 'ColorDetails') && (
                <AdminMaterialsAddOrEditItemModal />
              )}
            </>
          )}
        </div>
        {showConfirmPopup && (
          <ConfirmPopup 
            header={`Delete ${breadcrumbs?.currentPage.toLowerCase()}`}  
            description={`This deletion will also trigger deletion of all associated materials data. Are you sure you want to continue?`} 
            confirmButtonText={"Delete"}
            onCancel={() => setShowConfirmPopup(false)}
            onConfirm={() => {deleteItems(); setShowConfirmPopup(false)}}
            className="top-[300px]"
          />
        )}
    </div>
  );
}

export default AdminGlobalMaterials;
