import React from "react";

const SchedulerJobLogDateSelectorDay = ({ selectedDays, setSelectedDays, dateInfo, isMultiDay, today, blank, allowSelectPast = false, askIfContinueDate }) => {
  const isDaySelected = (remove = false) => {
    for (let i = 0; i < selectedDays?.length; i++) {
      if (
        selectedDays[i].getFullYear() === dateInfo.year
        && selectedDays[i].getMonth() === dateInfo.month
        && selectedDays[i].getDate() === dateInfo.day
      ) {
        if (remove) {
          setSelectedDays([...selectedDays.slice(0, i), ...selectedDays.slice(i + 1)])
        }
        return true
      }
    }
    return false
  }

  let selectedFlag = isDaySelected()
  let isMiddleDay = false
  let isFirstDay = false
  let isLastDay = false

  const isToday = (
    today?.day === dateInfo?.day
    && today?.month === dateInfo?.month
    && today?.year === dateInfo?.year
  )

  // sets styling for days that are next to each other
  const setSelectedDayCssStyle = () => {
    let previousDaySelected = false
    let nextDaySelected = false
    if (selectedFlag) {
      for (let i = 0; i < selectedDays.length; i++) {
        if (
          selectedDays[i].getFullYear() === dateInfo.year
          && selectedDays[i].getMonth() === dateInfo.month
          && selectedDays[i].getDate() === dateInfo.day - 1
        ) {
          previousDaySelected = true
          continue
        }

        if (
          selectedDays[i].getFullYear() === dateInfo.year
          && selectedDays[i].getMonth() === dateInfo.month
          && selectedDays[i].getDate() === dateInfo.day + 1
        ) {
          nextDaySelected = true
        }
      }
    }
    if (previousDaySelected && nextDaySelected) {
      selectedFlag = false
      isMiddleDay = true
    } else if (!previousDaySelected && nextDaySelected) {
      selectedFlag = false
      isFirstDay = true
    } else if (previousDaySelected && !nextDaySelected) {
      selectedFlag = false
      isLastDay = true
    }
  }
  setSelectedDayCssStyle()

  const daySetter = () => {
    // if already selected, return
    if (isDaySelected()) return

    // else, ask the user if they want to continue
    askIfContinueDate(dateInfo)

  }

  return (
    <div className={`w-[34px] h-[24px]`}>
      {blank ? '' : (
        <button
          className={
            `flex h-full w-full items-center justify-center font-bold text-xs text-dark-blue
            ${selectedFlag ? 'selected bg-dark-blue text-white rounded-md' : ''}
            ${isFirstDay ? 'selected rounded-l-md bg-dark-blue text-white' : ''}
            ${isLastDay ? 'selected rounded-r-md bg-dark-blue text-white' : ''}
            ${isMiddleDay ? 'selected bg-dark-blue text-white' : ''}
            ${isToday ? 'text-gunite-red' : ''}`
          }
          onClick={(e) => {
            e.preventDefault()
            daySetter()
          }}
        >
          {dateInfo?.day}
        </button>
      )}

    </div>
  )
}

export default SchedulerJobLogDateSelectorDay;
