import React, { useState, useEffect, useRef } from "react";
import CostBreakdownSelector from "../CostBreakdownSelector";
import { useScheduler } from "../../contexts/scheduler";
import CostBreakdownInput from "../CostBreakdownInput";

const CostBreakdownAddEmployee = ({ setAddingActive, add, crewUsed = [], DEFAULT_CREW, isPlaster,
   plasterType, addContractor, contractorsUsed = [], selectedJob
  }) => {
  const { crews } = useScheduler()
  const [newMember, setNewMember] = useState(DEFAULT_CREW)
  const [displayMembers, setDisplayMembers] = useState([])
  const [disableAdd, setDisableAdd] = useState(false)
  const [addMode, setAddMode] = useState('')

  useEffect(() => {
    // list of names who are unavailable
    const unavailable = crewUsed.map((member) => member.name)

    // list of names to use as options for the selector
    const displayMembers = []

    const calendarJobs = selectedJob?.calendarJobs;
    for (const calJob of calendarJobs){
      if (calJob.jobLog){
        for (const crewMember of calJob.jobLog.crewMembers){
          if (crewMember?.crewMember?.user?.name &&
            !unavailable.includes(crewMember.crewMember.user.name) &&
            !displayMembers.some((member) => member.name === crewMember?.crewMember?.user?.name
          )){
            const plasterPoolRate =  crewMember.crewMember.plasterPoolRate
            const quartzPoolRate = crewMember.crewMember.quartzPoolRate
            const pebblePoolRate = crewMember.pebblePoolRate
            const gunitePoolRate = crewMember.crewMember.poolRate
            let rateCrew = isPlaster ? plasterPoolRate : gunitePoolRate
  
            if (isPlaster && plasterType) {
              if (plasterType === 'quartz' && quartzPoolRate) {
                rateCrew = quartzPoolRate
              } else if (plasterType === 'pebble' && pebblePoolRate) {
                rateCrew = pebblePoolRate
              }
            }
            const memberObj = {
              ...DEFAULT_CREW,
              plasterPoolRate: plasterPoolRate,
              quartzPoolRate: quartzPoolRate,
              pebblePoolRate: pebblePoolRate,
              gunitePoolRate: gunitePoolRate,
              poolRate: rateCrew,
              name: crewMember.crewMember.user.name
            }
            displayMembers.push(memberObj)
          }
        }
      } 
    }

    for (const crew of crews) {
      if (!unavailable.includes(crew.foreman.user.name) &&
        !displayMembers.some((person) => person.name === crew.foreman.user.name)
      ){
        // collects the foremans info
        const plasterPoolRate =  crew.foreman.plasterPoolRate
        const quartzPoolRate = crew.foreman.quartzPoolRate
        const pebblePoolRate = crew.foreman.pebblePoolRate
        const gunitePoolRate = crew.foreman.gunitePoolRate
        let rate = isPlaster ? plasterPoolRate : gunitePoolRate

        if (isPlaster && plasterType) {
          if (plasterType === 'quartz' && quartzPoolRate) {
            rate = quartzPoolRate
          } else if (plasterType === 'pebble' && pebblePoolRate) {
            rate = pebblePoolRate
          }
        }

        const memberObj = {
          ...DEFAULT_CREW,
          plasterPoolRate: plasterPoolRate,
          quartzPoolRate: quartzPoolRate,
          pebblePoolRate: pebblePoolRate,
          gunitePoolRate: gunitePoolRate,
          poolRate: rate,
          name: crew.foreman.user.name
        }
        displayMembers.push(memberObj)
      }
      for (const member of crew.crewMembers) {
        if (!unavailable.includes(member.user.name) && 
          !displayMembers.some((person) => person.name === member.user.name)
        ){
          // collects the crew members info
          const plasterPoolRateCrew =  member.plasterPoolRate
          const quartzPoolRateCrew = member.quartzPoolRate
          const pebblePoolRateCrew = member.pebblePoolRate
          const gunitePoolRateCrew = member.poolRate
          let rateCrew = plasterPoolRateCrew ? plasterPoolRateCrew : gunitePoolRateCrew

          if (isPlaster && plasterType) {
            if (plasterType === 'quartz' && quartzPoolRateCrew) {
              rateCrew = quartzPoolRateCrew
            } else if (plasterType === 'pebble' && pebblePoolRateCrew) {
              rateCrew = pebblePoolRateCrew
            }
          }

          const memberObj = {
            ...DEFAULT_CREW,
            plasterPoolRate: plasterPoolRateCrew,
            quartzPoolRate: quartzPoolRateCrew,
            pebblePoolRate: pebblePoolRateCrew,
            gunitePoolRate: gunitePoolRateCrew,
            poolRate: rateCrew,
            name: member.user.name
          }
          displayMembers.push(memberObj)
        }
      }
    }
    setDisplayMembers(displayMembers)
  }, [])

  const updateState = (e) => {
    const newMember = displayMembers.find((member) => member.name === e.target.value)
    if (!newMember) {
      setNewMember(DEFAULT_CREW)
    } else {
      setNewMember(newMember)
    }
  }

  const updateContractorState = (e, target) => {
    if (!newMember) {
      setNewMember({
        ...DEFAULT_CREW,
        [target]: e.target.value
      })
    } else {
      setNewMember({
        ...newMember,
        [target]: e.target.value
      })
    }
  }

  // add button validation
  useEffect(() => {
    if (newMember?.name === '' || !newMember?.name) {
      setDisableAdd(true)
    } else {
      setDisableAdd(false)
    }
  }, [newMember?.name])

  useEffect(() => {
    updateContractorState({ target: { value: '' } }, 'name')
  }, [newMember.isContractor])

  return (

    <div className='flex flex-col md:flex-row w-full p-4 mt-4 justify-between items-end rounded border bg-subtle-grey gap-4'>
      <div className='flex flex-col w-full md:w-1/2 gap-4'>
        <div className='flex w-full md:w-3/4 mt-[-8px]'>
          <CostBreakdownSelector
            value={addMode}
            options={['Crew member', 'Contractor']}
            placeholder='Select option'
            onChange={(e) => {
              setAddMode(e.target.value)
              if (e.target.value === 'Contractor') {
                updateContractorState({ target: { value: true } }, 'isContractor')
              } else {
                updateContractorState({ target: { value: false } }, 'isContractor')
              }
            }}
          />
        </div>
        {addMode === 'Crew member' &&
          <div className='flex w-full md:w-3/4 mt-[-8px]'>
            <CostBreakdownSelector
              value={newMember?.name}
              options={displayMembers.map((member) => member.name)}
              placeholder='Crew member'
              onChange={(e) => {
                updateState(e)
              }}
            />
          </div>
        }
        {addMode === 'Contractor' &&
          <>
            <div className='flex pt-[8px] w-full md:w-3/4 mt-[-8px]'>
              <CostBreakdownInput
                  value={newMember.name || ''}
                  placeholder='Company'
                  onChange={(e) => {
                    updateContractorState(e, 'name')
                  }}
                />
            </div>
            <div className='flex pt-[8px] w-full md:w-3/4 mt-[-8px]'>
              <CostBreakdownInput
                  value={newMember.contractorService || ''}
                  placeholder='Service / Notes'
                  onChange={(e) => {
                    updateContractorState(e, 'contractorService')
                  }}
                />
            </div>
          </>
        }
      </div>

      <div className='flex w-full md:w-1/2 justify-end h-fit gap-4'>
        <button
          className='border border-navy-light rounded bg-all-f-white py-2 px-3 font-semibold text-dark-blue hover:text-white hover:bg-gunite-red'
          onClick={() => {
            setAddingActive(false)
          }}
        >
          Cancel
        </button>
        <button
          className={`border border-navy-light rounded py-2 px-4 font-semibold  ${disableAdd ? 'text-opaque-job-details-gray bg-disabled-gray' : 'bg-white text-dark-blue hover:bg-dark-blue hover:text-white'}`}
          disabled={disableAdd}
          onClick={() => {
            setAddingActive(false)
            if (addMode === 'Contractor') {
              addContractor(newMember)
            } else {
              add(newMember)
            }
          }}
        >
          Add
        </button>
      </div>

    </div>
  );
};

export default CostBreakdownAddEmployee;
