import React, { useState, useEffect } from "react";
import Input from '../Input'
import Selector from '../Selector';
import { useIntakeForm } from '../../contexts/intakeForm'
import PhoneInput from "../PhoneInput";

const SupervisorForm = ({ job, i, jobDetails, setJobDetails }) => {
  const EMAIL_REGEX = /\S+@\S+\.\S+/;
  const formattedPhonePattern = /^(\(\d{3}\)[\s])\d{3}\-\d{4}$/;
  const firstNameError = !job.supervisorFName
  const lastNameError = !job.supervisorLName
  const phoneError = !formattedPhonePattern.test(job.supervisorPhone)
  const emailError = !EMAIL_REGEX.test(job.supervisorEmail)

  return (
    <>
      <p className="text-secondary-blue/60 text-base font-semibold">
        Supervisor {jobDetails.length > 1 && ` for Job #${i+1}: ${jobDetails[i].jobName}`}
      </p>
      <form className="flex flex-col gap-6" key={`jobSuper${i}`}>
        <div className="flex gap-6">
          {/* First Name field */}
          <div className="w-1/2">
            <Input
              placeholder={'First Name *'}
              value={jobDetails[i].supervisorFName}
              onChange={(e) => {
                const newJob = {
                  ...jobDetails[i],
                  supervisorFName: e.target.value
                }
                const newJobDetails = [
                  ...jobDetails.slice(0, i),
                  newJob,
                  ...jobDetails.slice(i + 1),
                ]
                setJobDetails(newJobDetails)
              }}
              isError={firstNameError}
            />
          </div>
          {/* Last Name field */}
          <div className="w-1/2">
            <Input
              placeholder={'Last Name *'}
              value={jobDetails[i].supervisorLName}
              onChange={(e) => {
                const newJob = {
                  ...jobDetails[i],
                  supervisorLName: e.target.value
                }
                const newJobDetails = [
                  ...jobDetails.slice(0, i),
                  newJob,
                  ...jobDetails.slice(i + 1),
                ]
                setJobDetails(newJobDetails)
              }}
              isError={lastNameError}
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Phone field */}
          <div className="lg:w-1/2">
            <PhoneInput 
              value={jobDetails[i].supervisorPhone}
              placeholder={'Phone *'}
              onChange={(e) => {
                const newJob = {
                  ...jobDetails[i],
                  supervisorPhone: e.target.value
                }
                const newJobDetails = [
                  ...jobDetails.slice(0, i),
                  newJob,
                  ...jobDetails.slice(i + 1),
                ]
                setJobDetails(newJobDetails)
              }}
              isError={phoneError}
            />
          </div>
          {/* Email field */}
          <div className="lg:w-1/2">
            <Input
              placeholder={'Email address *'}
              value={jobDetails[i].supervisorEmail}
              onChange={(e) => {
                const newJob = {
                  ...jobDetails[i],
                  supervisorEmail: e.target.value
                }
                const newJobDetails = [
                  ...jobDetails.slice(0, i),
                  newJob,
                  ...jobDetails.slice(i + 1),
                ]
                setJobDetails(newJobDetails)
              }}
              isError={emailError}
            />
          </div>
        </div>
        {/* Notify me field */}
        <div className="relative lg:flex lg:gap-6">
          <Selector
            value={jobDetails[i].notificationMethod}
            placeholder={'Notify me via *'}
            options={['Phone', 'Email', 'SMS']}
            onChange={(e) => {
              const newJob = {
                ...jobDetails[i],
                notificationMethod: e.target.value
              }
              const newJobDetails = [
                ...jobDetails.slice(0, i),
                newJob,
                ...jobDetails.slice(i + 1),
              ]
              setJobDetails(newJobDetails)
            }}
          />
        </div>
      </form>
    </>
  )
}

export default SupervisorForm;
