import React, { useState } from 'react'
import _ from 'underscore';

const CostBreakdownSelector = ({value, placeholder, defaultOption = '', options = [], onChange, disabled, validate = true }) => {
  const [touched, setTouched] = useState(false)
  const isError = touched && !value
  const selectStyles = () => {
    if (disabled) {
      return `peer mt-0 block w-full px-3 border-0 text-base text-black/20 border-black/50`
    } else {
      return `peer mt-0 block w-full px-3 border focus:ring-0 rounded focus:border-black text-dark-blue ${isError && validate ? 'text-red-500 border-red-500' : 'border-black/42'}`
    }
  }
  const uniqueId = `selector-${_.uniqueId()}`

  return (
    <div className={`group w-full`}>
      <label
        htmlFor={uniqueId}
        className={`text-xs text-dark-blue`}
      >{placeholder}</label>

      <select
        id={uniqueId}
        value={value}
        className={selectStyles()}
        onChange={(e) => {
          setTouched(false)
          onChange(e)}}
        onBlur={() => {
          setTouched(true)
        }}
        disabled={disabled}
      >
        <option className='text-dark-blue' value=''>{defaultOption}</option>
        {options.map((option, i) => (
          <option key={i} className='text-dark-blue' value={option}>{option}</option>
        ))}
      </select>

    </div>
  )
}

export default CostBreakdownSelector
