import React, { useState } from 'react'
import { MdOutlineClose } from 'react-icons/md';
import { RxHamburgerMenu } from "react-icons/rx";
import { useScheduler } from '../../contexts/scheduler';
import { useAuth } from '../../contexts/auth';

const SchedulerTopBar = () => {
  const {showMobileNav, setShowMobileNav, selectedScheduler } = useScheduler()
  const { me } = useAuth()
  const apiUrl = process.env.REACT_APP_API_URL;

  const logoImage = me?.role === 'SCHEDULER' || me?.role === 'ADMIN'
    ? `${apiUrl}/${selectedScheduler?.location?.image}`
    : "/sidebar/gunite_logo_sm.svg"

  const handleToggle = () => {
    setShowMobileNav(!showMobileNav)
  }

  return (
    <>
      <div className='lg:hidden py-3 px-6 w-screen bg-dark-blue'>
        <div className='w-full flex flex-row justify-between items-center lg:hidden text-white bg-dark-blue'>
          <img src={`${logoImage}`} alt="Main Logo" className="w-10" crossOrigin='anonymous'/>
          <div onClick={handleToggle} className='cursor-pointer overflow-hidden'>
            {!showMobileNav &&(
              <RxHamburgerMenu onClick={handleToggle} className='text-3xl'/>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SchedulerTopBar
