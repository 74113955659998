const CostBreakdownResetWarning = ({ header, description, onContinue, onCancel }) => {
  return (
    <div className='flex justify-center items-center fixed w-screen h-screen top-0 left-0 bg-navy-light bg-opacity-60 z-10'>
      <div className="w-[500px] rounded-[4px] bg-all-f-white border border-navy-light p-4">
        <h2 className="text-lg font-bold text-dark-blue">{header}</h2>
        <p className="text-gray-blue my-4">{description}</p>
        <div className="flex justify-center gap-4">

          <button
            onClick={() => {
              onCancel()
            }}
            className="bg-white hover:bg-dark-blue text-dark-blue hover:text-white rounded-md border border-gray-300 py-2 px-4 mr-2"
          >
            Cancel
          </button>

          <button
            onClick={() => {
              onContinue()
            }}
             className="bg-white hover:bg-gunite-red text-gunite-red hover:text-white border border-gunite-red rounded-md py-2 px-4 "
          >
            Reset
          </button>

        </div>
      </div>
    </div>
  );
};

export default CostBreakdownResetWarning;
