import React from 'react';
import './index.scss';
import { useLocation } from 'react-router-dom';
import ProgressBar from '../ProgressBar';
import WarningMessage from '../WarningMessage';
import { useProgress } from '../../contexts/progress';

function ProgressPaneMobile(props) {
  const { pathname } = useLocation();
  const {
    currentStepGunite, currentStepPlaster, currentHeaderGunite,
    currentHeaderPlaster, currentSubTextGunite, currentSubTextPlaster,
    skipASRChoice, navigateToStart, zipCode, setZipCode,
    navigatePrevious, startPlaster
  } = useProgress();

  const handleChange = (event) => {
    setZipCode(event.target.value);
  }

  const { centerMapOnZip } = props;

  const shouldApplyMinHeight = (currentStepGunite === 3 && !skipASRChoice) ||
    (currentStepGunite === 2 && skipASRChoice) ||
    (currentStepPlaster === 2)

  return (
    <div className={`w-full opacity-90 p-[30px] progress_mobile_outer_container
      ${(!skipASRChoice && currentStepGunite >= 4 || skipASRChoice && currentStepGunite >= 3 || currentStepPlaster >= 3) ?
        'max-h-[300px]'
        :
        'max-h-[215px]'
      }
      ${shouldApplyMinHeight ?
        'min-h-[198px]'
        :
        ''
      }
    `}>

      {pathname.includes('choose-location') &&
        <div className='flex flex-col gap-3 bg-dark-blue/90 px-6'>
          <button onClick={() => navigateToStart()}>
            <div className="flex gap-2 items-center">
              <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
              <p className="font-semibold text-gunite-red text-sm font-medium">Back</p>
            </div>
          </button>
          <div className='flex flex-col gap-1'>
            <p className='text-white font-bold text-2xl'>Choose the service provider</p>
            <p className='text-white/60 text-sm font-medium'>Narrow down your location</p>
          </div>
          <form className='flex'
            onSubmit={(e) => {
            e.preventDefault();
            centerMapOnZip(zipCode)}}>
            <input 
              className='w-full'
              placeholder='Zip code *' 
              type="text"
              id="newZipMobile"
              value={zipCode}
              onChange={(e)=>{
                setZipCode(e.target.value)
              }}
            />
            <button>
              <div className='ml-[4px] p-[10px] w-10 bg-gunite-red'>
                <img src='/white_search_iconB.png'/>
              </div>
            </button>
          </form>
        </div>
      }

      {(currentStepGunite > 0 || currentStepPlaster > 0) && (
        <>
          <div className='flex justify-between'>
            <div className='col-span-1'>
              <button onClick={() => {
                pathname.includes('/plaster/intake-3a') ? startPlaster() : navigatePrevious();
              }}>
                <div className='grid-cols-2 flex text-#DB003C text-sm progress_back_and_arrow_container'>
                  <img src='/back_arrow_red.svg' alt='back arrow'/>
                  &nbsp;&nbsp;Back
                </div>
              </button>
            </div>

            {pathname.includes('gunite') ?
              <div className='col-span-1 text-white text-sm'>
                Step&nbsp;{currentStepGunite}&nbsp;
                {skipASRChoice ? 'of 4' : 'of 5'}
              </div>
              :
              <div className='col-span-1 text-white text-sm'>
                Step&nbsp;{currentStepPlaster}&nbsp;of 4
              </div>
            }
          </div>
        </>
      )}

      <div className='col-span-2 text-white text-2xl mt-2.5 font-bold progress_white_header_text'>
        {pathname.includes('gunite') ?
          currentHeaderGunite
          :
          currentHeaderPlaster
        }
      </div>
      <div className='col-span-2 opacity-70 text-white progress_white_subheader_text'>
        {pathname.includes('gunite') ?
          currentSubTextGunite
          :
          currentSubTextPlaster
        }
      </div>
      {(skipASRChoice && currentStepGunite === 2 || !skipASRChoice && currentStepGunite === 3)
        && pathname.includes('gunite') &&
        <ProgressBar totalSteps={4} />
      }
      {currentStepPlaster === 2 && pathname.includes('plaster') &&
        <ProgressBar totalSteps={4} />
      }
      {(!skipASRChoice && currentStepGunite === 4 || skipASRChoice && currentStepGunite === 3 ||currentStepPlaster === 3) &&
        <>
          <div className='col-span-3'>
            <WarningMessage />
          </div>
        </>
      }
      {(currentStepGunite === 5 || currentStepPlaster === 4) &&
        <>
          <div className='col-span-3'>
            <WarningMessage />
          </div>
        </>
      }
    </div>
  )
}

export default ProgressPaneMobile;
