import React, { createContext, useContext, useState, useEffect } from 'react';
import serviceMethods from "../service";
import { useAdmin } from './admin';
import { useAuth } from './auth';

export const AdminRatesContext = createContext();

const AdminRatesProvider = props => {
  const [rate, setRate] = useState([]);
  const { me } = useAuth()

  const updateRate = async (formValues) => {
    const body = {
      createdById: me.id,
      rateSuperObject: formValues
    }
    await serviceMethods.createRate(body)
    refreshRate()
  }

  async function refreshRate() {
    const res = await serviceMethods.readRate()
    if (res.ok) {
      const tempRate = await res.json();
      setRate(tempRate)
    }
  }

  useEffect(()=>{
    refreshRate()
  },[])

  return (
    <AdminRatesContext.Provider value = {{
     rate,
     updateRate
    }}>
      {props.children}
    </AdminRatesContext.Provider>
  )
}

const useAdminRates = () => useContext(AdminRatesContext)
export { useAdminRates }
export default AdminRatesProvider;
