import React, { useEffect, useState } from 'react'
import { useSearchParams, Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/auth'
import { useResponsive } from '../../../contexts/responsive'
import moment from 'moment'
import { useIntakeForm } from '../../../contexts/intakeForm'
import { formatPhoneNumber, asrBranches } from '../../../utils'

const JobDetails = () => {
  const { me } = useAuth()
  const { retrievedLocationsGunite, retrievedLocationsPlaster } = useIntakeForm();
  let [ searchParams ] = useSearchParams()
  const jobId = parseInt(searchParams.get('id'))
  const job = Array.isArray(me?.jobs) ? me.jobs.find(job => job.id === jobId) : null
  const requestedDate = job?.requestedDate ? moment(job.requestedDate) : ''
  const displayDate = requestedDate ? requestedDate.format('MMM Do, YYYY') : ''
  const [locationDisplayName, setLocationDisplayName] = useState('');
  const [locationDisplayPhone, setLocationDisplayPhone] = useState('');

  useEffect(()=>{
    if (job?.jobType === 'gunite'){
      const matchingLocation = retrievedLocationsGunite.find(location => location?.id === job?.locationId)
      setLocationDisplayName(matchingLocation?.label)
      setLocationDisplayPhone(matchingLocation?.phone)
    } else if (job?.jobType === 'plaster'){
      const matchingLocation = retrievedLocationsPlaster.find(location => location?.id === job?.locationId)
      setLocationDisplayName(matchingLocation?.label)
      setLocationDisplayPhone(matchingLocation?.phone)
    }
  },[job])

  const formattedPhoneNumber = formatPhoneNumber(locationDisplayPhone);

  const { responsive } = useResponsive();
  const isMobileView = (
    responsive.breakpoint === 'sm'
    || responsive.breakpoint === 'md'
  )

  return !job ? null : (
    <div className='flex flex-col h-full w-full lg:max-w-[980px] min-h-[calc(100vh-276px)] lg:min-h-[calc(100vh-188px)] lg:p-20 lg:gap-12 lg:m-auto'>
      <div className='hidden lg:flex flex-col gap-6'>
        <button>
          <Link to="/customer/myjobs">
            <div className='flex items-center gap-2'>
              <img src='/back_arrow_red.svg' />
              <p className='font-semibold text-gunite-red'>Back</p>
            </div>
          </Link>
        </button>
        <p className='font-bold text-[34px] text-secondary-blue'>{job.jobName}</p>
        {/* Notifications will go here */}
      </div>
      <div className='flex flex-col lg:flex-row lg:gap-12'>
        <div className='flex flex-col w-full px-6 py-4 gap-6 bg-navy-bright/10 lg:rounded-lg lg:border border-navy-light'>
          <p className='text-xl lg:text-2xl font-bold text-secondary-blue'>Details</p>
          <div className='flex flex-col gap-3'>
            <div className='flex gap-2 items-center'>
              <img src='/JobDetails/status_icon.svg' alt='Status icon' />
              <p className='text-[14px] text-gray-blue'>Status:</p>
              <img src='/JobDetails/received_badge.svg' />
            </div>
            <div className='flex gap-2 items-center'>
              <img src='/JobDetails/map_pin.svg' alt='map icon' />
              <p className='text-[14px] text-gray-blue'>Address:</p>
              <p className='text-[14px] font-semibold text-dark-blue'>{job.address}</p>
            </div>
            <div className='flex gap-2 items-center'>
              <img src='/JobDetails/requested_date_icon.svg' alt='calendar icon' />
              <p className='text-[14px] text-gray-blue'>Requested Date:</p>
              <p className='text-[14px] font-semibold text-dark-blue'>{displayDate}</p>
            </div>
          </div>
        </div>

        {isMobileView &&
          <div className='border-b-2 border-navy-light'></div>
        }

        <div className='flex flex-col w-full px-6 py-4 gap-6 bg-navy-bright/10 lg:rounded-lg lg:border border-navy-light'>
          <p className='text-xl lg:text-2xl font-bold text-secondary-blue'>Contact</p>
          <div className='flex flex-col gap-3'>
            <p className='text-[14px] font-semibold text-dark-blue'>{locationDisplayName}</p>
            <div className='flex gap-2 items-center'>
              <img src='/JobDetails/phone_icon.svg' alt='map icon' />
              <p className='text-[14px] text-gray-blue'>Phone: </p>
              <p className='text-[14px] font-semibold text-dark-blue'>{formattedPhoneNumber}</p>
            </div>
            <div className='flex gap-2 items-center'>
              <img src='/JobDetails/email_icon.svg' alt='map icon' />
              <p className='text-[14px] text-gray-blue'>Email:</p>
              <p className='text-[14px] font-semibold text-dark-blue'>{job.location.schedulers[0].user.email}</p>
            </div>
          </div>
        </div>

        {isMobileView &&
          <div className='border-b-2 border-navy-light'></div>
        }

      </div>
      <div className='flex flex-col gap-8 w-full px-6 py-4 bg-navy-bright/10 lg:rounded-lg lg:border border-navy-light'>
        <div className='flex justify-between items-center'>
          <p className='text-xl lg:text-2xl font-bold text-secondary-blue'>Job info</p>
        </div>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col gap-1'>
            <p className='font-semibold text-secondary-blue'>Schedule Request</p>
            <div className='flex flex-col gap-3 lg:gap-2 px-3 py-2 divide-navy-bright/10 bg-navy-bright/10 rounded'>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Service</p>
                <p className='font-bold text-black/75 lg:leading-loose'>{job.jobType == 'gunite' ? "Gunite" : "Plaster"}</p>
              </div>
              {
                (job.jobType === 'gunite' && (asrBranches.includes(job.locationId))) &&
                <div className='flex flex-col lg:flex-row justify-between'>
                  <p className='text-black/90 lg:leading-loose'>ASR Certification</p>
                  <p className='font-bold text-black/75 lg:leading-loose'>{job.guniteType === 'ASR Certification' ? "Yes" : 'No'}</p>
                </div>
              }
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Date</p>
                <p className='font-bold text-black/75 lg:leading-loose'>{displayDate}</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <p className='font-semibold text-secondary-blue'>Builder Information</p>
            <div className='flex flex-col gap-3 lg:gap-2 px-3 py-2 divide-navy-bright/10 bg-navy-bright/10 rounded'>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>First name</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{me.customer.firstName}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Last name</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{me.customer.lastName}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Business name</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{me.customer.bizName}</p>
              </div>

              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Business address</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{me.customer.bizAddress}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Phone number</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{me.customer.phone}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Email address</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{me.email}</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <p className='font-semibold text-secondary-blue'>Ready for work</p>
            <div className='flex flex-col gap-3 lg:gap-2 px-3 py-2 lg:divide-y divide-navy-bright/10 bg-navy-bright/10 rounded'>
              {job.jobType === 'plaster' ? (
                <div className='flex flex-col lg:flex-row justify-between'>
                  <p className='text-black/90 lg:leading-loose'>I confirm that the pool is cleaned / prepped</p>
                  <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].weWillPrepPool === true ? "Yes" : 'No'}</p>
                </div>
              ) : (
                <div className='flex flex-col gap-3 lg:gap-2 px-3 py-2 lg:divide-y divide-navy-bright/10 bg-navy-bright/10 rounded'>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>I confirm that the plumbing is done</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].plumbingIsDone === true ? "Yes" : "No"}</p>
                  </div>

                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>I confirm that the pool is dug out</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].poolIsDugOut === true ? "Yes" : "No"}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <p className='font-semibold text-secondary-blue'>Address</p>
            <div className='flex flex-col gap-3 lg:gap-2 px-3 py-2 lg:divide-y divide-navy-bright/10 bg-navy-bright/10 rounded'>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Job site Address</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.address}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>City</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.city}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>State</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.state}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Zip Code</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.zipCode}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Gate Code</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.gateCode}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Notes</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.notes}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Electricity On Site?</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{`${job.electricityOnSite ? 'Yes' : 'No'}`}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Water On Site?</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{`${job.waterOnSite ? 'Yes' : 'No'}`}</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <p className='font-semibold text-secondary-blue'>Pool</p>
            <div className='flex flex-col gap-3 lg:gap-2 px-3 py-2 lg:divide-y divide-navy-bright/10 bg-navy-bright/10 rounded'>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Pool plan</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].planUri}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Perimeter</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].perimeterFt + ' ft ' + job.pools[0].perimeterInches + ' inches'}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Square Ft</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].squareFt + ' ft'}</p>
              </div>
              {job.jobType === 'gunite'
                ?
		            <>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Depth</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].depth + ' ft'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Negative Edge</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].negativeEdge === true ? "Yes" : "No"}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Spa</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].spa === true ? "Yes" : "No"}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Raised Beam?</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].raisedBeam === true ? 'Yes' : 'No'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Retaining Walls?</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].retainingWalls === true ? 'Yes' : 'No'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Notch?</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].notch === true ? 'Yes' : 'No'}</p>
                  </div>
		            </>
                :
                <>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Length</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].lengthFt + ' ft ' + job.pools[0].lengthInches + ' inches'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Width</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].widthFt + ' ft ' + job.pools[0].widthInches + ' inches'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Deep End Depth</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].deepEndFt + ' ft ' + job.pools[0].deepEndInches + ' inches'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Shallow End Depth</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].shallowEndFt + ' ft ' + job.pools[0].shallowEndInches + ' inches'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Drain Covers</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].drainCovers}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Fittings</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].fittings}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Bar Stools</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].barstools}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Steps</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].steps}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Bench</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].benchFt + ' ft ' + job.pools[0].benchInches + ' inches'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Tanning Ledge</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].tanningLedgeFt + ' ft ' + job.pools[0].tanningLedgeInches + ' inches'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Waterfall</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].waterfall}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Basin</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].basin === true ? 'Yes' : 'No'}</p>
                  </div>
                  <div className='flex flex-col lg:flex-row justify-between'>
                    <p className='text-black/90 lg:leading-loose'>Spa</p>
                    <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].spa === true ? 'Yes' : 'No'}</p>
                  </div>
                </>
              }
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <p className='font-semibold text-secondary-blue'>Plaster</p>
            <div className='flex flex-col gap-3 lg:gap-2 px-3 py-2 lg:divide-y divide-navy-bright/10 bg-navy-bright/10 rounded'>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>New Pool or Re-plaster</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].newOrReplaster}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Material</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].material}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Color</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].color}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Material add-ins</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].materialAddIns}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Estimate needed</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.pools[0].estimateNeeded ? 'Yes' : 'No'}</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <p className='font-semibold text-secondary-blue'>Supervisor</p>
            <div className='flex flex-col gap-3 lg:gap-2 px-3 py-2 lg:divide-y divide-navy-bright/10 bg-navy-bright/10 rounded'>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>First name</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.supervisorFName}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Last name</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.supervisorLName}</p>
              </div>
              <div className='flex flex-col lg:flex-row justify-between'>
                <p className='text-black/90 lg:leading-loose'>Phone number</p>
                <p className='font-bold text-black/75 leading-relaxed lg:leading-loose'>{job.supervisorPhone}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobDetails
