import React, { useState, useEffect } from "react";

const CostBreakdownSubmittedFuel = ({ savedCostBreakdown }) => {

  const calculateTotal = () => {
    const total = savedCostBreakdown?.fuel?.reduce((accumulator, item) => {
      const milesToJob = Number(item.milesToJob) || 0
      const trips = Number(item.trips) || 0
      const costGallon = Number(item.costGallon) || 0
      const mpg = Number(item.mpg) || 0
      const yards = Number(item.yards) || 0
      const gallonsPerYard = Number(item.gallonsPerYard) || 0

      if (item.name === 'Compressor red') {
        const vehicleTotal = (yards * gallonsPerYard) * costGallon

        return accumulator += vehicleTotal
      } else {
        const vehicleTotal = (((milesToJob * 2) * trips) / mpg) * costGallon

        return accumulator += vehicleTotal
      }
    }, 0)

    return Number(total).toFixed(2)
  }

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      <div className='hidden md:flex w-full text-opaque-job-details-gray'>
        <div className='w-3/12'>
          <p className='pl-7'>Name</p>
        </div>

        <div className='flex w-7/12'>
          <p className='flex justify-end w-3/12'>Trips</p>
          <p className='flex justify-end w-5/12'>{`Miles (one way)`}</p>
          <p className='flex justify-end w-4/12'>Cost/Mile</p>
          <p className='flex justify-end w-4/12'>Cost/Gallon</p>
        </div>

        <div className='flex w-2/12'>
          <p className='flex justify-end w-full'>Total</p>
        </div>

        <div className='w-[32px]'></div>
      </div>

      {savedCostBreakdown?.fuel?.map((fuelItem, i) => {
        return (
          <div
            className={`flex flex-col md:flex-row w-full py-4 items-center text-dark-blue ${savedCostBreakdown.fuel.length === i + 1 ? '' : 'border-b'}`}
            key={`fuel${i + 1}`}
          >
            <div className='flex w-full md:w-3/12'>
              <p className='flex pr-4 items-center text-opaque-job-details-gray'>{i + 1}.</p>
              <p className='flex justify-start w-full items-center whitespace-nowrap truncate font-semibold'>{fuelItem.name || ''}</p>
            </div>

            {fuelItem.name !== 'Compressor red' ?
              <div className='flex flex-col md:flex-row w-full md:w-7/12'>

                <div className='flex md:w-3/12 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Trips:</p>
                  <p className='flex w-full justify-end items-center'>
                    {fuelItem.trips || 0}
                  </p>
                </div>

                <div className='flex md:w-5/12 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Miles to job:</p>
                  <p className='flex w-full justify-end items-center'>
                    {fuelItem.milesToJob || 0}
                  </p>
                </div>

                <div className='flex md:w-4/12 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Cost/Mile:</p>
                  <p className='flex w-full justify-end items-center'>
                    ${fuelItem.costMile || 0}
                  </p>
                </div>

                <div className='flex md:w-4/12 border-b md:border-none'>
                  <p className='w-1/2 md:hidden'>Cost/Gallon:</p>
                  <p className='flex w-full justify-end items-center'>
                    ${(fuelItem.costGallon ? Number(fuelItem.costGallon) : 0).toFixed(2)}
                  </p>
                </div>

              </div>
              :
              <div className='flex flex-col md:flex-row w-full md:w-7/12'>

                <div className='flex md:w-4/12 border-b md:border-none'>
                  <p className='w-4/12'>Yards:</p>
                  <p className='flex w-8/12 justify-end md:justify-start items-center'>
                    {fuelItem.yards || 0}
                  </p>
                </div>

                <div className='flex md:w-4/12 border-b md:border-none'>
                  <p className='w-1/2'>Gallons/Yard:</p>
                  <p className='flex w-1/2 justify-end md:justify-start items-center'>
                    {fuelItem.gallonsPerYard || 0}
                  </p>
                </div>

                <div className='flex md:w-4/12 border-b md:border-none'>
                  <p className='w-1/2'>Cost/Gallon:</p>
                  <p className='flex w-1/2 justify-end md:justify-start items-center'>
                    ${(fuelItem.costGallon ? Number(fuelItem.costGallon) : 0).toFixed(2)}
                  </p>
                </div>

              </div>
            }

            <div className='flex flex-col md:flex-row w-full md:w-2/12'>
              <div className='flex w-full'>
                <p className='w-1/2 md:hidden'>Total:</p>
                <p className='flex justify-end w-full items-center'>
                  ${fuelItem.name !== 'Compressor red' ?
                    (((Number(fuelItem.milesToJob) * 2) * Number(fuelItem.trips))  * (Number(fuelItem.costGallon) / Number(fuelItem.mpg))).toFixed(2)
                    :
                    ((Number(fuelItem.yards) * Number(fuelItem.gallonsPerYard)) * Number(fuelItem.costGallon)).toFixed(2)
                  }
                </p>
              </div>

            </div>

            <div className='w-[32px]'></div>

          </div>
        )
      })}

      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Fuel total
          </p>
          <div className='flex justify-end w-[100px]'>
            <p className='w-1/5 flex justify-end'>$</p>
            <p className='w-4/5 flex justify-end'>{calculateTotal()}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CostBreakdownSubmittedFuel;
