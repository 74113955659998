import React from "react";
import { useLocation } from 'react-router-dom';
import './index.scss';
import { useProgress } from '../../contexts/progress';

function ProgressPaneDesktop(props) {
  const { pathname } = useLocation();
  const {
    currentStepGunite, currentStepPlaster,
    stepDisplayTextGunite, stepDisplayTextPlaster, currentHeaderGunite,
    currentHeaderPlaster, currentSubTextGunite, currentSubTextPlaster,
    currentJobSiteStepGunite, currentJobSiteStepPlaster, navigateToStart,
    skipASRChoice,
    zipCode, setZipCode,
  } = useProgress();

  const { centerMapOnZip } = props;

  return (
    <div className='h-full max-w-xl opacity-90 progress_desktop_outer_container'>
      <div className='flex flex-col h-full p-6 md:p-20 progress_desktop_flex_container'>
        {(currentStepGunite === 0 || currentStepPlaster === 0) &&
          <div className='col-span-1 text-[34px] text-white font-bold progress_desktop_header_text'>
            {pathname.includes('gunite') ?
              currentHeaderGunite
              :
              currentHeaderPlaster
            }
          </div>
        }
        
        {(currentStepGunite === 0 || currentStepPlaster === 0) &&
          <div className='col-span-1 mt-5 text-white opacity-60 font-normal progress_desktop_subheader_text'>
            {pathname.includes('gunite') ?
              currentSubTextGunite
              :
              currentSubTextPlaster
            }
          </div>
        }

        {/* This block is only for the choose-location page */}
        {pathname.includes('choose-location') &&
          <div className='flex flex-col gap-3 bg-dark-blue/90 px-6 py-5'>
            <button onClick={() => navigateToStart()}>
              <div className="flex gap-2 items-center">
                <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
                <p className="font-semibold text-gunite-red text-sm font-medium">Back</p>
              </div>
            </button>
            <div className='flex flex-col gap-1'>
              <p className='text-white font-bold text-2xl'>Choose the service provider</p>
              <p className='text-white/60 text-sm font-medium'>Narrow down your location</p>
            </div>
            <form className='flex'
              onSubmit={(e) => {
                e.preventDefault();
                centerMapOnZip(zipCode)
              }}
            >
              <input 
                className='w-full'
                placeholder='Zip code *' 
                type="text"
                id="newZipDesktop"
                value={zipCode}
                onChange={(e)=>{
                  setZipCode(e.target.value)
                }}
              />
              <button>
                <div className='ml-[4px] p-[10px] w-10 bg-gunite-red'>
                  <img src='/white_search_iconB.png'/>
                </div>
              </button>
            </form>
          </div>
        }
        

        {(currentStepGunite > 0 || currentStepPlaster > 0) && (
          <>
            {pathname.includes('gunite') ?
              <div className='text-white text-base opacity-60 font-semibold mb-5'>
                Step {currentStepGunite}&nbsp;
                {skipASRChoice ? 'of 4' : 'of 5'}
              </div>
              :
              <div className='text-white text-base opacity-60 font-semibold mb-5'>
                Step {currentStepPlaster} of 4
              </div>
            }

            {pathname.includes('gunite') ? (
              //*GUNITE*
              Object.entries(stepDisplayTextGunite.header).slice(1).map(([key, value], index) => (
                <div key={key} value={value}
                  className={`text-white font-normal opacity-30 mb-3 text-2xl
                    ${index === currentStepGunite - 1 ? 'progress_desktop_current_step' : 'progress_desktop_not_current_step'}
                    ${currentStepGunite > index && 'progress_desktop_step_completed'}`}>
                    {value}

                    {skipASRChoice && currentStepGunite === 2 && index === 1 && (
                      <>
                        {Object.entries(stepDisplayTextGunite.jobSiteHeaders).map(([key, value], index) => (
                          <div key={key} value={value}
                            className={`progress_desktop_submenu_items text-base pl-4 mb-2
                              ${index === 0 && 'mt-2'}
                              ${index === 3 && 'mb-0'}
                              ${index === currentJobSiteStepGunite - 1 ? 'progress_desktop_job_site_current_step' : 'progress_desktop_job_site_not_current_step'}
                              ${currentJobSiteStepGunite > index && 'progress_desktop_job_site_step_completed'}`}>
                            {value}
                          </div>
                        ))}
                      </>
                    )}

                    {!skipASRChoice && currentStepGunite === 3 && index === 2 && (
                      <>
                        {Object.entries(stepDisplayTextGunite.jobSiteHeaders).map(([key, value], index) => (
                          <div key={key} value={value}
                            className={`progress_desktop_submenu_items text-base pl-4 mb-2
                              ${index === 0 && 'mt-2'}
                              ${index === 3 && 'mb-0'}
                              ${index === currentJobSiteStepGunite - 1 ? 'progress_desktop_job_site_current_step' : 'progress_desktop_job_site_not_current_step'}
                              ${currentJobSiteStepGunite > index && 'progress_desktop_job_site_step_completed'}`}>
                            {value}
                          </div>
                        ))}
                      </>
                    )}
                </div>
              )))
              :
              //*PLASTER*
              Object.entries(stepDisplayTextPlaster.header).slice(1).map(([key, value], index) => (
                <div key={key} value={value}
                  className={`text-white font-normal opacity-30 mb-3 text-2xl 
                    ${index === currentStepPlaster - 1 ? 'progress_desktop_current_step' : 'progress_desktop_not_current_step'}
                    ${currentStepPlaster > index && 'progress_desktop_step_completed'}`}>
                  {value}

                  {currentStepPlaster === 2 && index === 1 &&
                    <>
                      {Object.entries(stepDisplayTextPlaster.jobSiteHeaders).map(([key, value], index) => (
                        <div key={key} value={value}
                          className={`progress_desktop_submenu_items text-base pl-4 mb-2
                            ${index === 0 && 'mt-2'}
                            ${index === 4 && 'mb-0'}
                            ${index === currentJobSiteStepPlaster - 1 ? 'progress_desktop_job_site_current_step' : 'progress_desktop_job_site_not_current_step'}
                            ${currentJobSiteStepPlaster > index && 'progress_desktop_job_site_step_completed'}`}>
                          {value}
                        </div>
                      ))}
                    </>
                  }
                </div>
              ))
            }
            <div className='flex items-end h-full'>
              <div className='font-bold text-[31px] text-white opacity-50 progress_desktop_bottom_optional_text'>
                Gunite & Plaster Schedule Request
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ProgressPaneDesktop;
