import React from 'react'
import { useModal } from '../../contexts/modal'

const Modal = () => {
  const { modal, modalMethods } = useModal()
  const { isActive, content, width, xIsHidden } = modal
  const { close } = modalMethods
  const xHidden = xIsHidden ? 'is-hidden' : ''

  return (
    <div className={isActive ? `fixed w-full h-full bg-black/80 flex justify-center items-center z-10` : 'hidden' }>
          {content}
    </div>
  )
}
export default Modal
