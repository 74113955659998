import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { formatPhoneNumber } from '../../utils';

const styles = StyleSheet.create({
  addressText: {
    marginBottom: 2,
    fontWeight: 600,
    fontSize: 12,
  },
  addressWrapper: {
    width: '30%',
  },
  columnTitles: {
    width: '100%',
    padding: 5,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 1,
    backgroundColor: '#E4E4E4'
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  footerSection: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
  },
  footerWrapper: {
    width: '100%',
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  grandTotal: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  grandTotalBorder: {
    padding: 6,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    border: 4,
  },
  grandTotalText: {
    fontWeight: 600,
    fontSize: 20,
  },
  header: {
    width: '100%',
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    fontSize: 16
  },
  heading: {
    marginBottom: 10,
    paddingBottom: 1,
    fontWeight: 600,
    fontSize: 20,
    borderBottom: 1
  },
  headingSmall: {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 16,
    borderBottom: 1
  },
  infoWrapper: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: 1
  },
  infoWrapperDueDate: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: 4
  },
  invoiceBackSection: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  invoiceDescription: {
    fontSize: 10,
    paddingTop: 2,
    paddingLeft: 5,
    paddingRight: 5,
  },
  invoiceDescriptionHidden: {
    display: 'none'
  },
  invoiceRow: {
    width: '100%',
    padding: 5,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 1,
  },
  invoiceRowNameWrapper: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
  },
  invoiceRowText: {
    width: '33.3%',
    fontSize: 12,
  },
  invoiceRowTextBig: {
    // width: '40%',
    fontSize: 12,
  },
  invoiceRowTitle: {
    width: '33.3%',
    fontSize: 14,
  },
  invoiceRowTitleBig: {
    width: '40%',
    fontSize: 14,
  },
  invoiceRowTotal: {
    fontSize: 14,
  },
  invoiceRowTotalWrapper: {
    width: '33.3%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  invoiceSection: {
    marginBottom: 20
  },
  lineView: {
    width: '100%',
    marginBottom: 20,
    marginTop: 10,
    borderBottom: 1,
  },
  logo: {
    width: '120px',
    height: '120px',
    marginRight: 10
  },
  logoAddressWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  page: {
    width: '100%',
    padding: 30,
    paddingBottom: 95,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Helvetica',
  },
  pageNumberWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
  },
  sectionRowWrapper: {
    width: '100%',
    marginBottom: 30,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  sectionTitle: {
    marginBottom: 10,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  sectionTotal: {
    width: '100%',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 1,
    backgroundColor: '#E4E4E4'
  },
  sectionColWrapper: {
    width: '100%',
    marginBottom: '10px',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: 10,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    fontSize: 50,
  },
});

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

Font.registerHyphenationCallback(word => {
  // Return entire word as unique part
  return [word];
});

const CostBreakdownInvoicePDFTemplate = ({ breakdown, selectedJob, selectedScheduler, logoImage }) => {
  const newDate = new Date();
  const foreman = selectedJob?.costBreakdown[0]?.crew.find((item) => item.role === 'Foreman')?.name

  const dueDate = new Date(newDate)
  dueDate.setDate(dueDate.getDate() + 15)

  const dateOfService = selectedJob?.calendarJobs?.map((calJob) => {
    const date = new Date(calJob.scheduledDate)
    const formattedDate = `${months[date.getUTCMonth()] || ''}, ${date.getUTCDate() || ''} ${date.getUTCFullYear() || ''}`

    return formattedDate
  })

  const invoiceTotal = breakdown.invoice.reduce((accumulator, item) => {
    return accumulator += (item.amount * item.price)
  }, 0)

  const discountPercent = breakdown.discountPercent || 0
  const discountAmount = invoiceTotal * (discountPercent / 100)
  const balanceDue = invoiceTotal - discountAmount

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
  <Document title='invoice'>
    <Page size='A4' style={styles.page}>
      <Text style={styles.heading}>{selectedScheduler?.location.label}</Text>
      <View style={styles.header}>
        <View style={styles.logoAddressWrapper}>
          <Image src={logoImage} style={styles.logo} />

          <View >
          <Text style={styles.addressText}>{formatPhoneNumber(selectedScheduler?.location.phone)}</Text>
            <Text style={styles.addressText}>{selectedScheduler?.location.address}</Text>
            <Text style={styles.addressText}>{selectedScheduler?.location.city}, {selectedScheduler?.location.state}</Text>
            <Text style={styles.addressText}>{selectedScheduler?.location.zipCode}</Text>
          </View>
        </View>

        <View style={styles.sectionTitle}>
          <Text style={styles.title}>Invoice</Text>
          <Text style={styles.HeaderText}>for {selectedJob.owner.customer.bizName}</Text>
          <Text style={styles.HeaderText}>{months[newDate.getMonth()] || ''}, {newDate.getDate() || ''} {newDate.getFullYear() || ''}</Text>
          <Text style={styles.HeaderText}>invoice no. {breakdown?.id || ''}</Text>
        </View>
      </View>

      <View style={styles.sectionRowWrapper}>

        <View style={styles.addressWrapper}>
          <Text style={styles.heading}>Bill to</Text>
          <Text style={styles.addressText}>{selectedJob.owner.customer.bizName}</Text>
          <Text style={styles.addressText}>{selectedJob.owner.customer.bizAddress}</Text>
          <Text style={styles.addressText}>{selectedJob.owner.customer.city}, {selectedJob.owner.customer.state}</Text>
          <Text style={styles.addressText}>{selectedJob.owner.customer.zipCode}</Text>
        </View>

        <View style={styles.addressWrapper}>
          <Text style={styles.heading}>Job site</Text>
          <Text style={styles.addressText}>{selectedJob.jobName}</Text>
          <Text style={styles.addressText}>{selectedJob.address}</Text>
          <Text style={styles.addressText}>{selectedJob.city}, {selectedJob.state}</Text>
          <Text style={styles.addressText}>{selectedJob.zipCode}</Text>
        </View>

        <View style={styles.addressWrapper}>
          <Text style={styles.heading}>Pay to</Text>
          <Text style={styles.addressText}>{selectedScheduler.location.label}</Text>
          <Text style={styles.addressText}>{selectedScheduler.location.address}</Text>
          <Text style={styles.addressText}>{selectedScheduler.location.city}, {selectedScheduler.location.state}</Text>
          <Text style={styles.addressText}>{selectedScheduler.location.zipCode}</Text>
        </View>

      </View>

      <View style={styles.sectionRowWrapper}>
        <View style={styles.infoWrapper}>
          <Text style={styles.headingSmall}>Job Name</Text>
          <Text style={styles.addressText}>{selectedJob.jobName}</Text>
        </View>
        <View style={styles.infoWrapper}>
          <Text style={styles.headingSmall}>Foreman</Text>
          <Text style={styles.addressText}>{foreman}</Text>
        </View>
        <View style={styles.infoWrapper}>
          <Text style={styles.headingSmall}>Date of Service</Text>
          {dateOfService?.map((date, i) => {
            return (
              <Text key={`workDay${i}`} style={styles.addressText}>{date}</Text>
            )
          })}
        </View>
        <View style={styles.infoWrapper}>
          <Text style={styles.headingSmall}>Terms</Text>
          <Text style={styles.addressText}>FRI/FRI</Text>
        </View>
        <View style={styles.infoWrapperDueDate}>
          <Text style={styles.headingSmall}>Due Date</Text>
          <Text style={styles.addressText}>{months[dueDate.getMonth()] || ''}, {dueDate.getDate() || ''} {dueDate.getFullYear() || ''}</Text>
        </View>

      </View>

      <View style={styles.invoiceSection} wrap={false}>
        <View style={styles.columnTitles}>
          <Text style={styles.invoiceRowTitleBig}>Description</Text>
          <View style={styles.invoiceBackSection}>
            <Text style={styles.invoiceRowTitle}>Quantity</Text>
            <Text style={styles.invoiceRowTitle}>Price Each</Text>

            <View style={styles.invoiceRowTotalWrapper}>
              <Text style={styles.invoiceRowTotal}>Totals</Text>
            </View>
          </View>
        </View>

        {breakdown?.invoice?.map((item, index) => (
          <View key={`${index}-${item.name}`} style={styles.invoiceRow}>
            <View style={styles.invoiceRowNameWrapper}>

              <Text style={styles.invoiceRowTextBig}>{item.name === 'Plaster' ? `${item.manufacturer}, ${item.color}` : item.name}</Text>

              <Text style={item.description.length > 0 ? styles.invoiceDescription : styles.invoiceDescriptionHidden}>{item.description}</Text>

            </View>
            <View style={styles.invoiceBackSection}>

              <Text style={styles.invoiceRowText}>{item.amount || 0}</Text>

              <Text style={styles.invoiceRowText}>${numberWithCommas(Number(item.price).toFixed(2) || 0)}</Text>

              <View style={styles.invoiceRowTotalWrapper}>
                <Text style={styles.invoiceRowTotal}>${numberWithCommas(Number((item.price || 0) * (item.amount || 0)).toFixed(2))}</Text>
              </View>
            </View>
          </View>
        ))}

        <View style={styles.sectionTotal}>
          <Text style={styles.invoiceRowTotal}>Invoice total: </Text>
          <Text style={styles.invoiceRowTotal}>${numberWithCommas(Number(invoiceTotal || 0).toFixed(2))}</Text>
        </View>

        {discountAmount > 0 ?
          <View style={styles.sectionTotal}>
            <Text style={styles.invoiceRowTotal}>Discount: {discountPercent}%</Text>
            <Text style={styles.invoiceRowTotal}>-${numberWithCommas(Number(discountAmount || 0).toFixed(2))}</Text>
          </View>
          :
          <></>
        }

      </View>

      <View style={styles.grandTotal} wrap={false}>
        <View style={styles.grandTotalBorder}>
          <Text style={styles.grandTotalText}>Balance Due:</Text>
          <Text style={styles.grandTotalText}>${numberWithCommas(Number(balanceDue || 0).toFixed(2))}</Text>
        </View>
      </View>

      <View style={styles.footerWrapper} fixed >
        <View style={styles.pageNumberWrapper}>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => (
              `Page: ${pageNumber} / ${totalPages}`
            )}
          />
        </View>
      </View>

    </Page>
  </Document>
)};

export default CostBreakdownInvoicePDFTemplate;