import FilesBox from '../../components/FilesBox'
import { useEffect } from 'react'
import { useAdmin } from '../../contexts/admin'
import { useSchedulerFiles } from '../../contexts/SchedulerFiles'
import { useAdminFiles } from '../../contexts/adminFiles'
import AddButton from '../AddButton'
import Modal from '../Modal'
import ConfirmPopup from '../ConfirmPopup'
import UploadFileModal from '../UploadFileModal'

const AdminFiles = () => {
  const {
    selectedFileUris,
    setSelectedFileUris,
    showConfirmDeletePopup,
    setShowConfirmDeletePopup,
    handleTrashClick,
    handleConfirmDelete,
    confirmDeleteHeader,
    confirmDeleteDescription,
    searchTerm,
    setSearchTerm,
    currentPage,
    setCurrentPage,
    allItems,
    RESULTS_PER_PAGE,
    sortBy,
    setSortBy,
    sortAscending,
    setSortAscending,
    handleColumnHeaderClick,
    displayItems,
    uploadModalOpen,
    setUploadModalOpen,
    updateLocationFiles
  } = useAdminFiles();

  useEffect(()=>{
    updateLocationFiles()
  },[])

  return (
    <div>
      <h1 className="text-xl font-semibold text-dark-blue sm:ml-8">Branches list</h1>
      <div className="w-full overflow-y-scroll flex flex-col justify-start items-center">
        <FilesBox 
            allowItemSelection={true}
            selectedIds={selectedFileUris}
            setSelectedIds={setSelectedFileUris}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            allItems={allItems}
            RESULTS_PER_PAGE={RESULTS_PER_PAGE}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            handleColumnHeaderClick={handleColumnHeaderClick}
            displayItems={displayItems}
            handleTrashClick={handleTrashClick}
          />
          <AddButton text={'Add file'} className={`w-5/6`} onClick={()=>setUploadModalOpen(true)}/>
          {uploadModalOpen && ( <UploadFileModal /> )}
          {showConfirmDeletePopup && 
            <ConfirmPopup 
              header={confirmDeleteHeader} 
              description={confirmDeleteDescription} 
              confirmButtonText={"Delete"}
              onCancel={() => setShowConfirmDeletePopup(false)}
              onConfirm={handleConfirmDelete}
              className="top-[300px]"
            />
          }

      </div>
    </div>
  )

}

export default AdminFiles;