import { useAdminMaterials } from "../../contexts/adminMaterials";

const AdminMaterialsBreadcrumbs = () => {
  const {
    breadcrumbs,
    handleClickBreadcrumb
  } = useAdminMaterials();

  
  return (
    <>
      {breadcrumbs?.currentPage == 'Manufacturers' && (
        <h1 className="text-xl font-semibold text-opaque-job-details-gray">  
          <span className="text-dark-blue">
            {breadcrumbs?.selectedMaterial}
          </span>
        </h1>
      )}
       {breadcrumbs?.currentPage == 'Products' && (
        <h1 className="text-xl font-semibold text-opaque-job-details-gray">  
          <span 
            onClick={() => handleClickBreadcrumb('Manufacturers')}
            className="cursor-pointer"
          >
            {breadcrumbs?.selectedMaterial}
          </span>{' '}
          / {' '}
          <span className="text-dark-blue">
            {breadcrumbs?.selectedManufacturer}
          </span>
        </h1>
      )}
      {breadcrumbs?.currentPage == 'Tiers' && (
        <h1 className="text-xl font-semibold text-opaque-job-details-gray">  
          <span 
            onClick={() => handleClickBreadcrumb('Manufacturers')}
            className="cursor-pointer"
          >
            {breadcrumbs?.selectedMaterial}
          </span>{' '}
          / {' '}
          <span 
            onClick={() => handleClickBreadcrumb('Products')}
            className="cursor-pointer"
          >
            {breadcrumbs?.selectedManufacturer}
          </span>{' '}
          / {' '}
          <span className="text-dark-blue">
            {breadcrumbs?.selectedProduct}
          </span>
        </h1>
      )}
      {breadcrumbs?.currentPage == 'Colors' && (
        <h1 className="text-xl font-semibold text-opaque-job-details-gray">  
          <span 
            onClick={() => handleClickBreadcrumb('Manufacturers')}
            className="cursor-pointer"
          >
            {breadcrumbs?.selectedMaterial}
          </span>{' '}
          / {' '}
          <span 
            onClick={() => handleClickBreadcrumb('Products')}
            className="cursor-pointer"
          >
            {breadcrumbs?.selectedManufacturer}
          </span>{' '}
          / {' '}
          <span 
            onClick={() => handleClickBreadcrumb('Tiers')}
            className="cursor-pointer"
          >
            {breadcrumbs?.selectedProduct}
          </span>{' '}
          / {' '}
          <span className="text-dark-blue">
            {breadcrumbs?.selectedTier}
          </span>
        </h1>
      )}
      {breadcrumbs?.currentPage == 'ColorDetails' && (
         <h1 className="text-xl font-semibold text-opaque-job-details-gray">  
         <span 
           onClick={() => handleClickBreadcrumb('Materials')}
           className="cursor-pointer"
         >
           Materials
         </span>{' '}
         / {' '}
         <span 
           onClick={() => handleClickBreadcrumb('Manufacturers')}
           className="cursor-pointer"
         >
           {breadcrumbs?.selectedMaterial}
         </span>{' '}
         / {' '}
         <span 
           onClick={() => handleClickBreadcrumb('Products')}
           className="cursor-pointer"
         >
           {breadcrumbs?.selectedManufacturer}
         </span>{' '}
         / {' '}
         <span 
           onClick={() => handleClickBreadcrumb('Tiers')}
           className="cursor-pointer"
         >
           {breadcrumbs?.selectedProduct}
         </span>{' '}
         / {' '}
         <span 
           onClick={() => handleClickBreadcrumb('Colors')}
           className="cursor-pointer"
         >
           {breadcrumbs?.selectedTier}
         </span>{' '}
         / {' '}
         <span className="text-dark-blue">
           {breadcrumbs?.selectedColor}
         </span>
       </h1>
      )}
    </>
  )
}

export default AdminMaterialsBreadcrumbs;