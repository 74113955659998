import React from 'react';
import { useScheduler } from '../../contexts/scheduler';
import { getJobStatus, allStatusDisplayOptions } from '../../utils';
import serviceMethods from '../../service';
import { useAuth } from '../../contexts/auth'
import { useJobDetailsModal } from '../../contexts/jobDetailsModal';
import { useModal } from '../../contexts/modal';
import VerifyMoveModal from '../VerifyMoveModal';

const JobDetailsFooterButtons = () => {
  const { setMe, me } = useAuth()
  const { selectedScheduler, selectedJobId } = useScheduler();
  const { setShowMiniCalendar } = useJobDetailsModal()
  const { modalMethods } = useModal()

  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId);

  const handleClick = async (target, from, forward = false) => {
    const proceedFunction = async (unarchive = false) => {
      const log = {
        jobId: selectedJobId,
        activityType: target,
        performedById: me.id,
      }
      await serviceMethods.addActivityLog(log)
      if (unarchive) {
        const { me: newMe } = await serviceMethods.unarchive(selectedJobId)
        setMe(newMe)
      } else {
        const { me: newMe } = await serviceMethods.updateDate(selectedJobId, target)
        setMe(newMe)
      }
    }

    if (forward) {
      if (target === 'scheduled') {
        setShowMiniCalendar(true);
        return
      } else if (target === 'ready' && (from === 'intake' || from === 'backlog')) {
        const estimate = selectedJob.jobType === 'plaster' ? selectedJob.estimatedIA : selectedJob.estimatedYards
        if (estimate === 0) {
          // pop up warning to check data / calculate estimate
          const title = 'Verify job details.'
          const message = `Check that all necessary information is present. Calculate estimated ${selectedJob.jobType === 'plaster' ? 'IA' : 'yards'}.`
          const subMessage = ''
          openVerifyMoveModal(target, proceedFunction, title, message, subMessage, true)
          return
        }
      } else if (target === 'workCompleted') {
        // pop up warning, only foreman can mark labor complete
        const title = 'Move blocked.'
        const message = 'Daily Job Log needs to be submitted.'
        const subMessage = ''
        openVerifyMoveModal(target, proceedFunction, title, message, subMessage, true)
        return
      } else if (target === 'completed') {
        if (selectedJob.costBreakdown.length === 0 || selectedJob.costBreakdown[0].submittedAt === null) {
          // pop up warning the cost breakdown needs to be submit first
          const title = 'Move blocked.'
          const message = 'Complete and submit the cost breakdown.'
          const subMessage = ''
          openVerifyMoveModal(target, proceedFunction, title, message, subMessage, true)
          return
        }
      } else if (target === 'archive') {
        // pop up warning asking if they are sure (maybe)
        const title = `Move ${selectedJob.jobName} to ${target}.`
        const message = ''
        const subMessage = 'Are you sure?'
        openVerifyMoveModal(target, proceedFunction, title, message, subMessage)
        return
      }
    } else {
      if (target === 'unarchive') {
        const title = `Unarchive ${selectedJob.jobName}.`
        const message = ''
        const subMessage = 'Are you sure?'
        openVerifyMoveModal(target, () => proceedFunction(true), title, message, subMessage)
        return
      } else if (target === 'backlog' || target === 'scheduled' || target === 'workCompleted' || target === 'completed') {
        // pop up asking if user is sure
        const title = `Move ${selectedJob.jobName} back to ${target !== 'workCompleted' ? target : 'work completed'}.`
        const message = ''
        const subMessage = 'Are you sure?'
        openVerifyMoveModal(target, proceedFunction, title, message, subMessage)
        return
      } else if (target === 'intake') {
        // pop up warning this will reset all the dates
        const title = `Move ${selectedJob.jobName} back to ${target}.`
        const message = `This will reset the job.`
        const subMessage = 'Are you sure?'
        openVerifyMoveModal(target, proceedFunction, title, message, subMessage)
        return
      } else if (target === 'ready') {
        // pop up warning this will erase all scheduled shifts
        const title = `Move ${selectedJob.jobName} back to ${target}.`
        const message = `This will delete all scheduled shifts for ${selectedJob.jobName}.`
        const subMessage = 'Are you sure?'

        //collect calendarJob ids to mark them deleted
        const calendarJobIds = []
        selectedJob.calendarJobs.forEach((calJob) => {
          calendarJobIds.push(calJob.id)
        })

        const proceedFunctionPlus = () => {
          proceedFunction()
          // marks existing calendar jobs deleted
          serviceMethods.markCalendarJobDeleted(calendarJobIds)
        }

        openVerifyMoveModal(target, proceedFunctionPlus, title, message, subMessage)
        return
      }
    }

    proceedFunction()
  }

  const openVerifyMoveModal = (target, proceedFunction, title, message, subMessage, oneButton = false) => {
    modalMethods.setContent(
      <VerifyMoveModal
        updateDate={() => { proceedFunction() }}
        destination={target}
        jobId={selectedJobId}
        title={title}
        message={message}
        subMessage={subMessage}
        oneButton={oneButton}
      />
    )
    modalMethods.open()
  }

  const backButtonMaker = () => {
    if (selectedJob.deletedAt) {
      return (
        null
      );
    } else if (selectedJob.archivedAt) {
      return (
        <button onClick={() => handleClick('unarchive', 'archive')} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobDetails/event_busy.svg' alt='Ready for calendar' className='mr-1.5 select-none w-[11px]' />
          Unarchive
        </button>
      );
    } else if (selectedJob.completedAt) {
      return (
        <button onClick={() => handleClick('workCompleted', 'completed')} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobDetails/event_busy.svg' alt='Work completed' className='mr-1.5 select-none w-[11px]' />
          Back to Work completed
        </button>
      );
    } else if (selectedJob.workCompletedAt) {
      return (
        <button onClick={() => handleClick('scheduled', 'workCompleted')} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobDetails/event_busy.svg' alt='Scheduled icon' className='mr-1.5 select-none w-[11px]' />
          Back to Scheduled
        </button>
      );
    } else if (selectedJob.scheduledAt) {
      return (
        <button onClick={() => handleClick('ready', 'scheduled')} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobDetails/event_busy.svg' alt='Ready for calendar' className='mr-1.5 select-none w-[14px]' />
          Remove from Schedule
        </button>
      );
    } else if (selectedJob.readyAt) {
      return (
        <button onClick={() => handleClick('backlog', 'ready')} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobsList/backlog_icon.svg' alt='Backlog' className='mr-1.5 select-none w-[18px]' />
          Move to Backlog
        </button>
      );
    } else if (selectedJob.backloggedAt) {
      return (
        <button onClick={() => handleClick('intake', 'backlog')} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobsList/intake_icon.svg' alt='Intake' className='mr-1.5 select-none w-[14px]' />
          Move to Intake
        </button>
      );
    } else if (selectedJob.submittedAt) {
      return (
        <button onClick={() => handleClick('backlog', 'intake')} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobsList/backlog_icon.svg' alt='Backlog' className='mr-1.5 select-none w-[18px]' />
          Move to Backlog
        </button>
      )
    }
  }

  const forwardButtonMaker = () => {
    if (selectedJob.deletedAt) {
      return (
        null
      );
    } else if (selectedJob.archivedAt) {
      return (
        <div className='flex w-1/2'></div>
      );
    } else if (selectedJob.completedAt) {
      return (
        <button onClick={() => handleClick('archive', 'completed', true)} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobDetails/archived_icon.svg' alt='archived' className='mr-1.5 select-none w-[14px]' />
          Set as archived
        </button>
      );
    } else if (selectedJob.workCompletedAt) {
      return (
        <button onClick={() => handleClick('completed', 'workCompleted', true)} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobDetails/archived_icon.svg' alt='completed' className='mr-1.5 select-none w-[14px]' />
          Mark as Completed
        </button>
      );
    } else if (selectedJob.scheduledAt) {
      return (
        <button onClick={() => handleClick('workCompleted', 'scheduled', true)} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobDetails/completed_icon.svg' alt='work completed' className='mr-1.5 select-none w-[18px]' />
          Mark Work Completed
        </button>
      );
    } else if (selectedJob.readyAt) {
      return (
        <button onClick={() => handleClick('scheduled', 'ready', true)} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
            <img src='/JobDetails/calendar_month.svg' alt='Calendar month icon' className='mr-1.5 select-none w-[20px]' />
          Schedule job
        </button>
      );
    } else if (selectedJob.backloggedAt) {
      return (
        <button onClick={() => handleClick('ready', 'backlog', true)} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobsList/ready_for_calendar_icon.svg' alt='Ready for calendar' className='mr-1.5 select-none w-[14px]' />
          Mark as Ready for calendar
        </button>
      );
    } else if (selectedJob.submittedAt) {
      return (
        <button onClick={() => handleClick('ready', 'intake', true)} className="flex flex-row justify-center items-center border border-navy-light w-1/2 bg-white text-dark-blue py-2 px-4 rounded mr-2">
          <img src='/JobsList/ready_for_calendar_icon.svg' alt='Ready for calendar' className='mr-1.5 select-none w-[18px]' />
          Mark as Ready for Calendar
        </button>
      )
    }
  }


    return (
        <div className="bg-opacity-10 bg-navy-bright border-t border-navy-light flex justify-end p-4 border-t">
            {backButtonMaker()}
            <div className='w-2'/>
            {forwardButtonMaker()}
        </div>
    );
};

export default JobDetailsFooterButtons;
