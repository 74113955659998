const ConfirmPopup = ({ header, description, confirmButtonText, onConfirm, onCancel, className="right-3 top-[440px]"}) => {
  return (
    <div className={"w-[500px] absolute rounded-[4px] bg-all-f-white border border-navy-light p-4 " + className}>
        <h2 className="text-lg font-bold text-dark-blue">{header}</h2>
        <p className="text-gray-blue my-4">{description}</p>
        <div className="flex justify-end">
          <button onClick={onCancel} className="bg-white text-dark-blue rounded-md border border-gray-300 py-2 px-4 mr-2">
            Cancel
          </button>
          <button onClick={onConfirm} className="bg-dark-blue text-white rounded-md py-2 px-4 ">
            {confirmButtonText}
          </button>
        </div>
      </div>
  );
};

export default ConfirmPopup;