const AddButton = ({text, onClick, className}) => {
  const buttonClasses = `w-full bg-all-f-white text-dark-blue rounded-xl px-4 py-3 mt-4 border border-navy-light ${className}`;

  return (
    <button 
      onClick={onClick}
      className={buttonClasses}>
      <img src="/JobDetails/add_icon.svg" alt="add" className="w-[25px] inline mr-2" />
      {text}
    </button>
  )
}

export default AddButton;