import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const AdminMaterialsPaginationFooterBar = ({ paginate, currentPage, allItems, resultsPerPage }) => {

 return (
    <div className="flex justify-between items-center px-4 h-16 bg-opacity-10 bg-navy-bright bg-navy-bright rounded-b-xl border border-navy-light">
      <div></div>
      <div className="flex flex-row justify-center items-center select-none">
        <p className="text-gray-blue mr-3">{1 + (resultsPerPage * (currentPage - 1))} - {Math.min(currentPage * resultsPerPage, allItems?.length)} &nbsp; of &nbsp; {allItems?.length}</p>
        <FaChevronLeft onClick={() => paginate('prev')} className="cursor-pointer h-4 w-4 inline mr-2 text-gray-blue" />
        <FaChevronRight onClick={() => paginate('next')} className="cursor-pointer h-4 w-4 inline text-gray-blue" />
      </div>
    </div>
  );
}

export default AdminMaterialsPaginationFooterBar;
