import React, { useState } from 'react'
import {useDropzone} from 'react-dropzone'

const UploadFileDropzone = ({onDrop, hasUploaded}) => {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    multiple: false
  })

  return (
    <div {...getRootProps()} className='cursor-pointer my-6 mt-3 w-full flex-1 rounded-md border-dashed border border-dark-blue flex justify-center items-center'>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <div className='w-full flex flex-col justify-center items-center'>
            {!hasUploaded && <img src='/cloud_upload.svg' alt='upload' className='h-[30px]' />}
            {hasUploaded && <img src='/ActivityLog/check_circle.svg' alt='check' className='h-[30px]' />}
            <div className='py-3'>
              {!hasUploaded && <span className='text-dark-blue font-bold'>Click to upload file</span> }
              {hasUploaded && <span className='text-dark-blue font-bold'>Click to replace file</span> }
              <span className='text-gray-blue'> or drag it here</span>
            </div>
          </div>
      }
    </div>
  )
}

export default UploadFileDropzone;