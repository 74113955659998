import React from "react";
import { useLongPress } from "@uidotdev/usehooks";
import { useScheduler } from "../../contexts/scheduler";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import { useSchedulerCalendarMobile } from "../../contexts/schedulerCalendarMobile";


function CalendarJobCardFlashMobile({ job, i }) {
  const { setSelectedJobId } = useScheduler()
  const { moveJobFlashing, dropActive, setDropActive, dropActiveFlashing, setDropActiveFlashing } = useSchedulerCalendarMobile()
  const { setShowJobDetailsModal } = useJobDetailsModal()

  const jobObj = {
    calendarJobId: job.id,
    calendarJobCrewId: job.crews[0].id,
    calendarJobType: 'FLASHING'
  }

  const attrs = useLongPress(
    () => {
      moveJobFlashing(jobObj)
    },
    {
      threshold: 500,
    }
  );

  const crewColorsInactive = [
    'bg-[#f8f5c2]/60',
    'bg-[#b9fbc0]/60',
    'bg-[#9fe7ff]/60',
    'bg-[#fde4cf]/60',
    'bg-[#f1c0e8]/60',
    'bg-[#ffcfd2]/60',
    'bg-[#99e4eb]/60',
    'bg-[#d1bba7]/60',
    'bg-[#cbbaf0]/60',
    'bg-[#8bebbe]/60',
    'bg-[#bcd8ff]/60',
    'bg-[#bcc4d1]/60',
  ]

  const handleClick = () => {
    if (dropActive) {
      setDropActive(false)
    } else if (dropActiveFlashing) {
      setDropActiveFlashing(false)
    } else {
      setSelectedJobId(job.jobId)
      setShowJobDetailsModal(true)
    }
  }

  return (
    <div
      className={`flex h-full w-full pl-1 justify-between items-center mb-[1px] basis-7 ${crewColorsInactive[i]} rounded text-dark-blue text-[12px] leading-tight shadow border border-dark-blue`}
      onClick={handleClick}
      {...attrs}
    >
      <p className="font-extrabold leading-5">{job?.job?.jobName.toUpperCase()}</p>
      <div className="flex items-center bg-dark-blue text-white px-1 h-full min-h-[20px]">
        <p className="font-extrabold">F</p>
      </div>
    </div>
  )
}

export default CalendarJobCardFlashMobile;
