import JobStatusIcon from "../JobStatusIcon";
import { useScheduler } from "../../contexts/scheduler";
import JobDetailsStatusCardFlashing from "../JobDetailsStatusCardFlashing";
import JobDetailsStatusCardCalendarJobData from "../JobDetailsStatusCardCalendarJobData";
import { useAuth } from "../../contexts/auth";
import service from '../../service';

const JobDetailsStatusCard = () => {
  const { setMe } = useAuth()
  const { selectedJobId, selectedScheduler } = useScheduler();

  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)
  let firstCalJob = {}

  const areMultipleRegularCalendarJobs = () => {
    var count = 0
    firstCalJob = {}
    for (const calendarJob of selectedJob.calendarJobs) {
      if (calendarJob.calendarJobType === 'REGULAR') {
        if (count === 0) {
          firstCalJob = calendarJob
        }
        count += 1
      }
    }
    return count > 1
  }

  const markCalendarJobDeleted = async (flashId) => {
    let total = 0
    for (const calJob of selectedJob.calendarJobs) {
      if (calJob.calendarJobType === 'REGULAR') {
        total++
      }
    }

    const { me } = await service.markCalendarJobDeleted([flashId])
    setMe(me)

    if (total === 1) {
      const { me } = await service.updateDate(selectedJobId, 'ready')
        setMe(me)
    }
  }

  return (
    <div className='mb-8 p-4 w-[calc(100%)] lg:w-[calc(50%-12px)] rounded-xl border border-navy-light'>
      <p className='text-xl mb-2 font-bold text-secondary-blue'>Status</p>
      <div className='flex flex-col gap-3'>
        <div className='flex gap-2 items-center'>
          <img src='/JobDetails/status_icon.svg' alt='Status icon' />
          <p className='text-[14px] text-gray-blue'>Job Status:</p>
          <JobStatusIcon job={selectedJob} px={'px-2'} py={'py-1'}/>
          {!areMultipleRegularCalendarJobs()
            && Object.keys(firstCalJob).length > 0
            ? <div className='grow flex justify-end'>
                <button
                  className="text-gunite-red cursor-pointer"
                  onClick={() => { markCalendarJobDeleted(firstCalJob.id) }}
                >
                  <img
                    src='/JobDetails/trash_icon.svg'
                    alt='trashcan icon'
                  />
                </button>
              </div>
            : ''
          }
          </div>
        {!areMultipleRegularCalendarJobs()
          ? <div key={`statusCard-single-0`} >


              <JobDetailsStatusCardCalendarJobData
                calendarJob={firstCalJob}

              />
            </div>
          : selectedJob.calendarJobs.map((calendarJob, index) => (
              calendarJob.calendarJobType == 'REGULAR' && (
                <div key={`statusCard-${index}`} className='p-3 bg-navy-bright/10 border border-navy-light'>

                  <div className="mb-2 flex flex-row justify-between">
                    <div className='flex items-center'>
                      <img src='/PlannerColumn/ready_for_calendar_icon.svg' alt='Job icon' />
                      <p className='ml-1 text-[14px] font-semibold text-dark-blue'>Scheduled Shoot</p>
                    </div>
                    <button
                      className="text-gunite-red cursor-pointer"
                      onClick={() => { markCalendarJobDeleted(calendarJob.id) }}
                    >
                      <img
                        src='/JobDetails/trash_icon.svg'
                        alt='trashcan icon'
                        className='text-gunite-red'
                      />
                    </button>
                  </div>



                  <JobDetailsStatusCardCalendarJobData calendarJob={calendarJob}/>
                </div>
              )
            ))
        }
        {(selectedScheduler?.location?.service === 'GUNITE')?(<JobDetailsStatusCardFlashing />):null}
      </div>
    </div>
  );
};

export default JobDetailsStatusCard;
