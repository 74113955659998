import React from "react"
import cookies from 'browser-cookies'
import { useIntakeForm } from "../../../contexts/intakeForm"
import ProgressPaneMobile from '../../../components/ProgressPaneMobile';
import ProgressPaneDesktop from '../../../components/ProgressPaneDesktop';
import { useResponsive } from '../../../contexts/responsive';
import { useAuth } from '../../../contexts/auth';
import { useProgress } from "../../../contexts/progress";
import serviceMethods from "../../../service"
import JobDetailsForm from "../../../components/JobDetailsForm";
import "./index.scss";
import Trashcan from "../../../components/Trashcan";

const PlasterIntake3a = () => {
  const { jobDetails, setJobDetails, DEFAULT_JOB, poolDetails, setPoolDetails, DEFAULT_POOL } = useIntakeForm()
  const { navigateNext, startPlaster } = useProgress();
  const { me } = useAuth()

  const jobErrorChecker = () => {
    let error = false;
    if (Array.isArray(jobDetails)) {
      jobDetails.forEach((job) => {
        if (!job.jobName
          || !job.address
          || !job.city
          || !job.state
          || !job.zipCode
          || !job.gateCode
          || !job.residentialOrCommercial
        ) {
          error = true;
        }
      })
    }
    return error;
  }

  const { responsive } = useResponsive();
  const isMobileView = (
    responsive.breakpoint === 'sm'
    || responsive.breakpoint === 'md'
  )

  const submitPlasterAddress = async (e) => {
    e.preventDefault()
    //This block is for updating the jobs if one already exists
    let locationId = Number(cookies.get('branchId'))
    if (!locationId) locationId = jobDetails[0].locationId
    const updatedJobDetails = jobDetails.map(job => ({
      ...job,
      locationId,
      ownerId: me?.id
    }))
    const res = await serviceMethods.createPlasterJob3a(updatedJobDetails)
    if (res.ok) {
      const { createdJobs } = await res.json()
      setJobDetails(createdJobs)
      serviceMethods.setIntakeFlowIdCookie(createdJobs[0].intakeFlowId)
      navigateNext()
    }
  }


  return (
    <div className='flex flex-col lg:flex-row'>
      <div className="lg:w-1/3">
        {isMobileView ?
          <ProgressPaneMobile />
          :
          <ProgressPaneDesktop />
        }
      </div>
      <div className={`p-6 flex flex-col gap-12
          lg:p-20 lg:gap-12 lg:w-full lg:mb-0"
          ${isMobileView &&
        'plaster_intake_3a_content_container_mobile'
        }
        `}>
        {/* Note: this div hidden in mobile */}
        <div className="hidden lg:flex flex-col gap-6">
          <button onClick={() => { startPlaster() }}>
            <div className="flex gap-2 items-center">
              <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
              <p className="font-semibold text-gunite-red">Back</p>
            </div>
          </button>
          <div className="flex flex-col gap-1">
            <p className="text-secondary-blue font-bold text-4xl">Job site information</p>
            <p className="text-secondary-blue/60 font-semibold text-base">Information about job site, address, pool plan, and job site supervisor.</p>
          </div>
          <div className="flex gap-[2px] items-center">
            {/* progress bar */}
            <div className="h-2 w-1/4 bg-gunite-red" />
            <div className="h-1 w-1/4 bg-black/50" />
            <div className="h-1 w-1/4 bg-black/50" />
            <div className="h-1 w-1/4 bg-black/50" />
          </div>
        </div>
        {/* Note: this div is hidden in desktop */}
        <div className="flex flex-col lg:hidden gap-1">
          <p className="text-secondary-blue/60 text-base font-semibold">Important</p>
          <p className="font-roboto text-secondary-blue/60 text-base font-normal">In order to schedule our service, your job site needs to be prepared.</p>
        </div>

        {Array.isArray(jobDetails) ? jobDetails.map((job, i) => (
          <div key={`jobDetailsForm${i}`}>
            {jobDetails.length > 1 ?
              (
                <Trashcan
                  i={i}
                />
              ) : ''
            }
            <JobDetailsForm
              job={job}
              i={i}
              setJobDetails={setJobDetails}
              jobDetails={jobDetails}
              isGunite={false}
              key={`job-details-${i}`}
            />
          </div>
        )) : null}

        <button
          className={`w-full rounded p-3 lg:py-3 lg:px-9 lg:w-auto self-start text-2xl font-bold
            ${jobErrorChecker() ? 'bg-black/10 text-black/40 cursor-not-allowed' : 'bg-gunite-red text-white  cursor-pointer'}`}
          disabled={jobErrorChecker()}
          onClick={submitPlasterAddress}
        >Continue
        </button>
        <div className='flex flex-col gap-6'>
          <p className='font-semibold text-secondary-blue/60'>Need Another Job?</p>
          <button
            className='w-full py-3 px-4 lg:py-3 lg:px-9 lg:w-auto
                self-start rounded bg-tertiary-blue text-white text-xl lg:text-2xl
                lg:font-bold font-medium'
            onClick={(e) => {
              e.preventDefault()
              const newJobDetails = [...jobDetails, DEFAULT_JOB]
              setJobDetails(newJobDetails)
              const newPoolDetails = [...poolDetails, DEFAULT_POOL]
              setPoolDetails(newPoolDetails)
            }}>Add another job</button>
        </div>
      </div>
    </div>
  )
}

export default PlasterIntake3a;
