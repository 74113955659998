import { FaSearch } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import { useSchedulerActivityLog } from "../../contexts/SchedulerActivityLog";


const ActivityLogActionFilterDropdown = ({actionOptions, selectedActions, setSelectedActions }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [displayOptions, setdisplayOptions] = useState([]);
  const { labelForAction } = useSchedulerActivityLog();

  const dropdownWrapperRef = useRef(null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    setdisplayOptions(actionOptions.filter(option => labelForAction(option.label).toLowerCase().includes(searchTerm.toLowerCase())))
  }, [searchTerm, actionOptions])

  const handleOptionClick = (e, itemId) => {
    e.stopPropagation();
    const updatedItemsList = selectedActions.includes(itemId)
      ? selectedActions.filter((item) => item !== itemId)
      : [...selectedActions, itemId];
    setSelectedActions(updatedItemsList);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  const makeRowLabel = (activityType) => {
    switch (activityType) {
      case "intake":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/inbox.svg' alt='intake' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "backlog":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/assignment.svg' alt='backlog' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "ready":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/event_available.svg' alt='ready' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "scheduled":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/calendar_month.svg' alt='scheduled' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "rescheduled":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/event_upcoming.svg' alt='rescheduled' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "flashingScheduled":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/calendar_month.svg' alt='flashing scheduled' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
        case "guniteCompleted":
          return (
            <span className="flex flex-row items-center justify-start">
              <img src='/ActivityLog/check_circle.svg' alt='gunite completed' className="ml-[12px]"/>
              <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
            </span>
          )
          case "plasterCompleted":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/check_circle.svg' alt='plaster completed' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "completed":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/check_circle.svg' alt='completed' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "archive":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/archived.svg' alt='archived' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
        case "poolPlanUploaded":
          return (
            <span className="flex flex-row items-center justify-start">
              <img src='/ActivityLog/assignment_returned.svg' alt='pool plan uploaded' className="ml-[12px]"/>
              <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
            </span>
          )
      case "assignedCrew":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/groups.svg' alt='assigned crew' className="ml-[10px] w-[19px]"/>
            <p className="text-[14px] text-dark-blue ml-[8.5px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "assignedDrivers":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/search_hands_free.svg' alt='assigned drivers' className="ml-[12px]"/>
            <p className="text-[14px] text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "jobDetailsEdited":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/edit.svg' alt='job details edited' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
        case "jobTransferred":
          return (
            <span className="flex flex-row items-center justify-start">
              <img src='/ActivityLog/search_hands_free.svg' alt='job transferred' className="ml-[12px]"/>
              <p className="text-[14px] text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
            </span>
          )
      case "dailyJobLogSubmitted":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/assignment_returned.svg' alt='daily job log submitted' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
      case "costBreakdownSubmitted":
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/monetization_on.svg' alt='cost breakdown submitted' className="ml-[8.5px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[8.5px]">{labelForAction(activityType)}</p>
          </span>
        )
      default:
        return (
          <span className="flex flex-row items-center justify-start">
            <img src='/ActivityLog/assignment.svg' alt='assignment' className="ml-[12px]"/>
            <p className="text-[14px] ml-1.5 text-dark-blue ml-[10px]">{labelForAction(activityType)}</p>
          </span>
        )
    }
  }



  return (
    <div ref={dropdownWrapperRef} >
    <button
    className="ml-2 flex items-center justify-center h-full w-fit p-[8px] py-[5px] rounded-[4px] bg-all-f-white border border-navy-light"
    onClick={(e) => {
      e.preventDefault()
      setShowDropdown(!showDropdown)
      }}
    >
      <p className="text-[14px] font-[450] text-dark-blue select-none ">Actions</p>
      <img src={showDropdown ? '/show_less_arrow.svg' : '/show_more_arrow.svg'} alt='show filters' className="lg:pl-[3px] h-[20px] hidden lg:block" />
    </button>

      <div
      className={`w-[310px] top-[63px] left-8 absolute flex flex-col justify-between p-[8px] bg-all-f-white border border-navy-light rounded-[8px] shadow-md text-[14px] ${showDropdown ? '' : 'hidden'}`}
      >
        <div className="flex bg-white rounded-lg flex-option items-center border border-navy-light overflow-hidden">
          <FaSearch className="ml-3 text-gray-blue w-[16px]" />
          <input
          onChange={handleSearchChange}
          value={searchTerm}
          placeholder="Search"
          type="text" 
          className="p-[6px] flex-1 text-dark-blue border-none focus:ring-0" 
          />
        </div>
        <div className="overflow-y-scroll h-[300px] w-full">
        {displayOptions.map((option) => {
            return (
              <div
                key={option.id}
                className='flex w-full items-center p-[8px] cursor-pointer'
                onClick={(e) => handleOptionClick(e, option.id)}
              >
                <div className={`flex w-[16px] h-[16px] rounded-[4px] select-none ${selectedActions.includes(option.id) ? 'bg-dark-blue' : 'bg-all-f-white border'}`}>
                  {selectedActions.includes(option.id) && <img src='/check.svg' alt='check' className="w-full" />}
                </div>

                {makeRowLabel(option.label)}
              </div>
            );
        })}
        </div>
    </div>
  </div>
  )

}

export default ActivityLogActionFilterDropdown;