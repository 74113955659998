import React, { useState, useEffect } from "react";


const MiniCalendarDayRangeSelection = ({ selectedDays, setSelectedDays, dateInfo, isMultiDay, today, blank, allowSelectPast=true }) => {
  const [isInBetweenSelectedDays, setIsInBetweenSelectedDays] = useState(false)

  const selectedFlag = !isMultiDay && (
    (
      selectedDays?.day1 === dateInfo?.day
      && selectedDays?.month1 === dateInfo?.month
      && selectedDays?.year1 === dateInfo?.year
    )
  )
  const isFirstDay = (
    selectedDays?.day1 === dateInfo?.day
    && selectedDays?.month1 === dateInfo?.month
    && selectedDays?.year1 === dateInfo?.year
  )
  const isSecondDay = (
    selectedDays?.day2 === dateInfo?.day
    && selectedDays?.month2 === dateInfo?.month
    && selectedDays?.year2 === dateInfo?.year
  )
  const isToday = (
    today?.day === dateInfo?.day
    && today?.month === dateInfo?.month
    && today?.year === dateInfo?.year
  )

  const daySetter = () => {
    if (!allowSelectPast && (
      dateInfo.year < today.year ||
      (dateInfo.year === today.year && dateInfo.month < today.month) ||
      (dateInfo.year === today.year && dateInfo.month === today.month && dateInfo.day < today.day)
  )) {
      return;
  }

    if (!isMultiDay) {
      setSelectedDays({
        ...selectedDays,
        day1: dateInfo.day,
        month1: dateInfo.month,
        year1: dateInfo.year
      })
    } else {
      // Logic for setting multiDay jobs
      if (
        selectedDays?.day1 === dateInfo?.day
        && selectedDays?.month1  === dateInfo?.month
        && selectedDays?.year1 === dateInfo?.year
      ) {
        setSelectedDays({
          day1: selectedDays.day2,
          month1: selectedDays.month2,
          year1: selectedDays.year2,
          day2: 0,
          month2: '',
          year2: 0
        })
      } else if (
        selectedDays?.day2 === dateInfo?.day
        && selectedDays?.month2 === dateInfo?.month
        && selectedDays?.year2 === dateInfo?.year
      ) {
        setSelectedDays({
          ...selectedDays,
          day2: 0,
          month2: '',
          year2: 0
        })
      } else if (selectedDays?.day1 === 0) {
        setSelectedDays({
          ...selectedDays,
          day1: dateInfo.day,
          month1: dateInfo.month,
          year1: dateInfo.year
        })
      } else if (selectedDays?.year1 === dateInfo.year) {
        if (selectedDays?.month1 === dateInfo?.month) {
          if (selectedDays?.day1 < dateInfo.day) {
            setSelectedDays({
              ...selectedDays,
              day2: dateInfo.day,
              month2: dateInfo.month,
              year2: dateInfo.year
            })
          } else {
            if (selectedDays.day2 === 0) {
              setSelectedDays({
                day1: dateInfo.day,
                month1: dateInfo.month,
                year1: dateInfo.year,
                day2: selectedDays?.day1,
                month2: selectedDays.month1,
                year2: selectedDays?.year1
              })
            } else {
              setSelectedDays({
                ...selectedDays,
                day1: dateInfo.day,
                month1: dateInfo.month,
                year1: dateInfo.year,
              })
            }
          }
        } else {
          if (selectedDays.month1 < dateInfo.month) {
            setSelectedDays({
              ...selectedDays,
              day2: dateInfo.day,
              month2: dateInfo.month,
              year2: dateInfo.year
            })
          } else {
            setSelectedDays({
              day1: dateInfo.day,
              month1: dateInfo.month,
              year1: dateInfo.year,
              day2: selectedDays.day1,
              month2: selectedDays.month1,
              year2: selectedDays?.year1
            })
          }
        }
      } else {
        if (selectedDays?.year1 < dateInfo?.year) {
          setSelectedDays({
            ...selectedDays,
            day2: dateInfo.day,
            month2: dateInfo.month,
            year2: dateInfo.year
          })
        } else {
          setSelectedDays({
            day1: dateInfo.day,
            month1: dateInfo.month,
            year1: dateInfo.year,
            day2: selectedDays?.day1,
            month2: selectedDays?.month1,
            year2: selectedDays?.year1
          })
        }
      }
    }
  }

  useEffect(() => {
    let inBetween = false
    if (isMultiDay && selectedDays?.day1 !== 0 && selectedDays?.day2 !== 0) {
      inBetween = (
        dateInfo?.day > selectedDays?.day1
        && dateInfo?.day < selectedDays?.day2
        && dateInfo?.month === selectedDays?.month1
      )
      if (selectedDays?.month1 !== selectedDays?.month2) {
        if (dateInfo?.month === selectedDays?.month1) {
          inBetween = dateInfo?.day > selectedDays?.day1
        } else if (dateInfo?.month === selectedDays?.month2) {
          inBetween = dateInfo?.day < selectedDays?.day2
        } else if (dateInfo?.month > selectedDays.month1
          && dateInfo.month < selectedDays.month2) {
          inBetween = true
        }
      }
    }
    setIsInBetweenSelectedDays(inBetween)
  }, [selectedDays?.day2])

  return (
    <div className={`w-[34px] h-[24px]`}>
      {blank ? '' : (
        <button
          className={
            `flex h-full w-full items-center justify-center font-bold text-xs text-dark-blue
            ${selectedFlag ? 'selected bg-dark-blue text-white rounded-md' : ''}
            ${isFirstDay ? 'selected rounded-l-md bg-dark-blue text-white' : ''}
            ${isSecondDay ? 'selected rounded-r-md bg-dark-blue text-white' : ''}
            ${isInBetweenSelectedDays ? 'selected bg-multi-day-gray' : ''}
            ${isToday ? 'text-gunite-red' : ''}`
          }
          onClick={(e) => {
            e.preventDefault()
            daySetter()
          }}
        >
          {dateInfo?.day}
        </button>
      )}

    </div>
  )
}

export default MiniCalendarDayRangeSelection