import React, { useRef, useState, useEffect } from 'react'
import Map from '../../../components/Map'
import { useJsApiLoader } from '@react-google-maps/api'
import { mapOptions } from '../../../components/Map/MapConfiguration'
import { useLocation } from 'react-router-dom';
import cookies from 'browser-cookies';
import "./index.scss";
import ProgressPaneMobile from '../../../components/ProgressPaneMobile/index.js';
import ProgressPaneDesktop from '../../../components/ProgressPaneDesktop/index.js';
import { useResponsive } from '../../../contexts/responsive';
import { useProgress } from '../../../contexts/progress';
import serviceMethods from '../../../service.js';
import { useIntakeForm } from '../../../contexts/intakeForm.js';

const ChooseLocation = () => {
  const { pathname } = useLocation();
  const { isLoaded } = useJsApiLoader({
    id: mapOptions.googleMapApiKey,
    googleMapsApiKey: mapOptions.googleMapApiKey
  })
  const { retrievedLocationsGunite, retrievedLocationsPlaster } = useIntakeForm();
  const { responsive } = useResponsive();
  const isMobileView = (
    responsive.breakpoint === 'sm'
    || responsive.breakpoint === 'md'
  )
  const mapContainer = useRef()
  const [newCenter, setNewCenter] = useState({
    newLat: '',
    newLng: '',
  });
  const { zipCode } = useProgress()

  const centerMapOnZip = async (zipCode) => {
    if (zipCode.length === 5){
      try {
        var response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${mapOptions.googleMapApiKey}`);
        var data = await response.json();
  
        setNewCenter({
          newLat: (data.results[0].geometry.location.lat),
          newLng: (data.results[0].geometry.location.lng),
        })
  
        findNearestLocation(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng, retrievedLocationsGunite, retrievedLocationsPlaster)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const [selectedMarker, setSelectedMarker] = useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleMarkerInfoClose = () => {
    setSelectedMarker(null);
  };

  const [closestBranch, setClosestBranch] = useState(null);

  useEffect(()=>{
    if (closestBranch?.id && zipCode){
      serviceMethods.setLocationCookies(zipCode, closestBranch)
    }
  },[closestBranch, zipCode])

  useEffect(()=>{
    let storedBranchId = cookies.get('branchId')
    let storedBranchZip = cookies.get('searchZip')

    if (storedBranchId && storedBranchZip){
      if (pathname.includes('gunite')){
        let previousBranchGunite = retrievedLocationsGunite.find(branch => branch.id === parseInt(storedBranchId, 10));
        handleMarkerClick(previousBranchGunite)
      } else {
        let previousBranchPlaster = retrievedLocationsPlaster.find(branch => branch.id === parseInt(storedBranchId, 10));
        handleMarkerClick(previousBranchPlaster)
      }
    }
  },[cookies, closestBranch])
  

  function findNearestLocation(newLat, newLng, retrievedLocationsGunite, retrievedLocationsPlaster){
    let nearestLocation = null;
    let minDistance = Number.MAX_VALUE;
    let locationsArray;

    if (pathname.includes('gunite/choose-location')){
      locationsArray = retrievedLocationsGunite;
      for (const location of locationsArray){
        const distance = calculateDistance(newLat, newLng, location.latitude, location.longitude)
        if (distance < minDistance){
          minDistance = distance;
          nearestLocation = location;
        }
      }
    } else if (pathname.includes('plaster/choose-location')){
      locationsArray = retrievedLocationsPlaster;
      for (const location of locationsArray){
        const distance = calculateDistance(newLat, newLng, location.latitude, location.longitude)
        if (distance < minDistance){
          minDistance = distance;
          nearestLocation = location;
        }
      }
    }

    if (nearestLocation !== null){
      setClosestBranch(nearestLocation);
      handleMarkerClick(nearestLocation);
      setNewCenter({
        newLat: closestBranch?.latitude,
        newLng: closestBranch?.longitude,
      })
    }

    return nearestLocation;
  }

  function calculateDistance(lat1, lng1, lat2, lng2){
    const dx = lat2 - lat1;
    const dy = lng2 - lng1;
    return Math.sqrt(dx * dx + dy * dy);
  }

  //PREVENTS MAP FROM LOADING WITHOUT MARKERS
  const [markersLoaded, setMarkersLoaded] = useState(false);
  useEffect(()=>{
    const timeout = setTimeout(()=> {
      setMarkersLoaded(true);
    }, 100);
    return () => clearTimeout(timeout);
  },[])
  const renderMarkers = isLoaded && markersLoaded;

  return (
    <>
    <div className='lg:flex'>
      {isMobileView ?
        <ProgressPaneMobile centerMapOnZip={centerMapOnZip} />
        :
        <div className='min-w-[428px]'>
          <ProgressPaneDesktop centerMapOnZip={centerMapOnZip} />
        </div>
      }
      
      <div className={`flex
        ${isMobileView ? 'map_outer_container_mobile flex-col' : 'map_outer_container_desktop'}`} 
        ref={mapContainer}>
        <Map
          mapContainer={mapContainer}
          renderMarkers={renderMarkers}
          coordinates={newCenter}
          handleMarkerClick={handleMarkerClick}
          selectedMarker={selectedMarker}
          handleMarkerInfoClose={handleMarkerInfoClose}
        />
      </div>
    </div>
    </>
  )
}

export default ChooseLocation;
