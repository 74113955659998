import React, { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from './auth'
import { useAdmin } from './admin'
import Service from '../service'

const EmployeeContext = createContext()

const EmployeeContextProvider = props => {
  const { me, logout } = useAuth();
  const { accessToken } = Service.getAuthTokens()
  const { selectedLocation } = useAdmin();
  const navigate = useNavigate()
  const [newEmployeeSearchTerm, setNewEmployeeSearchTerm] = useState('')
  const [newEmployeeFormSearching, setNewEmployeeFormSearching] = useState(false);
  const [branchSpecificEmployees, setBranchSpecificEmployees] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState({})
  const [needToUpdateEmployees, setNeedToUpdateEmployees] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortAscending, setSortAscending]= useState(true);
  const [sortBy, setSortBy] = useState('name')
  const { pathname } = useLocation();

  const RESULTS_PER_PAGE = 10;

  useEffect(()=>{
    if (newEmployeeSearchTerm !== ''){
      setNewEmployeeFormSearching(true)
    } else {
      setNewEmployeeFormSearching(false)
    }
  },[newEmployeeSearchTerm])

  useEffect(()=>{
    if (!accessToken && !pathname.includes('onboard')) {
      navigate('/login')
      logout()
    }
  },[accessToken])

  const paginate = (currentPage, items) => {
    const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
    const endIndex = (startIndex + RESULTS_PER_PAGE);
    const itemsAfterPaginating = items?.slice(startIndex, Math.min(endIndex, items.length))
    return itemsAfterPaginating;
  }

  const sort = (items, columnToSortBy) => {
    if (columnToSortBy === 'name') {
      items.sort(function(a,b) {
        if(a.name.toLowerCase() > b.name.toLowerCase()) {return  1;}
        if(a.name.toLowerCase() < b.name.toLowerCase()) {return -1;}
        return 0;
      });
    } else if (columnToSortBy === 'role'){
      items.sort(function(a, b){
        if(a.role.toLowerCase() > b.role.toLowerCase()) {return 1;}
        if(a.role.toLowerCase() < b.role.toLowerCase()) {return -1;}
        return 0;
      })
    } else if (columnToSortBy === 'crew'){
      items.sort(function(a, b){
        if(a?.foreman?.toLowerCase() > b?.foreman?.toLowerCase()) {return 1;}
        if(a?.foreman?.toLowerCase() < b?.foreman?.toLowerCase()) {return -1;}
        return 0;
      })
    }
    return items;
  }

  const getEmployeesByBranch = async (locationId) => {
    const res = await Service.getProfile();
    if (res.ok){
      const data = await res.json();
      const matchingLocation = data.user.admin.allLocations.filter(branch => branch.id === locationId)
      if (matchingLocation.length > 0) {
        const tempCrewMembers = []
        const tempForeman = []
        const tempSchedulers = []
        const tempDrivers = []
        const branchCrewMembers = matchingLocation[0]?.crews

        if (branchCrewMembers){
          for (let i = 0; i < branchCrewMembers.length; i++){
            for (let j = 0; j < branchCrewMembers[i].crewMembers.length; j++){
              if (branchCrewMembers[i].crewMembers[j].deletedAt === null 
                || branchCrewMembers[i].crewMembers[j].isUnassigned === true) {
                  tempCrewMembers.push({
                    ...branchCrewMembers[i].crewMembers[j].user,
                    foreman: branchCrewMembers[i].name,
                    role: 'CREWMEMBER',
                    gunitePoolRate: branchCrewMembers[i].crewMembers[j].poolRate,
                    plasterPoolRate: branchCrewMembers[i].crewMembers[j].plasterPoolRate,
                    quartzPoolRate: branchCrewMembers[i].crewMembers[j].quartzPoolRate,
                    pebblePoolRate: branchCrewMembers[i].crewMembers[j].pebblePoolRate
                  })
              }
            }
            if (branchCrewMembers[i].foreman && branchCrewMembers[i].foreman.deletedAt === null){
              tempForeman.push({
                ...branchCrewMembers[i].foreman.user,
                crewId: branchCrewMembers[i].id,
                foreman: branchCrewMembers[i].name,
                role: 'FOREMAN',
                gunitePoolRate: branchCrewMembers[i].foreman.gunitePoolRate,
                plasterPoolRate: branchCrewMembers[i].foreman.plasterPoolRate,
                quartzPoolRate: branchCrewMembers[i].foreman.quartzPoolRate,
                pebblePoolRate: branchCrewMembers[i].foreman.pebblePoolRate
              })
            }
          }
          for (let k = 0; k < matchingLocation[0]?.schedulers.length; k++){
            tempSchedulers.push({
              ...matchingLocation[0].schedulers[k].user,
              phone: matchingLocation[0].schedulers[k].phone
            })
          }
          for (let l = 0; l < matchingLocation[0]?.drivers.length; l++){
            tempDrivers.push({
              ...matchingLocation[0]?.drivers[l].user,
              driverRate: matchingLocation[0]?.drivers[l].poolRate,
              role: 'DRIVER'
            })
          }
          const allEmployees = [
            ...tempCrewMembers, ...tempForeman,
            ...tempSchedulers, ...tempDrivers
          ];
          setBranchSpecificEmployees(allEmployees)
        }
      }
    }
  }

  useEffect(()=>{
    if (me?.role === 'ADMIN' || me?.role === 'BRANCHADMIN'){
      if (selectedLocation?.id){
        getEmployeesByBranch(selectedLocation.id);
      }
    }
  },[selectedLocation, needToUpdateEmployees])

  return (
    <EmployeeContext.Provider value={{
      newEmployeeSearchTerm, setNewEmployeeSearchTerm,
      newEmployeeFormSearching, setNewEmployeeFormSearching,
      branchSpecificEmployees, setBranchSpecificEmployees,
      getEmployeesByBranch, selectedEmployee, setSelectedEmployee,
      needToUpdateEmployees, setNeedToUpdateEmployees, paginate,
      currentPage, setCurrentPage, sortAscending, setSortAscending,
      sort, RESULTS_PER_PAGE, sortBy, setSortBy
    }}>
      {props.children}
    </EmployeeContext.Provider>
  )
}

const useEmployee = () => useContext(EmployeeContext)

export { useEmployee }
export default EmployeeContextProvider
