import React, { useState, useEffect, useRef } from "react";
import CostBreakdownExpandableMenu from "../CostBreakdownExpandableMenu";
import CostBreakdownInvoice from "../CostBreakdownInvoice"
import CostBreakdownCrews from "../CostBreakdownCrews";
import { useScheduler } from "../../contexts/scheduler";
import CostBreakdownDrivers from "../CostBreakdownDrivers";
import CostBreakdownMaterials from "../CostBreakdownMaterials";
import CostBreakdownFuel from "../CostBreakdownFuel";
import CostBreakdownSummary from "../CostBreakdownSummary";
import serviceMethods from '../../service'
import { useAuth } from "../../contexts/auth";
import CostBreakdownLeaveWarning from "../CostBreakdownLeaveWarning";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import CostBreakdownSubmittedInvoice from "../CostBreakdownSubmittedInvoice";
import CostBreakdownSubmittedCrew from "../CostBreakdownSubmittedCrew";
import CostBreakdownSubmittedDrivers from "../CostBreakdownSubmittedDrivers";
import CostBreakdownSubmittedMaterials from "../CostBreakdownSubmittedMaterials";
import CostBreakdownSubmittedFuel from "../CostBreakdownSubmittedFuel";
import CostBreakdownResetWarning from "../CostBreakdownResetWarning";
import CostBreakdownPDFViewer from "../CostBreakdownPDFViewer";

const DEFAULT_CHANGE_TRACKER = {
  costBreakdownId: null,
  update: {
    costBreakdown: {},
    invoice: {},
    crew: {},
    drivers: {},
    material: {},
    fuel: {},
  },
  add: {
    invoice: [],
    crew: [],
    drivers: [],
    material: [],
    fuel: [],
  },
  remove: {
    invoice: [],
    crew: [],
    drivers: [],
    material: [],
    fuel: [],
  }
}

const DEFAULT_DISCOUNT = { isDiscount: false, percent: 0 }

const CostBreakdown = () => {
  const {
    handleCloseJobDetailsModal,
    showCostBreakdownWarning,
    setShowCostBreakdownWarning,
    isCostBreakdownChange,
    showResetWarning,
    setShowResetWarning,
    globalRates,
    locationRates,
    DEFAULT_BREAKDOWN,
    DEFAULT_INVOICE_ITEM,
    DEFAULT_CREW,
    DEFAULT_DRIVER,
    DEFAULT_MATERIAL_PLASTER,
    DEFAULT_MATERIAL_GUNITE,
    DEFAULT_FUEL_PLASTER,
    DEFAULT_FUEL_GUNITE,
    DEFAULT_OPTIONS_GUNITE,
    DEFAULT_OPTIONS_PLASTER
  } = useJobDetailsModal()
  const { setMe } = useAuth()
  const { selectedJobId, selectedScheduler } = useScheduler()
  const plaster = selectedScheduler.location.service === 'PLASTER'
  const [crew, setCrew] = useState({})
  const [drivers, setDrivers] = useState({})
  const [breakdown, setBreakdown] = useState({
    ...DEFAULT_BREAKDOWN,
    costBreakdown: {
      ...DEFAULT_BREAKDOWN.costBreakdown,
      jobId: selectedJobId
    }
  })
  const [employerTaxRate, setEmployerTaxRate] = useState(DEFAULT_BREAKDOWN.costBreakdown.taxRate)
  const [batches, setBatches] = useState(0)
  const [resetToggle, setResetToggle] = useState(false)

  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)
  const submitActive = selectedJob.workCompletedAt !== null
  const saveActive = selectedJob.scheduledAt !== null
  const [activeButtons, setActiveButtons] = useState({ save: saveActive, submit: submitActive })

  const [savedCostBreakdown, setSavedCostBreakdown] = useState(selectedJob.costBreakdown.length > 0 ? selectedJob.costBreakdown[0] : null)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const invoiceOrBreakdown = useRef('')

  const [batchIngredients, setBatchIngredients] = useState({})

  const [discount, setDiscount] = useState(DEFAULT_DISCOUNT)

  const [totalYards, setTotalYards] = useState(0)
  const [plasterType, setPlasterType] = useState('regular')

  const handleSubmit = async () => {
    if (savedCostBreakdown) {
      const { me } = await serviceMethods.updateCostBreakdown(conditionBreakdownNumbers(), true)
      setMe(me)
    } else {
      const { me } = await serviceMethods.createCostBreakdown(conditionBreakdownNumbers(), true)
      setMe(me)
    }
    isCostBreakdownChange.current = false
  }

  const handleSaveDraft = async () => {
    if (savedCostBreakdown) {
      const { me } = await serviceMethods.updateCostBreakdown(conditionBreakdownNumbers())
      setMe(me)
    } else {
      const { me } = await serviceMethods.createCostBreakdown(conditionBreakdownNumbers())
      setMe(me)
    }
    isCostBreakdownChange.current = checkForChanges()
  }

  const handleReset = async () => {
    if (savedCostBreakdown) {
      const { me } = await serviceMethods.markCostBreakdownDeleted(savedCostBreakdown.id)
      setMe(me)
      setBatches(0)
      setDiscount(DEFAULT_DISCOUNT)
    } else {
      setBreakdown({
        ...DEFAULT_BREAKDOWN,
        costBreakdown: {
          ...DEFAULT_BREAKDOWN.costBreakdown,
          jobId: selectedJobId
        },
        crew: {
          ...DEFAULT_BREAKDOWN.crew,
          items: [...crew.members],
        },
        fuel: {
          ...DEFAULT_BREAKDOWN.fuel,
          items: [...(plaster ? DEFAULT_FUEL_PLASTER : DEFAULT_FUEL_GUNITE)]
        }
      })
      setBatches(0)
      setDiscount(DEFAULT_DISCOUNT)
      setResetToggle(!resetToggle)
    }
    isCostBreakdownChange.current = checkForChanges()
  }

  const handleUnlockRequest = async () => {
    const { me } = await serviceMethods.unlockCostBreakdown(savedCostBreakdown.id)
    setMe(me)
  }

  const conditionBreakdownNumbers = () => {
    const newBreakdown = {
      costBreakdown: {
        discountPercent: Number(breakdown.costBreakdown.discountPercent || 0),
        batches: Number(breakdown.costBreakdown.batches || 0),
        insurance: Number(breakdown.costBreakdown.insurance || 0),
        jobId: Number(breakdown.costBreakdown.jobId || 0),
        taxRate: Number(breakdown.costBreakdown.taxRate || 0),
      },
      invoice: [],
      crew: [],
      drivers: [],
      material: [],
      fuel: [],
    }
    for (const item of breakdown.invoice.items) {
      newBreakdown.invoice.push({
        ...item,
        amount: Number(item.amount || 0),
        minIA: Number(item.minIA || 0),
        minPrice: Number(item.minPrice || 0),
        price: Number(item.price || 0),
        tier: Number(item.tier || 0)
      })
    }
    for (const item of breakdown.crew.items) {
      newBreakdown.crew.push({
        ...item,
        pools: Number(item.pools || 0),
        poolRate: Number(item.poolRate || 0),
        travel: Number(item.travel || 0),
        extras: Number(item.extras || 0),
      })
    }
    for (const item of breakdown.drivers.items) {
      newBreakdown.drivers.push({
        ...item,
        pools: Number(item.pools || 0),
        poolRate: Number(item.poolRate || 0),
        travel: Number(item.travel || 0),
        extras: Number(item.extras || 0),
      })
    }
    for (const item of breakdown.material.items) {
      newBreakdown.material.push({
        ...item,
        bags: Number(item.bags || 0),
        costBag: Number(item.costBag || 0),
        yards: Number(item.yards || 0),
        tons: Number(item.tons || 0),
        costYard: Number(item.costYard || 0),
        costTon: Number(item.costTon || 0),
      })
    }
    for (const item of breakdown.fuel.items) {
      newBreakdown.fuel.push({
        ...item,
        trips: Number(item.trips || 0),
        milesToJob: Number(item.milesToJob || 0),
        costMile: Number(item.costMile || 0),
        costGallon: Number(item.costGallon || 0),
        mpg: Number(item.mpg || 0),
        yards: Number(item.yards || 0),
        gallonsPerYard: Number(item.gallonsPerYard || 0),
      })
    }
    if (savedCostBreakdown) {
      return findChanges(savedCostBreakdown, newBreakdown)
    } else {
      return newBreakdown
    }
  }

  const findChanges = (savedCB, newCB) => {
    let changes = DEFAULT_CHANGE_TRACKER

    for (const sectionKey of Object.keys(newCB)) {
      if (sectionKey === 'costBreakdown') {
        // check for changes in costbreakdown
        let costBreakdownChanges = {}
        if  ((newCB.costBreakdown.discountPercent || 0) !== (Number(savedCB.discountPercent) || 0)) {

          costBreakdownChanges.discountPercent = newCB.costBreakdown.discountPercent
        }
        if  ((newCB.costBreakdown.batches || 0) !== (Number(savedCB.batches) || 0)) {

          costBreakdownChanges.batches = newCB.costBreakdown.batches
        }
        if  (newCB.costBreakdown.employerTaxRate !== savedCB.employerTaxRate) {

          costBreakdownChanges.employerTaxRate = newCB.costBreakdown.employerTaxRate
        }
        if  (newCB.costBreakdown.insurance.toString() !== savedCB.insurance.toString()) {

          costBreakdownChanges.insurance = newCB.costBreakdown.insurance
        }
        changes = {
          ...changes,
          update: {
            ...changes.update,
            [sectionKey]: {...costBreakdownChanges }
          },
          costBreakdownId: savedCB.id
        }
        continue
      }
      const savedIds = savedCB[sectionKey].map((item) => item.id)

      for (const newItem of newCB[sectionKey]) {
        // if item has an id, track any changes
        if (newItem.id) {
          const savedItem = savedCB[sectionKey].find((obj) => obj.id === newItem.id)
          savedIds.splice(savedIds.indexOf(newItem.id), 1)

          for (const key of Object.keys(newItem)) {
            if (newItem[key]?.toString() !== savedItem[key]?.toString()) {
              changes = {
                ...changes,
                update: {
                  ...changes.update,
                  [sectionKey]: {
                    ...changes.update[sectionKey],
                    [newItem.id]: {
                      ...(changes.update[sectionKey][savedItem.id] || {}),
                      [key]: newItem[key]
                    }
                  }
                }
              }
            }
          }

        } else {
          // push entire newItem to add list
          changes = {
            ...changes,
            add: {
              ...changes.add,
              [sectionKey]: [
                ...changes.add[sectionKey],
                newItem
              ]
            }
          }
        }
      }

      // any ids still in the savedIds array need to be removed/marked deleted
      if (savedIds.length) {
        changes = {
          ...changes,
          remove: {
            ...changes.remove,
            [sectionKey]: [
              ...changes.remove[sectionKey],
              ...savedIds
            ]
          }
        }
      }

    }
    return changes
  }

  // returns true after first change found, otherwise false
  const checkForChanges = () => {
    if (savedCostBreakdown && savedCostBreakdown.submittedAt) return
    let changeFound = false
    let savedCB = savedCostBreakdown ? {
      costBreakdown: {
        jobId: savedCostBreakdown.id,
        taxRate: savedCostBreakdown.taxRate,
        insurance: savedCostBreakdown.insurance,
        batches: Number(savedCostBreakdown.batches),
        discountPercent: Number(savedCostBreakdown.discountPercent),
      },
      invoice: {
        items: savedCostBreakdown.invoice,
      },
      crew: {
        items: savedCostBreakdown.crew,
      },
      drivers: {
        items: savedCostBreakdown.drivers,
      },
      material: {
        items: savedCostBreakdown.material,
      },
      fuel: {
        items: savedCostBreakdown.fuel,
      }
    } : {
      costBreakdown: {
        jobId: selectedJobId,
        taxRate: DEFAULT_BREAKDOWN.costBreakdown.taxRate,
        insurance: DEFAULT_BREAKDOWN.costBreakdown.insurance,
        batches: 0,
        discountPercent: 0,
      },
      invoice: {
        items: [],
      },
      crew: {
        items: crew.members,
      },
      drivers: {
        items: drivers.drivers,
      },
      material: {
        items: plaster ? DEFAULT_MATERIAL_PLASTER : DEFAULT_MATERIAL_GUNITE,
      },
      fuel: {
        items: plaster ? DEFAULT_FUEL_PLASTER : DEFAULT_FUEL_GUNITE,
      }
    }

    for (const sectionKey of Object.keys(breakdown)) {
      if (changeFound) break

      if (sectionKey === 'costBreakdown') {
        // check for changes in costbreakdown
        if  ((breakdown.costBreakdown.discountPercent || 0).toString() !== (savedCB.costBreakdown.discountPercent || 0).toString()) {
          changeFound = true
          break
        }
        if  ((breakdown.costBreakdown.batches || 0).toString() !== (savedCB.costBreakdown.batches || 0).toString()) {
          changeFound = true
          break
        }
        if  ((breakdown.costBreakdown.employerTaxRate || 0).toString() !== (savedCB.costBreakdown.employerTaxRate || 0).toString()) {
          changeFound = true
          break
        }
        if  ((breakdown.costBreakdown.insurance || 0).toString() !== (savedCB.costBreakdown.insurance || 0).toString()) {
          changeFound = true
          break
        }
        continue
      }

      if (breakdown[sectionKey].items?.length !== savedCB[sectionKey].items?.length) {
        changeFound = true
        break
      }

      for (const newItem of breakdown[sectionKey].items) {
        if (changeFound) break
        // if item has an id, check for changes
        if (newItem.id) {
          const savedItem = savedCB[sectionKey].items.find((obj) => obj.id === newItem.id)
          if (!savedItem) {
            changeFound = true
            break
          }

          for (const key of Object.keys(newItem)) {
            if (changeFound) break
            if (newItem[key]?.toString() !== savedItem[key]?.toString()) {
              changeFound = true
              break
            }
          }
        } else {
          if (savedCostBreakdown) {
            // no id means its a newly added item
            changeFound = true
            break
          } else {
            const savedItem = savedCB[sectionKey].items.find((obj) => obj.name === newItem.name)
            if (!savedItem) {
              changeFound = true
              break
            }

            for (const key of Object.keys(newItem)) {
              if (changeFound) break
              if ((newItem[key])?.toString() !== (savedItem[key])?.toString()) {
                changeFound = true
                break
              }
            }
          }
        }
      }
    }
    return changeFound
  }

  const refreshSavedCostBreakdown = () => {
    const selectedJobCheck = selectedScheduler.location.jobs.find(job => job.id === selectedJobId)
    const oldCostBreakdown = selectedJobCheck.costBreakdown.length > 0 ? selectedJobCheck.costBreakdown[0] : null

    setSavedCostBreakdown(oldCostBreakdown)
  }

  const updateBreakdown = () => {
    const invoiceTotal = savedCostBreakdown?.invoice?.reduce((accumulator, item) => {
      const amount =  Number(item.amount) || 0
      const price = Number(item.price) || 0
      return accumulator += (amount * price)
    }, 0)

    const crewMembers = []
    const contractors = []
    savedCostBreakdown?.crew?.forEach((crew) => {
      if (crew.isContractor) {
        contractors.push(crew)
      } else {
        crewMembers.push(crew)
      }
    })

    const crewTotal = crewMembers.reduce((accumulator, item) => {
      const pools = Number(item.pools) || 0
      const poolRate = Number(item.poolRate) || 0
      const travel = Number(item.travel) || 0
      const extras = Number(item.extras) || 0
      return accumulator += ((pools * poolRate) + travel + extras)
    }, 0)

    const contractorTotal = contractors.reduce((accumulator, item) => {
      const amount = Number(item.contractorAmount) || 0
      const price = Number(item.contractorPrice) || 0
      return accumulator += (amount * price)
    }, 0)

    const driversTotal = savedCostBreakdown?.drivers?.reduce((accumulator, item) => {
      const pools = Number(item.pools) || 0
      const poolRate = Number(item.poolRate) || 0
      const travel = Number(item.travel) || 0
      const extras = Number(item.extras) || 0
      return accumulator += ((pools * poolRate) + travel + extras)
    }, 0)

    const materialTotal = plaster ? (
      savedCostBreakdown?.material?.reduce((accumulator, item) => {
        const bags = Number(item.bags) || 0
        const costBag = Number(item.costBag) || 0
        return accumulator += (bags * costBag)
      }, 0)
    ) : (
      savedCostBreakdown?.material?.reduce((accumulator, item) => {
        const yards = Number(item.yards) || 0
        const costYard = Number(item.costYard) || 0
        return accumulator += (yards * costYard)
      }, 0)
    )


    const fuelTotal = savedCostBreakdown?.fuel?.reduce((accumulator, item) => {
      const milesToJob = Number(item.milesToJob) || 0
      const trips = Number(item.trips) || 0
      const costGallon = Number(item.costGallon) || 0
      const mpg = Number(item.mpg) || 0
      const yards = Number(item.yards) || 0
      const gallonsPerYard = Number(item.gallonsPerYard) || 0

      if (item.name === 'Compressor red') {
        const vehicleTotal = yards * gallonsPerYard * costGallon

        return accumulator += vehicleTotal
      } else {
        const vehicleTotal = ((milesToJob * 2) * trips) * (costGallon / mpg)

        return accumulator += vehicleTotal
      }
    }, 0)
    setBreakdown({
      costBreakdown: {
        jobId: savedCostBreakdown.jobId,
        taxRate: Number(savedCostBreakdown.taxRate),
        insurance: Number(savedCostBreakdown.insurance),
        batches: Number(savedCostBreakdown.batches),
        discountPercent: Number(savedCostBreakdown.discountPercent),
      },
      invoice: {
        items: savedCostBreakdown.invoice,
        total: invoiceTotal
      },
      crew: {
        items: savedCostBreakdown.crew,
        total: {
          total: (crewTotal + contractorTotal),
          withTax: (crewTotal + contractorTotal) + ((crewTotal + contractorTotal) * savedCostBreakdown.taxRate)
        }
      },
      drivers: {
        items: savedCostBreakdown.drivers,
        total: {
          total: driversTotal,
          withTax: driversTotal + (driversTotal * savedCostBreakdown.taxRate)
        }
      },
      material: {
        items: savedCostBreakdown.material,
        total: materialTotal
      },
      fuel: {
        items: savedCostBreakdown.fuel,
        total: fuelTotal
      }
    })
  }

  const collectBatchIngredients = async () => {
    const ingredients = await serviceMethods.getBatchIngredients()
    const tempObject = {}
    ingredients.forEach((item) => {
      tempObject[`${item.manufacturer} - ${item.productName}`] = item
    })
    setBatchIngredients(tempObject)
  }

  useEffect(() => {
    const submitButton = selectedJob.workCompletedAt !== null || selectedJob.completedAt !== null
    const saveButton = selectedJob.scheduledAt !== null
    setActiveButtons({ save: saveButton, submit: submitButton })

  }, [selectedJob.workCompletedAt, selectedJob.schedueldAt, selectedJob])

  useEffect(() => {
    const crewObj = {
      names: [],
      members: []
    }
    const driverObj = {
      driverNames: [],
      drivers: []
    }

    selectedJob.calendarJobs.forEach((calJob) => {
      calJob.jobLog?.crewMembers.forEach((member) => {
        if (!crewObj.names.includes(member.crewMember.user.name)) {
          const memberObjects = [];
          crewObj.names.push(member.crewMember.user.name)
          memberObjects.push(member)

          memberObjects.forEach((member) => {
            const plasterPoolRate =  member.crewMember.plasterPoolRate
            const quartzPoolRate = member.crewMember.quartzPoolRate
            const pebblePoolRate = member.crewMember.pebblePoolRate
            const gunitePoolRate = member.crewMember.poolRate ? member.crewMember.poolRate : member.crewMember.gunitePoolRate
            const rate = plaster ? plasterPoolRate : gunitePoolRate

            const newMember = {
              ...DEFAULT_CREW,
              plasterPoolRate: plasterPoolRate,
              quartzPoolRate: quartzPoolRate,
              pebblePoolRate: pebblePoolRate,
              gunitePoolRate: gunitePoolRate,
              poolRate: rate,
              name: member.crewMember.user.name
            }
            crewObj.members.push(newMember)
          })
        }
      })

      const currentForeman = calJob.jobLog?.foreman
      if (!crewObj.names.includes(currentForeman?.name)) {
        const foremanObject = [];
        crewObj.names.push(currentForeman?.name)
        foremanObject.push(currentForeman)

        const plasterPoolRate = foremanObject[0]?.foreman[0].plasterPoolRate
        const gunitePoolRate = foremanObject[0]?.foreman[0].gunitePoolRate
        const quartzPoolRate = foremanObject[0]?.foreman[0].quartzPoolRate
        const pebblePoolRate = foremanObject[0]?.foreman[0].pebblePoolRate
        const rate = plaster ? plasterPoolRate : gunitePoolRate

        const newMember = {
          ...DEFAULT_CREW,
          plasterPoolRate: plasterPoolRate,
          gunitePoolRate: gunitePoolRate,
          pebblePoolRate: pebblePoolRate,
          quartzPoolRate: quartzPoolRate,
          poolRate: rate,
          name: currentForeman?.name
        }
        crewObj.members.push(newMember)
      }
      calJob.jobLog?.drivers.forEach((driver) => {
        const driverObjects = []
        if (!driverObj.driverNames.includes(driver.driver.user.name)) {
          driverObj.driverNames.push(driver.driver.user.name)
          driverObjects.push(driver)
        }

        driverObjects.forEach((driver) => {
          const newDriver = {
            ...DEFAULT_DRIVER,
            poolRate: driver.driver.poolRate,
            name: driver.driver.user.name
          }
          driverObj.drivers.push(newDriver)
        })
      })
    })
    setCrew(crewObj)
    setDrivers(driverObj)
  }, [selectedJob])

  useEffect(() => {
    // resets breakdown
    setBreakdown({
      ...DEFAULT_BREAKDOWN,
      costBreakdown: {
        ...breakdown.costBreakdown,
        jobId: selectedJobId
      }
    })

    refreshSavedCostBreakdown()
    isCostBreakdownChange.current = false
  }, [selectedJobId])

  useEffect(() => {
    setBreakdown({
      ...breakdown,
      costBreakdown: {
        ...breakdown.costBreakdown,
        batches: Number(batches)
      }
    })
  }, [batches])

  useEffect(() => {
    setBreakdown({
      ...breakdown,
      costBreakdown: {
        ...breakdown.costBreakdown,
        discountPercent: discount.isDiscount ? Number(discount.percent) : 0
      }
    })

  }, [discount.percent, discount.isDiscount])

  useEffect(() => {
    refreshSavedCostBreakdown()
  }, [selectedJob.costBreakdown])

  useEffect(() => {
    isCostBreakdownChange.current = checkForChanges()
  }, [breakdown])

  useEffect(() => {
    if (savedCostBreakdown) {
      updateBreakdown()
    }
  }, [savedCostBreakdown])

  useEffect(() => {
    if (plaster) {
      collectBatchIngredients()
    }
  }, [])

  return (
    <div className="flex flex-col px-4 pt-4 justify-center align-center">
      <div className='w-full flex justify-between'>
        <CostBreakdownExpandableMenu text='Invoice' subText={`for ${selectedJob?.owner?.customer?.bizName}`}>
          {savedCostBreakdown && savedCostBreakdown.submittedAt ?
            <CostBreakdownSubmittedInvoice
              savedCostBreakdown={savedCostBreakdown}
            />
            :
            <CostBreakdownInvoice
              breakdown={breakdown}
              setBreakdown={setBreakdown}
              plaster={plaster}
              savedCostBreakdown={savedCostBreakdown}
              resetToggle={resetToggle}
              DEFAULT_INVOICE_ITEM={DEFAULT_INVOICE_ITEM}
              DEFAULT_OPTIONS_GUNITE={DEFAULT_OPTIONS_GUNITE}
              DEFAULT_OPTIONS_PLASTER={DEFAULT_OPTIONS_PLASTER}
              discount={discount}
              setDiscount={setDiscount}
              setTotalYards={setTotalYards}
              setPlasterType={setPlasterType}
            />
          }
        </CostBreakdownExpandableMenu>
      </div>
      <div className='w-full flex justify-between pt-3 mt-2 border-t'>
        <CostBreakdownExpandableMenu text='Crew'>
          {savedCostBreakdown && savedCostBreakdown.submittedAt ?
            <CostBreakdownSubmittedCrew
              savedCostBreakdown={savedCostBreakdown}
              employerTaxRate={employerTaxRate}
            />
            :
            <CostBreakdownCrews
              crew={crew}
              breakdown={breakdown}
              setBreakdown={setBreakdown}
              employerTaxRate={employerTaxRate}
              savedCostBreakdown={savedCostBreakdown}
              resetToggle={resetToggle}
              DEFAULT_CREW={DEFAULT_CREW}
              isPlaster={plaster}
              plasterType={plasterType}
              selectedJob={selectedJob}
            />
          }
        </CostBreakdownExpandableMenu>
      </div>
      <div className={`w-full flex justify-between pt-3 mt-2 border-t ${plaster ? 'hidden' : ''}`}>
        <CostBreakdownExpandableMenu text='Drivers'>
          {savedCostBreakdown && savedCostBreakdown.submittedAt ?
            <CostBreakdownSubmittedDrivers
              savedCostBreakdown={savedCostBreakdown}
              employerTaxRate={employerTaxRate}
            />
            :
            <CostBreakdownDrivers
              drivers={drivers}
              breakdown={breakdown}
              setBreakdown={setBreakdown}
              employerTaxRate={employerTaxRate}
              savedCostBreakdown={savedCostBreakdown}
              resetToggle={resetToggle}
              DEFAULT_DRIVER={DEFAULT_DRIVER}
            />
          }
        </CostBreakdownExpandableMenu>
      </div>
      <div className='w-full flex justify-between pt-3 mt-2 border-t'>
        <CostBreakdownExpandableMenu text='Materials Used'>
          {savedCostBreakdown && savedCostBreakdown.submittedAt ?
            <CostBreakdownSubmittedMaterials
              plaster={plaster}
              savedCostBreakdown={savedCostBreakdown}
            />
            :
            <CostBreakdownMaterials
              batches={batches}
              setBatches={setBatches}
              breakdown={breakdown}
              setBreakdown={setBreakdown}
              plaster={plaster}
              savedCostBreakdown={savedCostBreakdown}
              resetToggle={resetToggle}
              DEFAULT_MATERIAL_GUNITE={DEFAULT_MATERIAL_GUNITE}
              DEFAULT_MATERIAL_PLASTER={DEFAULT_MATERIAL_PLASTER}
              batchIngredients={batchIngredients}
            />
          }
        </CostBreakdownExpandableMenu>
      </div>
      <div className='w-full flex justify-between pt-3 mt-2 border-t'>
        <CostBreakdownExpandableMenu text='Fuel'>
          {savedCostBreakdown && savedCostBreakdown.submittedAt ?
            <CostBreakdownSubmittedFuel
              savedCostBreakdown={savedCostBreakdown}
            />
            :
            <CostBreakdownFuel
              breakdown={breakdown}
              setBreakdown={setBreakdown}
              plaster={plaster}
              savedCostBreakdown={savedCostBreakdown}
              resetToggle={resetToggle}
              DEFAULT_FUEL_GUNITE={DEFAULT_FUEL_GUNITE}
              DEFAULT_FUEL_PLASTER={DEFAULT_FUEL_PLASTER}
              totalYards={totalYards}
            />
          }
        </CostBreakdownExpandableMenu>
      </div>
      <div className='w-full mt-2 border-t'>
        <CostBreakdownSummary
          breakdown={breakdown}
          plaster={plaster}
          savedCostBreakdown={savedCostBreakdown}
        />
      </div>

      {savedCostBreakdown && savedCostBreakdown.submittedAt ?
        <div className='flex flex-row w-full justify-end gap-4'>
          <button
            className='border border-navy-light p-1.5 text-dark-blue rounded hover:bg-dark-blue hover:text-white'
            onClick={() => {
              invoiceOrBreakdown.current = 'breakdown'
              setIsViewerOpen(!isViewerOpen)
            }}
          >View breakdown PDF</button>

          <button
            className='border border-navy-light p-1.5 text-dark-blue rounded hover:bg-dark-blue hover:text-white'
            onClick={() => {
              invoiceOrBreakdown.current = 'invoice'
              setIsViewerOpen(!isViewerOpen)
            }}
          >View invoice PDF</button>

          <button
            className='border border-navy-light p-1.5 text-dark-blue rounded hover:bg-dark-blue hover:text-white'
            onClick={() => {
              // Send request to make corrections/nullify submittedAt
              handleUnlockRequest()
            }}
          >Edit</button>
        </div>
        :
        <div className='flex flex-row w-full justify-between'>
          <button
            className='border border-gunite-red p-1.5 text-gunite-red rounded hover:bg-gunite-red hover:text-white active:bg-white active:text-gunite-red'
            onClick={() => {
              setShowResetWarning(true)
            }}
          >Reset</button>

          <div className='flex flex-row gap-4'>
            <button
              className={`border p-1.5 rounded ${activeButtons.save ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
              disabled={!activeButtons.save}
              onClick={() => {
                if (selectedJob.scheduledAt) {
                  handleSaveDraft()
                }
              }}
            >Save for later</button>

            <button
              className={`border p-1.5 rounded ${activeButtons.submit ? 'text-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue' : 'text-opaque-job-details-gray bg-disabled-gray'}`}
              disabled={!activeButtons.submit}
              onClick={() => {
                handleSubmit()
              }}
            >Submit</button>
          </div>
        </div>
      }

      {isViewerOpen ? (
        <CostBreakdownPDFViewer
          breakdown={savedCostBreakdown}
          selectedJob={selectedJob}
          isViewerOpen={isViewerOpen}
          setIsViewerOpen={setIsViewerOpen}
          invoiceOrBreakdown={invoiceOrBreakdown}
        />
      ) : (<></>)}

      <div className={`${showResetWarning ? '' : 'hidden'}`}>
        <CostBreakdownResetWarning
          header='Reset cost breakdown'
          description='If you continue, all values will be cleared.'
          onCancel={() => {
            setShowResetWarning(false)
          }}
          onContinue={() => {
            handleReset()
            setShowResetWarning(false)
          }}
        />
      </div>

      <div className={`${showCostBreakdownWarning.show ? '' : 'hidden'}`}>
        <CostBreakdownLeaveWarning
          header={"Leaving cost breakdown"}
          description='If you continue, you will lose your changes.'
          confirmButtonText={"Discard"}
          onCancel={() => {
            setShowCostBreakdownWarning({ show: false, continueFunction: {} })
          }}
          onContinue={() => {
            isCostBreakdownChange.current = false
            showCostBreakdownWarning.continueFunction()
            setShowCostBreakdownWarning({ show: false, continueFunction: {} })
          }}
          onSaveFirst={() => {
            isCostBreakdownChange.current = false
            if (selectedJob.scheduledAt) {
              handleSaveDraft()
            }
            showCostBreakdownWarning.continueFunction()
            setShowCostBreakdownWarning({ show: false, continueFunction: {} })
          }}
          activeButtons={activeButtons}
        />
      </div>

    </div>
  );
};

export default CostBreakdown;
