import React, { useEffect, useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useScheduler } from '../../contexts/scheduler';

const MobileNavOptions = ({ role }) => {
  const [focus, setFocus] = useState('planner');
  const { setShowMobileNav } = useScheduler();
  const { pathname } = useLocation(); // Get the current pathname directly

  useEffect(() => {
    // Update focus directly based on the exact pathname match
    setFocus(pathname);
  }, [pathname]);

  const handleLinkClick = () => {
    setShowMobileNav(false); // Close the mobile navigation on link click
  };

  const optionsData = [
    { name: 'Planner', route: `/${role}/planner`, iconPath: '/sidebar/planner_icon.svg', iconPathDisabled: '/sidebar/planner_icon_disabled.svg', width: '23px' },
    { name: 'Calendar', route: `/${role}/calendar`, iconPath: '/sidebar/calendar_icon.svg', iconPathDisabled: '/sidebar/calendar_icon_disabled.svg', width: '23px' },
    { name: 'Jobs List', route: `/${role}/jobsList`, iconPath: '/sidebar/jobs_list_icon.svg', iconPathDisabled: '/sidebar/jobs_list_icon_disabled.svg', width: '23px' },
    { name: 'Activity Log', route: `/${role}/activityLog`, iconPath: '/sidebar/activity_log_icon.svg', iconPathDisabled: '/sidebar/activity_log_icon_disabled.svg', width: '27px' },
    { name: 'Daily Job Log', route: `/${role}/dailyJobLog`, iconPath: '/sidebar/daily_job_log_icon.svg', iconPathDisabled: '/sidebar/daily_job_log_icon_disabled.svg', width: '24px' },
    { name: 'Files', route: `/${role}/files`, iconPath: '/sidebar/files_icon.svg', iconPathDisabled: '/sidebar/files_icon_disabled.svg', width: '24px' },
    { name: 'Customers', route: `/${role}/customers`, iconPath: '/sidebar/jobs_list_icon.svg', iconPathDisabled: '/sidebar/jobs_list_icon_disabled.svg', width: '24px' },
    { name: 'Account', route: `/${role}/account`, iconPath: '/sidebar/account_icon.svg', iconPathDisabled: '/sidebar/account_icon_disabled.svg', width: '25px' },
  ];

  if (role === 'admin') {
    optionsData.push({ name: 'Setup', route: `/${role}/setup/global`, iconPath: '/sidebar/setup_icon.svg', iconPathDisabled: '/sidebar/setup_icon_disabled.svg', width: '26px' });
  }

  return (
    <div className='flex flex-col h-fit justify-center items-start'>
      {optionsData.map((item) => (
        <NavLink
          to={item.route}
          onClick={handleLinkClick}
          key={item.name}
          className='flex w-full items-center py-[15px]'
          style={{ display: 'grid', gridTemplateColumns: '37px auto' }}
          activeclassname="text-white"
          exact="true"
        >
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div className='w-full flex justify-center items-center'>
              <img
                src={focus === item.route ? item.iconPath : item.iconPathDisabled} // Use focus to check active state
                alt={`${item.name} Icon`}
                style={{ width: '100%', maxWidth: item.width }}
              />
            </div>
          </div>
          <p className={`text-xl pl-8 ${focus === item.route ? 'text-white' : 'text-slate-400'}`}>{item.name}</p>
        </NavLink>
      ))}
    </div>
  );
};

export default MobileNavOptions;
