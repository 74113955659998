import UploadFileDropzone from '../UploadFileDropzone';
import { useAdminFiles } from '../../contexts/adminFiles';

const UploadFileModal = () => {
  const { onConfirmFileUpload, onCancelFileUpload, onDrop, uploadedFile, setUploadedFile, setUploadModalOpen } = useAdminFiles();

  return (
    <div className='fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center'>
      <div className={`absolute z-10 flex flex-col h-[370px] w-full sm:w-[600px] rounded-2xl border border-navy-light p-[20px] text-[14px] bg-navy-bright10 shadow-md justify-center items-center`}>
        <div className='w-full flex flex-row justify-between items-center'>
          <h1 className='text-[20px] font-[600]'>Upload File</h1>
          <div className='p-1 cursor-pointer'
            onClick={() => setUploadModalOpen(false)}
          >
            <img src='/JobDetails/close.svg' alt='close' className='h-[12px]' />
          </div>
        </div>
        <div className='w-full flex flex-row items-center mt-3'>
            <p className='text-dark-blue text-lg'>{uploadedFile.name}</p>
        </div>
        <UploadFileDropzone onDrop={onDrop} hasUploaded={uploadedFile.path? true : false}/>
        <div className="w-full flex justify-end">
          <button onClick={onCancelFileUpload} className="bg-white text-dark-blue rounded-md border border-gray-300 h-10 px-4 mr-2">
            Cancel
          </button>
          <button onClick={onConfirmFileUpload} 
              className={`bg-dark-blue text-white rounded-md h-10 px-4 ${!uploadedFile.path ? 'cursor-not-allowed' : ''}`}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  )
}

export default UploadFileModal;
