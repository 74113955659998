import React, { useState, useEffect } from "react";

const CostBreakdownSubmittedMaterials = ({ plaster, savedCostBreakdown }) => {

  const calculateTotal = () => {
    if (plaster) {
      const total = savedCostBreakdown?.material?.reduce((accumulator, item) => {
        const bags = Number(item.bags) || 0
        const costBag = Number(item.costBag) || 0
        return accumulator += (bags * costBag)
      }, 0)

      return Number(total * savedCostBreakdown.batches).toFixed(2)
    } else {
      const total = savedCostBreakdown.material.reduce((accumulator, item) => {
        const yards = item.yards ? Number(item.yards) : 0
        const costYard = item.costYard ? Number(item.costYard) : 0
        return accumulator += (yards * costYard)
      }, 0)

      return Number(total).toFixed(2)
    }
  }

  return plaster ? (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      <div className='hidden md:flex w-full text-opaque-job-details-gray'>
        <div className='w-5/12'>
          <p className='pl-7'>Name</p>
        </div>

        <div className='flex w-5/12'>
          <p className='flex justify-end w-1/2'>Bags</p>
          <p className='flex justify-end w-1/2'>Cost/Bag</p>
        </div>

        <div className='flex w-2/12'>
          <p className='flex justify-end w-full'>Total</p>
        </div>

        <div className='w-[32px]'></div>
      </div>

      {savedCostBreakdown?.material?.map((materialItem, i) => {
        return (
          <div
            className={`flex flex-col md:flex-row w-full py-4 items-center text-dark-blue ${i > 0 ? 'border-t' : ''}`}
            key={`material${i + 1}`}
          >
            <div className='flex w-full md:w-5/12'>
              <p className='flex pr-4 items-center text-opaque-job-details-gray'>{i + 1}.</p>
              <p className='flex justify-start w-full items-center text-ellipsis font-semibold'>{materialItem.name || ''}</p>
            </div>

            <div className='flex flex-col md:flex-row w-full md:w-5/12'>

              <div className='flex md:w-1/2 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Bags:</p>
                <p className='flex justify-end w-full  items-center'>
                  {materialItem.bags || ''}
                </p>
              </div>

              <div className='flex md:w-1/2 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Cost/Bag:</p>
                <p className='flex justify-end w-full  items-center'>
                  ${(Number(materialItem.costBag) || 0).toFixed(2)}
                </p>
              </div>

            </div>

            <div className='flex w-full md:w-2/12'>
              <div className='flex w-full md:border-none'>
                <p className='w-1/2 md:hidden'>Total:</p>
                <p className='flex justify-end w-full items-center'>
                  ${(Number(materialItem.bags) * Number(materialItem.costBag)).toFixed(2)}
                </p>
              </div>
            </div>

            <div className='w-[32px]'></div>

          </div>
        )
      })}

      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        <div className='flex flex-col md:flex-row w-full justify-end items-end text-sm text-dark-blue font-semibold'>
          <p className='flex md:w-1/2 md:pr-20'>
            Batches: {savedCostBreakdown.batches}
          </p>

          <div className='flex md:w-1/2 justify-end'>
            <p className='pr-8'>
              Total materials cost
            </p>
            <div className='flex justify-between w-[100px]'>
              <p>$</p>
              <p>{calculateTotal()}</p>
            </div>
          </div>

        </div>
      </div>

    </div>
  ) : (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      <div className='hidden md:flex w-full text-opaque-job-details-gray'>
        <div className='w-2/12'>
          <p className='pl-7'>Name</p>
        </div>

        <div className='flex w-8/12'>
          <p className='flex justify-end w-1/4'>Yards</p>
          <p className='flex justify-end w-1/4'>Tons</p>
          <p className='flex justify-end w-1/4'>Cost/Ton</p>
          <p className='flex justify-end w-1/4'>Cost/Yard</p>
        </div>

        <div className='flex w-2/12'>
          <p className='flex justify-end w-full'>Total</p>
        </div>

        <div className='w-[32px]'></div>
      </div>

      {savedCostBreakdown.material.map((materialItem, i) => {
        return (
          <div
            className={`flex flex-col md:flex-row w-full py-4 md:items-center text-dark-blue ${savedCostBreakdown.material.length === i + 1 ? '' : 'border-b'}`}
            key={`invoice${i + 1}`}
          >
            <div className='flex w-full md:w-2/12'>
              <p className='flex pr-4 items-center text-opaque-job-details-gray'>{i + 1}.</p>
              <p className='flex justify-start w-full items-center text-ellipsis font-semibold'>{materialItem.name || ''}</p>
            </div>

            <div className='flex flex-col md:flex-row w-full md:w-8/12'>

              <div className='flex md:w-1/4 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Yards:</p>
                <p className='flex justify-end w-full items-center'>
                  {materialItem.yards || ''}
                </p>
              </div>

              <div className='flex md:w-1/4 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Tons:</p>
                <p className='flex justify-end w-full items-center'>
                  {materialItem.tons || ''}
                </p>
              </div>

              <div className='flex md:w-1/4 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Cost/Ton:</p>
                <p className='flex justify-end w-full items-center'>
                  ${(materialItem.costTon ? Number(materialItem.costTon) : 0).toFixed(2)}
                </p>
              </div>

              <div className='flex md:w-1/4 border-b md:border-none'>
                <p className='w-1/2 md:hidden'>Cost/Yard:</p>
                <p className='flex justify-end w-full items-center'>
                  ${(materialItem.costYard ? Number(materialItem.costYard) : 0).toFixed(2)}
                </p>
              </div>

            </div>

            <div className='flex w-full md:w-2/12'>
              <div className='flex w-full md:border-none'>
                <p className='w-1/2 md:hidden'>Total:</p>
                <p className='flex justify-end w-full items-center'>
                  ${(Number(materialItem.yards) * Number(materialItem.costYard)).toFixed(2)}
                </p>
              </div>
            </div>

            <div className='w-[32px]'></div>

          </div>
        )
      })}

      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>

          <p className='w-fit pr-8'>
            Materials total
          </p>
          <div className='flex justify-end w-[100px]'>
            <p className='w-1/5 flex justify-end'>$</p>
            <p className='w-4/5 flex justify-end'>{calculateTotal()}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CostBreakdownSubmittedMaterials;
