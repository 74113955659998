import React, { useState, useEffect } from "react";
import CostBreakdownInput from "../CostBreakdownInput";
import { useScheduler } from "../../contexts/scheduler";

const CostBreakdownFuel = ({ breakdown, setBreakdown, plaster, savedCostBreakdown, resetToggle, DEFAULT_FUEL_GUNITE, DEFAULT_FUEL_PLASTER, totalYards = 0 }) => {
  const { selectedJobId } = useScheduler()
  const [vehiclesUsed, setVehiclesUsed] = useState(plaster ? DEFAULT_FUEL_PLASTER : DEFAULT_FUEL_GUNITE)
  const [fuelTotal, setFuelTotal] = useState(0)

  const updateState = (e, i, field) => {
    if (field === 'milesToJob') {
      const milesToJob = field === 'milesToJob' ? e.target.value : vehiclesUsed[i].milesToJob
      setVehiclesUsed([
        ...vehiclesUsed.slice(0, i),
        {
          ...vehiclesUsed[i],
          milesToJob: milesToJob,
        },
        ...vehiclesUsed.slice(i + 1),
      ])
    } else if (field === 'trips') {
      const trips = field === 'trips' ? e.target.value : vehiclesUsed[i].trips
      setVehiclesUsed([
        ...vehiclesUsed.slice(0, i),
        {
          ...vehiclesUsed[i],
          trips: trips,
        },
        ...vehiclesUsed.slice(i + 1),
      ])
    } else if (field === 'costGallon') {
      const costMile = e.target.value / vehiclesUsed[i].mpg
      setVehiclesUsed([
        ...vehiclesUsed.slice(0, i),
        {
          ...vehiclesUsed[i],
          costGallon: e.target.value,
          costMile: Number(costMile).toFixed(2)
        },
        ...vehiclesUsed.slice(i + 1),
      ])
    } else {
      setVehiclesUsed([
        ...vehiclesUsed.slice(0, i),
        {
          ...vehiclesUsed[i],
          [field]: e.target.value
        },
        ...vehiclesUsed.slice(i + 1),
      ])
    }
  }

  const calculateFuelTotal = () => {
    // math to calculate overall total
    const total = vehiclesUsed.reduce((accumulator, item) => {
      if (item.name === 'Compressor red') {
        const vehicleTotal = (item.yards || 0) * item.gallonsPerYard * item.costGallon

        return accumulator += vehicleTotal
      } else if (item.name === 'Mobile mixers') {
        const vehicleTotal = ((item.milesToJob * 2) * (Math.ceil(totalYards / 11))) * (item.costGallon / item.mpg)

        return accumulator += vehicleTotal
      } else {
        const vehicleTotal = ((item.milesToJob * 2) * item.trips) * (item.costGallon / item.mpg)

        return accumulator += vehicleTotal

      }
    }, 0)
    setFuelTotal(total)


    setBreakdown((breakdown) => {
      return {
        ...breakdown,
        fuel: {
          items: vehiclesUsed,
          total: total
        }
      }
    })
  }

  const calculateRowTotal = (row) => {
    // math to calculate row total
    if (row.name === 'Compressor red') {
      const total = (row.yards || 0) * row.gallonsPerYard * row.costGallon
      return total.toFixed(2)
    } else if (row.name === 'Mobile mixers') {
      const total = ((row.milesToJob * 2) * (Math.ceil(totalYards / 11))) * (row.costGallon / row.mpg)
      return total.toFixed(2)
    } else {
      const total = ((row.milesToJob * 2) * row.trips) * (row.costGallon / row.mpg)
      return total.toFixed(2)
    }
  }


  useEffect(() => {
    calculateFuelTotal()

    if (!plaster && totalYards !== 0 && Number(vehiclesUsed[1].trips) !== Math.ceil(Number(totalYards) / 11)) {
      // updating mobile mixer's trips to the job value
      updateState({ target: { value: (Math.ceil(totalYards / 11))}}, 1, 'trips')
    }
  }, [vehiclesUsed, breakdown.material, totalYards])

  useEffect(() => {
    if (savedCostBreakdown) {
      setVehiclesUsed(savedCostBreakdown.fuel)
    } else {
      const defaults = plaster ? DEFAULT_FUEL_PLASTER : DEFAULT_FUEL_GUNITE
      setVehiclesUsed(defaults)
    }
  }, [savedCostBreakdown, selectedJobId, resetToggle])

  return (
    <div className='flex flex-col w-full pt-4 justify-center align-center'>

      {vehiclesUsed.map((vehicle, i) => {
        return (
          <div
            key={`vehicle${i + 1}`}
            className={`w-full flex flex-col md:flex-row gap-4 items-end pt-2 mb-4 ${i !== 0 ? 'border-t' : ''}`}
          >
            <p className='pb-3 w-full md:w-2/12 text-dark-blue'>
              {vehicle.name}
            </p>

            {(['Mixer', 'Slurry Truck'].includes(vehicle.name)) &&
              <div className='flex flex-col md:flex-row items-end w-full md:w-8/12 gap-4'>

                <CostBreakdownInput
                  value={vehicle.trips}
                  placeholder='Trips to the job'
                  numbersOnly
                  onChange={(e) => {
                    updateState(e, i, 'trips')
                  }}
                />

                <CostBreakdownInput
                  value={vehicle.milesToJob}
                  placeholder='Miles (one way)'
                  numbersOnly
                  onChange={(e) => {
                    updateState(e, i, 'milesToJob')
                  }}
                />
                <CostBreakdownInput
                  value={Number(vehicle.costMile).toFixed(2)}
                  placeholder='Cost/Mile'
                  numbersOnly
                  disabled
                  onChange={(e) => {
                    updateState(e, i, 'costMile')
                  }}
                />
                <CostBreakdownInput
                  value={vehicle.costGallon}
                  placeholder='Cost/Gallon'
                  money
                  disabled
                  edit
                  onChange={(e) => {
                    updateState(e, i, 'costGallon')
                  }}
                />
              </div>
            }

            {(['Crew truck', 'Compressor clear'].includes(vehicle.name)) &&
              <div className='flex flex-col md:flex-row items-end w-full md:w-8/12 gap-4'>

                <div className='w-full'></div>

                <CostBreakdownInput
                  value={vehicle.milesToJob}
                  placeholder='Miles (one way)'
                  numbersOnly
                  onChange={(e) => {
                    updateState(e, i, 'milesToJob')
                  }}
                />
                <CostBreakdownInput
                  value={Number(vehicle.costMile).toFixed(2)}
                  placeholder='Cost/Mile'
                  numbersOnly
                  disabled
                  onChange={(e) => {
                    updateState(e, i, 'costMile')
                  }}
                />
                <CostBreakdownInput
                  value={vehicle.costGallon}
                  placeholder='Cost/Gallon'
                  money
                  disabled
                  edit
                  onChange={(e) => {
                    updateState(e, i, 'costGallon')
                  }}
                />
              </div>
            }

            {vehicle.name === 'Mobile mixers' &&
              <div className='flex flex-col md:flex-row items-end w-full md:w-8/12 gap-4'>
                <CostBreakdownInput
                  value={Math.ceil(totalYards / 11)}
                  placeholder='Trips to the job'
                  numbersOnly
                  disabled
                  onChange={(e) => {
                    updateState(e, i, 'trips')
                  }}
                />
                <CostBreakdownInput
                  value={vehicle.milesToJob}
                  placeholder='Miles (one way)'
                  numbersOnly
                  onChange={(e) => {
                    updateState(e, i, 'milesToJob')
                  }}
                />
                <CostBreakdownInput
                  value={Number(vehicle.costMile).toFixed(2)}
                  placeholder='Cost/Mile'
                  numbersOnly
                  disabled
                  onChange={(e) => {
                    updateState(e, i, 'costMile')
                  }}
                />
                <CostBreakdownInput
                  value={vehicle.costGallon}
                  placeholder='Cost/Gallon'
                  money
                  disabled
                  edit
                  onChange={(e) => {
                    updateState(e, i, 'costGallon')
                  }}
                />
              </div>
            }

            {vehicle.name === 'Compressor red' &&
              <div className='flex flex-col md:flex-row items-end w-full md:w-8/12 gap-4'>
                <CostBreakdownInput
                  value={vehicle.yards}
                  placeholder='Yards'
                  numbersOnly
                  onChange={(e) => {
                    updateState(e, i, 'yards')
                  }}
                />
                <CostBreakdownInput
                  value={vehicle.gallonsPerYard}
                  placeholder='Gallons/Yard'
                  numbersOnly
                  disabled
                  edit
                  onChange={(e) => {
                    updateState(e, i, 'gallonsPerYard')
                  }}
                />
                <CostBreakdownInput
                  value={vehicle.costGallon}
                  placeholder='Cost/Gallon'
                  money
                  disabled
                  edit
                  onChange={(e) => {
                    updateState(e, i, 'costGallon')
                  }}
                />
              </div>
            }

            <div className='block w-full md:w-2/12'>
              <p className='text-xs text-dark-blue pb-[2px] pt-[5px]'>Total</p>
              <div className='w-full px-3 py-2 rounded border border-tertiary-blue text-opaque-job-details-gray bg-disabled-gray'>
                ${calculateRowTotal(vehicle)}
              </div>
            </div>

          </div>
        )
      })}

      <div className='flex flex-col w-full rounded bg-card-bg-hover p-3 my-4 gap-2'>
        <div className='flex w-full justify-end text-sm text-dark-blue font-semibold'>
          <p className='w-fit pr-8'>
            Total fuel cost
          </p>
          <div className='flex justify-between w-[100px]'>
            <p>$</p>
            <p>{fuelTotal.toFixed(2)}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CostBreakdownFuel;
