import React, { useState } from "react";
import PlannerColumn from '../PlannerColumn'
import { useSchedulerPlanner } from '../../contexts/schedulerPlanner'
import './index.scss'
import { useScheduler } from "../../contexts/scheduler";


const Planner = () => {
  const { columnDetails } = useSchedulerPlanner()
  const { selectedScheduler } = useScheduler()
  const isPlaster = selectedScheduler?.location?.service === 'PLASTER'

  return (
    <>
      <div className='allColumnsWrapper flex lg:h-[calc(100vh-48px)] lg:w-[calc(100vw-80px)] flex-row min-h-[calc(720px-48px)] pl-[12px] py-[12px] bg-slate-100 gap-[12px] overflow-x-auto'>

        {columnDetails.map((column, i) => {
          return (
            <PlannerColumn
              column={column}
              i={i}
              key={`column${column.title}`}
              isPlaster={isPlaster}
            />
          )
        })}

      </div>
      <img
        id='spacer'
        className='hideSpacer'
        src='/PlannerColumn/job_card_spacer.svg'
        alt='spacer'
      />
    </>
  )
}

export default Planner;
