import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { useModal } from '../../contexts/modal';
import Input from "../Input";
import ForgotPasswordModal from "../ForgotPasswordModal";

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, isInvalidCreds, portalInactiveForAllUserLocations } = useAuth()
  const { modalMethods: { open, setContent }} = useModal()

  const emailError = email.length < 1
  const passwordError = password.length < 1
  return (
    <>
      <div>
        <p className="text-secondary-blue text-4xl font-bold">Welcome back</p>
        <p className="mt-1 text-secondary-blue/60 text-base font-semibold">Log in below</p>

      </div>

      <form
        className="flex flex-col gap-6"
        onSubmit={(e) => {
          e.preventDefault()
          login(email, password)
        }}
      >
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Email Address field */}
          <div className="relative lg:w-1/2 group">
            <Input
              value={email}
              placeholder={'Email Address *'}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              isError={emailError}
            />
          </div>

          {/* Password field */}
          <div className="relative lg:w-1/2 group">
            <Input
              value={password}
              placeholder={'Password *'}
              onChange={(e) => {
                setPassword(e.target.value)}}
              isError={passwordError}
              isPassword={true}
            />

          </div>
        </div>

        <div className={`flex items-center ${portalInactiveForAllUserLocations ? '' : 'hidden'}`}>
          <p className="text-gunite-red font-semibold">Portal is not active for your location.</p>
        </div>

        <div className={`flex items-center ${isInvalidCreds ? '' : 'hidden'}`}>
          <p className="text-gunite-red font-semibold">Incorrect email / password combination!</p>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {/* Log in button */}
            <div className="w-full lg:w-auto">
              <button type="submit" className="w-full lg:w-auto lg:px-9 lg:py-3 rounded p-3 bg-gunite-red text-white text-2xl font-bold">
                Log in
              </button>
            </div>

            {/* keep me logged in button: desktop only */}

            <input type="checkbox" id="stayLoggedIn" className="hidden ml-6 lg:inline-block h-5 w-5" />
            <label className="hidden lg:inline-block ml-2 font-medium text-lg" htmlFor="stayLoggedIn">Keep me logged in</label>

          </div>

          <div>
            <button
              className='text-tertiary-blue font-bold'
              onClick={(e) => {
                e.preventDefault()
                setContent(<ForgotPasswordModal/>)
                open()
              }}
            >
              Forgot password?
            </button>
          </div>
        </div>

      </form>

    </>
  )
}

export default SignIn;
