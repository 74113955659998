import JobDetailsDetailsTabReadable from "../JobDetailsDetailsTabReadable";
import JobDetailsDetailsTabEditable from "../JobDetailsDetailsTabEditable";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";

const JobDetailsDetailsTab = () => {
  const { detailsTabEditing, setDetailsTabEditing } = useJobDetailsModal();

  return (
    <div className="p-4 flex flex-col justify-center align-center">
      {!detailsTabEditing && (
        <>
          <button
            onClick={() => setDetailsTabEditing(true)}
            className="mt-[8px] p-[8px] py-[5px] rounded-[4px] bg-all-f-white border border-navy-light flex flex-row justify-center items-center w-full"
          >
            <img
              src="/JobDetails/edit.svg"
              alt="Edit"
              className="mr-2 w-[14px]"
            />
            <p className="text-[14px] font-[450] text-dark-blue select-none">
              Edit details
            </p>
          </button>
        </>
      )}

      {detailsTabEditing ? (
        <JobDetailsDetailsTabEditable />
      ) : (
        <JobDetailsDetailsTabReadable />
      )}

      {!detailsTabEditing && (
        <>
          <button
            onClick={() => setDetailsTabEditing(true)}
            className="mt-[8px] p-[8px] py-[5px] rounded-[4px] bg-all-f-white border border-navy-light flex flex-row justify-center items-center w-full"
          >
            <img
              src="/JobDetails/edit.svg"
              alt="Edit"
              className="mr-2 w-[14px]"
            />
            <p className="text-[14px] font-[450] text-dark-blue select-none">
              Edit details
            </p>
          </button>
        </>
      )}
    </div>
  );
};

export default JobDetailsDetailsTab;
