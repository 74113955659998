import React from 'react'
import PlannerTopBar from '../../../components/PlannerTopBar'
import SchedulerPlannerProvider from '../../../contexts/schedulerPlanner'
import Planner from '../../../components/Planner'
import JobDetails from '../../../components/JobDetails'


const PlannerView = () => {

  return (
    <SchedulerPlannerProvider>
      <div className='flex flex-col grow'>

        <PlannerTopBar />

        <Planner />

        <JobDetails />
      </div>
    </SchedulerPlannerProvider>
  )
}

export default PlannerView
