import { useState } from "react";
import PaginationFooterBar from '../PaginationFooterBar'
import PopupSearchBox from '../PopupSearchBox'
import { FaRegTrashAlt } from "react-icons/fa";
import ConfirmPopup from '../ConfirmPopup'
import { useAdmin } from "../../contexts/admin";
import AddButton from "../AddButton";
import AdminAddBranchModal from "../AdminAddBranchModal";

const AdminGlobalBranchesList = () => {

  const {
    allLocations,
    searchTerm,
    setSearchTerm,
    currentPage,
    setCurrentPage,
    RESULTS_PER_PAGE,
    displayItems,
    handleLocationClick,
    setShowAddBranchModal,
    showAddBranchModal,
    branchesSelectedForDeletion,
    setBranchesSelectedForDeletion,
    handleTrashClick,
    showConfirmDeletePopup,
    setShowConfirmDeletePopup,
    handleConfirmDelete,
    confirmDeleteHeader,
    confirmDeleteDescription
  } = useAdmin();

  const makeListData = () => {
    return (
      <div>
      <div className="flex py-2 px-3 border-navy-light bg-opacity-20 bg-navy-bright justify-between">
          <div className='flex flex-row w-1/2 items-center cursor-pointer select-none'>
            <p className='ml-6 text-gray-blue text-sm whitespace-nowrap'>Name</p>
          </div>
          <div className='flex flex-row w-1/2 ml-6 items-center cursor-pointer select-none' >
            <p className='text-gray-blue text-sm whitespace-nowrap'>Address</p>
          </div>
        </div>
        {displayItems.map((location, index) => (
          <div className="grid grid-cols-[1fr_1fr] gap-6 py-2 px-3 border-b border-navy-light" key={`location-list-${location.label}-${index}`}>
              <div className='flex flex-row items-center'>
                <input
                  type="checkbox"
                  className="mr-2 rounded checked:bg-dark-blue"
                  checked={branchesSelectedForDeletion.includes(location.id)}
                  onChange={() => handleCheckboxChange(location.id)}
                />
                <p className="cursor-pointer text-dark-blue font-medium text-lg"
                onClick={() => handleLocationClick(location)}>
                  {location.label}
                </p>
              </div>
              <div className='text-gray-blue'>{location.zipCode}</div>
          </div>
        ))}
      </div>
    )
  }


  const handleCheckboxChange = (fileUri) => {
    setBranchesSelectedForDeletion((prevSelectedIds) => {
      if (prevSelectedIds.includes(fileUri)) {
        return prevSelectedIds.filter((uri) => uri !== fileUri);
      } else {
        return [...prevSelectedIds, fileUri];
      }
    });
  };

  return (
    <div className="flex flex-col items-center w-full">
      <h1 className="text-xl w-full text-left font-semibold text-dark-blue">Branches list</h1>
      <div className='w-full border border-navy-light rounded-2xl my-6'>
        <div className="h-16 relative flex justify-between items-center px-6  rounded-t-xl border-b border-navy-light bg-opacity-20 bg-navy-bright">
          <h1 className="text-xl font-semibold text-dark-blue">Branches list</h1>
          <div className='flex flex-row items-center'>
            {branchesSelectedForDeletion.length > 0 && (
              <FaRegTrashAlt 
                onClick={handleTrashClick}
                size={20} 
                className="mr-3 cursor-pointer"
              />
            )}
            <PopupSearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </div>
        </div>
        {makeListData()}
        <PaginationFooterBar 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allItems={allLocations}
          resultsPerPage={RESULTS_PER_PAGE}
        />
      </div>
      <AddButton text={'Add new branch'} onClick={() => setShowAddBranchModal(true)}/>
      {showAddBranchModal &&
        <AdminAddBranchModal/>
      }
      {showConfirmDeletePopup && 
        <ConfirmPopup 
          header={confirmDeleteHeader} 
          description={confirmDeleteDescription} 
          confirmButtonText={"Delete"}
          onCancel={() => setShowConfirmDeletePopup(false)}
          onConfirm={handleConfirmDelete}
          className="top-[300px]"
        />
      }
    </div>
  );
}

export default AdminGlobalBranchesList;
