import React, { useState, useEffect } from "react";
import MiniCalendarDay from "../MiniCalendarDay";
import MiniCalendarDayRangeSelection from "../MiniCalendarDayRangeSelection";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";

const MiniCalendar = ({ selectedDays, setSelectedDays, isMultiDay, allowSelectPast = false, rangeSelection = false }) => {
  const { days, previousMonth, nextMonth, currentDay } = useJobDetailsModal()

  const [weekNumbers, setWeekNumbers] = useState([])

  const [mondays, setMondays] = useState([])
  const [tuesdays, setTuesdays] = useState([])
  const [wednesdays, setWednesdays] = useState([])
  const [thursdays, setTursdays] = useState([])
  const [fridays, setFridays] = useState([])
  const [saturdays, setSaturdays] = useState([])

  const monthsKey = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const sortDays = () => {
    clearDays()

    const mo = []
    const tu = []
    const we = []
    const th = []
    const fr = []
    const sa = []
    const weekDays = ['skip', mo, tu, we, th, fr, sa]

    // inserts blank days for months that don't start on a monday
    const numberOfBlanks = days[0].getUTCDay() - 1;
    for (let i = 1; i < numberOfBlanks + 1; i++) {
      const blankDay = (
        <MiniCalendarDay blank key={`blank${i}`} allowSelectPast={allowSelectPast}/>
      )
      weekDays[i].push(blankDay)
    }

    const newDate = new Date()
    const today = {
      day: newDate.getDate(),
      month: newDate.getMonth(),
      year: newDate.getFullYear(),
    }

    days.forEach((day, i) => {
      const date = {
        day: day.getUTCDate(),
        month: day.getUTCMonth(),
        year: day.getUTCFullYear(),
      }
      let calendarDayComponent

      if (rangeSelection) {
        calendarDayComponent = (
          <MiniCalendarDayRangeSelection
            key={`day-${date.month}-${date.day}-${Date.now()}`}
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            dateInfo={date}
            isMultiDay={isMultiDay}
            today={today}
            data-date={date}
            allowSelectPast={allowSelectPast}
          />
        )
      } else {
        calendarDayComponent = (
        <MiniCalendarDay
          key={`day-${date.month}-${date.day}-${Date.now()}`}
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
          dateInfo={date}
          isMultiDay={isMultiDay}
          today={today}
          data-date={date}
          allowSelectPast={allowSelectPast}
        />
      )
    }
      // skipping sunday, push each day into the proper weekDay list
      const dayOfTheWeek = day.getUTCDay()
      if (dayOfTheWeek !== 0) {
        weekDays[dayOfTheWeek].push(calendarDayComponent)
      }
    })

    getWeekNumbers()

    setMondays(mo)
    setTuesdays(tu)
    setWednesdays(we)
    setTursdays(th)
    setFridays(fr)
    setSaturdays(sa)
  }

  const clearDays = () => {
    mondays.current = []
    tuesdays.current = []
    wednesdays.current = []
    thursdays.current = []
    fridays.current = []
    saturdays.current = []
  }

  // given a date, determines the week number
  function getWeek(date) {
    date = date instanceof Date ? new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) : new Date()

    // ISO week date weeks start on Monday, so correct the day number
    var nDay = (date.getDay() + 6) % 7;

    // ISO 8601 states that week 1 is the week with the first Thursday of that year
    // Set the target date to the Thursday in the target week
    date.setDate(date.getDate() - nDay + 3);

    // Store the millisecond value of the target date
    var n1stThursday = date.valueOf();

    // Set the target to the first Thursday of the year
    // First, set the target to January 1st
    date.setMonth(0, 1);

    // Not a Thursday? Correct the date to the next Thursday
    if (date.getDay() !== 4) {
      date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
    }

    // The week number is the number of weeks between the first Thursday of the year
    // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
    return 1 + Math.ceil((n1stThursday - date) / 604800000);
  }

  const getWeekNumbers = () => {
    const numbers = []
    const firstWeek = getWeek(days[0])
    const lastWeek = getWeek(days[days.length - 1])
    for (let i = firstWeek; i <= lastWeek; i++) {
      numbers.push(
        <p
          className='flex justify-center items-center w-[34px] h-[24px] font-bold text-xs text-dark-blue' key={`day${i}`}
        >{i}</p>
      )
    }
    setWeekNumbers(numbers)
  }

  useEffect(() => {
    sortDays()
  }, [selectedDays, currentDay, isMultiDay, days])

  return (
    <>
      <div className='flex justify-between items-center text-dark-blue font-bold'>
        <img
          className='rounded-2xl h-[28px] w-[28px]'
          alt="calendar icon"
          src='/vectors/Calendar_left_chevron.svg'
          onClick={() => {
            previousMonth()
          }}
        />
        <p className=''>
          {`${monthsKey[days[0].getUTCMonth()]} ${days[0].getUTCFullYear()}`}
        </p>
        <img
          className='rounded-2xl h-[28px] w-[28px]'
          alt="calendar icon"
          src='/vectors/Calendar_right_chevron.svg'
          onClick={() => {
            nextMonth()
          }}
        />
      </div>

      <div className='flex justify-center w-full  pb-3 pt-2'>
        <div className='felx flex-col w-[1/7] items-center bg-gray-light rounded'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >W</p>
          {weekNumbers.map((num) => {
            return num
          })}
        </div>
        <div className='felx flex-col w-[1/7] items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Mo</p>
          {mondays.map((day) => {
            return day
          })}
        </div>
        <div className='felx flex-col w-[1/7] items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Tu</p>
          {tuesdays.map((day) => {
            return day
          })}
        </div>
        <div className='felx flex-col w-[1/7] items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >We</p>
          {wednesdays.map((day) => {
            return day
          })}
        </div>
        <div className='felx flex-col w-[1/7] items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Th</p>
          {thursdays.map((day) => {
            return day
          })}
        </div>
        <div className='felx flex-col w-[1/7] items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Fr</p>
          {fridays.map((day) => {
            return day
          })}
        </div>
        <div className='felx flex-col w-[1/7] items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Sa</p>
          {saturdays.map((day) => {
            return day
          })}
        </div>
      </div>

    </>
  )
}

export default MiniCalendar;
