import React, { useState, useEffect } from 'react'
import { useSchedulerJobLog } from '../../contexts/schedulerJobLog'
import SchedulerJobLogDateSelectorDay from '../SchedulerJobLogDateSelectorDay'

const SchedulerJobLogDateSelector = ({ askIfContinueDate }) => {
  const { days, previousMonth, nextMonth, currentDay, selectedDay, setSelectedDay } = useSchedulerJobLog()

  const [weekNumbers, setWeekNumbers] = useState([])

  const [mondays, setMondays] = useState([])
  const [tuesdays, setTuesdays] = useState([])
  const [wednesdays, setWednesdays] = useState([])
  const [thursdays, setTursdays] = useState([])
  const [fridays, setFridays] = useState([])
  const [saturdays, setSaturdays] = useState([])

  const monthsKey = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const sortDays = () => {
    clearDays()

    const mo = []
    const tu = []
    const we = []
    const th = []
    const fr = []
    const sa = []
    const weekDays = ['skip', mo, tu, we, th, fr, sa]

    // inserts blank days for months that don't start on a monday
    const numberOfBlanks = days[0].getDay() - 1;
    for (let i = 1; i < numberOfBlanks + 1; i++) {
      const blankDay = (
        <SchedulerJobLogDateSelectorDay blank key={`blank${i}`} allowSelectPast={true}/>
      )
      weekDays[i].push(blankDay)
    }

    const newDate = new Date()
    const today = {
      day: newDate.getDate(),
      month: newDate.getMonth(),
      year: newDate.getFullYear(),
    }

    days.forEach((day, i) => {
      const date = {
        day: day.getDate(),
        month: day.getMonth(),
        year: day.getFullYear(),
      }
      let calendarDayComponent


        calendarDayComponent = (
        <SchedulerJobLogDateSelectorDay
          key={`day-${date.month}-${date.day}-${Date.now()}`}
          selectedDays={[selectedDay]}
          setSelectedDays={setSelectedDay}
          dateInfo={date}
          isMultiDay={false}
          today={today}
          data-date={date}
          allowSelectPast={true}
          askIfContinueDate={askIfContinueDate}
        />
      )
      // skipping sunday, push each day into the proper weekDay list
      const dayOfTheWeek = day.getDay()
      if (dayOfTheWeek !== 0) {
        weekDays[dayOfTheWeek].push(calendarDayComponent)
      }
    })

    getWeekNumbers()

    setMondays(mo)
    setTuesdays(tu)
    setWednesdays(we)
    setTursdays(th)
    setFridays(fr)
    setSaturdays(sa)
  }

  const clearDays = () => {
    mondays.current = []
    tuesdays.current = []
    wednesdays.current = []
    thursdays.current = []
    fridays.current = []
    saturdays.current = []
  }

  // given a date, determines the week number
  function getWeek(date) {
    date = date instanceof Date ? new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) : new Date()

    // ISO week date weeks start on Monday, so correct the day number
    var nDay = (date.getDay() + 6) % 7;

    // ISO 8601 states that week 1 is the week with the first Thursday of that year
    // Set the target date to the Thursday in the target week
    date.setDate(date.getDate() - nDay + 3);

    // Store the millisecond value of the target date
    var n1stThursday = date.valueOf();

    // Set the target to the first Thursday of the year
    // First, set the target to January 1st
    date.setMonth(0, 1);

    // Not a Thursday? Correct the date to the next Thursday
    if (date.getDay() !== 4) {
      date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
    }

    // The week number is the number of weeks between the first Thursday of the year
    // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
    return 1 + Math.ceil((n1stThursday - date) / 604800000);
  }

  const getWeekNumbers = () => {
    const numbers = []
    const firstWeek = getWeek(days[0])
    const lastWeek = getWeek(days[days.length - 1])
    for (let i = firstWeek; i <= lastWeek; i++) {
      numbers.push(
        <p
          className='flex justify-center items-center w-[34px] h-[24px] font-bold text-xs text-dark-blue' key={`day${i}`}
        >{i}</p>
      )
    }
    setWeekNumbers(numbers)
  }

  useEffect(() => {
    sortDays()
  }, [selectedDay, currentDay, days])

  return (
    <div className='contents lg:inline lg:bg-white'>
      <div className='flex justify-between items-center text-dark-blue font-bold bg-white'>
        <img
          className='rounded-2xl h-[28px] w-[28px]'
          alt="calendar icon"
          src='/vectors/Calendar_left_chevron.svg'
          onClick={() => {
            previousMonth()
          }}
        />
        <p className=''>
          {`${monthsKey[days[0].getMonth()]} ${days[0].getFullYear()}`}
        </p>
        <img
          className='rounded-2xl h-[28px] w-[28px]'
          alt="calendar icon"
          src='/vectors/Calendar_right_chevron.svg'
          onClick={() => {
            nextMonth()
          }}
        />
      </div>

      <div className='flex justify-center w-full  pb-3 pt-2 bg-white'>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Mo</p>
          {mondays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Tu</p>
          {tuesdays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >We</p>
          {wednesdays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Th</p>
          {thursdays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Fr</p>
          {fridays}
        </div>
        <div className='felx flex-col w-1/6 items-center'>
          <p className='flex w-full justify-center pt-1 text-xs text-mini-calendar-gray' >Sa</p>
          {saturdays}
        </div>
      </div>

    </div>
  )
}

export default SchedulerJobLogDateSelector
