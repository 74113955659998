import React, { useState, useEffect, useRef } from "react";

const DropdownCheckbox = ({ columnDetails, title }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownWrapper = useRef()

  const handleClick = (e) => {
    if (!dropdownWrapper.current.contains(e.target)) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleClick)
    return () => {
      document.body.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div
      className='relative'
      ref={dropdownWrapper}
    >
      <button
        className='flex items-center justify-center h-[40px] w-[40px] lg:h-full lg:w-fit p-[8px] lg:pl-[12px] lg:pr-[8px] lg:py-[5px] rounded-[6px] lg:rounded-[4px] bg-all-f-white border border-navy-light'
        onClick={(e) => {
          setShowDropdown(!showDropdown)
        }}
      >
        <p className='text-[14px] font-[600] select-none hidden lg:block'>
          {title}
        </p>

        <img src={showDropdown ? '/show_less_arrow.svg' : '/show_more_arrow.svg'} alt='show filters' className="lg:pl-[6px] h-[20px] hidden lg:block" />

        <img src='/filter_icon.svg' alt='show filters' className="px-[1px] h-[15px] lg:hidden" />

      </button>

      <div className={`absolute top-11 -left-20 z-10 lg:left-0 flex flex-col h-fit w-[190px] rounded-[8px] border border-navy-light p-[8px] text-[14px] bg-all-f-white shadow-md ${showDropdown ? '' : 'hidden'}`}>

        {[...columnDetails].map((columnInfo, i) => (
          <button
            key={`option${i}`}
            className='flex w-full items-center p-[8px]'
            onClick={(e) => {
              columnInfo.setter(!columnInfo.hide)
            }}
          >

            <div className={`flex w-[16px] h-[16px] rounded-[4px] select-none ${columnInfo.hide ? 'bg-all-f-white border' : 'bg-dark-blue'}`}>
              <img src='/check.svg' alt='check' className="w-full" />
            </div>

            <p className='pl-[12px] select-none'>
              {columnInfo.title}
            </p>

          </button>
        ))}

      </div>

    </div>
  )
}

export default DropdownCheckbox;
