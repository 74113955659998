/* external imports */
import React, { useRef, createContext, useContext, useState, useEffect } from 'react';
/* internal imports */
import { useAdmin } from './admin';
import { useAdminMaterials } from './adminMaterials';
import serviceMethods from '../service';

const AdminBranchMaterials = createContext();

const AdminBranchMaterialsProvider = ({ children }) => {
  const [editItemModal, setEditItemModal] = useState({open: false, tableName: 'Tier', itemData: {}});
  const [branchMaterialsSourceData, setBranchMaterialsSourceData] = useState([]);

  const { allSourceData } = useAdminMaterials();

  const { getLocationIdFromUrl } = useAdmin();


  const getBranchMaterialsSourceData = async () => {
    if (allSourceData.length === 0) {
      return;
    }
    const locationId = parseInt(getLocationIdFromUrl())
    if (!locationId) { return }
    const res = await serviceMethods.getLocationMaterials(locationId);
    const locationMaterials = await res.json();

    // change the name of the manufacturerId, productId, etc. to manufacturerIdLocationRelation, productIdLocationRelation, etc.
    // to avoid confusion with the original manufacturerId, productId, etc.
    locationMaterials.locationManufacturers = locationMaterials.locationManufacturers.map(item => {
      const { manufacturerId, id, ...rest } = item;
      return { ...rest, manufacturerIdLocationRelation: manufacturerId };
    });

    locationMaterials.locationProducts = locationMaterials.locationProducts.map(item => {
      const { productId, id, ...rest } = item;
      return { ...rest, productIdLocationRelation: productId };
    });
  
    locationMaterials.locationTiers = locationMaterials.locationTiers.map(item => {
      const { tierId, id, ...rest } = item;
      return { ...rest, tierIdLocationRelation: tierId };
    });
  
    locationMaterials.locationColors = locationMaterials.locationColors.map(item => {
      const { colorId, id, ...rest } = item;
      return { ...rest, colorIdLocationRelation: colorId };
    });
  
    let branchMaterialsSourceData = allSourceData.map(manufacturer => {
      let updatedManufacturer = { ...manufacturer };

      for (const locationManufacturer of locationMaterials.locationManufacturers) {
        if (locationManufacturer.manufacturerIdLocationRelation === manufacturer.id) {
          updatedManufacturer = {
            ...manufacturer,
            ...locationManufacturer,
          };
        }
      }

      // Update products
      updatedManufacturer.product = updatedManufacturer.product.map(product => {
        let updatedProduct = { ...product };

        for (const locationProduct of locationMaterials.locationProducts) {
          if (locationProduct.productIdLocationRelation === product.id) {
            updatedProduct = {
              ...product,
              ...locationProduct,
            };
            
            if (locationProduct.minimumTotalIA !== null) {
              updatedProduct.minimumTotalIA = locationProduct.minimumTotalIA;
            }
            if (locationProduct.minimumPrice !== null) {
              updatedProduct.minimumPrice = locationProduct.minimumPrice;
            }
            if (locationProduct.totalIASmall !== null) {
              updatedProduct.totalIASmall = locationProduct.totalIASmall;
            }
            if (locationProduct.totalIALarge !== null) {
              updatedProduct.totalIALarge = locationProduct.totalIALarge;
            }
            if (locationProduct.spaAdd !== null) {
              updatedProduct.spaAdd = locationProduct.spaAdd;
            }
          }
        }

        // Update tiers
        updatedProduct.tier = updatedProduct.tier.map(tier => {
          let updatedTier = { ...tier };

          for (const locationTier of locationMaterials.locationTiers) {
            if (locationTier.tierIdLocationRelation === tier.id) {
              updatedTier = {
                ...tier,
                ...locationTier,
              };
            }
          }

          // Update colors
          updatedTier.color = updatedTier.color.map(color => {
            let updatedColor = { ...color };

            for (const locationColor of locationMaterials.locationColors) {
              if (locationColor.colorIdLocationRelation === color.id) {
                updatedColor = {
                  ...color,
                  ...locationColor,
                };
              }
            }

            return updatedColor;
          });

          return updatedTier;
        });

        return updatedProduct;
      });

      return updatedManufacturer;
    });

    setBranchMaterialsSourceData(branchMaterialsSourceData);
  };

  useEffect(() => {
    getBranchMaterialsSourceData();
  }, [allSourceData, getLocationIdFromUrl]);


  const handleSubmitEdit = async (formData) => {
    const tableName = getTableName(editItemModal.itemData);
    const locationId = parseInt(getLocationIdFromUrl())

    await serviceMethods.upsertLocationMaterialsSubcategoryItem({
      tableName: tableName,
      locationId: locationId,
      subcategoryId: editItemModal.itemData.id,
      data: {
        ...formData
      }
    })

    getBranchMaterialsSourceData();
  }

  const handleCheckboxChange = async (e, itemData, tableName) => {
    const locationId = parseInt(getLocationIdFromUrl())

    const unchecked = !e.target.checked;
  
    //run route to update checkboxes.
    await serviceMethods.upsertCheckboxChange({
      tableName: tableName,
      locationId: locationId,
      unchecked: unchecked,
      clickedItem: itemData,
    })

    getBranchMaterialsSourceData();
  }

  const getTableName = (itemData) => {
    if (itemData.tierId !== undefined) {
      return 'Color'
    } else if (itemData.productId !== undefined) {
      return 'Tier'
    } else if (itemData.manufacturerId !== undefined) {
      return 'Product'
    } else {
      return 'Manufacturer'
    }
  }
 
  return (
    <AdminBranchMaterials.Provider
      value={{
        editItemModal,
        setEditItemModal,
        handleSubmitEdit,
        handleCheckboxChange,
        getTableName,
        branchMaterialsSourceData,
      }}
    >
      {children}
    </AdminBranchMaterials.Provider>
  );
};

const useAdminBranchMaterials = () => useContext(AdminBranchMaterials);

export { useAdminBranchMaterials };
export default AdminBranchMaterialsProvider;
