import React from 'react'
import { Routes, Route } from 'react-router-dom'
import CustomerMobileTopPane from '../../../components/CustomerMobileTopPane'
import TopBar from '../../../components/TopBar'
import Footer from '../../../components/Footer'
import CustomerMyJobsView from '../CustomerMyJobsView'
import CustomerDraftsView from '../CustomerDraftsView'
import CustomerMyJobsContextProvider from '../../../contexts/customerMyJobs'
import CustomerDraftsContextProvider from '../../../contexts/customerDrafts'
import Account from '../Account'
import JobDetails from '../JobDetails'
import UpdatePassword from '../UpdatePassword'


const CustomerLayout = () => {
  return (
    <div className='flex flex-col'>
      <CustomerMyJobsContextProvider>
        <CustomerDraftsContextProvider>
          <TopBar />
          <CustomerMobileTopPane />
          <Routes>
            <Route exact={true} path='/myjobs' element={<CustomerMyJobsView />} />
            <Route exact={true} path='/drafts' element={<CustomerDraftsView />} />
            <Route exact={true} path='/account' element={<Account />} />
            <Route exact={true} path='/update-password' element={<UpdatePassword />} />
            <Route exact={true} path='/job-details' element={<JobDetails />} />
          </Routes>
          <Footer />
        </CustomerDraftsContextProvider>
      </CustomerMyJobsContextProvider>
    </div>
  )
}

export default CustomerLayout
